import React, { useState } from 'react';
import { usePostRatingsMutation } from 'api';
import Grid from '@mui/material/Grid';
import { Rating } from 'react-simple-star-rating';
import { useApiResponseMessage } from 'hooks';
import CheckboxNew from 'components/CheckboxNew';
import { Button, TextLink } from 'components/Buttons';
import TextArea from 'components/TextArea';
import Loading from 'components/Loading';
import StandardModal from 'components/StandardModal';
import FormContainer from 'components/FormContainer/FormContainer';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/leftArrowIcon.svg';
import { COLORS } from 'style/colors';
import styles from './ratingModal.module.scss';

type RatingProps = {
  orderId: string;
  targetName: string;
  closeRatingModal: () => any;
  nestedModal?: boolean; // default is true
};

function RatingModal({
  orderId,
  targetName,
  closeRatingModal,
  nestedModal = true,
}: RatingProps) {
  const { apiResponseMessage } = useApiResponseMessage();

  const [userRating, setUserRating] = useState(0);
  const [userReviewText, setUserReviewText] = useState('');
  const [yardRating, setYardRating] = useState(0);
  const [yardReviewText, setYardReviewText] = useState('');
  const [followUp, setFollowUp] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [saveRatings] = usePostRatingsMutation();

  const convertRatings = (rating: number) => {
    let starCount = 0;

    if (rating === 100) {
      starCount = 5;
    } else if (rating === 80) {
      starCount = 4;
    } else if (rating === 60) {
      starCount = 3;
    } else if (rating === 40) {
      starCount = 2;
    } else if (rating === 20) {
      starCount = 1;
    }

    return starCount;
  };

  const handleSaveRatings = async () => {
    try {
      setIsLoading(true);

      const rating = {
        orderId,
        rating: convertRatings(userRating),
        reviewText: userReviewText,
        ...(yardRating > 0 && { yardRating: convertRatings(yardRating) }),
        yardReviewText,
        yardCanFollowUp: followUp,
      };

      const payload: any = await saveRatings({
        rating,
      }).unwrap();

      if (payload.success) {
        apiResponseMessage('Rating submitted');

        closeRatingModal();
      }
    } catch (err: any) {
      setIsLoading(false);
      const errorMessages = err.data?.data;
      let displayedError = 'An error occurred, please try again';

      if (errorMessages.length > 0) {
        const messages = errorMessages.map((error: any) => error.msg);
        displayedError = messages.join('. ');
      }

      apiResponseMessage(displayedError, 6000);
    }
  };

  const content = () => (
    <Grid container>
      {isLoading && <Loading screenOverlay />}
      <Grid container className={styles.headerWrapper}>
        <Grid item xs={8}>
          Rate Your Order
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.formsWrapper}>
        <FormContainer
          className={styles.formContainer}
          title={`How did ${targetName} do?`}
          content={
            <>
              <Rating
                onClick={(rating: number) => setUserRating(rating)}
                ratingValue={userRating}
                fillColor={COLORS.sand}
              />
              <TextArea
                placeholder="Tell us more"
                value={userReviewText}
                onChange={(e) => setUserReviewText(e.target.value)}
                className={styles.textArea}
              />
            </>
          }
        />
        <FormContainer
          className={styles.formContainer}
          title="How would you rate Check The Yard?"
          content={
            <>
              <Rating
                onClick={(rating: number) => setYardRating(rating)}
                ratingValue={yardRating}
                fillColor={COLORS.sand}
              />
              <TextArea
                placeholder="Tell us more"
                value={yardReviewText}
                onChange={(e) => setYardReviewText(e.target.value)}
                className={styles.textArea}
              />
            </>
          }
        />
      </Grid>
      <Grid container justifyContent="center">
        <CheckboxNew
          label="Are you ok if we follow up with you?"
          checked={followUp}
          onChange={() => setFollowUp(!followUp)}
        />
        <Grid item xs={9}>
          <Button
            text="SUBMIT RATING"
            onClick={handleSaveRatings}
            buttonColor="sand20"
            containerClassName={styles.saveButtonContainer}
            buttonClassName={styles.saveButton}
          />
        </Grid>
        <TextLink className={styles.backButton} onClick={closeRatingModal}>
          <LeftArrowIcon />
          Cancel
        </TextLink>
      </Grid>
    </Grid>
  );

  return (
    <StandardModal
      modalContent={content()}
      closeModalAction={closeRatingModal}
      fullScreenModal={false}
      nestedModal={nestedModal}
      modalClassName={styles.modal}
      buttonContainerClassName={styles.modalButton}
    />
  );
}

export default RatingModal;
