import React from 'react';
import Page from 'components/Page';
import { PageContentType } from 'components/Page/types';
import image from 'assets/images/bigDLogo.png';

function TestPage() {
  const samplePage: PageContentType = {
    heading: 'This is a Header',
    heroImage: {
      src: image,
      alt: 'This is some alt text',
    },
    sections: [
      {
        id: 'subheader1',
        type: 'subheader',
        text: 'Look at this secondary content.',
      },
      {
        id: 'text1',
        type: 'text',
        partial: true,
        text: 'A successful marketing plan relies heavily on the pulling-power of advertising copy. Writing result-oriented ad copy is difficult, as it must appeal to, entice, and convince consumers to take action. There is no magic formula to write perfect ad copy; it is based on a number of factors, including ad placement, demographic, even the consumer’s mood when they see your ad. So how is any writer supposed to pen a stunning piece of advertising copy — copy that sizzles and sells? The following tips will jumpstart your creative thinking and help you write a better ad.',
      },
      {
        id: 'text2',
        type: 'text',
        partial: true,
        text: [
          <b>Grabs Attention: Consumers are inundated with ads</b>,
          ', so it’s vital that your ad catches the eye and immediately grabs interest. You could do this with a headline or slogan (such as VW’s “Drivers Wanted” campaign), color or layout (Target’s new colorful, simple ads are a testimony to this) or illustration (such as the Red Bull characters or Zoloft’s depressed ball and his ladybug friend). Promises Credible Benefit: To feel compelled by an ad, the consumer must stand to gain something; the product is often not enough. What would the consumer gain by using your product or service? This could be tangible, like a free gift; prestige, power or fame. But remember: you must be able to make good on that promise, so don’t offer anything unreasonable.',
        ],
      },
      {
        id: 'text3',
        type: 'text',
        text: 'Consumers are inundated with ads, so it’s vital that your ad catches the eye and immediately grabs interest. You could do this with a headline or slogan (such as VW’s “Drivers Wanted” campaign), color or layout (Target’s new colorful, simple ads are a testimony to this) or illustration (such as the Red Bull characters or Zoloft’s depressed ball and his ladybug friend). Promises Credible Benefit: To feel compelled by an ad, the consumer must stand to gain something; the product is often not enough. What would the consumer gain by using your product or service? This could be tangible, like a free gift; prestige, power or fame. But remember: you must be able to make good on that promise, so don’t offer anything unreasonable.',
        image: {
          src: image,
          alt: 'This is some alt text',
          align: 'right',
        },
      },
      {
        id: 'text4',
        type: 'text',
        text: 'Consumers are inundated with ads, so it’s vital that your ad catches the eye and immediately grabs interest. You could do this with a headline or slogan (such as VW’s “Drivers Wanted” campaign), color or layout (Target’s new colorful, simple ads are a testimony to this) or illustration (such as the Red Bull characters or Zoloft’s depressed ball and his ladybug friend). Promises Credible Benefit: To feel compelled by an ad, the consumer must stand to gain something; the product is often not enough. What would the consumer gain by using your product or service? This could be tangible, like a free gift; prestige, power or fame. But remember: you must be able to make good on that promise, so don’t offer anything unreasonable.',
        image: {
          src: image,
          alt: 'This is some alt text',
          align: 'right',
          caption: "Captions don't have to be alt text.",
        },
      },
      {
        id: 'callout1',
        type: 'callout',
        text: [
          <b>Look at this</b>,
          " large pull quote or something. You're impressed.",
        ],
      },
    ],
  };

  return <Page content={samplePage} />;
}

export default TestPage;
