import { useAppSelector } from 'hooks';
import { selectIsLoggedIn } from 'slices/accountSlice';

/**
 * Hook to provide identity-related variables to components.
 * Right now, it only provides `loggedIn`, but eventually it could
 * be extended to provide `user`, etc.
 */
const useIdentity = () => {
  const loggedIn = useAppSelector((state) => selectIsLoggedIn(state.account));
  const stripeAccountVerified = useAppSelector(
    (state) => state.account.stripeAccountVerified,
  );
  const businessName = useAppSelector((state) => state.account.businessName);
  const userAccountType = useAppSelector((state) => state.account.accountType);
  const userId = useAppSelector(
    (state) => state.account.credentials?.userId || '',
  );
  return {
    userId,
    loggedIn,
    businessName,
    userAccountType,
    stripeAccountVerified,
  };
};

export default useIdentity;
