import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetConfigQuery } from 'api';
import { useGetUserDetails } from 'hooks';
import Grid from '@mui/material/Grid';
import TextLink from 'components/Links/TextLink';
import logo from 'assets/yardLogos/checkTheYardOnDark.svg';
import styles from './footer.module.scss';

const appVersion = require('../../../package.json').version;

type ListObj = {
  name: string;
  path: string;
  openInNewTab?: boolean;
};

function HomeFooter() {
  const navigate = useNavigate();
  const getUserResponse = useGetUserDetails();
  const { data: config } = useGetConfigQuery();
  const { API_VERSION, RELEASE_VERSION = 'Not Set' } = config?.data || {};
  const [adminUser, setAdminUser] = useState(false);

  useEffect(() => {
    if (getUserResponse?.success) {
      const { user } = getUserResponse.data;
      const isAdmin = user?.role?.toLowerCase() === 'admin' || false;
      setAdminUser(isAdmin);
    } else if (adminUser) {
      setAdminUser(false);
    }
  }, [getUserResponse, adminUser]);

  const buyingList: ListObj[] = [
    {
      name: 'Concrete',
      path: '/shopping/concrete',
    },
    {
      name: 'Electrical',
      path: '/shopping/electrical',
    },
    {
      name: 'Furnishings',
      path: '/shopping/furnishings',
    },
    {
      name: 'Specialties',
      path: '/shopping/specialties',
    },
  ];

  const sellingList: ListObj[] = [
    {
      name: 'See My Listings',
      path: '/selling/my_yard_listings',
    },
    {
      name: 'View Orders',
      path: '/orders',
    },
    {
      name: 'How to Sell',
      path: '/interested_in_selling',
    },
  ];

  const resourcesList: ListObj[] = [
    {
      name: 'Terms and Conditions',
      path: '/terms_and_conditions',
      openInNewTab: true,
    },
    {
      name: 'Privacy Policy',
      path: '/privacy_policy',
      openInNewTab: true,
    },
    {
      name: 'Contact Information',
      path: '/help',
    },
    {
      name: 'About Us',
      path: '/about',
    },
  ];

  const listOptions = (list: ListObj[]) =>
    list.map((option) => {
      if (option.openInNewTab) {
        return (
          <TextLink
            className={styles.listItem}
            key={option.name}
            href={option.path}
            textColor="custom"
          >
            {option.name}
          </TextLink>
        );
      }
      return (
        <div
          key={option.name}
          className={styles.listItem}
          onClick={() => {
            navigate(option.path);
          }}
        >
          {option.name}
        </div>
      );
    });

  const appVersions = () => {
    if (adminUser) {
      return (
        <Grid item xs={12} sm={12} lg={11} className={styles.releaseInfo}>
          Release Version: {RELEASE_VERSION} ~ UI Version: {appVersion} ~ API
          Version: {API_VERSION}
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid container className={styles.footer} justifyContent="center">
      <Grid container direction="row" className={styles.maxWidth}>
        <Grid
          item
          xs={12}
          sm={10}
          md={11}
          lg={10}
          container
          className={styles.topSection}
        >
          <Grid
            item
            sm={7}
            md={6.5}
            lg={7}
            container
            className={styles.lists}
            rowGap={5}
          >
            <Grid item xs={10} sm={3} md={4} lg={4} className={styles.list}>
              <div className={styles.listHeader}>Buying</div>
              {listOptions(buyingList)}
              {/* removed for beta */}
              {/* <div
              className={`${styles.listItem} ${styles.lastItem}`}
              onClick={() => navigate('/shopping')}
            >
              Shop All Categories
            </div> */}
            </Grid>

            <Grid item xs={10} sm={4} md={4} lg={4} className={styles.list}>
              <div className={styles.listHeader}>Selling</div>
              {listOptions(sellingList)}
              <div
                className={`${styles.listItem} ${styles.lastItem}`}
                onClick={() => navigate('/product_listing')}
              >
                Create New Listing
              </div>
            </Grid>

            <Grid item xs={10} sm={4} md={4} lg={4} className={styles.list}>
              <div className={styles.listHeader}>Resources</div>
              {listOptions(resourcesList)}
              <div
                className={`${styles.listItem} ${styles.lastItem}`}
                onClick={() => navigate('/help')}
              >
                Get Help
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            sm={5}
            md={5.5}
            lg={5}
            container
            direction="column"
            className={styles.taglineWrapper}
          >
            <div
              className={`${styles.taglineText} ${styles.smallBigText} ${styles.redText}`}
            >
              EASIEST <br /> WAY TO
            </div>
            <div
              className={`${styles.taglineText} ${styles.bigText} ${styles.whiteText}`}
            >
              BUY & <br />
              SELL
            </div>
            <div
              className={`${styles.taglineText} ${styles.bigText} ${styles.redText}`}
            >
              BUILDING <br /> MATERIALS
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          md={11}
          lg={10}
          container
          className={styles.bottomSection}
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={8.5}
            lg={9.5}
            className={styles.copyright}
          >
            © 2023 Check The Yard Something Something, LLC. All Rights Reserved.
            Use of this site is subject to certain Terms Of Use.
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3.5}
            lg={2.5}
            container
            className={styles.logoContainer}
          >
            <img className={styles.logo} src={logo} alt="Check The Yard logo" />
          </Grid>
          {appVersions()}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HomeFooter;
