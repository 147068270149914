import React from 'react';
import Avatar from '@mui/material/Avatar';
import styles from './stepTracker.module.scss';

export interface Step {
  stepName: string;
  onClick?: () => any;
}

type Props = {
  icon: any;
  steps: Step[];
  activeStep: number;
};

function StepTracker({ icon, steps = [], activeStep = 0 }: Props) {
  const iconStep = (step: Step, idx: number) => {
    let nameAlignment = styles.centerName;
    if (idx === 0) {
      nameAlignment = styles.leftName;
    } else if (idx === steps.length - 1) {
      nameAlignment = styles.rightName;
    }

    return (
      <div className={`${styles.iconStepContainer} ${nameAlignment}`}>
        <Avatar className={styles.iconAvatar}>{icon}</Avatar>
        <div className={styles.stepName}>{step.stepName.toUpperCase()}</div>
      </div>
    );
  };

  const dotStep = (step: Step, index: number) => {
    const { stepName, onClick = null } = step;
    const clickableClassName = onClick && styles.clickableStep;

    let dotColor = styles.greyDot;
    let nameColor = styles.greyName;
    if (activeStep >= index) {
      dotColor = styles.sandDot;
      nameColor = styles.blackName;
    }

    let nameAlignment = styles.centerName;
    if (index === 0) {
      nameAlignment = styles.leftName;
    } else if (index === steps.length - 1) {
      nameAlignment = styles.rightName;
    }

    return (
      <div
        className={`${styles.dotStepContainer} ${nameAlignment} ${clickableClassName}`}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <div className={`${styles.dotStep} ${dotColor}`} />
        <div
          className={`${styles.inactiveStepName} ${styles.stepName} ${nameColor}`}
          onClick={step.onClick}
        >
          {stepName.toUpperCase()}
        </div>
      </div>
    );
  };

  const line = (index: number) => {
    const color = activeStep - 1 >= index ? styles.sandLine : styles.greyLine;
    return (
      <div
        className={`${styles.line} ${color}`}
        style={{ width: `calc(100% / ${steps.length - 1})` }}
      />
    );
  };

  let lastStepNoIconClassName = styles.hideStep;
  let lastStepName = '';
  return (
    <>
      <div className={styles.wrapper}>
        {steps.map((step: Step, idx: number) => {
          const isActiveStep = activeStep === idx;
          let stepIndicator = dotStep(step, idx);

          if (isActiveStep && icon) {
            stepIndicator = iconStep(step, idx);
          } else if (isActiveStep && steps.length - 1 === idx) {
            lastStepNoIconClassName = styles.lastStepNoIcon;
            lastStepName = step.stepName;
          }

          return (
            <React.Fragment key={step.stepName}>
              {stepIndicator}
              {idx < steps.length - 1 && line(idx)}
            </React.Fragment>
          );
        })}
      </div>
      <div className={lastStepNoIconClassName}>
        {lastStepName.toUpperCase()}
      </div>
    </>
  );
}

export default StepTracker;
