import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import { TextLink, Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkbox/Checkbox';
import FormContainer from 'components/FormContainer/FormContainer';
import StandardModal from 'components/StandardModal/StandardModal';
import BigDLogo from 'assets/images/bigDLogo.png';
import { COLORS } from 'style/colors';
import styles from './emailVerified.module.scss';

type Props = {
  closeEmailVerifiedModal: () => void;
  verificationToken: string;
};

// TODO: I don't think we plan on using a verified email anymore since we can use the Stripe verified account flag, but I'll leave this here until we know

function EmailVerified({ closeEmailVerifiedModal, verificationToken }: Props) {
  const navigate = useNavigate();
  const [yardNewsletters, setYardNewsletters] = React.useState(true);
  // const [status, setStatus] = useState('Waiting');

  useEffect(() => {
    // const doVerify = async () => {
    //   setStatus('Verifying');
    //   verifyCall({
    //     body: { token: verificationToken },
    //   })
    //     .unwrap()
    //     .then((payload) =>
    //       setStatus(payload.verified ? 'Email verified!' : 'Not verified'),
    //     )
    //     .catch((err) => {
    //       if ('status' in err) {
    //         setStatus(err.status === 410 ? 'Token is expired' : 'Failed');
    //       } else {
    //         setStatus('Failed');
    //       }
    //     });
    // };
    // doVerify().catch((err) => {
    //   setStatus(`Error ${err}`);
    // });
  }, [verificationToken]);

  const newslettersApiCall = () => {
    if (yardNewsletters) {
      // api call for setting user's news letters
      // TO DO: api call to save news letters
    }
  };

  const content = () => (
    <Grid container className={styles.container}>
      <Grid container className={styles.headerWrapper}>
        <Grid item xs={12} md={8} className={styles.headerContainer}>
          <Grid item xs={7} md={3} className={styles.header}>
            Account Verified
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        container
        className={styles.bodyContainer}
      >
        <Grid container item xs={12} className={styles.topSectionWrapper}>
          <span className={styles.readyToCheckout}>
            You&apos;re ready to check out or start selling items on The Yard.
          </span>
          <Checkbox
            labelClassName={styles.newsletterCheckbox}
            label="Sign me up for Yard newsletters."
            checked={yardNewsletters}
            checkedColor={COLORS.midnightBlack}
            onClick={() => setYardNewsletters(!yardNewsletters)}
          />
        </Grid>
        <FormContainer
          className={styles.formContainer}
          bodyClassName={styles.formBody}
          title="Add a profile photo"
          actionNeeded
          content={
            <>
              <div className={styles.middleTextContainer}>
                <span className={styles.emailVerifiedMiddleText}>
                  Users with profile images of their business are more likely to
                  sell their items and be ranked higher on The Yard.
                </span>
              </div>
              <div className={styles.profileHeader}>EXAMPLE PROFILE</div>
              <div className={styles.exampleProfileWrapper}>
                <img
                  className={styles.businessLogo}
                  src={BigDLogo}
                  alt="Big D Construction"
                />
                <div className={styles.emailVerifiedBusinessNameContainer}>
                  <span className={styles.emailVerifiedBusinessName}>
                    <span className={styles.soldBy}>Sold By </span>
                    Big-D Construction
                  </span>
                  <span className={styles.emailVerifiedBusinessLocation}>
                    Salt Lake City, UT
                  </span>
                  <span className={styles.emailVerifiedBusinessHistory}>
                    Member since June 1, 2022
                  </span>
                </div>
              </div>
              <div className={styles.formButtonContainer}>
                <div className={styles.emailVerifiedBusinessButtonContainer}>
                  <TextLink
                    text="MY ACCOUNT"
                    className={styles.myAccount}
                    onClick={() => {
                      newslettersApiCall();
                      navigate('/account');
                    }}
                  />
                  <Button
                    roundedButton
                    buttonColor="black"
                    text="ADD IMAGE"
                    onClick={() => {
                      newslettersApiCall();
                      navigate('/account/user_details');
                    }}
                  />
                </div>
              </div>
            </>
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        lg={3}
        container
        flexDirection="column"
        className={styles.footer}
      >
        <TextLink
          text="Go to My Cart"
          className={styles.bottomLink}
          onClick={() => {
            newslettersApiCall();
            navigate('/shopping_cart');
          }}
        />
        <TextLink
          text="Sell my first item"
          className={styles.bottomLink}
          onClick={() => {
            newslettersApiCall();
            navigate('/product_listing');
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <StandardModal
      modalContent={content()}
      modalClassName={styles.emailVerifiedModal}
      buttonContainerClassName={styles.modalButtons}
      closeModalAction={() => {
        newslettersApiCall();
        closeEmailVerifiedModal();
        navigate('/');
      }}
      closeIconColor={COLORS.white}
      showBackButton={false}
    />
  );
}

export default EmailVerified;
