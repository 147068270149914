import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
// width used to determine if on mobile or desktop
const useWindowDimensions = () => {
  const [windowDim, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDim;
};

// this is using the MUI screen sizes (also defined in index.module.scss)
const useScreenSize = () => {
  const { width } = useWindowDimensions();
  let screenSize = 'xs';

  if (width >= 1536) {
    screenSize = 'xl';
  } else if (width >= 1200) {
    screenSize = 'lg';
  } else if (width >= 900) {
    screenSize = 'md';
  } else if (width >= 600) {
    screenSize = 'sm';
  }

  return screenSize;
};

export default useScreenSize;
