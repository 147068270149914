import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { IconButton } from 'components/Buttons';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import styles from './dialogPopup.module.scss';

type SimpleDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string | JSX.Element;
  actionButtons?: JSX.Element;
  className?: string;
};

function DialogPopup(props: SimpleDialogProps) {
  const { title, open, onClose, content, className, actionButtons } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={`${className}`}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className={styles.header}>
        {title}
        <IconButton icon={CloseIcon} onClick={onClose} filledOrBorder="none" />
      </DialogTitle>
      <div className={styles.content}>{content}</div>
      {actionButtons && (
        <div className={styles.actionButtons}>{actionButtons}</div>
      )}
    </Dialog>
  );
}

export default DialogPopup;
