import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactLoading from 'react-loading';
import { useCreateFileUrlMutation } from 'api';
import Grid from '@mui/material/Grid';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import FormContainer from 'components/FormContainer/FormContainer';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/circleCancelIcon.svg';
import { COLORS } from 'style/colors';
import styles from './components.module.scss';
import { Description as DescriptionType } from '../../types';
import { BaseProps } from '../types';

type Props = {
  baseProps: BaseProps;
  setSnackBar: (snackBar: any) => void;
};

function DescriptionForm({ baseProps, setSnackBar }: Props) {
  const { descriptionInfo, setDescriptionInfo, invalidProps } = baseProps;
  const { productColorOrStyle, productDescription, productImages } =
    descriptionInfo;
  const {
    invalidClassName,
    invalidInputsOnClick,
    setInvalidInputsOnClick,
    requiredFieldText,
  } = invalidProps;

  const [getUploadUrl] = useCreateFileUrlMutation();
  const [imageUploading, setImageUploading] = useState(false);

  let invalidImages = invalidInputsOnClick.productImages;
  const invalidPhotosClassName = invalidImages && styles.invalidPhotos;

  if (invalidImages && productImages.length >= 3) {
    invalidImages = false;
  }

  useEffect(() => {
    if (invalidInputsOnClick.productImages && productImages.length >= 3) {
      setInvalidInputsOnClick({
        ...invalidInputsOnClick,
        productImages: false,
      });
    }
  }, [productImages, invalidInputsOnClick, setInvalidInputsOnClick]);

  const uploadImage = useCallback(
    async (file: any) => {
      const uploadUrl = await getUploadUrl({
        body: {
          mimeType: file.type,
        },
      }).unwrap();

      try {
        const res = await fetch(uploadUrl.url, {
          method: 'put',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        });

        if (res.status === 200) {
          const amazonUrlTrimmed = uploadUrl.url.split('?X-')[0];
          const newImage = {
            preview: URL.createObjectURL(file),
            url: amazonUrlTrimmed,
            fileName: uploadUrl.fileName,
            mimeType: file.type,
          };

          setDescriptionInfo((prevState: DescriptionType) => ({
            ...descriptionInfo,
            productImages: [
              ...prevState.productImages,
              ...[newImage],
            ].reverse(),
          }));
        }
      } catch (err) {
        setSnackBar({
          show: true,
          message: 'An error occurred, please try again',
        });
      }
    },
    [getUploadUrl, descriptionInfo, setDescriptionInfo, setSnackBar],
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        setImageUploading(true);

        acceptedFiles.forEach((file: any) => uploadImage(file));

        setImageUploading(false);
      } else {
        setSnackBar({
          show: true,
          message: 'Invalid image type. Must be .png or .jpeg',
        });
        setImageUploading(false);
      }
    },
    [uploadImage, setSnackBar],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'],
    multiple: true,
    noDrag: true,
  });

  const removeImage = (imageUrl: string) => {
    const removedImage = productImages.filter(
      (images) => images.url !== imageUrl,
    );

    setDescriptionInfo({
      ...descriptionInfo,
      productImages: removedImage,
    });

    if (!invalidInputsOnClick.productImages && removedImage.length < 3) {
      setInvalidInputsOnClick({
        ...invalidInputsOnClick,
        productImages: true,
      });
    }
  };

  const contents = (
    <>
      <TextInput
        containerClassName={`${styles.input} ${invalidClassName(
          !productColorOrStyle,
        )}`}
        label="Color/Finish or Style"
        value={productColorOrStyle}
        onChange={(e: any) => {
          setDescriptionInfo({
            ...descriptionInfo,
            productColorOrStyle: e.target.value,
          });

          if (invalidInputsOnClick.productColorOrStyle && e.target.value) {
            setInvalidInputsOnClick({
              ...invalidInputsOnClick,
              productColorOrStyle: false,
            });
          }
        }}
        assistiveText={
          invalidInputsOnClick.productColorOrStyle ? requiredFieldText : ''
        }
        invalidUserInput={invalidInputsOnClick.productColorOrStyle}
      />
      <TextArea
        value={productDescription || ''}
        label="Description"
        onChange={(e: any) => {
          setDescriptionInfo({
            ...descriptionInfo,
            productDescription: e.target.value,
          });

          if (invalidInputsOnClick.productDescription && e.target.value) {
            setInvalidInputsOnClick({
              ...invalidInputsOnClick,
              productDescription: false,
            });
          }
        }}
        assistiveText={
          invalidInputsOnClick.productDescription ? requiredFieldText : ''
        }
        invalidUserInput={invalidInputsOnClick.productDescription}
        containerClassName={invalidClassName(!productColorOrStyle)}
      />
      <div className={styles.photoContainer}>
        <div
          className={`${
            styles.header
          } ${invalidPhotosClassName} ${invalidClassName(invalidImages)}`}
        >
          Photos
        </div>
        <div className={styles.warning}>
          The more photos the better! Please upload&nbsp;
          <span
            className={`${styles.threePhotosText} ${invalidPhotosClassName}`}
          >
            at least 3 photos
          </span>
          &nbsp;of the product.
        </div>
        <Grid container alignItems="center" className={styles.photosContainer}>
          <div className={styles.uploadContainer}>
            {/* eslint-disable react/jsx-props-no-spreading */}
            <div {...getRootProps()}>
              {!imageUploading && (
                <>
                  <div
                    className={`${styles.addPhotos} ${invalidPhotosClassName}`}
                  >
                    <PlusIcon
                      fill={invalidImages ? COLORS.taurusRed40 : COLORS.sand20}
                    />
                  </div>
                  <input {...getInputProps()} />
                </>
              )}
              {imageUploading && (
                <ReactLoading
                  type="spinningBubbles"
                  height="100%"
                  width="100%"
                  color="black"
                />
              )}
            </div>
          </div>
          {productImages.length > 0 &&
            productImages.map((img) => (
              <div key={img.url} className={styles.imageContainer}>
                <img
                  src={img.preview}
                  alt={img.fileName}
                  className={styles.image}
                />
                <RemoveIcon
                  className={`${styles.removeIcon} ${styles.removeImageIcon}`}
                  onClick={() => removeImage(img.url)}
                />
              </div>
            ))}
        </Grid>
      </div>
    </>
  );

  return (
    <FormContainer
      className={styles.form}
      title="Description*"
      content={contents}
    />
  );
}

export default DescriptionForm;
