import React from 'react';
import { Order } from 'api';
import Table from 'components/Table/Table';
import OrderItem from './OrderItem';
import styles from './myOrdersScreen.module.scss';

type Props = {
  soldOrders: Order[];
};

function SalesTab({ soldOrders }: Props) {
  const userOrdersView = (pageData: Order[]) => (
    <div className={styles.tableData}>
      {pageData.map((itemObj: any) => (
        <OrderItem order={itemObj} orderType="sales" key={itemObj.id} />
      ))}
    </div>
  );

  return (
    <Table
      className={styles.table}
      tableName=""
      startingView="custom"
      customTableView={(pageData: Order[]) => userOrdersView(pageData)}
      onlyAllowStartingView
      data={soldOrders}
      pageSize={9}
      emptyTableMessage="You haven't sold any items yet. Once you do, they will appear here."
    />
  );
}

export default SalesTab;
