import React, { useState, useEffect, useMemo } from 'react';
import { Address, DeliveryAddress, User } from 'api';
import { TextLink } from 'components/Buttons';
import Loading from 'components/Loading';
import FormContainer from 'components/FormContainer/FormContainer';
import { ShippingAddressForm } from 'components/Forms';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import PackageInfo from './PackageInfo/PackageInfo';
import styles from './shipping.module.scss';
import {
  Shipping as ShippingType,
  PackageDetails,
  FieldValidation,
  PackageValidation,
  FieldValidationBaseProps,
} from '../types';

interface FieldValidationProps extends FieldValidationBaseProps {
  setShippingFieldsValidation: (fields: FieldValidation[]) => void;
}

type Props = {
  user: User | undefined;
  shippingInfo: ShippingType | undefined;
  setShippingInfo: (val: any) => void;
  setSaveAddressToUser: (val: boolean) => void;
  fieldValidationProps: FieldValidationProps;
};

function Shipping({
  user,
  shippingInfo,
  setShippingInfo,
  setSaveAddressToUser,
  fieldValidationProps,
}: Props) {
  const {
    validateFieldsOnClick,
    setValidateFieldsOnClick,
    setShippingFieldsValidation,
    handleClickScroll,
  } = fieldValidationProps;

  const [usersSavedAddresses, setUsersSavedAddresses] =
    useState<DeliveryAddress[]>();
  const [shipFromAddress, setShipFromAddress] = useState<Address>();
  const [isValidAddress, setIsValidAddress] = useState(false);

  useEffect(() => {
    if (user) {
      const { deliveryAddresses = [], address } = user;
      const defaultAddress = {
        address,
        default: true,
      };

      if (deliveryAddresses.length === 0) {
        setUsersSavedAddresses([defaultAddress]);
      } else {
        setUsersSavedAddresses(deliveryAddresses);
      }
    }
  }, [user]);

  const emptyPackage = {
    handlingUnits: null,
    shipmentType: null,
    pieces: null,
    description: null,
    stackable: false,
    length: null,
    width: null,
    height: null,
    weight: null,
    class: null,
    nmfc: null,
    hazardous: false,
  };

  const [packageDetails, setPackageDetails] = useState<PackageDetails[]>([
    { ...emptyPackage, id: 1 },
  ]);

  const [packageValidation, setPackageValidation] = useState<
    PackageValidation[]
  >([]);

  const infoFromParent = () => {
    // if the parent has saved shipping info, set the state to that
    if (shippingInfo) {
      const {
        shipFromAddress: previousShipFromAddress,
        packageDetails: previousPackageDetails,
      } = shippingInfo;

      if (previousShipFromAddress) setShipFromAddress(previousShipFromAddress);
      if (previousPackageDetails.length > 0) {
        setPackageDetails(previousPackageDetails);
      }
    }
  };
  // we want to only run this once on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(infoFromParent, []);

  const fieldValidation = useMemo(() => {
    const packageValidationFields = packageValidation
      .map((pckValObj) => pckValObj.fields)
      .flat();

    return [
      {
        displayName: 'Ship From Address',
        isMissing: !shipFromAddress,
        isInvalid: !isValidAddress,
      },
      ...packageValidationFields,
    ];
  }, [shipFromAddress, isValidAddress, packageValidation]);

  useEffect(
    () => handleClickScroll(fieldValidation),
    [
      validateFieldsOnClick,
      fieldValidation,
      setValidateFieldsOnClick,
      handleClickScroll,
    ],
  );

  useEffect(
    () => setShippingFieldsValidation(fieldValidation),
    [fieldValidation, setShippingFieldsValidation],
  );

  const shippingFrom = () => {
    if (!usersSavedAddresses) return <Loading />;

    return (
      <ShippingAddressForm
        shippingType="shipping"
        dropdownLabel="Ship From*"
        usersSavedAddresses={usersSavedAddresses}
        saveAddress={(shippingAddress) => {
          setShipFromAddress(shippingAddress);
        }}
        isValidAddress={isValidAddress}
        setIsValidAddress={setIsValidAddress}
        showSaveCheckbox
        setSaveAddressToUser={setSaveAddressToUser}
        validateOnClickProps={{
          invalidClassName: 'invalidInput',
          validateFieldsOnClick,
        }}
      />
    );
  };

  const packageForm = () => (
    <>
      {packageDetails.map((packageObj) => (
        <PackageInfo
          key={packageObj.id.toString()}
          keyId={packageObj.id}
          packageDetails={packageObj}
          setPackageDetails={(updatedPackage) => {
            const updatedPackageDetails = packageDetails.map(
              (packageDetail) => {
                if (packageDetail.id === updatedPackage.id) {
                  return updatedPackage;
                }
                return packageDetail;
              },
            );
            setPackageDetails(updatedPackageDetails);
          }}
          deletePackage={(id: number) => {
            const updatedPackageDetails = packageDetails.filter(
              (packageDetail) => packageDetail.id !== id,
            );
            setPackageDetails(updatedPackageDetails);
            // remove package validation for deleted package
            const updatedPackageValidation = packageValidation.filter(
              (packageVal) => packageVal.id !== id,
            );
            setPackageValidation(updatedPackageValidation);
          }}
          validateFieldsOnClick={validateFieldsOnClick}
          packageValidation={packageValidation}
          setPackageValidation={setPackageValidation}
        />
      ))}
      <div className={styles.addPackageButtonWrapper}>
        <TextLink
          className={styles.addPackageButton}
          onClick={() => {
            const packageId = packageDetails.length + 1;
            const newPackage = { ...emptyPackage, id: packageId };
            setPackageDetails([...packageDetails, newPackage]);
          }}
        >
          <PlusIcon />
          ADD ANOTHER PACKAGE
        </TextLink>
      </div>
    </>
  );

  // save shipping info to parent state
  const saveShippingInfo = () => {
    const newShipping = {
      ...(shipFromAddress && { shipFromAddress }),
      ...(packageDetails && { packageDetails }),
    };

    if (
      Object.keys(newShipping).length !== 0 &&
      JSON.stringify(shippingInfo) !== JSON.stringify(newShipping)
    ) {
      setShippingInfo(newShipping);
    }
  };

  useEffect(saveShippingInfo, [
    shipFromAddress,
    packageDetails,
    shippingInfo,
    setShippingInfo,
  ]);

  return (
    <div>
      <div className={styles.formWrapper}>
        <FormContainer
          bodyClassName={styles.shippingForm}
          titleClassName={styles.shippingFormTitle}
          className={styles.shippingForm}
          title="Shipping"
          content={shippingFrom()}
        />
      </div>
      <div className={styles.formWrapper}>
        <FormContainer title="Packages" content={packageForm()} />
      </div>
    </div>
  );
}

export default Shipping;
