import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import { ReactComponent as DropdownArrowIcon } from 'assets/icons/dropDownIcon.svg';
import { ReactComponent as UpArrowIcon } from 'assets/icons/dropUpIcon.svg';
import { ReactComponent as PageNavigation } from 'assets/icons/pageNavigationIcon.svg';
import { COLORS } from 'style/colors';
import formStyles from 'style/formStyles.module.scss'; // used for global input styling
import styles from './dropdown.module.scss';

export interface ListObj {
  label: string | JSX.Element;
  value?: string;
  options?: {
    label: string;
    value: string;
  }[];
  selected?: boolean;
  onClick?: any;
  disabled?: boolean;
}

type Props = {
  options: ListObj[];
  defaultValue?: string | ListObj;
  value?: string | ListObj | null;
  dropdownType?: 'form' | 'select'; // use 'form' for form elements and 'select' for things like table sort
  buttonClassName?: string;
  containerClassName?: string;
  onChange?: any;
  label?: string | null;
  placeholder?: string;
  selectedValueTextColor?: string; // default is midnightBlack
  quantityDropdown?: boolean; // set to true for Quantity dropdowns
  menuWidth?: string; // examples: '200px', '100%' - default is '100%'
  dropdownIndicatorColor?: string; // default is black
  dropdownIndicatorType?: 'arrow' | 'pageNavigation';
  isSearchable?: boolean; // can the user type in the input, default is false
  onInputChange?: (value: string, action?: any) => any; // function for searched value
  name?: string;
  invalidUserInput?: boolean;
  assistiveText?: string; // text below the input to assist the user
  assistiveTextOnClick?: () => void;
  disabled?: boolean;
  tabSelectsValue?: boolean; // default is true
};

// use our own dropdown arrows
function DropdownIndicator(props: any) {
  const { selectProps } = props;
  const pageNavigationClass =
    selectProps.className.includes('pageNavigationIcon');

  if (pageNavigationClass) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.DropdownIndicator {...props}>
        <PageNavigation />
      </components.DropdownIndicator>
    );
  }

  if (selectProps.menuIsOpen) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.DropdownIndicator {...props}>
        <UpArrowIcon
          style={{ fill: selectProps.styles.dropdownIndicator.fill }}
        />
      </components.DropdownIndicator>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <DropdownArrowIcon
        style={{ fill: selectProps.styles.dropdownIndicator.fill }}
      />
    </components.DropdownIndicator>
  );
}

function Dropdown({
  options,
  value,
  defaultValue,
  dropdownType = 'form',
  buttonClassName = '',
  containerClassName = '',
  onChange,
  label = null,
  placeholder = '',
  selectedValueTextColor = COLORS.midnightBlack,
  quantityDropdown = false,
  menuWidth,
  dropdownIndicatorColor = COLORS.midnightBlack,
  dropdownIndicatorType = 'arrow',
  isSearchable = false,
  invalidUserInput,
  disabled = false,
  assistiveTextOnClick,
  ...props
}: Props) {
  const selectDropdown = dropdownType === 'select';
  const initialValue = value || defaultValue;
  const [selectedValue, setSelectedValue] = useState<any>();

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  // for css color if the input contains a value
  const filledClassName = selectedValue && formStyles.filled;
  const invalidClassName = invalidUserInput && formStyles.invalidUserInput;
  // for css transform if the input contains a value, need styles.transform for quantity dropdown
  const transformLabel =
    selectedValue && `${formStyles.transform} ${styles.transform}`;
  const defaultInputWrapper = !selectDropdown && formStyles.defaultInputWrapper;
  // for styling Quantity label
  const quantityLabel = quantityDropdown && styles.quantityLabelWrapper;
  // for css of disabled input
  const disabledInput = disabled && formStyles.disabledInput;

  const showAssistiveText = props.assistiveText && props.assistiveText !== '';

  // I couldn't find a way of passing custom props to the DropdownIndicator, so I'm looking for this
  // className in the DropdownIndicator func to determine if I need to use the pageNavigation icon
  const usePageNavigationIcon =
    dropdownIndicatorType === 'pageNavigation' && 'pageNavigationIcon';

  // used for styling the different parts of Select
  const customStyles = {
    container: () => ({
      height: selectDropdown ? '32px' : 'inherit',
      backgroundColor: 'inherit',
      borderRadius: 'inherit',
    }),
    control: () => ({
      height: '100%',
      backgroundColor: 'inherit',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 'inherit',
      borderRadius: 'inherit',
    }),
    singleValue: (provided: any) => {
      const paddingTop = selectedValue && label ? '10px' : '2px';
      let color = selectedValueTextColor;
      if (disabled) {
        color = COLORS.metal;
      } else if (quantityDropdown) {
        color = COLORS.sand20;
      }

      let singleValueStyles = {};
      if (quantityDropdown) {
        // @include Subtitle__Subtitle-1--Sand--Bold--Desktop
        singleValueStyles = {
          fontFamily: 'Roboto',
          fontSize: '24px',
          fontWeight: 'bold',
          letterSpacing: '1.25px',
          paddingTop: '0',
          margin: '0',
          textOverflow: 'unset',
        };
      }

      return { ...provided, paddingTop, color, ...singleValueStyles };
    },
    input: (provided: any) => ({
      ...provided,
      position: 'absolute',
      paddingTop: '10px',
    }),
    selectContainer: (provided: any) => ({
      ...provided,
      borderColor: 'inherit',
      borderRadius: 'inherit',
    }),
    valueContainer: (provided: any) => {
      let display = 'flex';
      let overflow = 'unset';
      if ((selectedValue && label) || (placeholder !== '' && !selectedValue)) {
        display = 'grid';
        overflow = 'hidden';
      }

      const paddingLeft = selectDropdown || quantityDropdown ? '8px' : '16px';
      const paddingBottom = selectDropdown ? '2px' : '0';
      const paddingTop = quantityDropdown ? '10px' : '';

      return {
        ...provided,
        display,
        overflow,
        paddingLeft,
        paddingBottom,
        paddingTop,
        alignItems: 'center',
        height: '100%',
      };
    },
    indicatorSeparator: (base: any) =>
      // remove the line between value and the down arrow
      ({
        ...base,
        display: 'none',
      }),
    dropdownIndicator: (base: any) => {
      if (selectDropdown) {
        // remove extra padding so arrow is closer to value in select
        return {
          ...base,
          paddingLeft: '0',
          fill: disabled ? COLORS.metal : dropdownIndicatorColor,
        };
      }
      return {
        ...base,
        fill: disabled ? COLORS.metal : dropdownIndicatorColor,
      };
    },
    menu: (base: any) =>
      // menu dropdown minWidth
      ({
        ...base,
        minWidth: '100px',
        width: menuWidth || '100%',
        zIndex: 5,
        marginTop: '2px',
      }),
    option: (provided: any, state: any) => {
      const defaults = {
        ...provided,
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
      };
      if (state.isDisabled) {
        return {
          ...defaults,
          color: COLORS.metal,
          backgroundColor: COLORS.offWhite,
        };
      }
      if (state.isSelected) {
        return {
          ...defaults,
          backgroundColor: COLORS.sand20,
        };
      }
      if (state.isFocused) {
        return {
          ...defaults,
          backgroundColor: COLORS.sandBright20,
        };
      }
      return defaults;
    },
    placeholder: (base: any) => ({
      // Subtitle__Subtitle-2-Midnight--Mobile
      ...base,
      color: disabled ? COLORS.metal : COLORS.midnightBlack,
      fontSize: '14px',
      lineHeight: '1.71',
      letterSpacing: '0.47px',
    }),
  };

  return (
    <div className={`${styles.wrapper} ${containerClassName}`}>
      <div
        className={`${defaultInputWrapper} ${filledClassName} ${quantityLabel} ${invalidClassName} ${disabledInput}`}
      >
        {label && (
          <label
            className={`${formStyles.defaultInputLabel} ${transformLabel} ${styles.quantityLabel}`}
            htmlFor={label}
          >
            {label}
          </label>
        )}
        <Select
          className={classNames(buttonClassName, usePageNavigationIcon)}
          options={options}
          isSearchable={isSearchable}
          styles={customStyles}
          isDisabled={disabled}
          isOptionDisabled={(option: any) => option.disabled}
          value={initialValue}
          onChange={(option) => {
            setSelectedValue(option);
            if (onChange) {
              onChange(option);
            }
          }}
          placeholder={placeholder}
          components={{ DropdownIndicator }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
      {showAssistiveText && (
        <div
          className={`${formStyles.assistiveText} ${
            invalidUserInput && formStyles.redAssistiveText
          } ${assistiveTextOnClick && formStyles.clickableAssistiveText}`}
          onClick={() => {
            if (assistiveTextOnClick) {
              assistiveTextOnClick();
            }
          }}
        >
          {props.assistiveText}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
