import React, { MouseEventHandler } from 'react';
import Avatar from '@mui/material/Avatar';
import { COLORS } from 'style/colors';
import styles from './iconButton.module.scss';

type Props = {
  className?: string;
  avatarClassName?: string;
  labelClassName?: string;
  iconClassName?: string;
  icon: any;
  iconStyle?: any; // object of any special styling - most likely will only really use { fontSize: 'Xpx' }
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label?: string;
  labelPosition?: 'bottom' | 'right'; // default is bottom
  textColor?: 'black' | 'sand-bright'; // default is black
  roundedAvatar?: boolean; // default is true
  filledOrBorder?: 'filled' | 'border' | 'none'; // default is filled
  shadowOutline?: boolean; // default is false - true gives the shadow outline to avatar
  activeButton?: boolean; // default is true
  disabled?: boolean;
};

function IconButton({
  className,
  avatarClassName,
  labelClassName,
  iconClassName,
  icon,
  iconStyle = {},
  onClick,
  label,
  labelPosition = 'bottom',
  textColor = 'black',
  roundedAvatar = true,
  filledOrBorder = 'filled',
  shadowOutline = false,
  activeButton = true,
  disabled = false,
  ...props
}: Props) {
  const textClass =
    textColor === 'black' ? styles.blackLabel : styles.sandLabel;
  const squaredClass = !roundedAvatar && styles.squareAvatar;
  const shadowBorder = shadowOutline && styles.shadowOutline;
  const labelPositionClass =
    labelPosition === 'bottom' ? styles.bottomLabel : styles.rightLabel;

  let avatarStyleClass = '';
  if (filledOrBorder === 'filled') {
    avatarStyleClass = styles.filledAvatar;
  } else if (filledOrBorder === 'border') {
    avatarStyleClass = styles.borderAvatar;
  }

  let iconColor = activeButton ? COLORS.midnightBlack : COLORS.sand;
  if (disabled) {
    iconColor = COLORS.metalBright50;
  } else if (iconStyle.fill) {
    iconColor = iconStyle.fill;
  }

  // this is so I can pass the default styling to whatever icon is passed
  const Icon = icon;
  const iconDefaultStyle = { fontSize: '18px' };
  // since border adds 1px, make the avatar 1px smaller if border is used
  const avatarWidthHight = filledOrBorder === 'border' ? '31px' : '32px';
  const avatarWithLabel = label && styles.avatarWithLabel;

  return (
    <button
      type="button"
      className={`
        ${styles.button}
        ${className}
        ${labelPositionClass}
      `}
      disabled={disabled}
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Avatar
        className={`
          ${styles.avatar}
          ${avatarStyleClass}
          ${squaredClass}
          ${shadowBorder}
          ${avatarWithLabel}
          ${avatarClassName}
        `}
        sx={{ width: avatarWidthHight, height: avatarWidthHight }}
      >
        <Icon
          style={{ ...iconDefaultStyle, ...iconStyle }}
          fill={iconColor}
          className={iconClassName}
        />
      </Avatar>
      {label && (
        <span
          className={`
            ${styles.label}
            ${textClass}
            ${labelClassName}
          `}
        >
          {label}
        </span>
      )}
    </button>
  );
}

export default IconButton;
