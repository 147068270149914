import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TransactionState {
  selectedShoppingCategory?: string;
  screenCallback?: string;
}

const initialState: TransactionState = {
  selectedShoppingCategory: undefined,
  screenCallback: undefined,
};

// TODO: this is crap
export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setScreenCallback: (state, action: PayloadAction<string>) => {
      state.screenCallback = action.payload;
    },
    setSelectedShoppingCategory: (state, action: PayloadAction<string>) => {
      state.selectedShoppingCategory = action.payload;
    },
  },
});

export const { setScreenCallback, setSelectedShoppingCategory } =
  transactionSlice.actions;
export default transactionSlice.reducer;
