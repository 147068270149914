import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Listing } from 'api';
import { useFormatPrice, useGetListingTotal } from 'hooks';
import productConditionRatings from 'constants/productConditionRatings';
import Grid from '@mui/material/Grid';
import { BuyNowButton, AddToCartButton } from 'components/Buttons';
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import { COLORS } from 'style/colors';
import styles from './cardItem.module.scss';

type Props = {
  listing: Listing;
  isCart?: boolean;
  cartQuantity?: number; // passed from Cart to determine price
};

function CardItem({ listing, isCart = false, cartQuantity }: Props) {
  const navigate = useNavigate();
  const {
    soldAs,
    seller,
    category,
    subCategory,
    productImages,
    id: listingId,
    totalPrice,
    sold: isSold = false,
    basePrice = 0,
  } = listing;

  const isSoldAsLot = soldAs.toLowerCase() === 'lot';
  const sellerRating = seller?.rating || { average: 0, count: 0 };

  const listingImage = productImages[0]?.url;
  // for handling if the image returns an error
  const [imageError, setImageError] = useState(false);

  let displayedPrice = useFormatPrice(totalPrice);
  const perUnitPrice = useGetListingTotal(basePrice, cartQuantity);
  if (!isSoldAsLot && isCart && cartQuantity) {
    displayedPrice = perUnitPrice;
  }

  const condition = () => {
    const { productCondition, productConditionRating } = listing;
    if (productCondition === 'AS-IS') {
      return (
        productConditionRatings.find(
          (rating) => rating.rating === productConditionRating,
        )?.name || 'As-Is'
      );
    }
    return productCondition.toLowerCase();
  };

  const soldBadge = () => (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={styles.soldBadge}
    >
      SOLD
    </Grid>
  );

  const soldOutClassName = isSold && styles.soldOut;

  return (
    <Grid item xs={12} sm={6} md={6} className={styles.wrapper} key={listingId}>
      <div
        className={`${styles.cardContainer} ${soldOutClassName}`}
        onClick={() => {
          navigate(
            `/product/${category.toLowerCase()}/${subCategory}/${listingId}`,
          );
        }}
      >
        <Grid container item xs={12} className={styles.topInfo}>
          <Grid item xs={6} className={styles.leftSide}>
            {imageError || !listingImage ? (
              <div className={styles.noImageBackground}>
                {isSold ? soldBadge() : 'NO IMAGE'}
              </div>
            ) : (
              <>
                <img
                  src={listingImage}
                  alt={listing.productName}
                  className={styles.image}
                  onError={() => {
                    setImageError(true);
                  }}
                />
                {isSold && soldBadge()}
              </>
            )}
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="space-between"
            direction="column"
            className={styles.rightSide}
          >
            <div className={styles.subcategory}>{subCategory}</div>
            <div className={styles.name}>{listing.productName}</div>
            <div className={styles.price}>{displayedPrice}</div>
            <div className={styles.sellerRatingContainer}>
              {sellerRating.averageRating > 0 && (
                <>
                  <div className={styles.seller}>Seller</div>
                  <div className={styles.sellerRating}>
                    {sellerRating.averageRating}
                  </div>
                  <StarIcon
                    className={styles.starIcon}
                    fill={isSold ? COLORS.metal : COLORS.midnightBlack}
                  />
                </>
              )}
            </div>
            <div className={styles.condition}>{condition()}</div>
          </Grid>
        </Grid>
        <Grid container className={styles.bottomButtons}>
          <div className={styles.lineSeparator} />
          {isSold && isCart ? (
            <AddToCartButton listing={listing} buttonType="removeButton" />
          ) : (
            <>
              <Grid item xs={5} className={styles.button}>
                <AddToCartButton
                  listing={listing}
                  buttonType={isCart ? 'removeButton' : 'button'}
                />
              </Grid>
              <Grid item xs={7}>
                <BuyNowButton
                  listing={listing}
                  buttonType="text"
                  cartQuantity={cartQuantity}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Grid>
  );
}

export default CardItem;
