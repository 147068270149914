import React from 'react';
import { useNavigate } from 'react-router';
import { Listing } from 'api';
import { useFormatPrice } from 'hooks';
import Grid from '@mui/material/Grid';
import productConditionRatings from 'constants/productConditionRatings';
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import Menu from './Menu';
import styles from './listItem.module.scss';

type Props = {
  listing: Listing;
  homeScreenTable: boolean;
};

function ListItem({ listing, homeScreenTable }: Props) {
  const navigate = useNavigate();
  const { seller, category, subCategory } = listing;

  const sellerRating = seller?.rating;

  const condition = () => {
    const { productCondition, productConditionRating } = listing;
    if (productCondition === 'AS-IS') {
      return (
        productConditionRatings.find(
          (rating) => rating.rating === productConditionRating,
        )?.name || 'As-Is'
      );
    }
    return productCondition.toLowerCase();
  };

  return (
    <Grid container className={styles.container} key={listing.id}>
      <div
        className={styles.imageWrapper}
        onClick={() => {
          navigate(
            `/product/${category.toLowerCase()}/${subCategory}/${listing.id}`,
          );
        }}
      >
        <img
          src={listing.productImages[0]?.url}
          alt={listing.productName}
          className={styles.image}
        />
      </div>
      <div className={styles.rightSide}>
        <Grid
          item
          xs={12}
          className={styles.topInfo}
          onClick={() => {
            navigate(
              `/product/${category.toLowerCase()}/${subCategory}/${listing.id}`,
            );
          }}
        >
          <div className={styles.subCategory}>
            {homeScreenTable ? category.toLowerCase() : subCategory}
          </div>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.priceRow}
          >
            <div className={styles.name}>{listing.productName}</div>
            <div className={styles.price}>
              {useFormatPrice(listing.totalPrice)}
            </div>
          </Grid>
        </Grid>
        <div className={styles.lineSeparator} />
        <Grid
          container
          justifyContent="space-between"
          item
          xs={12}
          className={styles.bottomInfo}
        >
          <div className={styles.bottomLeft}>
            <div className={styles.sellerRatingContainer}>
              {sellerRating.averageRating > 0 ? (
                <>
                  <div className={styles.seller}>Seller</div>
                  <div className={styles.sellerRating}>
                    {sellerRating.averageRating}
                  </div>
                  <StarIcon className={styles.starIcon} />
                </>
              ) : (
                <span className={styles.seller}>New Seller</span>
              )}
            </div>
            <div className={styles.condition}>{condition()}</div>
          </div>
          <Menu listing={listing} />
        </Grid>
      </div>
    </Grid>
  );
}

export default ListItem;
