import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useGetUsersByUserIdVerifyQuery } from 'api';
import { useGetUserDetails } from 'hooks';
import { setStripeAccountVerified, setAccountInfo } from 'slices/accountSlice';
import Loading from 'components/Loading/Loading';

type Props = {
  userId: string;
  successfulSignInCallback?: () => void;
};

// this component is used to verify the user's stripe account after they sign in
// a loader is displayed while the verification call is made
function SuccessfulSignin({ userId, successfulSignInCallback }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getUserResponse = useGetUserDetails();

  useEffect(() => {
    if (getUserResponse?.data.user) {
      const { user } = getUserResponse.data;
      dispatch(
        setAccountInfo({
          accountType: user.type || null,
          businessName: user.business ? user.business.name : user.name,
        }),
      );
    }
  }, [getUserResponse, dispatch]);

  const { currentData: userIsVerifiedData } = useGetUsersByUserIdVerifyQuery(
    { userId },
    { skip: userId === '' },
  );

  useEffect(() => {
    if (userIsVerifiedData?.success) {
      const isVerified = userIsVerifiedData?.data?.verified || false;
      dispatch(setStripeAccountVerified(isVerified));

      // move this out of the signInApiCall function so that the verified call has time to run
      if (successfulSignInCallback) {
        successfulSignInCallback();
      } else {
        navigate(-1);
      }
    } else if (userIsVerifiedData) {
      // if the verification call fails, still navigate back one so the user isn't stuck on a loading screen
      navigate(-1);
    }
  }, [userIsVerifiedData, dispatch, successfulSignInCallback, navigate]);

  return <Loading screenOverlay />;
}

export default SuccessfulSignin;
