import React from 'react';
import Page from 'components/Page';
import TextLink from 'components/Links/TextLink';
import styles from './termsAndConditionsScreen.module.scss';

function TermsAndConditionsScreen() {
  const boldText = (text: string) => (
    <span className={styles.boldText}>{text}</span>
  );

  const sectionHeaderText = (text: string) => (
    <div className={styles.sectionHeader}>{text}</div>
  );

  const addressFormat = (address: JSX.Element) => (
    <div className={styles.addressFormat}>{address}</div>
  );

  const indent = (text: any) => <p className={styles.indent}>{text}</p>;

  const termsAndConditions = {
    heading: 'Terms & Conditions',
    sections: [
      {
        id: 'initial-paragraph',
        type: 'text',
        text: [
          <>
            {boldText('Last Updated')}: July 13, 2023
            <br />
            <br />
            <p>
              {boldText('IMPORTANT')}: PLEASE READ THE FOLLOWING TERMS OF
              SERVICE (INCLUDING ANY TERMS INCORPORATED HEREIN BY REFERENCE,
              THIS “{boldText('AGREEMENT')}”) CAREFULLY. IF YOU DO NOT AGREE
              WITH THIS AGREEMENT, YOU MUST NOT USE THE SERVICE (AS DEFINED
              BELOW) OR ANY OF THE GOODS, PRODUCTS, SERVICES, CONTENT, FEATURES
              OR FUNCTIONALITY AVAILABLE THROUGH IT. BY ACCESSING OR USING THE
              SERVICE OR ANY OF THE AVAILABLE GOODS, PRODUCTS, SERVICES,
              CONTENT, FEATURES OR FUNCTIONALITY, YOU REPRESENT AND WARRANT THAT
              YOU HAVE THE RIGHT, AUTHORITY AND CAPACITY TO CONSENT TO THIS
              AGREEMENT IN THEIR ENTIRETY AND YOU ACKNOWLEDGE THAT YOU HAVE
              READ, UNDERSTAND AND FULLY ACCEPT AND AGREE TO ALL OF THIS
              AGREEMENT.
            </p>
            <p>
              This Agreement is entered into by and between CHECKTHEYARD, INC.
              (“
              {boldText('Company')},” “{boldText('we')},” “
              {boldText('The Yard')}” or “{boldText('us')}”) and each person who
              uses The Yard online marketplace in any capacity (“
              {boldText('you')}” or “{boldText('your')}”). This Agreement
              governs your use of The Yard online marketplace, including,
              without limitation, all products, and all content such as text,
              information, news, images, contracts, software and other
              downloadable material made available to you through such
              marketplace by us and/or third parties (collectively, the
              “Service”). We may, at our sole discretion, modify this Agreement
              at any time by posting a new version of this Agreement on our
              website and updating the “Last Updated” date at the top of the
              page. By accessing the Service at any time after such
              modifications, you are agreeing to such modifications. We reserve
              the right to refuse to provide the Service to any person for any
              reason and/or to modify or discontinue the Service in whole or in
              part at any time, with or without prior notice. You agree that by
              registering, accessing, or using the Service, you are agreeing to
              the Terms as a legally binding contract with us. If you do not
              agree to this Agreement, do not register for, access, or otherwise
              use the Service.
            </p>
          </>,
        ],
      },
      {
        id: 'terms-for-all-users-header',
        type: 'text',
        text: [<>{sectionHeaderText('TERMS FOR ALL USERS')}</>],
      },
      {
        id: 'users-step-1-accounts',
        type: 'text',
        text: [
          <>
            {boldText('1. Accounts.')} Use of the Service requires you to open
            or register an account. You must complete the registration process
            by providing us with current, complete and accurate information, as
            prompted by the applicable registration form(s), which may include a
            verified bank account or other financial, business, tax or personal
            information, or other information as requested by the payment
            processing services provider which Company uses to provide payment
            processing services for all transactions on the Service, which, as
            of the date of this Agreement, is Stripe (“
            {boldText('Payment Processor')}”). You acknowledge that in the
            course of creating an account, you will be prompted to enter into a
            direct agreement with Payment Processor. You hereby grant Company
            the right to represent you towards Payment Processor with respect to
            your use of the payment services provided by Payment Processor. You
            further acknowledge that by providing any information to us or to
            Payment Processor which is untrue, inaccurate, not current or
            incomplete, we reserve the right to terminate your access and use of
            the Service. As part of the registration process, you may be asked
            to select a username and password. You are entirely responsible for
            maintaining the security and confidentiality of your account and
            password. FURTHERMORE, YOU ARE ENTIRELY RESPONSIBLE FOR ANY AND ALL
            ACTIVITIES AND CONDUCT, WHETHER BY YOU OR ANYONE ELSE, THAT ARE
            CONDUCTED THROUGH YOUR ACCOUNT. You agree to notify us immediately
            of any unauthorized use of your account or any other breach of
            security. Neither we nor our licensors will be liable for any loss
            that you may incur as a result of someone else using your password
            or account, either with or without your knowledge. You may be held
            liable for any losses incurred by us, our licensors or another party
            due to someone else using your account or password.
          </>,
        ],
      },
      {
        id: 'users-step-2-copyright',
        type: 'text',
        text: [
          <>
            {boldText('2. Copyright, Licenses and User Submissions.')} As
            between you and Company, Company is the sole and exclusive owner of
            the Service and all intellectual property rights therein. YOU MAY
            NOT MODIFY, COPY, REPRODUCE, REPUBLISH, UPLOAD, POST, TRANSMIT, OR
            DISTRIBUTE, IN ANY MANNER, THE SERVICE, INCLUDING TEXT, GRAPHICS,
            CODE AND/OR SOFTWARE. Any other use of the Service not permitted by
            this Agreement is strictly prohibited.
          </>,
        ],
      },
      {
        id: 'users-step-3-user-submissions',
        type: 'text',
        text: [
          <>
            {boldText('3. User Submissions.')} Content, material, media, links,
            images, trademarks, logos and other content submitted by users for
            inclusion on the Service, Product Listings (defined below), feedback
            about the Service, and any information submitted on other public
            areas of the Service are referred to in this Agreement collectively
            as “{boldText('User Submissions')}.” Whether or not any User
            Submission is published, it will be subject to this Agreement.
            Company does not guarantee any confidentiality with respect to a
            User Submission, regardless of whether it is published. You are
            solely responsible for your own User Submissions and the
            consequences of posting or publishing them. You represent and
            warrant that you own or have the necessary licenses, rights,
            consents and permissions to your User Submissions (and all content
            included therein), including the right to authorize Company to use
            the User Submissions in the manner contemplated by the Service and
            this Agreement. By submitting or posting User Submissions on or
            through the Service, you grant us a worldwide, non-exclusive,
            sublicensable (through multiple tiers), transferrable, fully paid,
            royalty-free license to use, copy, reproduce, process, adapt,
            modify, publish, transmit, display, and distribute the User
            Submissions in any and all media or distribution methods (existing
            now or later developed). This includes making User Submissions
            available to other companies, organizations, or individuals who
            partner with us for the distribution or publication of content on
            other media, as well as using your User Submissions for marketing
            purposes. You also waive any rights of privacy, publicity, or other
            rights of a similar nature applicable to all these uses, to the
            extent permissible under applicable law. You also agree to all such
            uses of your content with no compensation paid to you. We neither
            endorse nor assume any liability for the contents of any User
            Submission. We generally do not pre-screen, monitor, or edit User
            Submissions. However, we and our agents have the right at our sole
            discretion to remove any User Submission or other content that, in
            our judgment, does not comply with this Agreement and any other
            rules of user conduct for the Service, or is otherwise harmful,
            objectionable, offensive, or inaccurate. We are not responsible for
            any failure or delay in removing any such User Submission. You
            hereby consent to such removal and waive any claim against us
            arising out of such removal of any User Submission, whether it is
            your own or another user’s.
          </>,
        ],
      },
      {
        id: 'users-step-4-infringes-your-copyright',
        type: 'text',
        text: [
          <>
            {boldText('4. If You See Something That Infringes Your Copyright.')}{' '}
            We take claims of copyright infringement seriously. It is our policy
            to disable and/or terminate the accounts of users who are repeat
            infringers. We will respond to notices of alleged copyright
            infringement that comply with applicable law. If you believe any
            content accessible on the Service infringes your copyright, you may
            request removal of such content (or access thereto) by submitting
            written notification to our copyright agent designated below. In
            accordance with the Online Copyright Infringement Liability
            Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. §
            512) (“DMCA”), your written notice of alleged copyright infringement
            must include substantially the following:
            <ul>
              <li>your physical or electronic signature;</li>
              <li>
                identification of the copyrighted work you believe to have been
                infringed or, if the claim involves multiple works on the
                Service, a representative list of such works;{' '}
              </li>
              <li>
                identification of the content you believe to be infringing in a
                sufficiently precise manner to allow us to locate that content;
              </li>
              <li>
                adequate information by which we can contact you (including your
                name, postal address, telephone number and, if available, email
                address);
              </li>
              <li>
                a statement that you have a good faith belief that use of the
                copyrighted material is not authorized by the copyright owner,
                its agent or the law;
              </li>
              <li>
                a statement that the information in your written notice is
                accurate; and
              </li>
              <li>
                a statement, under penalty of perjury, that you are authorized
                to act on behalf of the copyright owner.
              </li>
            </ul>
            Our designated agent to receive DMCA notices is:
            <br />
            {addressFormat(
              <>
                Cory Moore
                <br />
                P.O. Box 540730
                <br />
                NSL, UT
                <br />
                cory.moore@checktheyard.com
              </>,
            )}
            *NOTE: This contact information is for inquiries regarding potential
            copyright infringement only.
            <br />
            <br />
            If you fail to comply with all of the requirements of Section
            512(c)(3) of the DMCA, your DMCA notice may not be effective. Please
            be aware that if you knowingly materially misrepresent that content
            on the Service is infringing your copyright, you may be held liable
            for damages (including costs and attorneys’ fees) under Section
            512(f) of the DMCA.
          </>,
        ],
      },
      {
        id: 'users-step-5-rules-for-use-of-service',
        type: 'text',
        text: [
          <>
            {boldText('5. Rules for Use of Service.')}
            <br />
            When you sign up for the Service, you agree to the following rules:
            <ul>
              <li>
                You represent and warrant that you are at least 18 years of age.
                If you are under age 18, you may not use the Service.
              </li>
              <li>
                You must be a human to open an account. Accounts registered by
                “bots” or other automated methods are not permitted.
              </li>
              <li>
                You will use the Service only for lawful purposes and agree to
                not use the Service in any way that will infringe upon the use
                or rights of any third party.
              </li>
              <li>
                Your use of the Service is subject to all applicable laws and
                regulations, and you are solely responsible for the substance of
                your communications through the Service.
              </li>
              <li>
                You will not upload any copyrighted, trademarked, or proprietary
                materials on the Service without the express permission of the
                owner.
              </li>
              <li>
                You agree that you will not engage in any behavior that
                constitutes unauthorized or unsolicited advertising, junk or
                bulk email (also known as “spamming”), chain letters, any other
                form of unauthorized solicitation, or any form of lottery or
                gambling.
              </li>
              <li>
                You will not share content from the Service with anyone who is
                not properly licensed to access the content.
              </li>
              <li>
                You will not upload, share, post, distribute or otherwise
                partake in any behavior that is unlawful, threatening, abusive,
                harassing, defamatory, libelous, deceptive, fraudulent, invasive
                of another’s privacy, tortious, contains explicit or graphic
                descriptions or accounts of sexual acts (including but not
                limited to sexual language of a violent or threatening nature
                directed at another individual or group of individuals), or
                otherwise violates our rules or policies.
              </li>
              <li>
                You will not partake in any behavior that victimizes, harasses,
                degrades, or intimidates an individual or group of individuals
                on the basis of religion, gender, sexual orientation, race,
                ethnicity, age, or disability.
              </li>
              <li>
                You will not share software viruses or any other computer code,
                files, or programs that are designed or intended to disrupt,
                damage, or limit the functioning of any software, hardware, or
                telecommunications equipment or to damage or obtain unauthorized
                access to any data or other information of any third party.
              </li>
              <li>
                You will not impersonate any person or entity, including any of
                our employees or representatives.
              </li>
              <li>
                You will not use your account to breach security of another
                account or attempt to gain unauthorized access to another
                network or server. Not all parts of the Service may be available
                to you or other Users of the Service. You shall not interfere
                with anyone else’s use and enjoyment of the Service. Users who
                violate systems or network security may incur criminal or civil
                liability.
              </li>
              <li>
                You will not access, tamper with, or use non-public areas of the
                Service, Company’s computer systems, or the technical delivery
                systems of Company’s service providers.
              </li>
              <li>
                You will not attempt to divert Users to another website or
                service.
              </li>
              <li>
                You will not access or search the Service by any means other
                than through our currently available search functionalities
                provided via our website. You may not use automated means to
                access the Service.
              </li>
            </ul>
          </>,
        ],
      },
      {
        id: 'users-step-6-data-policy',
        type: 'text',
        text: [
          <>
            {boldText('6. Data Policy.')} When you interact directly with
            another User, you are solely responsible for the information that
            you share with that User. We do not control what Users do with the
            information they obtain from other Users on the platform. If you
            provide any information to Company, you do so voluntarily. Company
            owns (and you hereby assign to us) all transaction information and
            all other information relating to orders placed through the Service.
            Our use of all such information we collect from or about you is
            subject to our{' '}
            <TextLink
              href="/privacy_policy"
              className={styles.link}
              textColor="leather"
            >
              Privacy Policy
            </TextLink>
            . By using the Service, you consent to our collection and use of
            data as outlined therein.
          </>,
        ],
      },
      {
        id: 'users-step-7-operation-of-service',
        type: 'text',
        text: [
          <>
            {boldText('7. Operation of the Service.')} We may modify or cease
            operating the Service or any function of the Service, with or
            without prior notice or liability to you. You use the Service at
            your sole discretion and risk. We are not responsible for any
            inaccuracies, errors or liabilities that may arise out of your use
            of the Service.
          </>,
        ],
      },
      {
        id: 'terms-for-sellers-header',
        type: 'text',
        text: [<>{sectionHeaderText('TERMS FOR SELLERS')}</>],
      },
      {
        id: 'seller-step-1-your-listings-and-content',
        type: 'text',
        text: [
          <>
            {boldText('1. Your Listings and Content.')} We may display product
            listings shared by you or on your behalf (each a “
            {boldText('Product Listing')}”) on the Service. You are responsible
            for your Product Listings. Even if we host and display your Product
            Listings on the Service, you are solely responsible for the contents
            of your Product Listings. You must ensure that all Product Listings
            are true, accurate and complete at all times, including without
            limitation the description, price, applicable taxes or fees,
            shipping information, required legal disclosures and other
            advertisement, offer or promotional content. You must ensure that
            your Product Listing provides other users of the Service (“
            {boldText('Users')}”) with the terms, conditions and policies that
            apply to the transaction for that product.
          </>,
        ],
      },
      {
        id: 'seller-step-2-your-products',
        type: 'text',
        text: [
          <>
            {boldText('2. Your Products.')} The products you offer for sale
            through the Service, your User Content and use of the Service must
            comply at all times with this Agreement, our other applicable terms
            and policies and applicable laws, rules, and regulations. You are
            the seller of record for all products you offer on the Service. You
            are solely responsible for establishing the terms governing Users’
            use of such products. You are solely responsible for determining
            that the Service is suitable for your intended use. Sales of
            counterfeit or pirated products and sales prohibited by government
            sanctions are strictly prohibited. Without limiting the generality
            of the policies set forth above, you are expressly prohibited from
            displaying, promoting, offering, marketing or selling counterfeit or
            pirated products or services through the Service. You may not use
            the Service in connection with any activities, individuals or
            entities that are located in a country or region that is subject to
            comprehensive U.S. sanctions law or in a manner that would otherwise
            violate applicable U.S. or non-U.S. trade sanctions laws. You are
            responsible for providing customer service to Users in connection
            with your products, and Company may provide assistance with such
            customer service in its sole discretion upon your or another User’s
            request. Even if we do provide such assistance, we have no control
            over and do not guarantee the performance or actions of any buyer,
            including the ability of buyers to pay for products or services you
            offer or that a buyer will actually complete a transaction.
          </>,
        ],
      },
      {
        id: 'seller-step-3-prohibited-products',
        type: 'text',
        text: [
          <>
            {boldText('3. Prohibited Products.')} The following categories of
            products are prohibited from being listed or sold via the Service:
            <ul>
              <li>Hazardous materials;</li>
              <li>Pharmaceuticals;</li>
              <li>
                Products that may not be legally sold and/or shipped in the
                United States; and
              </li>
              <li>
                Any products prohibited for sale or shipping by the Carrier or
                Payment Processor.
              </li>
            </ul>
          </>,
        ],
      },
      {
        id: 'seller-step-4-shipment-delivery',
        type: 'text',
        text: [
          <>
            {boldText('4. Shipment; Delivery.')} All purchases of products
            through the Service are made via a shipment contract with a
            third-party carrier (a “{boldText('Carrier')}”), pursuant to a quote
            that is accepted by you and the buyer via the Service. The risk of
            loss and title for such items passes from seller to buyer pursuant
            to your contract with such Carrier. Company never assumes risk of
            loss for, nor takes title to, such products, including for returned
            or refunded items. You will, at your own expense, be solely
            responsible for, and bear all liability for, the fulfillment of all
            orders, including without limitation, packaging and shipping
            products. You are responsible for properly specifying shipping
            options for all products through the Service. You will be
            responsible for all shipping, handling, and any other charges for
            each product, separate from the purchase price. You will be
            responsible for shipping all products purchased by buyers. You will
            be responsible for all shipping charges and for any costs or charges
            related to shipping-related problems, including without limitation
            damaged or lost products, late shipments or mis-delivery.
          </>,
        ],
      },
      {
        id: 'seller-step-5-returns-refunds',
        type: 'text',
        text: [
          <>
            {boldText('5. Returns; Refunds.')} You are responsible for
            providing, managing, paying for, and fulfilling any sales, warranty,
            returns, refunds or accommodations to Users who buy your products
            through the Service. You will stop and/or cancel any order if
            requested by Company; provided that, if you have transferred
            products to a Carrier, you will use commercially reasonable efforts
            to stop and or cancel delivery by the Carrier. In the event that
            your products are returned directly to Company or to a third party,
            you acknowledge and agree that such products shall be considered
            abandoned by you, and Company shall have sole discretion to handle
            the disposition of your products. You will pay any and all costs
            incurred by Company and third parties who improperly receive your
            products.
          </>,
        ],
      },
      {
        id: 'seller-step-6-recalls',
        type: 'text',
        text: [
          <>
            {boldText('6. Recalls.')} You are responsible for ensuring the
            integrity and safety of your products. As between us, you are solely
            responsible for any defect or non-conformity in any product you
            offer and for complying with any recall or safety alert, or similar
            direction or notice, with respect to any product related to your
            Product Listings. You agree to promptly remove any Product Listing
            upon issuance of any recall or safety alert, or similar direction or
            notice, or claim of infringement of intellectual property rights
            with respect to products or services relating to your Product
            Listings. You shall also notify Company within 24 hours of becoming
            aware of the recall.
          </>,
        ],
      },
      {
        id: 'seller-step-7-reporting',
        type: 'text',
        text: [
          <>
            {boldText('7. Reporting.')} You will, within a reasonable period of
            time (not to exceed 30 days) following request from Company, provide
            Company with any reports, information or other documentation
            relating to your compliance with this Agreement and applicable law
            reasonably requested by Company. In the event Company requests that
            you provide Company with copies of reports that you were required to
            file with the Consumer Product Safety Commission or any other
            regulatory agency, you will provide such reports within 7 days of
            our written request.
          </>,
        ],
      },
      {
        id: 'seller-step-8-user-data',
        type: 'text',
        text: [
          <>
            {boldText('8. User Data.')} You may only use any User’s data,
            content, or other information you receive from Company in connection
            with your use of the Service (“{boldText('User Data')}”) in
            accordance with applicable laws, rules and regulations. To the
            extent there is a conflict between or among any of the above, the
            most restrictive term, policy, law or regulation shall apply. You
            may not sell or misuse User Data. Without limiting the generality of
            the foregoing, you may only use User Data (a) to support the
            transactions arising from the User’s use of the Service or (b) for
            any purpose for which you have obtained the valid consent of the
            User or otherwise have a legal basis. You may not engage in direct
            email marketing to Users unless the User has opted into receiving
            marketing emails from you.
          </>,
        ],
      },
      {
        id: 'seller-step-9-export',
        type: 'text',
        text: [
          <>
            {boldText('9. Export.')} You agree to comply with all applicable
            export and re-export controls, embargoes, and economic and trade
            sanctions laws and regulations, including in any event, those of the
            United States and the European Union (“{boldText('Export Laws')}”).
            You represent that your products are non-controlled and that any
            product you sell via the Service will not be (i) downloaded or
            accessed by a Sanctioned Person, (ii) exported, re-exported
            (including any ‘deemed exports’), shipped, distributed, delivered,
            sold, resold, supplied, or otherwise transferred, directly or
            indirectly, to any Sanctioned Person or otherwise in a manner
            contrary to the Export Laws, (iii) used for any purpose prohibited
            by the Export Laws, or (iv) used for non-civilian purposes (e.g.
            armaments, nuclear technology, weapons, any other usage in the field
            of defense and military), unless permitted by the Export Laws or
            respective governmental licenses or approvals. Without limiting the
            foregoing, you represent and warrant that (i) you are not a
            Sanctioned Person; and (ii) you will not access or otherwise use, or
            facilitate a third party’s access or use of, the Service from a
            location in a Sanctioned Country. “{boldText('Sanctioned Country')}”
            means a country or territory that is itself the subject or target of
            any comprehensive trade or economic sanctions (currently Cuba, Iran,
            North Korea, Syria, and the Crimea region of Ukraine). “
            {boldText('Sanctioned Person')}” means any person (i) listed in the
            Specially Designated Nationals and Blocked Persons List maintained
            by the U.S. Department of Treasury’s Office of Foreign Assets
            Control or in any Export-Control-Related list of designated persons
            maintained by the U.S. Department of Commerce, the U.S. Department
            of State, the United Nations Security Council, the European Union,
            any Member State of the European Union, or the United Kingdom; (ii)
            operating, organized, or resident in a Sanctioned Country; (iii) the
            government of, or acting for or on behalf of the government of,
            Venezuela or a Sanctioned Country; or (iv) owned or controlled by
            one or more such persons.
          </>,
        ],
      },
      {
        id: 'seller-step-10-pricing',
        type: 'text',
        text: [
          <>
            {boldText('10. Pricing.')} Your Product Listing must include the
            price of the product, and you may not increase the price of any
            product once it has been ordered by a particular User. The price we
            display to other Users on the Service shall be an amount between 10%
            and 20% greater than the price you list (such increase, the “
            {boldText('Markup')}”), depending on the nature of the product and
            the size of the transaction. You bear the risk of fraud or loss,
            including the risk of chargebacks or credit card fraud associated
            with any transaction with any User.
          </>,
        ],
      },
      {
        id: 'seller-step-11-payments',
        type: 'text',
        text: [
          <>
            {boldText('11. Payments.')} When a User makes payment for a product
            you provide on the Service, Payment Processor will calculate the
            gross amount of the sale as the amount actually received by Payment
            Processor from the User (“{boldText('Gross Amount')}”). From this,
            Payment Processor will subtract the Markup (which will be retained
            by Company), any applicable taxes, shipping fees, and Payment
            Processor fees (the resulting amount, the “{boldText('Net Amount')}
            ”). Except as otherwise agreed between Company and you in writing,
            you will receive the Net Amount less any applicable deductions, such
            as refunds. If we change this payment rate, we will provide you 30
            days’ notice, which notice may be via email or by posting a notice
            through our Service. Company is not responsible for foreign currency
            conversion fees, wiring fees, or any other processing fees that you
            may incur. You are responsible and liable for all income, sales, and
            other taxes associated with the fees you earn. Company reserves the
            right to withhold any payment until it has received all tax
            identification information it requires to satisfy its obligations.
            Company reserves the right to withhold or cancel payments to you if
            it deems, in its sole judgement and absolute discretion, that you
            engaged in behavior that violated any part of this Agreement.
            Further, Company may recoup, set off, or credit against amounts
            payable to you all present and future indebtedness of you to Company
            or a Carrier arising from this or any other transaction with you or
            any of your affiliates whether or not related to this Agreement.
          </>,
        ],
      },
      {
        id: 'seller-step-12-taxes',
        type: 'text',
        text: [
          <>
            {boldText('12. Taxes.')} If Company reasonably determines that a law
            or any taxing authority requires Company to deduct or withhold any
            taxes from a payment to you, Company shall deduct and withhold any
            taxes required to be withheld by Company under applicable law as and
            when the legal obligation to withhold arises, and you hereby
            irrevocably consent to such withholdings. You agree that you will
            provide Company with appropriate withholding certificates or other
            certificates or documentation, including but not limited to IRS
            Forms W9 or W-8 before any payment is made to you under this
            Agreement, as required by law, and upon subsequent request by
            Company. You further agree to timely file all required returns,
            report any income, and pay any applicable taxes, incurred as a
            result of the payments you receive under this Agreement, and
            provide, upon request, evidence to Company, including IRS Form 4669
            (or other similar form requested by Company), that such income was
            reported.
          </>,
        ],
      },
      {
        id: 'seller-step-13-termination',
        type: 'text',
        text: [
          <>
            {boldText('13. Termination/Suspension.')} We reserve the right to
            remove Product Listings, suspend payouts, and ban Users from selling
            on the Service for any reason at any time, without prior notice,
            including in cases where a Product Listing does not comply with our
            policies or legal terms (including this Agreement); a User engages
            in behavior that might reflect unfavorably on us or bring us into
            public disrepute; a User uses the Service in a way that constitutes
            unfair competition, such as promotion of their off-site business in
            a way that violates Company’s policies; or as otherwise determined
            by Company in its sole discretion.
          </>,
        ],
      },
      {
        id: 'rights-of-company-header',
        type: 'text',
        text: [<>{sectionHeaderText('RIGHTS OF COMPANY')}</>],
      },
      {
        id: 'company-step-1-general-disclaimers',
        type: 'text',
        text: [
          <>
            {boldText('1. General Disclaimers.')} We do not hire or employ Users
            nor are we responsible or liable for any interactions involved
            between Users. We are not liable for disputes, claims, losses,
            injuries, or damage of any kind that might arise out of or relate to
            the conduct of Users.
            <p>
              THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
              WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE
              WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE
              MAKE NO WARRANTY THAT (A) THE SERVICE WILL MEET YOUR REQUIREMENTS,
              (B) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
              ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
              THE SERVICE WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE
              QUALITY OF ANY PRODUCT PURCHASED OR OBTAINED BY YOU FROM THE
              SERVICE WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES,
              ERRORS OR DEFECTS.
            </p>
            <p>
              You understand and agree that temporary interruptions of the
              Service may occur as normal events. You further understand and
              agree that we have no control over third party networks you may
              access in the course of the use of the Service, and therefore,
              delays and disruption of other network transmissions are
              completely beyond our control. While we work to protect the
              security of your account and related information, we cannot
              guarantee that unauthorized third parties will not be able to
              defeat our security measures. THE USE OF THE SERVICE OR THE
              DOWNLOADING OR OTHER ACQUISITION OF ANY MATERIALS THROUGH THE
              SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR
              AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE OR
              LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES. SOME STATES OR
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO
              SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>
          </>,
        ],
      },
      {
        id: 'company-step-2-indemnity',
        type: 'text',
        text: [
          <>
            {boldText('2. Indemnity.')} You agree to defend, indemnify and hold
            Company, its parents, subsidiaries, affiliates and their respective
            members, directors, officers, employees, stockholders and agents
            harmless against any losses, expenses, costs or damages (including
            Company’s reasonable attorneys&apos; fees and other reasonable costs
            of litigation) arising from, incurred as a result of, or in any
            manner related to (a) your breach of this Agreement, (b) your use of
            the Service, (c) your User Submissions; (d) your sale of products
            using the Service or a buyer’s use of such products, (e) your acts
            or omissions with respect to User Data, (f) any fines, penalties, or
            similar charges imposed as a result of your failure to collect,
            remit or report any taxes in connection with any transaction on the
            Service, (g) any fines, penalties, or similar charges imposed as a
            result of cancellations, chargebacks, buyer complaints or (h) your
            breach of your obligations under your agreement with Payment
            Processor or any Carrier.
          </>,
        ],
      },
      {
        id: 'company-step-3-limitation-of-liability',
        type: 'text',
        text: [
          <>
            {boldText('3. Limitation of Liability.')} IN NO EVENT SHALL WE BE
            LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE,
            INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY
            DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING
            FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT WE HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF
            LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE
            SERVICE OR OF ANY WEBSITE REFERENCED OR LINKED FROM THE SERVICE.
            FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR ANY PRODUCTS OFFERED
            THROUGH THE SERVICE OR FOR ASSISTANCE IN CONDUCTING TRANSACTIONS
            WITH OTHER USERS. IN NO EVENT SHALL THE TOTAL LIABILITY OF COMPANY
            TO YOU FOR ANY AND ALL DAMAGES, LOSSES, AND CAUSES OF ACTION EXCEED
            THE AMOUNT PAID BY YOU, IF ANY, IN CONNECTION WITH USING THE
            SERVICE. SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY
            NOT APPLY TO YOU. To the extent that we may not, as a matter of
            applicable law, limit liabilities, the extent of our liability will
            be the minimum permitted under such applicable law.
          </>,
        ],
      },
      {
        id: 'company-step-4-arbitration',
        type: 'text',
        text: [
          <>
            {boldText('4. Arbitration Agreement.')} PLEASE READ THIS SECTION
            CAREFULLY. IT AFFECTS YOUR RIGHTS AND IMPACTS THE WAY THAT YOU AND
            COMPANY BRING CLAIMS AGAINST EACH OTHER AND HOW THOSE CLAIMS ARE
            DECIDED. YOU AND COMPANY AGREE THAT ANY DISPUTE OR CLAIM RELATING IN
            ANY WAY TO THE INTERPRETATION, APPLICABILITY, ENFORCEABILITY, OR
            FORMATION OF THIS AGREEMENT OR YOUR USE OF THE SERVICE WILL BE
            RESOLVED EXCLUSIVELY THOUGH FINAL AND BINDING ARBITRATION, RATHER
            THAN IN COURT. THIS AGREEMENT TO ARBITRATE IS INTENDED TO BE BROADLY
            INTERPRETED AND INCLUDES ANY DISPUTE, CLAIM, OR CONTROVERSY BETWEEN
            YOU AND COMPANY REGARDING ANY ASPECT OF YOUR RELATIONSHIP WITH US OR
            ANY CONDUCT OR FAILURE TO ACT ON OUR PART, INCLUDING CLAIMS BASED ON
            BREACH OF CONTRACT, TORT (FOR EXAMPLE, A NEGLIGENCE CLAIM),
            VIOLATION OF LAW OR ANY CLAIMS BASED ON ANY OTHER THEORY, AND
            INCLUDING THOSE BASED ON EVENTS THAT OCCURRED PRIOR TO THE DATE OF
            THIS AGREEMENT, WITH THE FOLLOWING EXCEPTIONS: (A)YOU MAY ASSERT
            CLAIMS IN YOUR LOCAL SMALL CLAIMS COURT IF ITS RULES PERMIT IT; (B)
            ANY CLAIM REGARDING THE VALIDITY, PROTECTION OR ENFORCEMENT OF A
            PARTY’S INTELLECTUAL PROPERTY RIGHTS (SUCH AS ITS PATENT, COPYRIGHT,
            TRADEMARK, TRADE SECRET, OR MORAL RIGHTS, BUT NOT INCLUDING ITS
            PRIVACY OR PUBLICITY RIGHTS) MUST BE BROUGHT IN COURT; AND (C) IN
            THE EVENT THIS AGREEMENT TO ARBITRATE IS FOR ANY REASON HELD TO BE
            UNENFORCEABLE, ANY DISPUTE OR CLAIM AGAINST US (EXCEPT FOR
            SMALL-CLAIMS COURT ACTIONS) MAY BE COMMENCED ONLY IN A FEDERAL OR
            STATE COURT LOCATED IN SALT LAKE CITY, UTAH, AND WE BOTH CONSENT TO
            THE JURISDICTION OF AND VENUE IN THOSE COURTS FOR SUCH PURPOSES. WE
            BOTH ALSO CONSENT TO THE JURISDICTION OF AND VENUE IN THOSE COURTS
            FOR PURPOSES OF ANY CLAIM REGARDING THE VALIDITY, PROTECTION OR
            ENFORCEMENT OF A PARTY’S INTELLECTUAL PROPERTY RIGHTS (EXCLUDING ITS
            PRIVACY OR PUBLICITY RIGHTS). YOU AGREE TO WAIVE ANY AND ALL
            OBJECTIONS TO THE EXERCISE OF JURISDICTION OVER YOU BY SUCH COURTS
            AND TO VENUE IN SUCH COURTS. THIS AGREEMENT TO ARBITRATE IS GOVERNED
            BY THE FEDERAL ARBITRATION ACT, INCLUDING ITS PROCEDURAL PROVISIONS,
            IN ALL RESPECTS.
            {indent(`ANY ARBITRATION WILL BE CONDUCTED BY THE PARTIES IN
            THEIR INDIVIDUAL CAPACITIES ONLY AND NOT AS A CLASS ACTION OR OTHER
            REPRESENTATIVE ACTION. UNLESS BOTH YOU AND WE AGREE OTHERWISE, THE
            ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR
            PARTY’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
            CONSOLIDATED, REPRESENTATIVE, CLASS, OR PRIVATE ATTORNEY GENERAL
            ACTION OR PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
            (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN
            FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
            NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL
            CLAIM(S). If this specific provision is found to be unenforceable,
            then this section will be null and void and neither of us will be
            entitled to arbitrate our dispute.`)}
            {indent(
              <>
                The arbitration will be administered by the American Arbitration
                Association (“{boldText('AAA')}”) and will be governed by AAA’s
                Consumer Arbitration Rules (“{boldText('AAA Rules')}”), as
                modified by this Agreement. The AAA Rules may be accessed at
                www.adr.org or by calling the AAA at 1-800-778-7879. The
                arbitrator shall be empowered to grant whatever relief would be
                available in a court under law or in equity, and must enforce
                the same limitations stated in this Agreement as a court would.
                The arbitrator will issue an award decision in writing but will
                not provide an explanation for the award unless you or Company
                requests one. The arbitrator’s award shall be final and binding,
                and judgment on the award rendered by the arbitrator may be
                entered in any court having jurisdiction thereof.
              </>,
            )}
            {indent(`A party who intends to arbitrate' (“Claimant”) must first
            send to the other party (“Respondent”) a written notice, entitled
            “Demand for Arbitration” (“Demand”). The Demand must: (1) briefly
            explain the dispute; (2) provide the Claimant’s name and address,
            the address of the Claimant’s representative (if the Claimant has
            one), and the Respondent’s name and address (for Company, see
            below); (3) specify the amount of money in dispute, if applicable;
            (4) if the Claimant is requesting an in-person hearing, identify the
            requested location for the hearing; and (5) include a statement of
            what the Claimant wants. The Claimant must send one copy of the
            Demand to AAA at the same time the Claimant sends it to the
            Respondent. When sending a copy of the Demand to AAA, the Claimant
            must also include a copy of this arbitration agreement and any
            amendments to it and the then current filing fee required by the
            AAA. The Demand must be sent to the AAA at the following address:`)}
            {addressFormat(
              <>
                American Arbitration Association
                <br />
                Case Filing Services
                <br />
                1101 Laurel Oak Road, Suite 100
                <br />
                Voorhees, NJ 08043
              </>,
            )}
            <p>
              Payment of all filing, administration and arbitrator fees will be
              governed by the AAA’s rules. Any request for payment of filing,
              administration, and arbitrator fees by Company should be submitted
              by mail to the AAA along with your Demand and we will make
              arrangements to pay all such necessary fees directly to the AAA.
              In the event that the arbitrator determines that the claim(s) you
              assert in the arbitration are frivolous, you agree to reimburse us
              for all fees associated with the arbitration paid by Company on
              your behalf that you otherwise would be obligated to pay under the
              AAA Rules.
            </p>
            <p>
              IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN IN
              ARBITRATION, YOU AND COMPANY EACH UNCONDITIONALLY WAIVE ANY RIGHT
              TO A JURY TRIAL. THIS MEANS THAT ANY CLAIM WOULD BE DECIDED BY A
              JUDGE, NOT A JURY. FURTHER, WITH RESPECT TO ANY CLAIM THAT
              PROCEEDS IN A COURT, YOU AND WE AGREE THAT EACH OF US MAY BRING
              CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, OR
              REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING.
            </p>
          </>,
        ],
      },
      {
        id: 'company-step-5-additional-terms',
        type: 'text',
        text: [
          <>
            {boldText('5. Additional Terms.')}
            <ul>
              <li>
                {boldText('Cooperation With Government Authorities.')} If
                necessary and in accordance with applicable law, Company will
                cooperate with local, state, federal, international and/or
                worldwide government authorities to protect the Service,
                visitors, customers, Company, its parents, subsidiaries,
                affiliates and their respective members, directors, officers,
                employees, stockholders and agents and operational providers,
                from the unauthorized use of this Service.
              </li>
              <li>
                {boldText('Entire Agreement.')} This Agreement, including the
                Privacy Policy, contains the entire agreement between you and
                Company relating to the subject matter hereof, and supersedes
                any other oral or written communications relating thereto. This
                Agreement may not be amended or supplemented by (a) any document
                or form originated by you relating to the subject matter hereof,
                or (b) statements of any of Company’s employees.
              </li>
              <li>
                {boldText('Termination.')} Your right to access and use the
                Service immediately terminates without further notice upon your
                breach of this Agreement. Company may terminate this Agreement
                and/or your right to use the Service at any time, with or
                without cause. All sections under the “Terms for Sellers”
                heading and this “Rights of Company” heading survive the
                expiration or termination of this Agreement for any reason
                whatsoever.
              </li>
              <li>
                {boldText('Assignment.')} Company may assign this Agreement, in
                whole or in part, in its sole discretion.
              </li>
              <li>
                {boldText('Waiver Of Breach.')} Any failure to enforce any term
                or provision of this Agreement shall not be deemed a waiver of
                that or any other breach of that or any other term or provision
                of this Agreement. In addition, any failure to enforce any term
                or provision of this Agreement shall not constitute a waiver of
                a future breach of that or any other term or provision of this
                Agreement.
              </li>
              <li>
                {boldText('Notice.')} Company may deliver notice to you under
                this Agreement by means of electronic mail, a general notice on
                www.checktheyard.com or by written communication delivered by
                USPS to your address on record. You may give notice to us at the
                following address: {boldText('P.O. Box 540730, NSL, UT 84054')}
              </li>
              <li>
                {boldText('Headings.')} The headings of articles and sections
                contained in this Agreement are for reference purposes only and
                shall not affect in any way the meaning or interpretation of
                this Agreement.
              </li>
              <li>
                {boldText('Invalidity.')} If any provision of this Agreement
                shall be determined to be unenforceable as applied to any
                particular case or circumstance, the provision or provisions in
                question shall not be unenforceable in any other case or
                circumstance, nor shall any other provision or provisions herein
                contained thereby be or become unenforceable and such provision
                shall be reformed so that it would be enforceable to the maximum
                extent permitted in such circumstances.
              </li>
              <li>
                {boldText('Contact Us.')} If you have any questions about this
                Agreement, please contact us at help@checktheyard.com.
              </li>
            </ul>
          </>,
        ],
      },
    ],
  };

  return <Page content={termsAndConditions} />;
}

export default TermsAndConditionsScreen;
