import React from 'react';
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Box } from 'parts';
import { useScreenSize } from 'hooks';
import { SearchBar, FilterSortMenu, BottomCtaSection } from './components';
import Header from './Header';
import styles from './shoppingScreen.module.scss';

function ShoppingScreen() {
  const screenSize = useScreenSize();
  const hideFilterMenu = screenSize === 'xs' || screenSize === 'sm';

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Grid container direction="column">
        <Header />
        <Grid container className={styles.maxWidth}>
          <Grid item xs={12} className={styles.searchBar}>
            <SearchBar />
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item md={3.2}>
              {!hideFilterMenu && <FilterSortMenu />}
            </Grid>
            <Grid item xs={12} md={8}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BottomCtaSection />
    </Box>
  );
}

export default ShoppingScreen;
