import Page from 'components/Page';
import React from 'react';

function AboutScreen() {
  const about = {
    heading: 'ABOUT THE YARD',

    sections: [
      {
        id: 'textOne',
        type: 'text',
        text: 'The Yard was created to offer a modern and frictionless solution to the typical, old-school model of buying, selling, and shipping construction materials. Why? Because it was built by people who’ve been there.',
      },
      {
        id: 'textTwo',
        type: 'text',
        text: 'The Yard was founded by leaders in the construction industry with deep backgrounds in commercial construction and materials manufacturing. During their years in the business, they kept coming up against the same problem: buying more than you need for a job to keep projects on-schedule.',
      },
      {
        id: 'textThree',
        type: 'text',
        text: 'They watched as more and more of their space became devoted to remnant, unused materials without a simple solution for selling to someone who needed it. So they took matters into their own hands and built The Yard to provide an opportunity to sell that surplus to other contractors who need it, at a fair price, through a simple process.',
      },
      {
        id: 'textFour',
        type: 'text',
        text: 'They applied decades of experience, relationships, and insights to create a web-based marketplace for buying and selling building materials that apply modern e-commerce functionality to the unique material and logistical challenges of the construction world.',
      },
      {
        id: 'textFive',
        type: 'text',
        text: 'Shipping large quantities of seconds and unused material used to be a time-consuming process built on phone calls, handshakes, and paperwork with an inherent level of financial risk. Today, The Yard makes all that a thing of the past.',
      },
    ],
  };
  return <Page content={about} />;
}

export default AboutScreen;
