import React from 'react';
import colorVars from 'style/index.module.scss';
import styles from './checkbox.module.scss';
import { ReactComponent as CheckedIcon } from '../../assets/icons/checkboxCheckedIcon.svg';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkboxUncheckedIcon.svg';

type Props = {
  className?: string;
  labelClassName?: string;
  label?: string | any;
  sublabel?: string;
  checked?: boolean;
  onClick?: any;
  size?: 'small' | 'large';
  wrappedCheckbox?: boolean;
  icon?: JSX.Element;
  color?: string; // default is sand
  checkedColor?: string; // default is black
  invalid?: boolean;
};

export function Checkbox({
  className,
  labelClassName,
  label,
  sublabel,
  checked,
  onClick,
  size,
  wrappedCheckbox,
  icon,
  color = colorVars.sand,
  checkedColor = colorVars.midnightBlack,
  invalid,
  ...props
}: Props) {
  let checkboxSize = {};
  if (size) {
    checkboxSize =
      size === 'large'
        ? { width: '30px', height: '30px' }
        : { width: '20px', height: '20px' };
  }

  if (wrappedCheckbox) {
    return (
      <div
        role="checkbox"
        className={`${className} ${styles.wrappedCheckboxWrapper}`}
        onClick={onClick}
      >
        <div
          className={styles.wrappedCheckbox}
          onClick={onClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {checked ? (
            <CheckedIcon style={checkboxSize} fill={checkedColor} />
          ) : (
            <CheckboxIcon style={checkboxSize} fill={color} />
          )}
        </div>
        <div
          className={`
            ${labelClassName}
            ${styles.wrappedCheckboxLabel}
          `}
        >
          {icon && <span className={styles.wrappedCheckboxIcon}>{icon}</span>}
          {label}
        </div>
      </div>
    );
  }

  const invalidClass = invalid && styles.invalidLabel;

  return (
    <div className={`${className} ${styles.checkboxWrapper}`} onClick={onClick}>
      <div
        className={styles.checkbox}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {checked ? (
          <CheckedIcon style={checkboxSize} fill={checkedColor} />
        ) : (
          <CheckboxIcon style={checkboxSize} fill={color} />
        )}
      </div>
      <div
        className={`
          ${labelClassName}
          ${invalidClass}
          ${styles.checkboxLabel}
        `}
      >
        {label}
      </div>
      <div className={`${styles.subLabel} ${invalidClass}`}>{sublabel}</div>
    </div>
  );
}

export default Checkbox;
