import React from 'react';
import { useGetPayoutDetails } from 'hooks';
import Grid from '@mui/material/Grid';
import styles from './paymentCard.module.scss';

function SellerPayoutInfo() {
  const payoutMethod = useGetPayoutDetails();

  const payoutInfo = () => {
    const { type, icon, last4 } = payoutMethod;
    const isBankAccount = type === 'us_bank_account' || type === 'bank_account';

    return (
      <Grid container>
        <div className={styles.paymentMethodWrapper}>
          <img src={icon} alt="payment type" className={styles.paymentLogo} />
        </div>
        <Grid
          item
          xs={7}
          container
          alignItems="center"
          className={styles.paymentInfo}
        >
          {isBankAccount && <div className={styles.paymentMethod}>ACH</div>}
          ***{last4}
          <div className={styles.viaStripe}>via Stripe</div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
      container
      direction="column"
      className={styles.sectionContainer}
    >
      <div className={`${styles.sectionHeader} ${styles.largeHeaderSpace}`}>
        Settlement Method
      </div>
      <div className={`${styles.text} ${styles.paymentInfo}`}>
        {payoutInfo()}
      </div>
    </Grid>
  );
}

export default SellerPayoutInfo;
