import React from 'react';
import { Box } from 'parts';
import { useMediaQuery } from '@mui/material';
import PageHeader from './PageHeader';
import PageHeroImage from './PageHeroImage';
import PageSubheader from './PageSubheader';
import PageBodyText from './PageBodyText';
import PageCallout from './PageCallout';
import PageHeaderBackdrop from './PageHeaderBackdrop';
import { PagePropsType, SectionType } from './types';

function Page({ content, showHeaderBackdrop = true }: PagePropsType) {
  const { heading, heroImage, sections } = content;

  const renderSection = (section: SectionType) => {
    switch (section.type) {
      case 'subheader':
        return (
          <PageSubheader id={section.id} key={section.id} text={section.text} />
        );
      case 'text':
        return (
          <PageBodyText
            key={section.id}
            align={section.align}
            text={section.text}
            image={section.image}
            partial={section.partial}
          />
        );
      case 'callout':
        return <PageCallout key={section.id} text={section.text} />;
      default:
        return <div />;
    }
  };

  const isDesktop = useMediaQuery('(min-width:900px)');

  return (
    <Box>
      {showHeaderBackdrop && <PageHeaderBackdrop>{heading}</PageHeaderBackdrop>}
      <Box
        width={isDesktop ? '100%' : 'unset'}
        mb="100px"
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={isDesktop ? '0px' : '16px'}
      >
        <PageHeader>{heading}</PageHeader>
        {heroImage && <PageHeroImage src={heroImage.src} alt={heroImage.alt} />}
        {sections.map((section) => renderSection(section))}
      </Box>
    </Box>
  );
}
export default Page;
