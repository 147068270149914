import { useDispatch } from 'react-redux';
import { showResponseMessage } from 'slices/apiResponseSlice';

export default function useApiResponseMessage() {
  const dispatch = useDispatch();

  const apiResponseMessage = (message: string, duration?: number) => {
    dispatch(
      showResponseMessage({
        responseMessage: message,
        openDuration: duration ?? 4000,
      }),
    );
  };

  return { apiResponseMessage };
}
