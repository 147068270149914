import React, { useState, useEffect, useMemo } from 'react';
import SnackbarDefault from 'components/Snackbar/Snackbar';
import {
  NameForm,
  DescriptionForm,
  ConditionForm,
  AdditionalDocuments,
} from './components';
import {
  Description as DescriptionType,
  FieldValidation,
  FieldValidationBaseProps,
} from '../types';

interface FieldValidationProps extends FieldValidationBaseProps {
  setDescriptionFieldsValidation: (fields: FieldValidation[]) => void;
}

type Props = {
  descriptionInfo: DescriptionType;
  setDescriptionInfo: (val: any) => void;
  fieldValidationProps: FieldValidationProps;
};

function Description({
  descriptionInfo,
  setDescriptionInfo,
  fieldValidationProps,
}: Props) {
  const {
    validateFieldsOnClick,
    setValidateFieldsOnClick,
    setDescriptionFieldsValidation,
    handleClickScroll,
  } = fieldValidationProps;

  const {
    productName,
    productBrand,
    productColorOrStyle,
    productDescription = '',
    productImages,
    productCondition,
    productConditionRating,
    productConditionNotes,
  } = descriptionInfo;

  const [snackBar, setSnackBar] = useState({
    show: false,
    message: 'An error occurred, please try again',
  });

  const requiredFieldText = 'This field is required';

  const defaultInvalidInputs = {
    productName: false,
    productBrand: false,
    productColorOrStyle: false,
    productDescription: false,
    productImages: false,
    productCondition: false,
    productConditionRating: false,
    productConditionNotes: false,
  };

  const [invalidInputsOnClick, setInvalidInputsOnClick] =
    useState(defaultInvalidInputs);

  const fieldValidation = useMemo(
    () => [
      { displayName: 'Product Name', isMissing: !productName },
      { displayName: 'Brand', isMissing: !productBrand },
      {
        displayName: 'Color/Style',
        isMissing: !productColorOrStyle,
      },
      {
        displayName: 'Description',
        isMissing: !productDescription,
      },
      {
        displayName: 'Images',
        isMissing: productImages.length === 0,
        isInvalid: productImages.length < 3,
      },
      {
        displayName: 'Condition',
        isMissing: !productCondition,
      },
      {
        displayName: 'Condition Rating',
        isMissing: !productConditionRating,
      },
      {
        displayName: 'Condition Notes',
        isMissing: !productConditionNotes,
      },
    ],
    [
      productName,
      productBrand,
      productColorOrStyle,
      productDescription,
      productImages,
      productCondition,
      productConditionRating,
      productConditionNotes,
    ],
  );

  useEffect(() => {
    if (validateFieldsOnClick) {
      const invalidValues = {
        productName: fieldValidation[0].isMissing,
        productBrand: fieldValidation[1].isMissing,
        productColorOrStyle: fieldValidation[2].isMissing,
        productDescription: fieldValidation[3].isMissing,
        productImages: !!(
          fieldValidation[4].isMissing || fieldValidation[4].isInvalid
        ),
        productCondition: fieldValidation[5].isMissing,
        productConditionRating: fieldValidation[6].isMissing,
        productConditionNotes: fieldValidation[7].isMissing,
      };
      setInvalidInputsOnClick(invalidValues);
    }
  }, [validateFieldsOnClick, fieldValidation]);

  useEffect(
    () => handleClickScroll(fieldValidation),
    [
      validateFieldsOnClick,
      fieldValidation,
      setValidateFieldsOnClick,
      handleClickScroll,
    ],
  );

  useEffect(
    () => setDescriptionFieldsValidation(fieldValidation),
    [fieldValidation, setDescriptionFieldsValidation],
  );

  const invalidClassName = (isInvalid: boolean) =>
    isInvalid ? 'invalidInput' : '';

  const invalidProps = {
    invalidClassName,
    invalidInputsOnClick,
    setInvalidInputsOnClick,
    requiredFieldText,
  };

  const baseProps = {
    descriptionInfo,
    setDescriptionInfo,
    invalidProps,
  };

  return (
    <div>
      <NameForm baseProps={baseProps} />
      <DescriptionForm baseProps={baseProps} setSnackBar={setSnackBar} />
      <ConditionForm baseProps={baseProps} />
      <AdditionalDocuments
        descriptionInfo={descriptionInfo}
        setDescriptionInfo={setDescriptionInfo}
        setSnackBar={setSnackBar}
      />
      <SnackbarDefault
        open={snackBar.show}
        message={snackBar.message}
        onClose={() => {
          setSnackBar({
            show: false,
            message: 'An error occurred, please try again',
          });
        }}
      />
    </div>
  );
}

export default Description;
