import React, { useState, useEffect } from 'react';
import { useGetUsersByUserIdOnboardQuery } from 'api';
import { useIdentity } from 'hooks';
import Banner from 'components/Banner';
import Loading from 'components/Loading';

type Props = {
  userId: string;
};

function StripeAccountBanner({ userId }: Props) {
  const [stripeAccountSetupLink, setStripeAccountSetupLink] =
    useState<string>();
  const [buttonClicked, setButtonClicked] = useState(false);

  const { currentData: onboardQueryData } = useGetUsersByUserIdOnboardQuery(
    { userId },
    { skip: !buttonClicked },
  );

  useEffect(() => {
    if (onboardQueryData) {
      setStripeAccountSetupLink(onboardQueryData?.data?.link.url);
    }
  }, [onboardQueryData]);

  useEffect(() => {
    if (stripeAccountSetupLink) {
      // open the stripe setup in current tab so that the returnUrl and refreshUrl work properly
      window.open(stripeAccountSetupLink, '_self');
    }
  });

  // show loading until the user is navigated to the stripe account setup page
  const { location } = window;
  const showLoading = () => {
    if (buttonClicked && location.href !== stripeAccountSetupLink) {
      return <Loading screenOverlay />;
    }
    return null;
  };

  return (
    <>
      {showLoading()}
      <Banner
        bannerText="You need to complete your Stripe account"
        buttonText="COMPLETE ACCOUNT"
        buttonAction={() => setButtonClicked(true)}
        showCancelButton={false}
      />
    </>
  );
}

function ShowBanner() {
  const { stripeAccountVerified, userId } = useIdentity();

  const showCompleteStripeAccountBanner = userId && !stripeAccountVerified;

  return showCompleteStripeAccountBanner ? (
    <StripeAccountBanner userId={userId} />
  ) : null;
}

export default ShowBanner;
