import { useEffect, useState } from 'react';
import { useGetPaymentMethodsQuery } from 'api';
import amexLogo from 'assets/paymentTypeLogos/amex_logo.png';
import discoverLogo from 'assets/paymentTypeLogos/discover_logo.jpg';
import mastercardLogo from 'assets/paymentTypeLogos/mastercard_logo.svg';
import visaLogo from 'assets/paymentTypeLogos/visa_logo.png';
import achLogo from 'assets/paymentTypeLogos/ach_icon.svg';
import defaultCardIcon from 'assets/paymentTypeLogos/default_card_icon.svg';

type CardBrands = 'visa' | 'mastercard' | 'amex' | 'discover' | string; // catch all for other card brands

type Payment = {
  id: string;
  type: 'card' | 'us_bank_account' | string;
  us_bank_account?: {
    last4: string;
    networks: {
      preferred: string;
    };
  };
  card?: {
    last4: string;
    brand: CardBrands;
  };
  isDefault?: boolean;
};

const useGetPaymentDetails = () => {
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<
    Payment | any
  >();
  const [allPaymentMethods, setAllPaymentMethods] = useState<Payment[] | any[]>(
    [],
  );
  const { currentData } = useGetPaymentMethodsQuery();

  useEffect(() => {
    if (currentData?.data) {
      setDefaultPaymentMethod(currentData.data.paymentMethods.default);
      setAllPaymentMethods(currentData.data.paymentMethods.all);
    }
  }, [currentData]);

  if (!currentData) {
    return [];
  }

  const displayedContent = allPaymentMethods.map((paymentOption: Payment) => {
    let last4 = '';
    let icon = defaultCardIcon;

    switch (paymentOption.type) {
      case 'card': {
        last4 = paymentOption.card?.last4 || '';
        break;
      }
      case 'us_bank_account': {
        last4 = paymentOption.us_bank_account?.last4 || '';
        icon = achLogo;
        break;
      }
      default:
        break;
    }

    switch (paymentOption?.card?.brand) {
      case 'visa':
        icon = visaLogo;
        break;
      case 'mastercard':
        icon = mastercardLogo;
        break;
      case 'amex':
        icon = amexLogo;
        break;
      case 'discover':
        icon = discoverLogo;
        break;
      default:
        break;
    }

    return {
      id: paymentOption.id,
      type: paymentOption.type,
      icon,
      last4,
      isDefault: defaultPaymentMethod?.id === paymentOption.id,
    };
  });

  // Sort the payment methods so that the default payment method is first
  displayedContent.sort((a, b) => {
    if (a.isDefault && !b.isDefault) {
      return -1;
    }
    if (!a.isDefault && b.isDefault) {
      return 1;
    }
    return 0;
  });

  return displayedContent;
};

export default useGetPaymentDetails;
