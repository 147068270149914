import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Address, DeliveryAddress } from 'api';
import Dropdown from 'components/Dropdown';
import { isPostalCode } from 'validator';
import Checkbox from 'components/Checkbox/Checkbox';
import { AddressForm } from 'components/Forms';
import styles from './shippingAddressForm.module.scss';

type DropdownOption = {
  label: string;
  value: string;
};

type ValidateOnClickProps = {
  invalidClassName: string;
  validateFieldsOnClick: boolean;
};

type Props = {
  usersSavedAddresses: DeliveryAddress[]; // the user's saved addresses
  saveAddress: (data: any) => void; // save the address to the parent component
  shippingType: 'shipping' | 'delivery'; // for the header of the bottom section 'Shipping Details' or 'Delivery Details'
  isValidAddress: boolean;
  setIsValidAddress: (data: boolean) => void;
  dropdownLabel?: string; // default is 'Saved Addresses'
  headerText?: string; // used if there is a header above the address dropdown
  showSaveCheckbox?: boolean; // show the checkbox to save the address to the user's account
  setSaveAddressToUser?: (data: boolean) => void; // send the save checkbox value to the parent component
  prepopulateAddress?: boolean; // default is true, but at checkout we don't want to prepopulate the address
  disableSavedInputs?: boolean; // default is true
  validateOnClickProps?: ValidateOnClickProps;
};

function ShippingAddressForm({
  usersSavedAddresses,
  saveAddress,
  isValidAddress,
  setIsValidAddress,
  setSaveAddressToUser,
  dropdownLabel = 'Saved Addresses',
  headerText,
  shippingType,
  showSaveCheckbox = false,
  prepopulateAddress = true,
  disableSavedInputs = true,
  validateOnClickProps,
}: Props) {
  const { invalidClassName } = validateOnClickProps || {};

  const newAddress = useMemo(
    () => ({
      name: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      type: '',
      instructions: '',
    }),
    [],
  );

  const [displayedAddress, setDisplayedAddress] = useState<Address>(newAddress);
  const { name, address, city, state, zip } = displayedAddress;
  const isValidZipCode = zip && isPostalCode(zip, 'US');

  const [hideForm, setHideForm] = useState(true);
  const [saveAddressChecked, setSaveAddressChecked] = useState(false);
  const [resetInvalidFields, setResetInvalidFields] = useState(false);

  useEffect(() => {
    if (setSaveAddressToUser) {
      setSaveAddressToUser(saveAddressChecked);
    }
  }, [saveAddressChecked, setSaveAddressToUser]);

  const addressOption = (addressObj: Address) => {
    const label = `${addressObj.address} ${addressObj?.address2 || ''}`;
    const addCountry = {
      ...addressObj,
      country: 'US',
    };
    const value = JSON.stringify(addCountry);

    return { label, value };
  };

  // turn all the users saved addresses into dropdown options
  const savedAddressOptions = usersSavedAddresses.map((addressObj) =>
    addressOption(addressObj.address),
  );

  const addressOptions = useMemo(
    () => [
      { label: 'New Address', value: 'newAddress' },
      ...savedAddressOptions,
    ],
    [savedAddressOptions],
  );

  const [selectedAddressOption, setSelectedAddressOption] =
    useState<DropdownOption>();
  const isNewAddress = selectedAddressOption?.value === 'newAddress';

  const handleAddressChange = (selectedAddress: Address) => {
    setDisplayedAddress(selectedAddress);
    // reset invalid fields in AddressForm
    setResetInvalidFields(true);
  };

  const prepopulateAddressFields = () => {
    if (prepopulateAddress && !selectedAddressOption) {
      if (usersSavedAddresses.length === 0) {
        setSelectedAddressOption(addressOptions[0]);
        handleAddressChange(newAddress);
      } else {
        const defaultAddress =
          usersSavedAddresses.find((addressObj) => addressObj.default)
            ?.address || usersSavedAddresses[0].address;

        const defaultOption = savedAddressOptions.find(
          (option) => option.value === JSON.stringify(defaultAddress),
        );
        setSelectedAddressOption(defaultOption);
        handleAddressChange(defaultAddress);
        saveAddress(defaultAddress);
      }
    }
  };

  useEffect(prepopulateAddressFields, [
    prepopulateAddress,
    selectedAddressOption,
    savedAddressOptions,
    usersSavedAddresses,
    saveAddress,
    addressOptions,
    newAddress,
  ]);

  const showFormOnNewAddress = () => {
    if (hideForm && isNewAddress) {
      setHideForm(false);
    }
  };

  useEffect(showFormOnNewAddress, [
    selectedAddressOption,
    hideForm,
    isNewAddress,
  ]);

  const clearAddress = () => {
    // reset state
    setDisplayedAddress(newAddress);
    // reset invalid fields in AddressForm
    setResetInvalidFields(true);
    // clear the parent component's address when New Address is selected
    saveAddress(null);
  };

  const handleSaveNewAddress = useCallback(() => {
    if (isNewAddress && name && address && city && state && isValidZipCode) {
      saveAddress(displayedAddress);
    }
  }, [
    displayedAddress,
    isNewAddress,
    saveAddress,
    name,
    address,
    city,
    state,
    isValidZipCode,
  ]);

  useEffect(handleSaveNewAddress, [displayedAddress, handleSaveNewAddress]);

  const handleSelectedAddressChange = (selectedOption: DropdownOption) => {
    if (selectedOption && selectedOption.value !== 'newAddress') {
      const selectedAddress = JSON.parse(selectedOption.value);
      handleAddressChange(selectedAddress);
      // send the selected address to the parent component
      saveAddress(selectedAddress);
    } else {
      clearAddress();
    }
  };

  const disableInput = disableSavedInputs && !isNewAddress;
  const dropdownClassName = !hideForm && styles.backgroundColor;
  // using css to hide the form so that the AddressForm is still rendered & can update the parent component
  const hideFormClassName = hideForm ? styles.hideForm : '';
  // this is used in the parent to scroll to the first invalid input
  const setInvalidClassName = (isInvalid: boolean) =>
    isInvalid && invalidClassName ? invalidClassName : '';

  const showHideFormText = hideForm ? 'Show details' : 'Hide details';

  return (
    <>
      <div className={`${styles.dropdownWrapper} ${dropdownClassName}`}>
        {headerText && <div className={styles.headerText}>{headerText}</div>}
        <Dropdown
          label={selectedAddressOption ? dropdownLabel : ''}
          placeholder="Select an address"
          options={addressOptions}
          value={selectedAddressOption}
          onChange={(option: DropdownOption) => {
            setSelectedAddressOption(option);
            handleSelectedAddressChange(option);
          }}
          dropdownType="form"
          assistiveText={showHideFormText}
          assistiveTextOnClick={() => setHideForm(!hideForm)}
          containerClassName={setInvalidClassName(!selectedAddressOption)}
        />
      </div>
      <div className={`${styles.inputsWrapper} ${hideFormClassName}`}>
        <AddressForm
          address={displayedAddress}
          setAddress={setDisplayedAddress}
          isValidAddress={isValidAddress}
          setIsValidAddress={setIsValidAddress}
          showAddressInputs={isNewAddress}
          disableSavedInputs={disableInput}
          validateOnClickProps={validateOnClickProps}
          shippingAddressProps={{
            shippingType,
            resetInvalidFields,
            setResetInvalidFields,
          }}
          showNameInputForIndividuals
        />
        {showSaveCheckbox && isNewAddress && (
          <Checkbox
            label="Save Address"
            checked={saveAddressChecked}
            onClick={() => setSaveAddressChecked(!saveAddressChecked)}
          />
        )}
      </div>
    </>
  );
}

export default ShippingAddressForm;
