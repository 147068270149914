import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import colorVars from 'style/index.module.scss';
import styles from './badge.module.scss';

interface Props {
  label: string;
  onClose: () => any;
}

function Badge({ label, onClose }: Props) {
  return (
    <div className={styles.container}>
      {label}
      <CloseIcon
        onClick={onClose}
        fill={colorVars.sand20}
        className={styles.closeIcon}
      />
    </div>
  );
}

export default Badge;
