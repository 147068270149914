import React, { useState, useEffect } from 'react';
import { useGetListingsQuery, Listing } from 'api';
import Table from 'components/Table/Table';

function LatestItemsTable() {
  const [tableData, setTableData] = useState<Listing[]>([]);
  const { currentData, isError, isFetching } = useGetListingsQuery({
    page: '0',
    perPage: '9999',
    sortDirection: 'DESC',
    sortBy: 'createdAt',
  });

  const applyFilters = () => {
    if (isError) {
      // TO DO: error message handler
    }

    if (!isFetching && currentData) {
      setTableData(currentData);
    }
  };

  useEffect(applyFilters, [isFetching, currentData, isError]);

  return (
    <Table
      tableName="Latest Items"
      startingView="list"
      data={tableData}
      homeScreenTable
    />
  );
}

export default LatestItemsTable;
