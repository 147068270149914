import React from 'react';
import formStyles from 'style/formStyles.module.scss'; // used for global input styling

type Props = {
  value: string;
  onChange: (e: any) => void;
  label?: string;
  onBlur?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  className?: string;
  containerClassName?: string;
  assistiveText?: string; // text below the input to assist the user
  invalidUserInput?: boolean; // use for red border when user input is invalid
};

function TextArea({
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  readOnly = false,
  className,
  containerClassName,
  assistiveText,
  invalidUserInput,
  ...props
}: Props) {
  const showAssistiveText = assistiveText && assistiveText !== '';
  // for css color if the input contains a value
  const filledClassName = value && formStyles.filled;
  const transformLabel = value && formStyles.transform;
  // for css if the user provides an invalid value
  const invalidInput = invalidUserInput && formStyles.invalidUserInput;
  const invalidLabelStyling = invalidUserInput && formStyles.invalidLabel;

  if (label) {
    return (
      <div className={`${formStyles.outerWrapper} ${containerClassName}`}>
        <div className={formStyles.defaultTextAreaWrapper}>
          {label && (
            <label
              className={`${formStyles.defaultInputLabel} ${transformLabel} ${invalidLabelStyling}`}
              htmlFor={label}
            >
              {label}
            </label>
          )}
          <textarea
            className={`${formStyles.defaultTextArea} ${formStyles.defaultInputWithLabel} ${filledClassName} ${className} ${invalidInput}`}
            value={value || ''}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            disabled={readOnly}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        </div>
        {showAssistiveText && (
          <div
            className={`${formStyles.assistiveText} ${
              invalidUserInput && formStyles.redAssistiveText
            }`}
          >
            {assistiveText}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`${formStyles.outerWrapper} ${containerClassName}`}>
      <textarea
        className={`${formStyles.defaultTextArea} ${filledClassName} ${className} ${invalidInput}`}
        value={value || ''}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        disabled={readOnly}
      />
      {showAssistiveText && (
        <div
          className={`${formStyles.assistiveText} ${
            invalidUserInput && formStyles.redAssistiveText
          }`}
        >
          {assistiveText}
        </div>
      )}
    </div>
  );
}

export default TextArea;
