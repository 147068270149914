import React from 'react';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import yardLogo from 'assets/yardLogos/yardPrimary.svg';
import styles from './ctaItem.module.scss';

interface Props {
  containerClassName?: string;
  headerText: string;
  subtext: string;
  buttonText: string;
  customOnClick?: any;
  screenPath?: string; // path to the screen ex. "/about"
  numberOfBoldWords?: number; // default 3 - number of words in the title that should be bold
  href?: string;
}

function CtaItem({
  containerClassName,
  headerText,
  subtext,
  buttonText,
  customOnClick = null,
  screenPath = '/',
  numberOfBoldWords = 3,
  href,
}: Props) {
  const navigate = useNavigate();

  const styledTitle = () => {
    const stringArray = headerText.split(' ');
    const firstThreeWords = stringArray.slice(0, numberOfBoldWords).join(' ');
    const restOfHeader = stringArray.slice(numberOfBoldWords).join(' ');

    return (
      <Grid item xs={7.9} md={10} className={styles.headerText}>
        <span className={styles.boldHeaderText}>{firstThreeWords}</span>
        &nbsp;{restOfHeader}
      </Grid>
    );
  };

  return (
    <Grid container className={`${styles.ctaContainer} ${containerClassName}`}>
      <Grid item xs={12} md={1} className={styles.yardLogoContainer}>
        <img src={yardLogo} alt="The Yard logo" className={styles.yardLogo} />
        <div className={styles.logoVerticalLine} />
        <div className={styles.logoHorizontalLine} />
      </Grid>
      <Grid item xs={12} md={7} container className={styles.textContainer}>
        {styledTitle()}
        <Grid item xs={12} className={styles.subText}>
          {subtext}
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} className={styles.buttonContainer}>
        {href ? (
          <Button
            text={buttonText}
            containerClassName={styles.button}
            buttonColor="sand20"
            href={href}
          />
        ) : (
          <Button
            text={buttonText}
            containerClassName={styles.button}
            buttonColor="sand20"
            onClick={() => {
              if (customOnClick) {
                customOnClick();
              } else {
                navigate(screenPath);
              }
            }}
          />
        )}
      </Grid>
      <div
        className={`${styles.border} ${styles.topBorder} ${styles.leftBorder}`}
      />
      <div
        className={`${styles.border} ${styles.topBorder} ${styles.rightBorder}`}
      />
      <div
        className={`${styles.border} ${styles.bottomBorder} ${styles.leftBorder}`}
      />
      <div
        className={`${styles.border} ${styles.bottomBorder} ${styles.rightBorder}`}
      />
    </Grid>
  );
}

export default CtaItem;
