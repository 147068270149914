import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useState,
  useEffect,
} from 'react';
import { isMobilePhone } from 'validator';
import formStyles from 'style/formStyles.module.scss';

type Props = {
  className?: string;
  containerClassName?: string;
  value?: string | undefined | null;
  placeholder?: string;
  label?: string; // use this instead of placeholder if you want a floating label input
  validateMobile?: boolean;
  isInvalid?: boolean;
  setIsInvalid?: (invalid: boolean) => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  assistiveText?: string; // text below the input to assist the user
  onBlurValidationOnly?: boolean; // if true, only validate on blur - default is false
};

function PhoneInput({
  className,
  placeholder,
  onChange,
  value,
  label,
  validateMobile,
  isInvalid,
  setIsInvalid,
  containerClassName,
  required = false,
  assistiveText,
  onBlurValidationOnly = false,
  ...props
}: Props) {
  const [invalidPhone, setInvalidPhone] = useState(false);

  const validMobilePhone = (phone: string | undefined | null) => {
    if (!phone) return false;

    return isMobilePhone(phone, ['en-US']);
  };

  const formatPhoneNumber = (val: string | undefined | null) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!val) return '';

    // clean the input for any non-digit values.
    let phoneNumber = val.replace(/[^\d]/g, '');

    // remove the country code if the user added it
    if (phoneNumber.charAt(0) === '1') {
      phoneNumber = phoneNumber.substring(1);
    }

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const parentCheckForValidPhone = () => {
    // if the parent component has set isInvalid to true, then we need to set the invalidPhone state to true
    if (isInvalid !== undefined) {
      if (isInvalid && !invalidPhone) {
        setInvalidPhone(true);
      } else if (!isInvalid && invalidPhone) {
        setInvalidPhone(false);
      }
    }
  };

  useEffect(parentCheckForValidPhone, [isInvalid, invalidPhone]);

  const checkForValidPhone = (isOnBlur: boolean = false) => {
    const emptyStringIsValid = !required && value === '';
    const formattedValue = formatPhoneNumber(value);
    /* we only want to validate onBlur if it is an invalid value, but we want to 
    immediately change the input to valid if the user enters a valid value */
    const ignoreOnBlurIfValid =
      formattedValue.length === 14 || emptyStringIsValid;

    if (onBlurValidationOnly && !isOnBlur && !ignoreOnBlurIfValid) return;

    if (validateMobile) {
      if (value !== '' && !validMobilePhone(value)) {
        setInvalidPhone(true);
        if (setIsInvalid) setIsInvalid(true);
      } else if (
        (emptyStringIsValid || validMobilePhone(value)) &&
        invalidPhone
      ) {
        setInvalidPhone(false);
        if (setIsInvalid) setIsInvalid(false);
      }
      // confirm that the length is correct if we aren't validating mobile
    } else if (
      formattedValue.length !== 14 &&
      !invalidPhone &&
      !emptyStringIsValid
    ) {
      setInvalidPhone(true);
      if (setIsInvalid) setIsInvalid(true);
    } else if (
      (emptyStringIsValid || formattedValue.length === 14) &&
      invalidPhone
    ) {
      setInvalidPhone(false);
      if (setIsInvalid) setIsInvalid(false);
    }
  };

  useEffect(checkForValidPhone, [
    value,
    isInvalid,
    validateMobile,
    invalidPhone,
    setIsInvalid,
    setInvalidPhone,
    onBlurValidationOnly,
    required,
  ]);

  const showAssistiveText =
    (assistiveText && assistiveText !== '') || invalidPhone;

  // for css color if the input contains a value
  const filledClassName = value && formStyles.filled;
  // for css transform if the input contains a value
  const transformLabel = value && formStyles.transform;
  // for css if a label is present
  const inputWithLabelClassName = label && formStyles.defaultInputWithLabel;
  // for css if the user provides an invalid value
  const invalidClassName = invalidPhone ? formStyles.invalidUserInput : '';

  return (
    <div className={`${formStyles.outerWrapper} ${containerClassName}`}>
      <div
        className={`
          ${formStyles.defaultInputWrapper}
          ${filledClassName}
          ${invalidClassName}
        `}
      >
        {label && (
          <label
            className={`${formStyles.defaultInputLabel} ${transformLabel}`}
            htmlFor={label}
          >
            {label}
          </label>
        )}
        <input
          className={`
            ${formStyles.defaultInput}
            ${className}
            ${inputWithLabelClassName}
          `}
          type="tel"
          name="phone"
          placeholder={placeholder}
          value={formatPhoneNumber(value)}
          onChange={onChange}
          onBlur={(e) => {
            e.preventDefault();
            if (required && value === '') {
              setInvalidPhone(true);
            }
            if (onBlurValidationOnly) {
              checkForValidPhone(true);
            }
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
      {showAssistiveText && (
        <div
          className={`${formStyles.assistiveText} ${
            invalidPhone && formStyles.redAssistiveText
          }`}
        >
          {assistiveText || 'Invalid phone number'}
        </div>
      )}
    </div>
  );
}

export default PhoneInput;
