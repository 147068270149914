import React from 'react';
import { Listing } from 'api';
import Grid from '@mui/material/Grid';
import { ReactComponent as DocumentIcon } from 'assets/icons/myTermsIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/navigateForwardArrowIcon.svg';
import styles from './materialsSection.module.scss';
import parentStyles from '../productScreen.module.scss';

interface Props {
  listing: Listing;
}

function MaterialsSection({ listing }: Props) {
  const { seller, productDocuments = [] } = listing;

  return (
    <Grid item xs={12} className={styles.materialsContainer}>
      <div className={parentStyles.sectionHeader}>More Materials</div>
      <Grid item xs={12}>
        {productDocuments.map((productDocument) => (
          <a
            className={styles.removeATagCss}
            href={productDocument.url}
            key={productDocument.fileName}
            target="_blank"
            rel="noreferrer"
          >
            <Grid
              className={styles.documentRow}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={10} className={styles.documentLeftSide}>
                <DocumentIcon className={styles.documentIcon} />
                <Grid>
                  <div className={styles.sellerName}>{seller.name}</div>
                  <div className={styles.documentName}>
                    {productDocument.fileName}
                  </div>
                </Grid>
              </Grid>
              <ArrowIcon />
            </Grid>
          </a>
        ))}
      </Grid>
    </Grid>
  );
}

export default MaterialsSection;
