import React, { useState, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import { usePostPaymentMethodsMutation } from 'api';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import { Button } from 'components/Buttons';
import StripePayment from 'components/StripePayment';
import stripeLogo from 'assets/stripeLogos/poweredByStripe-blurple.svg';
import PaymentDetailCard, {
  PaymentDetails,
} from './PaymentDetailCard/PaymentDetailCard';
import styles from './stripe.module.scss';
import StripeAccount from './StripeAccount';

type Props = {
  userId: string;
  paymentMethods: PaymentDetails[];
  stripeAccountVerified: boolean;
};

function Stripe({ userId, paymentMethods, stripeAccountVerified }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [createPaymentMethod] = usePostPaymentMethodsMutation();
  const [clientSecret, setClientSecret] = useState('');
  const [showPaymentModal, hidePaymentModal] = useModal(
    () => (
      <StripePayment
        isAddPayment
        clientSecret={clientSecret}
        closeModal={hidePaymentModal}
      />
    ),
    [clientSecret],
  );

  useEffect(() => {
    if (clientSecret && clientSecret !== '') {
      showPaymentModal();
    }
  }, [clientSecret, showPaymentModal]);

  const handleCreatePaymentMethod = async () => {
    const response: any = await createPaymentMethod().unwrap();

    if (response.success) {
      setClientSecret(response?.data.setupIntent.client_secret);
    }
  };

  const usersPaymentMethods = () => {
    // the first payment method in the array is the default payment method (if there is one)
    const defaultPaymentMethod = paymentMethods[0]?.isDefault
      ? paymentMethods[0]
      : null;

    const otherPaymentMethods = paymentMethods.filter(
      (method: PaymentDetails) => !method.isDefault,
    );

    return (
      <Grid container>
        <Grid item xs={12} className={styles.header}>
          Payment Methods
        </Grid>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {defaultPaymentMethod && (
              <Grid item xs={12} className={styles.paymentOptionWrapper}>
                <PaymentDetailCard
                  paymentOption={defaultPaymentMethod}
                  isDefaultPayment
                  setIsLoading={setIsLoading}
                />
              </Grid>
            )}
            <Grid container columnSpacing={1}>
              {otherPaymentMethods.map((method) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={method.id}
                  className={styles.paymentOptionWrapper}
                >
                  <PaymentDetailCard
                    paymentOption={method}
                    setIsLoading={setIsLoading}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Button
                title="ADD PAYMENT METHOD"
                buttonType="secondary"
                buttonColor="sand20"
                onClick={() => handleCreatePaymentMethod()}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.formContainer} ${styles.topForm}`}>
        <StripeAccount
          userId={userId}
          stripeAccountVerified={stripeAccountVerified}
        />
      </div>
      <div className={styles.formContainer}>{usersPaymentMethods()}</div>
      <div className={styles.stripeLogoContainer}>
        <img src={stripeLogo} alt="stripe logo" className={styles.stripeLogo} />
      </div>
    </div>
  );
}

export default Stripe;
