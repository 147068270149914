import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { hideResponseMessage } from 'slices/apiResponseSlice';
import SnackbarDefault from 'components/Snackbar/Snackbar';

function ApiResponseHandler() {
  const dispatch = useDispatch();

  const apiResponseMessage = useAppSelector(
    (state) => state.apiResponse.responseMessage,
  );

  const openDuration = useAppSelector(
    (state) => state.apiResponse.openDuration,
  );

  const handleClose = () => {
    dispatch(hideResponseMessage());
  };

  if (!apiResponseMessage) return null;

  return (
    <SnackbarDefault
      open
      message={apiResponseMessage}
      onClose={handleClose}
      autoHideDuration={openDuration}
    />
  );
}

export default ApiResponseHandler;
