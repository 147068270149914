import React from 'react';
import TextInput from 'components/TextInput/TextInput';
import FormContainer from 'components/FormContainer/FormContainer';
import styles from './components.module.scss';
import { BaseProps } from '../types';

type Props = {
  baseProps: BaseProps;
};

function NameForm({ baseProps }: Props) {
  const { descriptionInfo, setDescriptionInfo, invalidProps } = baseProps;
  const { productName, productBrand, productLine } = descriptionInfo;
  const {
    invalidClassName,
    invalidInputsOnClick,
    setInvalidInputsOnClick,
    requiredFieldText,
  } = invalidProps;

  const contents = (
    <>
      <TextInput
        containerClassName={`${styles.input} ${invalidClassName(!productName)}`}
        label="Product Name*"
        value={productName}
        onChange={(e) => {
          setDescriptionInfo({
            ...descriptionInfo,
            productName: e.target.value,
          });

          if (invalidInputsOnClick.productName && e.target.value) {
            setInvalidInputsOnClick({
              ...invalidInputsOnClick,
              productName: false,
            });
          }
        }}
        assistiveText={
          invalidInputsOnClick.productName ? requiredFieldText : ''
        }
        invalidUserInput={invalidInputsOnClick.productName}
      />
      <TextInput
        containerClassName={`${styles.input} ${invalidClassName(
          !productBrand,
        )}`}
        label="Manufacturer or Brand*"
        value={productBrand}
        onChange={(e) => {
          setDescriptionInfo({
            ...descriptionInfo,
            productBrand: e.target.value,
          });

          if (invalidInputsOnClick.productBrand && e.target.value) {
            setInvalidInputsOnClick({
              ...invalidInputsOnClick,
              productBrand: false,
            });
          }
        }}
        assistiveText={
          invalidInputsOnClick.productBrand ? requiredFieldText : ''
        }
        invalidUserInput={invalidInputsOnClick.productBrand}
      />
      <TextInput
        containerClassName={styles.input}
        label="Product Line"
        value={productLine}
        onChange={(e) =>
          setDescriptionInfo({
            ...descriptionInfo,
            productLine: e.target.value,
          })
        }
      />
    </>
  );

  return (
    <FormContainer
      className={styles.form}
      title="Product Name"
      content={contents}
    />
  );
}

export default NameForm;
