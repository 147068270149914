import React from 'react';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import StandardModal from 'components/StandardModal/StandardModal';
import styles from './productListed.module.scss';

function ProductListed() {
  const navigate = useNavigate();

  const content = () => (
    <Grid container direction="column" alignItems="center">
      <div className={styles.header}>Product Listed!</div>
      <Grid item xs={12} md={6}>
        <Button
          containerClassName={styles.button}
          buttonType="secondary"
          buttonColor="sand20"
          title="LIST A NEW PRODUCT"
          onClick={() => {
            navigate('/product_listing', { replace: true });
          }}
        />
        <Button
          containerClassName={styles.button}
          buttonColor="sand20"
          title="GO TO SALES HOME"
          onClick={() => {
            navigate('/orders/sales', { replace: true });
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <StandardModal
      modalClassName={styles.productListedModal}
      modalContent={content()}
      closeModalAction={() => {
        // TS is giving an error when passing -1 with the replace object, but it is accurate so I'm ignoring it
        /* @ts-ignore */
        navigate(-1, { replace: true });
      }}
    />
  );
}

export default ProductListed;
