import React, { useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import { useLocation, useNavigate } from 'react-router';
import { useIdentity } from 'hooks';
import Grid from '@mui/material/Grid';
import EmailVerifiedModal from 'components/EmailVerifiedModal/EmailVerifiedModal';
import CtaItem from 'components/CtaItem/CtaItem';
import CtaItemsSection from './HomeCtaItems/CtaItemsSection';
import FeaturedCategories from './FeaturedCategories';
import LatestItemsTable from './LatestItemsTable';
import LastViewedItemsTable from './LastViewedItemsTable';
import styles from './homeScreen.module.scss';

interface Props {
  state: {
    verificationToken: string;
  };
}

function HomeScreen() {
  const navigate = useNavigate();
  const { loggedIn } = useIdentity();
  const { state } = useLocation() as Props;
  const verificationToken = state?.verificationToken;

  const [showEmailVerifiedModal, hideVerifiedEmailModal] = useModal(
    () => (
      <EmailVerifiedModal
        closeEmailVerifiedModal={() => hideVerifiedEmailModal()}
        verificationToken={verificationToken}
      />
    ),
    [],
  );

  useEffect(() => {
    if (verificationToken) {
      showEmailVerifiedModal();
    }
  }, [verificationToken, showEmailVerifiedModal]);

  const topSection = () => {
    if (!loggedIn) {
      return (
        <Grid item xs={12} className={styles.sectionContainer}>
          <CtaItemsSection />
        </Grid>
      );
    }
    return null;
  };

  const bottomSection = () => {
    if (loggedIn) {
      return (
        <CtaItem
          headerText="Interested in selling?"
          subtext="Listing your items is easy—You provide the details, and we'll walk you through every step along the way."
          buttonText="GET STARTED"
          numberOfBoldWords={2}
          screenPath="/product_listing"
        />
      );
    }
    return (
      <CtaItem
        headerText="Ready to join The Yard?"
        subtext="We’ll walk you through how to get started."
        buttonText="SIGN UP"
        customOnClick={() => navigate('/create_account')}
      />
    );
  };

  return (
    <Grid container>
      <div className={styles.headerWrapper}>
        <h1 className={styles.title}>Welcome to The Yard</h1>
        <div className={styles.subtitle}>The builder&apos;s marketplace.</div>
      </div>
      {topSection()}
      <Grid
        item
        xs={12}
        className={`${styles.sectionContainer} ${styles.latestItemsContainer}`}
      >
        <FeaturedCategories />
      </Grid>
      {loggedIn && (
        <Grid item xs={12} className={styles.sectionContainer}>
          <LastViewedItemsTable />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={`${styles.sectionContainer} ${styles.latestItemsContainer}`}
      >
        <LatestItemsTable />
      </Grid>
      <Grid item xs={12} className={styles.bottomCta}>
        {bottomSection()}
      </Grid>
    </Grid>
  );
}

export default HomeScreen;
