import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Address, DeliveryAddress } from 'api';
import { useIdentity } from 'hooks';
import { Box } from 'parts';
import Dropdown from 'components/Dropdown';
import TextArea from 'components/TextArea';
import { isPostalCode } from 'validator';
import TextInput from 'components/TextInput';
import Checkbox from 'components/Checkbox/Checkbox';
import StateDropdown from 'components/FormComponents/StateDropdown';
import FormValidationWrapper from 'components/FormValidationWrapper';
import addressTypes from 'constants/addressTypes';
import styles from './shippingAddressForm.module.scss';

type DropdownOption = {
  label: string;
  value: string;
};

type Props = {
  usersSavedAddresses: DeliveryAddress[]; // the user's saved addresses
  saveAddress: (data: any) => void; // save the address to the parent component
  shippingType: 'shipping' | 'delivery'; // for the header of the bottom section 'Shipping Details' or 'Delivery Details'
  isValidAddress: boolean;
  setIsValidAddress: (data: boolean) => void;
  dropdownLabel?: string; // default is 'Saved Addresses'
  headerText?: string; // used if there is a header above the address dropdown
  showSaveCheckbox?: boolean; // show the checkbox to save the address to the user's account
  setSaveAddressToUser?: (data: boolean) => void; // send the save checkbox value to the parent component
  prepopulateAddress?: boolean; // default is true, but at checkout we don't want to prepopulate the address
  disableSavedInputs?: boolean; // default is true
};

function ShippingAddressForm({
  usersSavedAddresses,
  saveAddress,
  isValidAddress,
  setIsValidAddress,
  setSaveAddressToUser,
  dropdownLabel = 'Saved Addresses',
  headerText,
  shippingType,
  showSaveCheckbox = false,
  prepopulateAddress = true,
  disableSavedInputs = true,
}: Props) {
  // should the name input label be 'Name' or 'Company'
  const { userAccountType } = useIdentity();
  const isIndividual = userAccountType === 'individual';

  const newAddress = useMemo(
    () => ({
      name: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      type: '',
      instructions: '',
    }),
    [],
  );

  const [displayedAddress, setDisplayedAddress] = useState<Address>(newAddress);
  const { name, address, address2, city, state, zip, instructions } =
    displayedAddress;
  const [type, setType] = useState<DropdownOption | null>();

  const [hideForm, setHideForm] = useState(true);
  const [saveAddressChecked, setSaveAddressChecked] = useState(false);

  useEffect(() => {
    if (setSaveAddressToUser) {
      setSaveAddressToUser(saveAddressChecked);
    }
  }, [saveAddressChecked, setSaveAddressToUser]);

  // for form validation
  const defaultInvalidInputs = useMemo(
    () => ({
      name: false,
      address: false,
      city: false,
      state: false,
      zip: false,
      type: false,
    }),
    [],
  );
  const isValidZipCode = zip && isPostalCode(zip, 'US');
  const requiredFieldText = 'This field is required';
  const [invalidInputsOnBlur, setInvalidInputsOnBlur] =
    useState(defaultInvalidInputs);

  const requiredInputs = [
    { name: !name },
    { address: !address },
    { city: !city },
    { state: !state },
    { zip: !isValidZipCode },
    { type: !type },
  ];

  const addressOption = (addressObj: Address) => {
    const label = `${addressObj.address} ${addressObj?.address2 || ''}`;
    const addCountry = {
      ...addressObj,
      country: 'US',
    };
    const value = JSON.stringify(addCountry);

    return { label, value };
  };

  // turn all the users saved addresses into dropdown options
  const savedAddressOptions = usersSavedAddresses.map((addressObj) =>
    addressOption(addressObj.address),
  );

  const addressOptions = useMemo(
    () => [
      { label: 'New Address', value: 'newAddress' },
      ...savedAddressOptions,
    ],
    [savedAddressOptions],
  );

  const [selectedAddressOption, setSelectedAddressOption] =
    useState<DropdownOption>();
  const isNewAddress = selectedAddressOption?.value === 'newAddress';

  const handleAddressChange = (selectedAddress: Address) => {
    const typeOption = addressTypes.find(
      (option) => option.value === selectedAddress.type,
    );

    setDisplayedAddress(selectedAddress);
    setType(typeOption);
  };

  const prepopulateAddressFields = () => {
    if (prepopulateAddress && !selectedAddressOption) {
      if (usersSavedAddresses.length === 0) {
        setSelectedAddressOption(addressOptions[0]);
        handleAddressChange(newAddress);
      } else {
        const defaultAddress =
          usersSavedAddresses.find((addressObj) => addressObj.default)
            ?.address || usersSavedAddresses[0].address;

        const defaultOption = savedAddressOptions.find(
          (option) => option.value === JSON.stringify(defaultAddress),
        );
        setSelectedAddressOption(defaultOption);
        handleAddressChange(defaultAddress);
        saveAddress(defaultAddress);
      }
    }
  };

  useEffect(prepopulateAddressFields, [
    prepopulateAddress,
    selectedAddressOption,
    savedAddressOptions,
    usersSavedAddresses,
    saveAddress,
    addressOptions,
    newAddress,
  ]);

  const showFormOnNewAddress = () => {
    if (hideForm && isNewAddress) {
      setHideForm(false);
    }
  };

  useEffect(showFormOnNewAddress, [
    selectedAddressOption,
    hideForm,
    isNewAddress,
  ]);

  const clearAddress = () => {
    // reset state
    setDisplayedAddress(newAddress);
    setType(null);
    setInvalidInputsOnBlur(defaultInvalidInputs);
    // clear the parent component's address when New Address is selected
    saveAddress(null);
  };

  const handleSaveNewAddress = useCallback(() => {
    if (isNewAddress && name && address && city && state && isValidZipCode) {
      saveAddress(displayedAddress);
    }
  }, [
    displayedAddress,
    isNewAddress,
    saveAddress,
    name,
    address,
    city,
    state,
    isValidZipCode,
  ]);

  useEffect(handleSaveNewAddress, [displayedAddress, handleSaveNewAddress]);

  const handleSelectedAddressChange = (selectedOption: DropdownOption) => {
    if (selectedOption && selectedOption.value !== 'newAddress') {
      const selectedAddress = JSON.parse(selectedOption.value);
      handleAddressChange(selectedAddress);
      // send the selected address to the parent component
      saveAddress(selectedAddress);

      // if the user selects a saved address, highlight any missing required fields
      setInvalidInputsOnBlur({
        name: !selectedAddress.name,
        address: !selectedAddress.address,
        city: !selectedAddress.city,
        state: !selectedAddress.state,
        zip: !selectedAddress.zip,
        type: !selectedAddress.type,
      });
    } else {
      clearAddress();
    }
  };

  const allRequiredFieldsFilled = useCallback(() => {
    if (name && address && city && state && isValidZipCode && type) {
      return true;
    }
    return false;
  }, [name, address, city, state, type, isValidZipCode]);

  const validAddress = () => {
    if (allRequiredFieldsFilled()) {
      setIsValidAddress(true);
    } else if (isValidAddress) {
      setIsValidAddress(false);
    }
  };

  useEffect(validAddress, [
    name,
    address,
    city,
    state,
    zip,
    type,
    isValidAddress,
    setIsValidAddress,
    allRequiredFieldsFilled,
  ]);

  const disableInput = disableSavedInputs && !isNewAddress;
  const dropdownClassName = !hideForm && styles.backgroundColor;

  let showHideFormText = hideForm ? 'Show details' : 'Hide details';
  if (!isValidAddress) showHideFormText = '';

  const zipCodeAssistiveText = () => {
    if (invalidInputsOnBlur.zip) {
      if (!!zip && !isValidZipCode) {
        return 'Invalid zip code';
      }
      return requiredFieldText;
    }
    return '';
  };

  return (
    <FormValidationWrapper
      wrapperId="address-form"
      requiredInputs={requiredInputs}
      defaultInvalidInputs={defaultInvalidInputs}
      formIsValid={allRequiredFieldsFilled()}
      invalidInputsOnBlur={invalidInputsOnBlur}
      setInvalidInputsOnBlur={setInvalidInputsOnBlur}
    >
      <div className={`${styles.dropdownWrapper} ${dropdownClassName}`}>
        {headerText && <div className={styles.headerText}>{headerText}</div>}
        <Dropdown
          label={selectedAddressOption ? dropdownLabel : ''}
          placeholder="Select an address"
          options={addressOptions}
          value={selectedAddressOption}
          onChange={(option: DropdownOption) => {
            setSelectedAddressOption(option);
            handleSelectedAddressChange(option);
          }}
          dropdownType="form"
          assistiveText={showHideFormText}
          assistiveTextOnClick={() => setHideForm(!hideForm)}
        />
      </div>
      {!hideForm && (
        <div className={styles.inputsWrapper}>
          <form autoComplete="on">
            <Box mb={9}>
              <TextInput
                value={name}
                name="name"
                disabled={disableInput && !invalidInputsOnBlur.name}
                onChange={(e) => {
                  setDisplayedAddress({
                    ...displayedAddress,
                    name: e.target.value,
                  });

                  if (invalidInputsOnBlur.name && e.target.value) {
                    setInvalidInputsOnBlur({
                      ...invalidInputsOnBlur,
                      name: false,
                    });
                  }
                }}
                label={isIndividual ? 'Name*' : 'Company*'}
                invalidUserInput={invalidInputsOnBlur.name}
                assistiveText={
                  invalidInputsOnBlur.name ? requiredFieldText : ''
                }
              />
            </Box>
            {isNewAddress && (
              <>
                <Box mb={9}>
                  <TextInput
                    name="address"
                    label="Address*"
                    value={address}
                    disabled={disableInput && !invalidInputsOnBlur.address}
                    onChange={(e) => {
                      setDisplayedAddress({
                        ...displayedAddress,
                        address: e.target.value,
                      });

                      if (invalidInputsOnBlur.address && e.target.value) {
                        setInvalidInputsOnBlur({
                          ...invalidInputsOnBlur,
                          address: false,
                        });
                      }
                    }}
                    invalidUserInput={invalidInputsOnBlur.address}
                    assistiveText={
                      invalidInputsOnBlur.address ? requiredFieldText : ''
                    }
                  />
                </Box>
                <Box mb={9}>
                  <TextInput
                    value={address2}
                    disabled={disableInput}
                    onChange={(e) => {
                      setDisplayedAddress({
                        ...displayedAddress,
                        address2: e.target.value,
                      });
                    }}
                    name="address2"
                    label="Address Line 2"
                  />
                </Box>
              </>
            )}
            <Box mb={9}>
              <TextInput
                name="city"
                value={city}
                disabled={disableInput && !invalidInputsOnBlur.city}
                onChange={(e) => {
                  setDisplayedAddress({
                    ...displayedAddress,
                    city: e.target.value,
                  });

                  if (invalidInputsOnBlur.city && e.target.value) {
                    setInvalidInputsOnBlur({
                      ...invalidInputsOnBlur,
                      city: false,
                    });
                  }
                }}
                label="City*"
                invalidUserInput={invalidInputsOnBlur.city}
                assistiveText={
                  invalidInputsOnBlur.city ? requiredFieldText : ''
                }
              />
            </Box>
            <Box mb={9} display="flex" justifyContent="space-between">
              <Box width="30%" mr={2} minWidth="110px">
                <StateDropdown
                  value={state}
                  disabled={disableInput && !invalidInputsOnBlur.state}
                  onChange={(value) => {
                    if (displayedAddress.state !== value.value) {
                      setDisplayedAddress({
                        ...displayedAddress,
                        state: value.value,
                      });
                    }
                    if (invalidInputsOnBlur.state && value.value) {
                      setInvalidInputsOnBlur({
                        ...invalidInputsOnBlur,
                        state: false,
                      });
                    }
                  }}
                  showAsterisk
                  invalidUserInput={invalidInputsOnBlur.state}
                  assistiveText={
                    invalidInputsOnBlur.state ? requiredFieldText : ''
                  }
                />
              </Box>
              <Box width="70%">
                <TextInput
                  name="zip"
                  value={zip}
                  disabled={disableInput && !invalidInputsOnBlur.zip}
                  onChange={(e) => {
                    const val = e.target.value;
                    setDisplayedAddress({
                      ...displayedAddress,
                      zip: val,
                    });

                    if (invalidInputsOnBlur.zip && isPostalCode(val, 'US')) {
                      setInvalidInputsOnBlur({
                        ...invalidInputsOnBlur,
                        zip: false,
                      });
                    }
                  }}
                  label="Zip*"
                  invalidUserInput={invalidInputsOnBlur.zip}
                  assistiveText={zipCodeAssistiveText()}
                />
              </Box>
            </Box>
          </form>
          <div className={styles.addressTypeSection}>
            <div className={styles.headerText}>{shippingType} Details</div>
            <Box mb={9}>
              <Dropdown
                label="Type of Address*"
                options={addressTypes}
                value={type}
                onChange={(option: DropdownOption) => {
                  setType(option);

                  setDisplayedAddress({
                    ...displayedAddress,
                    type: option.value,
                  });

                  if (invalidInputsOnBlur.type && option) {
                    setInvalidInputsOnBlur({
                      ...invalidInputsOnBlur,
                      type: false,
                    });
                  }
                }}
                dropdownType="form"
                assistiveText="What type of location is this?"
                invalidUserInput={invalidInputsOnBlur.type}
                key={`address_type_key__${JSON.stringify(type)}`} // This will force Select to re-render itself when the selection is updated
              />
            </Box>
            <Box mb={9}>
              <TextArea
                label="Delivery Instructions"
                value={instructions || ''}
                onChange={(e) => {
                  setDisplayedAddress({
                    ...displayedAddress,
                    instructions: e.target.value,
                  });
                }}
              />
            </Box>
            {showSaveCheckbox && isNewAddress && (
              <Checkbox
                label="Save Address"
                checked={saveAddressChecked}
                onClick={() => setSaveAddressChecked(!saveAddressChecked)}
              />
            )}
          </div>
        </div>
      )}
    </FormValidationWrapper>
  );
}

export default ShippingAddressForm;
