import React from 'react';
import Page from 'components/Page';

type Props = {
  type: 'listing' | 'url'; // use 'listing' for listing not found; use 'url' for url not found
};

function NotFound404({ type }: Props) {
  let headerText = 'Sorry, this listing is no longer available.';
  let bodyText =
    'The materials you were watching for have likely sold. Feel free to search for other listings that match the materials you were looking for. New listings go live every day on The Yard.';

  if (type === 'url') {
    headerText = 'Sorry, the page you’re looking for doesn’t exist.';
    bodyText =
      'If you pasted a link, double check for errors. If it’s something else, please contact us.';
  }

  const content = {
    heading: headerText,
    sections: [
      {
        id: '404-body',
        type: 'text',
        text: bodyText,
      },
    ],
  };

  return <Page content={content} showHeaderBackdrop={false} />;
}

export default NotFound404;
