/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import Ripples from 'react-ripples';
import { COLORS } from 'style/colors';
import styles from './ripple.module.scss';

function CoreButton(props: MuiButtonProps) {
  const { variant, color } = props;
  const displaySettings = {
    base: COLORS.sand20,
    font: COLORS.white,
    rollover: COLORS.sand20Rollover,
    border: '',
    borderRollover: '',
    fontRollover: '',
  };

  if (color === 'secondary') {
    displaySettings.base = COLORS.midnightBlack;
    displaySettings.rollover = COLORS.midnightRollover;
  }

  if (variant === 'outlined') {
    if (color === 'secondary') {
      displaySettings.base = '';
      displaySettings.rollover = COLORS.midnightBlack;
      displaySettings.font = COLORS.midnightBlack;
      displaySettings.border = `solid 2px ${COLORS.midnightBlack}`;
      displaySettings.borderRollover = `solid 2px ${COLORS.midnightBlack}`;
      displaySettings.fontRollover = COLORS.white;
    } else {
      displaySettings.base = '';
      displaySettings.font = COLORS.sand20;
      displaySettings.border = `solid 2px ${COLORS.sand20}`;
      displaySettings.borderRollover = `solid 2px ${COLORS.sand20Rollover}`;
      displaySettings.fontRollover = COLORS.white;
    }
  }

  if (variant === 'text') {
    if (color === 'secondary') {
      displaySettings.base = '';
      displaySettings.rollover = COLORS.midnightBlack;
      displaySettings.font = COLORS.midnightBlack;
      displaySettings.border = `solid 2px transparent`;
      displaySettings.borderRollover = `solid 2px ${COLORS.midnightBlack}`;
      displaySettings.fontRollover = COLORS.white;
    } else {
      displaySettings.base = '';
      displaySettings.font = COLORS.sand20;
      displaySettings.border = `solid 2px transparent`;
      displaySettings.rollover = '#cbb8a6';
      displaySettings.borderRollover = `solid 2px #cbb8a6`;
      displaySettings.fontRollover = COLORS.white;
    }
  }

  return (
    <MuiButton
      {...props}
      fullWidth
      disableRipple // Ideally we'd use MUI's ripple, but we can't easily adjust its color
      sx={{
        backgroundColor: displaySettings.base,
        border: displaySettings.border,
        borderRadius: 0,
        color: displaySettings.font,
        fontSize: '0.875rem',
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: '1.75px',

        '&:hover': {
          border: displaySettings.borderRollover,
          backgroundColor: displaySettings.rollover,
          color: displaySettings.fontRollover,
        },
      }}
    />
  );
}

function Button(props: MuiButtonProps) {
  const { disableRipple } = props;

  if (disableRipple) {
    return <CoreButton {...props} />;
  }

  return (
    <Ripples during={600} color={COLORS.ripple} className={styles.ripple}>
      <CoreButton {...props} />
    </Ripples>
  );
}

export default Button;
