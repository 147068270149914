import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isStrongPassword } from 'validator';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router';
import { usePostUsersResetPasswordMutation } from 'api';
import TextLink from 'components/Links/TextLink';
import { Button } from 'components/Buttons';
import { TextInput } from 'components/TextInput/TextInput';
import SnackbarDefault from 'components/Snackbar/Snackbar';
import FormContainer from 'components/FormContainer/FormContainer';
import StandardModal from 'components/StandardModal/StandardModal';
import styles from './resetPassword.module.scss';

function ResetPasswordContent(sendToHomeScreen: any, token: string) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordCreated, setPasswordCreated] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidConfPass, setInvalidConfPass] = useState(false);

  const strongPassword = isStrongPassword(password); // default min requirements: 8 chars, 1 symbol, 1 number, upper and lower case chars
  const nextButtonDisabled = password !== confirmPassword || !strongPassword;
  const nextButtonEnabled = password === confirmPassword && strongPassword;

  const determineIfInvalidPassword = () => {
    if (password !== '' && !strongPassword) {
      setInvalidPassword(true);
    } else if (password === '' || (strongPassword && invalidPassword)) {
      setInvalidPassword(false);
    }
  };

  useEffect(determineIfInvalidPassword, [
    password,
    strongPassword,
    invalidPassword,
  ]);

  const determineIfInvalidConfirmedPassword = () => {
    if (
      confirmPassword !== '' &&
      confirmPassword !== password &&
      !invalidConfPass
    ) {
      setInvalidConfPass(true);
    } else if (
      confirmPassword === '' ||
      (nextButtonEnabled && invalidConfPass)
    ) {
      setInvalidConfPass(false);
    }
  };

  useEffect(determineIfInvalidConfirmedPassword, [
    password,
    confirmPassword,
    invalidConfPass,
    nextButtonEnabled,
  ]);

  const [updatePasswordApiCall] = usePostUsersResetPasswordMutation();

  const updatePassword = async () => {
    try {
      const payload: any = await updatePasswordApiCall({
        body: {
          token,
          password,
        },
      }).unwrap();

      if (payload.success) {
        setPasswordCreated(true);
      }
    } catch (err) {
      setShowSnackbar(true);
    }
  };

  if (passwordCreated) {
    setTimeout(() => {
      sendToHomeScreen();
    }, 5000);
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>Password changed!</div>
        <div className={styles.text}>
          You&apos;re all set. This window will close and you&apos;ll be
          automatically redirected to The Yard in a few seconds.
          <br />
          This window will close in a few seconds.
          <div className={styles.closeButton}>
            <TextLink to="/" textColor="leather">
              If not, click here.
            </TextLink>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <SnackbarDefault
        open={showSnackbar}
        message="An error occurred, please try again"
        onClose={() => {
          setShowSnackbar(false);
        }}
      />
      <FormContainer
        title="Create a new password"
        actionNeeded
        content={
          <>
            <TextInput
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              invalidUserInput={invalidPassword}
            />
            <PasswordStrengthBar
              className="create-password__password-strength-bar"
              password={password}
              minLength={0} // set to 0 so we don't see 'too short'
              scoreWords={[
                'Strength: poor',
                'Strength: poor',
                'Strength: okay',
                'Strength: good',
                'Strength: strong',
              ]}
            />
            <div className={styles.passwordRequirementsContainer}>
              <ul className={styles.requiredList}>
                <li>Must be at least 8 characters</li>
                <li>Must contain upper and lower case letters</li>
                <li>Must contain at least 1 number and special character</li>
              </ul>
            </div>
            <TextInput
              label="Confirm password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              invalidUserInput={invalidConfPass}
            />
            <div className={styles.buttonContainer}>
              <Button
                text="NEXT"
                containerClassName={styles.nextButton}
                onClick={updatePassword}
                disabled={nextButtonDisabled}
                buttonColor="sand20"
                roundedButton
              />
            </div>
          </>
        }
      />
      <div className={styles.emailTheYard}>
        <TextLink to="/help" textColor="leather">
          Email The Yard for other issues.
        </TextLink>
      </div>
    </div>
  );
}

function ResetPasswordModal() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const token = params.get('token') ?? '';

  const sendToHomeScreen = () => {
    navigate('/');
  };

  return (
    <StandardModal
      contentWrapperClassName={styles.modalContent}
      buttonContainerClassName={styles.modalButtons}
      modalContent={ResetPasswordContent(sendToHomeScreen, token)}
      closeModalAction={() => {
        sendToHomeScreen();
      }}
    />
  );
}

export default ResetPasswordModal;
