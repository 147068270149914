import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks';
import { useGetListingsQuery } from 'api';
import Table from 'components/Table/Table';
import styles from './searchItemsTable.module.scss';

function SearchResultsTable() {
  const [tableData, setTableData] = useState<any>([]);
  const resultsCount = tableData.length;

  const reduxFiltersAndSort = useAppSelector((state) => state.shopping);
  const { selectedFilters, selectedSort, keywords = '' } = reduxFiltersAndSort;
  const categoryFilters = selectedFilters?.category || [];
  const subcategoryFilters = selectedFilters?.subcategory || [];

  const capitalizeCategories = categoryFilters.map((name) =>
    name.toUpperCase(),
  );

  const apiArgs = {
    ...(categoryFilters.length > 0 && {
      category: capitalizeCategories.join('|'),
    }),
    ...(subcategoryFilters.length > 0 && {
      subcategory: subcategoryFilters.join('|'),
    }),
    ...(selectedSort && { sortBy: selectedSort }),
    ...(keywords !== '' && { keyword: keywords }),
    page: '0',
    perPage: '99999',
    sortDirection: 'DESC',
  };

  const { currentData, isError, isFetching } = useGetListingsQuery(apiArgs);

  const applyFilters = () => {
    if (isError) {
      // TO DO: error message handler
    }

    if (!isFetching && currentData) {
      setTableData(currentData);
    }
  };

  useEffect(applyFilters, [
    selectedFilters,
    selectedSort,
    isFetching,
    isError,
    currentData,
  ]);

  return (
    <Table
      sortTable
      tableName={`Showing ${resultsCount} results`}
      startingView="card"
      data={tableData}
      className={styles.table}
      pageSize={10}
      hideShowAllLink
      emptyTableMessage="No results found"
    />
  );
}

export default SearchResultsTable;
