import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetCartQuery, Cart } from 'api';
import { useIdentity, useIsMobile } from 'hooks';
import { IconButton } from 'components/Buttons';
import { ReactComponent as CartIcon } from 'assets/icons/cartIcon.svg';
import styles from './cartIconButton.module.scss';

type Props = {
  location: 'header' | 'mainMenu';
  isInSellMode?: boolean;
  onClick?: () => any;
};

type CartIconLoggedInProps = {
  cartButton: JSX.Element;
  isMainMenu: boolean;
};

function CartIconLoggedInButton({
  isMainMenu,
  cartButton,
}: CartIconLoggedInProps) {
  const [cartItems, setCartItems] = useState<Cart[]>([]);

  const { currentData } = useGetCartQuery();

  useEffect(() => {
    if (currentData?.success) {
      setCartItems(currentData?.data);
    }
  }, [currentData]);

  const activeCart = cartItems.length > 0;

  if (activeCart) {
    const menuButtonClassName = isMainMenu
      ? styles.menuActiveCart
      : styles.headerActiveCart;

    return (
      <div className={styles.activeCartWrapper}>
        {cartButton}
        <div className={`${styles.activeCart} ${menuButtonClassName}`}>
          {cartItems.length}
        </div>
      </div>
    );
  }

  return cartButton;
}

function CartIconButton({ location, isInSellMode, onClick }: Props) {
  const { loggedIn } = useIdentity();
  const navigate = useNavigate();
  const isMainMenu = location === 'mainMenu';
  const isMobileScreen = useIsMobile();

  const cartButton = () => {
    if (isMainMenu) {
      return (
        <IconButton
          icon={CartIcon}
          onClick={() => {
            navigate('/shopping_cart');
            if (onClick) onClick(); // needed to close the menu
          }}
          label="Cart"
          labelPosition="right"
        />
      );
    }

    return (
      <IconButton
        className={`${styles.buttonWrapper} ${styles.cartButton}`}
        avatarClassName={styles.iconAvatar}
        iconClassName={styles.icon}
        icon={CartIcon}
        label={isMobileScreen ? '' : 'Cart'}
        onClick={() => navigate('/shopping_cart')}
        textColor={isInSellMode ? 'sand-bright' : 'black'}
      />
    );
  };

  if (!loggedIn) {
    return cartButton();
  }

  return (
    <CartIconLoggedInButton isMainMenu={isMainMenu} cartButton={cartButton()} />
  );
}

export default CartIconButton;
