import React, { useState, useEffect } from 'react';
import { useGetListingsQuery } from 'api';
import { useAppSelector } from 'hooks';
import Grid from '@mui/material/Grid';
import Table from 'components/Table/Table';
import styles from './subcategoryTable.module.scss';

interface Props {
  subcategory: string;
  className?: string;
}

function SubcategoryTable({ subcategory, className }: Props) {
  const reduxFiltersAndSort = useAppSelector((state) => state.shopping);
  const [tableData, setTableData] = useState<any>([]);
  const { selectedFilters, selectedSort } = reduxFiltersAndSort;

  const apiArgs = {
    subcategory,
    sortBy: 'createdAt',
    page: '0',
    perPage: '99999',
    sortDirection: 'DESC',
  };

  const { currentData, isError, isFetching } = useGetListingsQuery(apiArgs);

  const applyFilters = () => {
    if (isError) {
      // TO DO: error message handler
    }

    if (!isFetching && currentData) {
      setTableData(currentData);
    }
  };

  useEffect(applyFilters, [
    selectedFilters,
    selectedSort,
    isFetching,
    isError,
    currentData,
  ]);

  return (
    <Table
      tableName={
        <Grid container className={styles.tableHeader}>
          <div className={styles.latestIn}>Latest In</div> &nbsp;
          <Grid item xs={9} className={styles.subcategory}>
            {subcategory.toLowerCase()}
          </Grid>
        </Grid>
      }
      startingView="list"
      data={tableData}
      sortTable
      className={`${styles.table} ${className}`}
      emptyTableMessage="No results found"
    />
  );
}

export default SubcategoryTable;
