import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Button } from 'components/Buttons';
import colorVars from 'style/index.module.scss';

interface Props {
  open: boolean;
  onClose: (fireApiCall?: boolean) => void;
  message: string;
  showCancel?: boolean; // default is false
  autoHideDuration?: number; // default is 4000
}

export interface SnackbarObj {
  open: boolean;
  message: string;
  autoHideDuration?: number;
}

function SnackbarDefault({
  open,
  onClose,
  message,
  showCancel = false,
  autoHideDuration = 4000,
}: Props) {
  return (
    <Snackbar
      className="default-snackbar"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={() => (showCancel ? onClose(true) : onClose())}
      message={message}
      autoHideDuration={autoHideDuration}
      action={
        showCancel && (
          <Button
            text="CANCEL"
            onClick={() => onClose(false)}
            buttonColor="sand20"
            buttonClassName="default-snackbar__cancel"
          />
        )
      }
      sx={{ borderRadius: '4px' }}
      ContentProps={{
        sx: {
          fontFamily: 'Roboto',
          fontSize: '14px',
          lineHeight: '1.14',
          letterSpacing: '0.44px',
          color: colorVars.white,
          backgroundColor: colorVars.midnightBlack,
        },
      }}
    />
  );
}

export default SnackbarDefault;
