import React, { useState, useEffect } from 'react';
import { useGetUsersByUserIdDashboardQuery } from 'api';
import { ReactComponent as OpenInNewIcon } from 'assets/icons/openInNewIcon.svg';
import Loading from 'components/Loading';
import { Button } from 'components/Buttons';
import { COLORS } from 'style/colors';

type Props = {
  userId: string;
};

function ViewDashboardButton({ userId }: Props) {
  const [stripeDashboardLink, setStripeDashboardLink] = useState<string>();
  const [buttonClicked, setButtonClicked] = useState(false);

  const { currentData } = useGetUsersByUserIdDashboardQuery(
    { userId },
    { skip: !buttonClicked },
  );

  useEffect(() => {
    if (currentData) {
      setStripeDashboardLink(currentData?.data?.link.url);
    }
  }, [currentData]);

  useEffect(() => {
    if (stripeDashboardLink) {
      // open the stripe dashboard in a new tab
      window.open(stripeDashboardLink, '_blank');
      // reset the button state so that the button can be clicked again
      setButtonClicked(false);
    }
  }, [stripeDashboardLink]);

  if (buttonClicked && !stripeDashboardLink) {
    return <Loading screenOverlay />;
  }

  return (
    <Button
      title="VIEW STRIPE DASHBOARD"
      onClick={() => setButtonClicked(true)}
      icon={<OpenInNewIcon fill={COLORS.sand20} />}
      buttonType="secondary"
      buttonColor="sand20"
    />
  );
}

export default ViewDashboardButton;
