import { useGetUsersByUserIdQuery } from 'api';
import { useAppSelector } from 'hooks';
import { selectUserId } from 'slices/accountSlice';

const useGetUserDetails = () => {
  const userId = useAppSelector((state) => selectUserId(state.account)) ?? '';
  // need to use currentData instead of data because after logging out the data object still contains the successful user object
  const apiResponse = useGetUsersByUserIdQuery(
    { userId },
    { skip: !userId },
  ).currentData;

  return apiResponse;
};

export default useGetUserDetails;
