import React from 'react';
import { useGetPaymentDetails } from 'hooks';
import Dropdown from 'components/Dropdown/Dropdown';
import Grid from '@mui/material/Grid';
import styles from './savedPaymentMethods.module.scss';

export type PaymentDetails = {
  id: string;
  type: string;
  icon: string;
  last4: string;
  isDefault: boolean;
};

type Props = {
  setSelectedPaymentMethod: (paymentMethod: PaymentDetails | null) => void;
};

function SavedPaymentMethods({ setSelectedPaymentMethod }: Props) {
  const paymentMethodDetails = useGetPaymentDetails();

  if (!paymentMethodDetails.length) return null;

  const savedPaymentOptions = paymentMethodDetails.map((method) => {
    const labelWithIcon = (
      <Grid container className={styles.optionWrapper}>
        <Grid item xs={1} className={styles.iconWrapper}>
          <img
            src={method.icon}
            alt="payment type icon"
            className={styles.icon}
          />
        </Grid>
        <Grid
          item
          xs={11}
          container
          alignItems="center"
          className={styles.infoWrapper}
        >
          <span>
            {method.type === 'us_bank_account' && 'ACH '} ***{method.last4}
          </span>
          {method.isDefault && (
            <div className={styles.defaultText}>Default</div>
          )}
        </Grid>
      </Grid>
    );

    return {
      label: labelWithIcon,
      value: method.id,
    };
  });

  const dropdownOptions = [
    ...savedPaymentOptions,
    {
      label: 'Add Payment Method',
      value: undefined,
    },
  ];

  const setSelectedPaymentId = (id: string) => {
    const savedMethod = paymentMethodDetails.find((method) => method.id === id);

    if (savedMethod) {
      setSelectedPaymentMethod(savedMethod);
    } else {
      setSelectedPaymentMethod(null);
    }
  };

  return (
    <Grid container className={styles.wrapper}>
      <Dropdown
        label="Saved Payment Methods"
        options={dropdownOptions}
        containerClassName={styles.dropdownWrapper}
        onChange={(option: any) => setSelectedPaymentId(option.value)}
      />
    </Grid>
  );
}

export default SavedPaymentMethods;
