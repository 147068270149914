import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Order } from 'api';
import Grid from '@mui/material/Grid';
import Breadcrumbs from 'components/Breadcrumbs';
import { Button } from 'components/Buttons';
import { OrderMenu } from 'components/OrderComponents';
import StepTracker, { Step } from 'components/StepTracker/StepTracker';
import { ReactComponent as YardPrimary } from 'assets/yardLogos/yardPrimary.svg';
import { ReactComponent as OrderPlacedIcon } from 'assets/icons/statuses/listedStatusIcon.svg';
import { ReactComponent as ShippedIcon } from 'assets/icons/statuses/shippingStatusIcon.svg';
import { ReactComponent as CompleteIcon } from 'assets/icons/statuses/completeStatusIcon.svg';
import { ReactComponent as CanceledIcon } from 'assets/icons/circleCancelIcon.svg';
import styles from './header.module.scss';

type Props = {
  order: Order;
  isBuyer: boolean;
};

function Header({ order, isBuyer }: Props) {
  const navigate = useNavigate();
  const {
    listing,
    status: orderStatus,
    createdAt = '',
    canceled,
    refunded,
    logs = [],
  } = order;

  const canceledDueToPaymentFailure = logs.some(
    (log) => log.status === 'PAYMENT-FAILED',
  );

  let step: 'step1' | 'step2' | 'step3' = 'step1';

  switch (orderStatus) {
    case 'DRAFT':
    case 'PENDING-PAYMENT':
    case 'SUBMITTED':
    case 'PAID':
      step = 'step1';
      break;
    case 'PAYMENT-FAILED':
    case 'SHIPPED':
    case 'AWAITING-PICKUP':
      step = 'step2';
      break;
    default:
      // all other statuses are considered complete
      step = 'step3';
      break;
  }

  const createdAtDate = () => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as const;

    return new Date(createdAt).toLocaleDateString('en-US', options);
  };

  let stepIcons = [
    <OrderPlacedIcon className={styles.whiteIcon} />,
    ...(canceled
      ? [<CanceledIcon className={styles.whiteIcon} />]
      : [<ShippedIcon className={styles.whiteIcon} />]),
    <CompleteIcon className={styles.whiteIcon} />,
  ];

  let workflowSteps: Step[] = [
    { stepName: 'Order Placed' },
    ...(canceled ? [{ stepName: 'Canceled' }] : [{ stepName: 'Shipped' }]),
    { stepName: canceled ? 'Refunded' : 'Review' },
  ];

  if (canceledDueToPaymentFailure) {
    stepIcons = [
      <OrderPlacedIcon className={styles.whiteIcon} />,
      <CanceledIcon className={styles.whiteIcon} />,
      <CanceledIcon className={styles.whiteIcon} />,
    ];

    workflowSteps = [
      { stepName: 'Order Placed' },
      { stepName: 'Payment Failed' },
      { stepName: 'Canceled' },
    ];
  }

  let currentStep = 0;
  if (refunded || step === 'step3') {
    currentStep = 2;
  } else if (canceled || step === 'step2') {
    currentStep = 1;
  }

  const additionalMenuOptions = [
    {
      label: 'What happens next?',
      value: 'whatNext',
      onClick: () => {
        navigate('/faqs');
      },
    },
    {
      label: 'Get help',
      value: 'help',
      onClick: () => {
        navigate('/help');
      },
    },
  ];

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={11} md={12} container className={styles.maxWidth}>
        <Grid
          item
          xs={12}
          sm={10}
          md={11}
          xl={12}
          container
          className={styles.logoHeader}
        >
          <Grid item xs={5} md={2}>
            <YardPrimary
              className={styles.yardLogo}
              onClick={() => navigate('/')}
            />
          </Grid>
          <OrderMenu
            wrappedButton
            order={order}
            isBuyer={isBuyer}
            additionalOptions={additionalMenuOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => navigate('/')}
            buttonType="secondary"
            buttonColor="sand20"
            text="Continue Shopping"
            containerClassName={styles.continueShoppingButton}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={11}>
          <Breadcrumbs
            background="grey"
            containerClassName={styles.breadcrumbs}
            wrapperClassName={styles.breadcrumbWrapper}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={11} xl={12} container>
          <div className={styles.imageContainer}>
            <img
              src={listing?.productImages[0].url}
              alt="product"
              className={styles.image}
            />
          </div>
          <Grid
            item
            xs={7}
            sm={10}
            container
            direction="column"
            className={styles.mainInfoWrapper}
          >
            <div className={styles.yardNumber}>{order.number}</div>
            <div className={styles.nameAndDateContainer}>
              <div
                className={styles.productName}
                onClick={() =>
                  navigate(
                    `/product/${listing?.category.toLowerCase()}/${
                      listing?.subCategory
                    }/${order.listingId}`,
                  )
                }
              >
                {listing?.productName}
              </div>
              <div className={styles.separationDot}>&nbsp;•&nbsp;</div>
              <div className={styles.orderedOn}>
                Ordered on {createdAtDate()}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.tracker}>
          <Grid item xs={12} sm={10} xl={11}>
            <StepTracker
              icon={stepIcons[currentStep]}
              steps={workflowSteps}
              activeStep={currentStep}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;
