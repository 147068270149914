import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Address } from 'api';
import { useIdentity } from 'hooks';
import { isPostalCode } from 'validator';
import { Box } from 'parts';
import Dropdown from 'components/Dropdown';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
import addressTypes from 'constants/addressTypes';
import { PhoneInputNoValidation } from 'components/PhoneInput';
import StateDropdown from 'components/FormComponents/StateDropdown';
import styles from './addressForm.module.scss';

type DropdownOption = {
  label: string;
  value: string;
};

type PhoneProps = {
  phoneNumber: string | null; // the phone number state in the parent component
  setPhoneNumber: (data: string) => void; // set the phone number state in the parent component
};

type ValidateOnClickProps = {
  invalidClassName: string;
  validateFieldsOnClick: boolean;
};

type Props = {
  address: Address;
  setAddress: (data: Address) => void; // set the address in the parent component
  isValidAddress: boolean;
  setIsValidAddress: (data: boolean) => void;
  showAddressInputs?: boolean; // default is true; false is for ShippingAddressForm
  disableSavedInputs?: boolean; // default is false
  // since the phone number isn't attached to the Address, we need to handle it separately
  phoneProps?: PhoneProps | null;
  validateOnClickProps?: ValidateOnClickProps;
  accountType?: 'company' | 'individual' | null; // used for create account
  showNameInputForIndividuals?: boolean; // default is false; name input is only shown for companies unless in account settings
  shippingAddressProps?: {
    shippingType: 'shipping' | 'delivery';
    resetInvalidFields: boolean;
    setResetInvalidFields: (data: boolean) => void;
  }; // for ShippingAddressForm
};

const defaultInvalidInputs = {
  name: false,
  address: false,
  city: false,
  state: false,
  zip: false,
  phone: false,
  type: false,
};

function AddressForm({
  address,
  setAddress,
  isValidAddress,
  setIsValidAddress,
  disableSavedInputs = false,
  showAddressInputs = true,
  phoneProps,
  accountType,
  validateOnClickProps,
  showNameInputForIndividuals = false,
  shippingAddressProps,
}: Props) {
  const { phoneNumber, setPhoneNumber } = phoneProps || {};
  const { invalidClassName, validateFieldsOnClick } =
    validateOnClickProps || {};
  const {
    name,
    address: addressLine1,
    address2,
    city,
    state,
    zip,
    type,
    instructions,
  } = address;

  // should the name input label be 'Name' or 'Company'
  const { userAccountType } = useIdentity();
  const isCompany = accountType
    ? accountType === 'company'
    : userAccountType === 'company';

  const showNameInput = isCompany || showNameInputForIndividuals;

  const [selectedTypeOption, setSelectedTypeOption] =
    useState<DropdownOption | null>();

  const updateSelectedTypeOption = () => {
    /* if the user has type set, but the selectedTypeOption is null,
    we need to set it - or if a different address was selected in 
    ShippingAddressForm then we need to reset it */
    if ((type && !selectedTypeOption) || selectedTypeOption?.value !== type) {
      const savedType = addressTypes.find(
        (addressType) => addressType.value === type,
      );
      setSelectedTypeOption(savedType);
    } else if (!type && selectedTypeOption) {
      setSelectedTypeOption(null);
    }
  };
  useEffect(updateSelectedTypeOption, [type, selectedTypeOption]);

  /* since the phone input for address is the business phone, which might not be a 
  cell phone, we just want to confirm that the length is accurate */
  const validPhoneByLength = phoneNumber && phoneNumber.length === 10;
  const isValidZipCode = zip && isPostalCode(zip, 'US');

  const requiredFieldText = 'This field is required';
  const [invalidInputsOnClick, setInvalidInputsOnClick] =
    useState(defaultInvalidInputs);

  const invalidInputs = useMemo(
    () => ({
      name: showNameInput ? !name : false,
      address: !addressLine1,
      city: !city,
      state: !state,
      zip: !isValidZipCode,
      type: !type,
      phone: phoneProps ? !validPhoneByLength : false,
    }),
    [
      showNameInput,
      name,
      addressLine1,
      city,
      state,
      isValidZipCode,
      type,
      phoneProps,
      validPhoneByLength,
    ],
  );

  useEffect(() => {
    if (validateFieldsOnClick) {
      setInvalidInputsOnClick(invalidInputs);
    }
  }, [validateFieldsOnClick, invalidInputs]);

  const handleResetInvalidFields = () => {
    // reset the invalid fields when a new option is selected in the ShippingAddressForm address dropdown
    if (shippingAddressProps && shippingAddressProps.resetInvalidFields) {
      setInvalidInputsOnClick(defaultInvalidInputs);

      shippingAddressProps.setResetInvalidFields(false);
    }
  };

  useEffect(handleResetInvalidFields, [shippingAddressProps]);

  const allRequiredFieldsFilled = useCallback(() => {
    const phoneValidation = phoneProps ? validPhoneByLength : true;
    const validName = showNameInput ? name : true;

    if (
      validName &&
      addressLine1 &&
      city &&
      state &&
      isValidZipCode &&
      type &&
      phoneValidation
    ) {
      return true;
    }
    return false;
  }, [
    name,
    addressLine1,
    city,
    state,
    type,
    showNameInput,
    isValidZipCode,
    phoneProps,
    validPhoneByLength,
  ]);

  const validAddress = () => {
    const filledOut = allRequiredFieldsFilled();
    if (filledOut && !isValidAddress) {
      setIsValidAddress(true);
    } else if (!filledOut && isValidAddress) {
      setIsValidAddress(false);
    }
  };

  useEffect(validAddress, [
    name,
    addressLine1,
    city,
    state,
    zip,
    type,
    phoneNumber,
    validPhoneByLength,
    isValidAddress,
    setIsValidAddress,
    allRequiredFieldsFilled,
  ]);

  const zipCodeAssistiveText = () => {
    if (invalidInputsOnClick.zip) {
      if (zip && !isValidZipCode) {
        return 'Invalid zip code';
      }
      return requiredFieldText;
    }
    return '';
  };

  const phoneNumberAssistiveText = () => {
    if (invalidInputsOnClick.phone) {
      if (!phoneNumber) {
        return requiredFieldText;
      }
      return 'Invalid phone number';
    }
    return '';
  };

  // this is used in the parent to scroll to the first invalid input
  const setInvalidClassName = (isInvalid: boolean) =>
    isInvalid && invalidClassName ? invalidClassName : '';

  const addressTypeDropdown = () => {
    // This will force Select to re-render itself when the selection is updated
    const randomNumber = Math.floor(Math.random() * 1000);
    const dropdownKey = `address_type_key__${
      type ? JSON.stringify(type) : randomNumber
    }`;

    return (
      <Box mb={9}>
        <Dropdown
          label="Type of Address*"
          options={addressTypes}
          value={selectedTypeOption}
          onChange={(option: DropdownOption) => {
            setSelectedTypeOption(option);
            setAddress({
              ...address,
              type: option.value,
            });

            if (invalidInputsOnClick.type && option) {
              setInvalidInputsOnClick({
                ...invalidInputsOnClick,
                type: false,
              });
            }
          }}
          dropdownType="form"
          assistiveText="What type of location is this?"
          invalidUserInput={invalidInputsOnClick.type}
          key={dropdownKey}
          containerClassName={setInvalidClassName(!type)}
        />
      </Box>
    );
  };

  const addressDetailsSection = () => {
    if (shippingAddressProps) {
      const { shippingType } = shippingAddressProps;

      return (
        <div className={styles.addressTypeSection}>
          <div className={styles.headerText}>{shippingType} Details</div>
          {addressTypeDropdown()}
          <Box mb={9}>
            <TextArea
              label="Delivery Instructions"
              value={instructions || ''}
              onChange={(e) => {
                setAddress({
                  ...address,
                  instructions: e.target.value,
                });
              }}
            />
          </Box>
        </div>
      );
    }

    return addressTypeDropdown();
  };

  return (
    <div>
      <form autoComplete="on">
        {showNameInput && (
          <Box mb={9}>
            <TextInput
              value={name}
              name="name"
              disabled={disableSavedInputs && !invalidInputsOnClick.name}
              onChange={(e) => {
                setAddress({
                  ...address,
                  name: e.target.value,
                });

                if (invalidInputsOnClick.name && e.target.value) {
                  setInvalidInputsOnClick({
                    ...invalidInputsOnClick,
                    name: false,
                  });
                }
              }}
              label={isCompany ? 'Company*' : 'Name*'}
              invalidUserInput={invalidInputsOnClick.name}
              assistiveText={invalidInputsOnClick.name ? requiredFieldText : ''}
              containerClassName={setInvalidClassName(!name)}
            />
          </Box>
        )}
        {showAddressInputs && (
          <>
            <Box mb={9}>
              <TextInput
                name="address"
                label="Address*"
                value={addressLine1}
                disabled={disableSavedInputs && !invalidInputsOnClick.address}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    address: e.target.value,
                  });

                  if (invalidInputsOnClick.address && e.target.value) {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      address: false,
                    });
                  }
                }}
                invalidUserInput={invalidInputsOnClick.address}
                assistiveText={
                  invalidInputsOnClick.address ? requiredFieldText : ''
                }
                containerClassName={setInvalidClassName(!addressLine1)}
              />
            </Box>
            <Box mb={9}>
              <TextInput
                value={address2}
                disabled={disableSavedInputs}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    address2: e.target.value,
                  });
                }}
                name="address2"
                label="Address Line 2"
              />
            </Box>
          </>
        )}
        <Box mb={9}>
          <TextInput
            name="city"
            value={city}
            disabled={disableSavedInputs && !invalidInputsOnClick.city}
            onChange={(e) => {
              setAddress({
                ...address,
                city: e.target.value,
              });

              if (invalidInputsOnClick.city && e.target.value) {
                setInvalidInputsOnClick({
                  ...invalidInputsOnClick,
                  city: false,
                });
              }
            }}
            label="City*"
            invalidUserInput={invalidInputsOnClick.city}
            assistiveText={invalidInputsOnClick.city ? requiredFieldText : ''}
            containerClassName={setInvalidClassName(!city)}
          />
        </Box>
        <Box mb={9} display="flex" justifyContent="space-between">
          <Box width="30%" mr={2} minWidth="110px">
            <StateDropdown
              value={state || ''}
              disabled={disableSavedInputs && !invalidInputsOnClick.state}
              onChange={(value) => {
                setAddress({
                  ...address,
                  state: value.value,
                });

                if (invalidInputsOnClick.state && value.value) {
                  setInvalidInputsOnClick({
                    ...invalidInputsOnClick,
                    state: false,
                  });
                }
              }}
              showAsterisk
              invalidUserInput={invalidInputsOnClick.state}
              assistiveText={
                invalidInputsOnClick.state ? requiredFieldText : ''
              }
              containerClassName={setInvalidClassName(!state)}
            />
          </Box>
          <Box width="70%">
            <TextInput
              name="zip"
              value={zip}
              disabled={disableSavedInputs && !invalidInputsOnClick.zip}
              onChange={(e) => {
                const val = e.target.value;
                setAddress({
                  ...address,
                  zip: val,
                });

                if (invalidInputsOnClick.zip && isPostalCode(val, 'US')) {
                  setInvalidInputsOnClick({
                    ...invalidInputsOnClick,
                    zip: false,
                  });
                }
              }}
              label="Zip*"
              invalidUserInput={invalidInputsOnClick.zip}
              assistiveText={zipCodeAssistiveText()}
              containerClassName={setInvalidClassName(!isValidZipCode)}
            />
          </Box>
        </Box>
        {phoneProps && (
          <Box mb={9}>
            <PhoneInputNoValidation
              value={phoneNumber || ''}
              label="Phone Number*"
              onChange={(phone) => {
                if (setPhoneNumber) {
                  setPhoneNumber(phone);
                }

                const isValid = phone.length === 10;
                if (invalidInputsOnClick.phone && isValid) {
                  setInvalidInputsOnClick({
                    ...invalidInputsOnClick,
                    phone: false,
                  });
                }
              }}
              isInvalid={invalidInputsOnClick.phone}
              assistiveText={phoneNumberAssistiveText()}
              containerClassName={setInvalidClassName(!validPhoneByLength)}
            />
          </Box>
        )}
      </form>
      {addressDetailsSection()}
    </div>
  );
}

export default AddressForm;
