import React from 'react';
import classNames from 'classnames';
import { ITypography } from './types';
import styles from './typography.module.scss';

function H2({ children, bold }: ITypography) {
  const boldClass = bold && styles.bold;

  return <h2 className={classNames(styles.h2, boldClass)}>{children}</h2>;
}

export default H2;
