import React, { useState, useEffect, useMemo } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { isEmail, isStrongPassword, isMobilePhone } from 'validator';
import Grid from '@mui/material/Grid';
import Checkbox from 'components/Checkbox/Checkbox';
import TextInput from 'components/TextInput';
import { PhoneInputNoValidation } from 'components/PhoneInput';
import FormContainer from 'components/FormContainer/FormContainer';
import TooltipWrapper from 'components/TooltipWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { COLORS } from 'style/colors';
import formStyles from 'style/formStyles.module.scss';
import styles from './userDetailsScreen.module.scss';
import { UserDetailsObj, FieldValidation } from '../types';

type Props = {
  user: UserDetailsObj;
  setUser: (user: UserDetailsObj) => any;
  validateFieldsOnClick: boolean;
  setValidateFieldsOnClick: (val: boolean) => void;
  setUserFieldsValidation: (fields: FieldValidation[]) => void;
};

function UserDetailsScreen({
  user,
  setUser,
  validateFieldsOnClick,
  setValidateFieldsOnClick,
  setUserFieldsValidation,
}: Props) {
  const { email, phone, password, confirmPassword, name, isOver18 } = user;
  const strongPassword = isStrongPassword(password);
  const validPhone = phone && isMobilePhone(phone, ['en-US']);
  const validEmail = email && isEmail(email);
  const validConfirmPassword = confirmPassword && confirmPassword === password;

  const missingFieldAssistiveText = 'This field is required';
  const defaultInvalidInputs = {
    email: false,
    phone: false,
    password: false,
    confirmPassword: false,
    name: false,
    isOver18: false,
  };
  // used for setting the invalidUserInput prop for inputs on click
  const [invalidInputsOnClick, setInvalidInputsOnClick] =
    useState(defaultInvalidInputs);

  const invalidInputs = useMemo(
    () => ({
      email: !validEmail,
      phone: !validPhone,
      name: !name,
      isOver18: !isOver18,
      password: !strongPassword,
      confirmPassword: !validConfirmPassword,
    }),
    [
      validEmail,
      validPhone,
      name,
      isOver18,
      strongPassword,
      validConfirmPassword,
    ],
  );

  useEffect(() => {
    if (validateFieldsOnClick) {
      setInvalidInputsOnClick(invalidInputs);
    }
  }, [validateFieldsOnClick, invalidInputs]);

  // send invalid and missing fields to the continue button
  const fieldValidation = useMemo(
    () => [
      { displayName: 'Phone', isMissing: !phone, isInvalid: !validPhone },
      { displayName: 'Email', isMissing: !email, isInvalid: !validEmail },
      { displayName: 'Name', isMissing: !name },
      { displayName: 'Over 18', isMissing: !isOver18 },
      {
        displayName: 'Password',
        isMissing: !password,
        isInvalid: !strongPassword,
      },
      {
        displayName: 'Confirm Password',
        isMissing: !confirmPassword,
        isInvalid: !validConfirmPassword,
      },
    ],
    [
      email,
      validEmail,
      phone,
      validPhone,
      name,
      isOver18,
      password,
      strongPassword,
      confirmPassword,
      validConfirmPassword,
    ],
  );

  useEffect(
    () => setUserFieldsValidation(fieldValidation),
    [fieldValidation, setUserFieldsValidation],
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickScroll = () => {
    if (validateFieldsOnClick) {
      const formIsValid = fieldValidation.every(
        (field) => !field.isMissing && !field.isInvalid,
      );

      if (formIsValid) {
        setValidateFieldsOnClick(false);
      } else {
        const firstElement = document.querySelector('.invalidInput');
        if (firstElement) {
          // scroll to first invalid input
          firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // reset validateFieldsOnClick in order run this again on click
        setValidateFieldsOnClick(false);
      }
    }
  };

  useEffect(handleClickScroll, [
    validateFieldsOnClick,
    invalidInputsOnClick,
    fieldValidation,
    setValidateFieldsOnClick,
  ]);

  const passwordVisibilityIcons = (input: 'confirm password' | 'password') => {
    const isPasswordInput = input === 'password';
    const viewPassword = isPasswordInput ? showPassword : showConfirmPassword;

    return (
      <TooltipWrapper
        tooltipText={viewPassword ? `Hide ${input}` : `Show ${input}`}
      >
        <div className={styles.visibilityIcon}>
          {viewPassword ? (
            <VisibilityOffIcon
              onClick={() => {
                if (isPasswordInput) {
                  setShowPassword(false);
                } else {
                  setShowConfirmPassword(false);
                }
              }}
              aria-label={`Hide ${input}`}
            />
          ) : (
            <VisibilityIcon
              onClick={() => {
                if (isPasswordInput) {
                  setShowPassword(true);
                } else {
                  setShowConfirmPassword(true);
                }
              }}
              aria-label={`Show ${input}`}
            />
          )}
        </div>
      </TooltipWrapper>
    );
  };

  const phoneNumberAssistiveText = () => {
    if (invalidInputsOnClick.phone) {
      if (!phone) {
        return missingFieldAssistiveText;
      }
      return 'Invalid phone number';
    }
    return '';
  };

  const emailAssistiveText = () => {
    if (invalidInputsOnClick.email) {
      if (!email) {
        return missingFieldAssistiveText;
      }
      return 'Invalid email';
    }
    return '';
  };

  const confirmPasswordAssistiveText = () => {
    if (invalidInputsOnClick.confirmPassword) {
      if (!confirmPassword) {
        return missingFieldAssistiveText;
      }
      return 'Passwords must match';
    }
    return '';
  };

  const invalidCheckboxStyling =
    invalidInputsOnClick.isOver18 && styles.invalidCheckbox;

  const invalidClassName = (isInvalid: boolean) =>
    isInvalid ? 'invalidInput' : '';

  return (
    <Grid item xs={12}>
      <FormContainer
        title="User Details"
        bodyClassName={styles.formBody}
        content={
          <form>
            <Grid container className={styles.topSectionContainer}>
              <PhoneInputNoValidation
                containerClassName={`${styles.formInput} ${invalidClassName(
                  !validPhone,
                )}`}
                label="Personal Phone Number*"
                value={phone}
                onChange={(phoneNumber) => {
                  setUser({
                    ...user,
                    phone: phoneNumber,
                  });

                  const isValid = isMobilePhone(phoneNumber, ['en-US']);
                  if (invalidInputsOnClick.phone && isValid) {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      phone: false,
                    });
                  }
                }}
                isInvalid={invalidInputsOnClick.phone}
                assistiveText={phoneNumberAssistiveText()}
              />
              <TextInput
                containerClassName={`${styles.formInput} ${invalidClassName(
                  !validEmail,
                )}`}
                label="Email Address*"
                value={email}
                name="email"
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });

                  const isValid = isEmail(e.target.value);
                  if (invalidInputsOnClick.email && isValid) {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      email: false,
                    });
                  }
                }}
                invalidUserInput={invalidInputsOnClick.email}
                assistiveText={emailAssistiveText()}
              />
            </Grid>
            <Grid container className={styles.sectionContainer}>
              <TextInput
                containerClassName={`${styles.formInput} ${invalidClassName(
                  !name,
                )}`}
                label="First and Last Name*"
                name="name"
                value={name}
                onChange={(e) => {
                  setUser({ ...user, name: e.target.value });

                  if (invalidInputsOnClick.name && e.target.value.length > 2) {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      name: false,
                    });
                  }
                }}
                invalidUserInput={invalidInputsOnClick.name}
                assistiveText={
                  invalidInputsOnClick.name ? missingFieldAssistiveText : ''
                }
              />
              <div
                className={`${styles.checkboxWrapper} ${invalidCheckboxStyling}`}
              >
                <Checkbox
                  className={`${styles.over18} ${invalidClassName(!isOver18)}`}
                  label="I am over 18 years of age.*"
                  checked={isOver18}
                  color={
                    invalidInputsOnClick.isOver18
                      ? COLORS.taurusRed40
                      : COLORS.sand
                  }
                  invalid={invalidInputsOnClick.isOver18}
                  onClick={() => {
                    setUser({ ...user, isOver18: !isOver18 });

                    if (invalidInputsOnClick.isOver18) {
                      setInvalidInputsOnClick({
                        ...invalidInputsOnClick,
                        isOver18: false,
                      });
                    }
                  }}
                />
                <div
                  className={`${formStyles.assistiveText} ${
                    invalidInputsOnClick.isOver18 && formStyles.redAssistiveText
                  } ${styles.over18AssistiveText}`}
                >
                  {invalidInputsOnClick.isOver18
                    ? missingFieldAssistiveText
                    : ''}
                </div>
              </div>
            </Grid>
            <Grid container className={styles.sectionContainer}>
              <div className={styles.visibilityWrapper}>
                <TextInput
                  containerClassName={`${styles.formInput} ${invalidClassName(
                    !password,
                  )}`}
                  type={showPassword ? 'text' : 'password'}
                  label="Password*"
                  value={password}
                  autoComplete="newPassword"
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });

                    if (
                      invalidInputsOnClick.password &&
                      isStrongPassword(e.target.value)
                    ) {
                      setInvalidInputsOnClick({
                        ...invalidInputsOnClick,
                        password: false,
                      });
                    }
                  }}
                  invalidUserInput={invalidInputsOnClick.password}
                  assistiveText={
                    invalidInputsOnClick.password
                      ? missingFieldAssistiveText
                      : ''
                  }
                />
                {passwordVisibilityIcons('password')}
              </div>
              <Grid container direction="column">
                <PasswordStrengthBar
                  className={styles.passwordStrengthBar}
                  password={password}
                  minLength={0} // set to 0 so we don't see 'too short'
                  scoreWords={[
                    'Strength: poor',
                    'Strength: poor',
                    'Strength: okay',
                    'Strength: good',
                    'Strength: strong',
                  ]}
                />
                <div className={styles.passwordRequirementsContainer}>
                  Your password must:
                  <ul className={styles.passwordRequirements}>
                    <li className={styles.passwordRequirement}>
                      Contain at least 8 characters
                    </li>
                    <li className={styles.passwordRequirement}>
                      Use upper and lower case characters
                    </li>
                    <li className={styles.passwordRequirement}>
                      Contain at least 1 number
                    </li>
                    <li className={styles.passwordRequirement}>
                      Contain at least 1 special character
                    </li>
                  </ul>
                </div>
              </Grid>
              <div className={styles.visibilityWrapper}>
                <TextInput
                  containerClassName={`${styles.formInput} ${invalidClassName(
                    !validConfirmPassword,
                  )}`}
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm Password*"
                  value={confirmPassword}
                  onChange={(e) => {
                    setUser({ ...user, confirmPassword: e.target.value });

                    if (
                      invalidInputsOnClick.confirmPassword &&
                      e.target.value === password
                    ) {
                      setInvalidInputsOnClick({
                        ...invalidInputsOnClick,
                        confirmPassword: false,
                      });
                    }
                  }}
                  invalidUserInput={invalidInputsOnClick.confirmPassword}
                  assistiveText={confirmPasswordAssistiveText()}
                />
                {passwordVisibilityIcons('confirm password')}
              </div>
            </Grid>
          </form>
        }
        actionNeeded
      />
    </Grid>
  );
}

export default UserDetailsScreen;
