import React from 'react';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import Card from '../components/Card';
import styles from './support.module.scss';
import parentStyles from '../helpScreen.module.scss';

function Support() {
  const navigate = useNavigate();

  return (
    <Card halfCard>
      <Grid container direction="column">
        <div className={parentStyles.sectionTitle}>Support</div>
        <div className={styles.subHeading}>Have an issue?</div>
        <span>
          Create a support ticket and we&apos;ll get the ball rolling.
        </span>
        <div className={styles.buttonContainer}>
          <Button
            title="OPEN A TICKET"
            buttonColor="sand"
            onClick={() => navigate('/support_ticket')}
          />
        </div>
      </Grid>
    </Card>
  );
}

export default Support;
