import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import SearchInput from 'components/SearchInput/SearchInput';
import styles from './searchBar.module.scss';

function SearchBar() {
  const [searchWords, setSearchWords] = useState('');

  return (
    <Grid className={styles.wrapper}>
      <div className={styles.imageBackground} />
      <Grid item xs={12} md={9} className={styles.inputButtonContainer}>
        <SearchInput
          className={styles.searchInput}
          inputClassName={styles.input}
          placeholder="Search for listings"
          value={searchWords}
          onChange={(e) => setSearchWords(e.target.value)}
        />
      </Grid>
      <div className={styles.imageBackground} />
    </Grid>
  );
}

export default SearchBar;
