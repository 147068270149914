import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
// width used to determine if on mobile or desktop
const useWindowDimensions = () => {
  const [windowDim, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDim;
};

const useIsMobile = () => {
  const { width } = useWindowDimensions();
  const mobile = width < 786;

  return mobile;
};

export default useIsMobile;
