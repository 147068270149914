import React, { useState, useEffect, useMemo } from 'react';
import { useGetListingTotal } from 'hooks';
import Grid from '@mui/material/Grid';
import Dropdown from 'components/Dropdown/Dropdown';
import TextInput from 'components/TextInput/TextInput';
import FormContainer from 'components/FormContainer/FormContainer';
import styles from './pricing.module.scss';
import {
  Pricing as PricingType,
  FieldValidation,
  FieldValidationBaseProps,
} from '../types';

type Option = {
  label: string;
  value: string;
};

interface FieldValidationProps extends FieldValidationBaseProps {
  setPricingFieldsValidation: (fields: FieldValidation[]) => void;
}

type Props = {
  pricingInfo: PricingType;
  setPricingInfo: (val: any) => void;
  fieldValidationProps: FieldValidationProps;
};

function Pricing({ pricingInfo, setPricingInfo, fieldValidationProps }: Props) {
  const {
    validateFieldsOnClick,
    setValidateFieldsOnClick,
    setPricingFieldsValidation,
    handleClickScroll,
  } = fieldValidationProps;

  const { soldAs, basePrice, quantity, minimumOrder } = pricingInfo;
  const isLot = soldAs === 'lot';

  // calculate the buyer total to display to the seller
  const buyerTotal = useGetListingTotal(basePrice);

  const calculatePerUnitTotalPrice = () => {
    if (!isLot && basePrice && quantity) {
      const total = (basePrice || 1) * (quantity || 1);

      setPricingInfo({ ...pricingInfo, basePrice: total });
    }
  };

  useEffect(calculatePerUnitTotalPrice, [
    isLot,
    basePrice,
    quantity,
    pricingInfo,
    setPricingInfo,
  ]);

  const defaultInvalidInputs = {
    soldAs: false,
    basePrice: false,
    quantity: false,
    minimumOrder: false,
  };
  const [invalidInputsOnClick, setInvalidInputsOnClick] =
    useState(defaultInvalidInputs);

  const fieldValidation = useMemo(
    () => [
      { displayName: 'Sold As', isMissing: !soldAs },
      { displayName: 'Quantity', isMissing: !quantity },
      { displayName: 'Minimum Order', isMissing: !isLot && !minimumOrder },
      { displayName: 'Price', isMissing: !basePrice },
    ],
    [soldAs, basePrice, isLot, quantity, minimumOrder],
  );

  useEffect(() => {
    if (validateFieldsOnClick) {
      const invalidValues = {
        soldAs: fieldValidation[0].isMissing,
        quantity: fieldValidation[1].isMissing,
        minimumOrder: fieldValidation[2].isMissing,
        basePrice: fieldValidation[3].isMissing,
      };
      setInvalidInputsOnClick(invalidValues);
    }
  }, [validateFieldsOnClick, fieldValidation]);

  useEffect(
    () => handleClickScroll(fieldValidation),
    [
      validateFieldsOnClick,
      fieldValidation,
      setValidateFieldsOnClick,
      handleClickScroll,
    ],
  );

  useEffect(
    () => setPricingFieldsValidation(fieldValidation),
    [fieldValidation, setPricingFieldsValidation],
  );

  const requiredFieldText = 'This field is required';
  const invalidClassName = (isInvalid: boolean) =>
    isInvalid ? 'invalidInput' : '';

  const quantityInput = (
    <TextInput
      containerClassName={`${styles.input} ${invalidClassName(!quantity)}`}
      label={isLot ? 'Quantity in lot*' : 'Quantity*'}
      value={quantity}
      onChange={(e: any) => {
        const numberValue = Number(e.target.value);
        // set the minimum order to the quantity if the user is selling as a lot
        setPricingInfo({
          ...pricingInfo,
          ...(isLot && { minimumOrder: numberValue }),
          quantity: numberValue,
        });
      }}
      type="number"
      wholeNumbersOnly
      invalidUserInput={invalidInputsOnClick.quantity}
      assistiveText={invalidInputsOnClick.quantity ? requiredFieldText : ''}
    />
  );

  const pricingSection = () => {
    const invalidMinimumOrder = !isLot && !minimumOrder;

    return (
      <>
        {isLot ? (
          quantityInput
        ) : (
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={5}>
              {quantityInput}
            </Grid>
            <Grid item xs={6}>
              <TextInput
                containerClassName={`${styles.input} ${invalidClassName(
                  invalidMinimumOrder,
                )}`}
                label="Minimum order size*"
                value={minimumOrder}
                onChange={(e: any) => {
                  setPricingInfo({
                    ...pricingInfo,
                    minimumOrder: e.target.value,
                  });
                }}
                type="number"
                wholeNumbersOnly
                invalidUserInput={invalidInputsOnClick.minimumOrder}
                assistiveText={
                  invalidInputsOnClick.minimumOrder ? requiredFieldText : ''
                }
              />
            </Grid>
          </Grid>
        )}
        <TextInput
          containerClassName={`${styles.input} ${invalidClassName(!basePrice)}`}
          label={isLot ? 'Price*' : 'Price per unit*'}
          value={basePrice}
          onChange={(e: any) => {
            // only allow 2 decimal places for price
            const isValidPrice = /^(\d*\.{0,1}\d{0,2}$)/.test(e.target.value);

            if (isValidPrice) {
              setPricingInfo({
                ...pricingInfo,
                basePrice: Number(e.target.value),
              });

              if (invalidInputsOnClick.basePrice && e.target.value) {
                setInvalidInputsOnClick({
                  ...invalidInputsOnClick,
                  basePrice: false,
                });
              }
            }
          }}
          invalidUserInput={invalidInputsOnClick.basePrice}
          assistiveText={
            invalidInputsOnClick.basePrice ? requiredFieldText : ''
          }
          type="number"
          data-type="currency"
          showDollarSign
          min={0}
          step={0.01}
        />
      </>
    );
  };

  const pricingForm = () => {
    // for beta, only allow lot
    const comingSoonOption = [
      {
        label: 'Coming Soon',
        options: [
          {
            label: 'Smaller units',
            value: 'perunit',
            disabled: true,
          },
        ],
      },
    ];
    const options = [{ label: 'Lot', value: 'lot' }, ...comingSoonOption];
    // once beta is over, uncomment this and update Dropdown to value={selectedOption}
    // const selectedOption = options.find((option) => option.value === soldAs);

    const dropdownClassName = !isLot && styles.input;
    return (
      <>
        <Dropdown
          containerClassName={`${dropdownClassName} ${invalidClassName(
            !soldAs,
          )}}`}
          label="Sell as..."
          options={options}
          value={options[0]}
          menuWidth="100%"
          onChange={(option: Option) => {
            setPricingInfo({
              ...pricingInfo,
              soldAs: option.value,
            });
          }}
        />
        {isLot && (
          <div className={styles.lotText}>
            Full quantity of identical items will be grouped together for a
            single sale to a single buyer
          </div>
        )}
        {soldAs && pricingSection()}
        <Grid container direction="row" className={styles.priceRow}>
          <span className={styles.boldPrice}>Buyer Price:</span>
          <span className={styles.boldPrice}>{buyerTotal}</span>
        </Grid>
        <Grid container className={styles.disclaimer}>
          This price includes platform fees and 3rd party payment processing,
          maintenance, and support services, it excludes shipping and taxes
        </Grid>
      </>
    );
  };

  return (
    <Grid>
      <FormContainer title="Pricing" content={pricingForm()} />
    </Grid>
  );
}

export default Pricing;
