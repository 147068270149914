import React from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as CircleXIcon } from 'assets/icons/circleCancelIcon.svg';
import circleCheckedIcon from 'assets/icons/circleCheckedIcon.svg';
import { COLORS } from 'style/colors';
import ViewDashboardButton from './ViewDashboardButton';
import CompleteSetupButton from './CompleteSetupButton';
import styles from './stripeAccount.module.scss';

type Props = {
  userId: string;
  stripeAccountVerified: boolean;
};

function StripeAccount({ userId, stripeAccountVerified }: Props) {
  return (
    <Grid container className={styles.accountWrapper}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <div className={styles.header}>Stripe Account</div>
        <div className={styles.verified}>
          {stripeAccountVerified ? (
            <>
              Verified &nbsp;
              <img src={circleCheckedIcon} alt="verified logo" />
            </>
          ) : (
            <>
              Not Verified &nbsp; <CircleXIcon fill={COLORS.taurusRed20} />
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={10} className={styles.accountText}>
        {stripeAccountVerified
          ? 'Manage your account on Stripe. This is where you can view your transactions and manage your payout method.'
          : 'Your Stripe account is not completed. Please complete your Stripe account to start accepting payments.'}
      </Grid>
      <div className={styles.stripeButtonContainer}>
        {stripeAccountVerified ? (
          <ViewDashboardButton userId={userId} />
        ) : (
          <CompleteSetupButton userId={userId} />
        )}
      </div>
    </Grid>
  );
}

export default StripeAccount;
