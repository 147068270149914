import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router';
import {
  useCreateListingMutation,
  Listing,
  usePutUsersByUserIdMutation,
  User,
} from 'api';
import { useGetUserDetails, useApiResponseMessage } from 'hooks';
import Grid from '@mui/material/Grid';
import productConditionRatings from 'constants/productConditionRatings';
import { BackButton } from 'components/Buttons';
import IconDropdown from 'components/IconDropdown';
import { ConfirmNavigationPopup } from 'components/Popups';
import Loading from 'components/Loading';
import CategoryScreen from './Category';
import DescriptionScreen from './Description';
import PricingScreen from './Pricing';
import ShippingScreen from './Shipping';
import {
  HeaderStepTracker,
  PreviousStepInfo,
  ContinueButton,
} from './components';
import {
  ButtonObject,
  Category,
  Description,
  Pricing,
  Shipping,
  FieldValidation,
  FieldValidationBaseProps,
} from './types';
import styles from './productListingScreen.module.scss';

function ProductListingScreen() {
  const getUserResponse = useGetUserDetails();
  const { apiResponseMessage } = useApiResponseMessage();
  const navigate = useNavigate();
  // used for scrolling to top of page on page change
  const headerRef = useRef<HTMLDivElement>(null);
  const [saveListing] = useCreateListingMutation();
  const [updateUser] = usePutUsersByUserIdMutation();

  // for managing what step the user is on
  const steps = useMemo(
    () => ['category', 'product description', 'pricing', 'shipping'],
    [],
  );
  const [step, setStep] = useState(steps[0]);
  // for setting Continue button func & icons
  const stepIdx = steps.findIndex((s) => s === step);

  const [isLoading, setIsLoading] = useState(false);
  // since we validate fields on click, we need to know when to validate
  const [validateFieldsOnClick, setValidateFieldsOnClick] = useState(false);
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [continueButton, setContinueButton] = useState<ButtonObject>({
    name: 'Description',
    stepIdx: 1,
  });
  const [backButton, setBackButton] = useState<ButtonObject>({
    name: 'Yard',
    stepIdx: -1,
  });

  const [showConfirmNavigatePopup, setShowConfirmNavigatePopup] =
    useState(false);

  const [categoryInfo, setCategoryInfo] = useState<Category>();
  const [categoryFieldsValidation, setCategoryFieldsValidation] = useState<
    FieldValidation[]
  >([]);

  const [descriptionInfo, setDescriptionInfo] = useState<Description>({
    productName: '',
    productBrand: '',
    productLine: '',
    productColorOrStyle: '',
    productDescription: '',
    productImages: [],
    productCondition: 'As-is' as const,
    productConditionRating: {
      number: 5,
      name: productConditionRatings[4].name,
    },
    productConditionNotes: productConditionRatings[4].description,
    productDocuments: [],
  });
  const [descriptionFieldsValidation, setDescriptionFieldsValidation] =
    useState<FieldValidation[]>([]);

  const [pricingInfo, setPricingInfo] = useState<Pricing>({
    soldAs: 'lot',
    basePrice: 0,
    quantity: 0,
    minimumOrder: 0,
  });
  const [pricingFieldsValidation, setPricingFieldsValidation] = useState<
    FieldValidation[]
  >([]);

  const [shippingInfo, setShippingInfo] = useState<Shipping>();
  const [shippingFieldsValidation, setShippingFieldsValidation] = useState<
    FieldValidation[]
  >([]);
  const [saveAddressToUser, setSaveAddressToUser] = useState(false);

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (getUserResponse?.data.user) {
      setUser(getUserResponse.data.user);
    }
  }, [getUserResponse]);

  const removeEmptyValues = (object: any) => {
    const copiedObject = { ...object };
    return Object.keys(copiedObject)
      .filter((key) => {
        if (copiedObject[key] != null || copiedObject[key] !== '') {
          return copiedObject[key];
        }
        return null;
      })
      .reduce((acc, key) => ({ ...acc, [key]: copiedObject[key] }), {});
  };

  const updateUserDeliveryAddresses = async () => {
    try {
      if (user && shippingInfo && shippingInfo.shipFromAddress) {
        const { shipFromAddress } = shippingInfo;
        const { id: userId, deliveryAddresses = [], address } = user;

        // check if user already has address in deliveryAddresses, new users should
        // always have address in deliveryAddresses, but existing users may not
        const deliveryAddressesContainAddress = deliveryAddresses.some(
          (da) => JSON.stringify(da.address) === JSON.stringify(address),
        );

        const addAddress = [];
        if (!deliveryAddressesContainAddress) {
          addAddress.push({
            address,
            default: true,
          });
        }

        await updateUser({
          userId,
          body: {
            ...user,
            deliveryAddresses: [
              ...deliveryAddresses,
              ...addAddress,
              {
                address: shipFromAddress,
                default: false,
              },
            ],
          },
        });
      }
    } catch (err) {
      apiResponseMessage('An error occurred when saving your address');
    }
    navigate('/product_listed', { replace: true });
  };

  const createListing = async () => {
    try {
      if (categoryInfo && shippingInfo) {
        const updatedCategory = {
          category: categoryInfo.categoryName,
          subCategory: categoryInfo.subCategoryName,
        };

        const { productCondition, productConditionRating } = descriptionInfo;
        const updatedDescription = {
          ...descriptionInfo,
          productCondition: productCondition.toUpperCase(),
          productConditionRating: productConditionRating.number,
          productImages: descriptionInfo.productImages.map((img: any) => ({
            url: img.url,
            fileName: img.fileName,
            mimeType: img.mimeType,
          })),
          productDocuments: descriptionInfo?.productDocuments?.map(
            (doc: any) => ({
              url: doc.url,
              fileName: doc.fileName,
              mimeType: doc.mimeType,
            }),
          ),
        };

        const { soldAs, basePrice, quantity, minimumOrder } = pricingInfo;

        const updatedPricing = {
          soldAs: soldAs.toUpperCase(),
          basePrice,
          minimumOrder,
          listedQty: quantity,
        };

        const { shipFromAddress, packageDetails } = shippingInfo;
        // any is because TS is saying we are missing properties on Listing
        // but we aren't, remove any at some point
        const listingObject: Listing | any = {
          status: 'OPEN',
          pickupAllowed: false,
          shipFromAddress,
          packages: packageDetails,
          properties: {
            containsHazardousMaterials: false,
          },
          ...updatedCategory,
          ...removeEmptyValues(updatedDescription),
          ...removeEmptyValues(updatedPricing),
        };

        const payload: any = await saveListing({
          listing: listingObject,
        }).unwrap();

        if (payload) {
          if (saveAddressToUser) {
            updateUserDeliveryAddresses();
          } else {
            navigate('/product_listed', { replace: true });
          }
        }
      }
    } catch (err: any) {
      const message =
        err?.data?.message || 'An error occurred. Please try again.';
      apiResponseMessage(message);
      setIsLoading(false);
    }
  };

  // on step change, update the Back and Continue buttons wording/functionality
  useEffect(() => {
    if (step === steps[0]) {
      setBackButton({ name: 'Yard', stepIdx: -1 });
      setContinueButton({
        name: 'Description',
        stepIdx: 1,
      });
    } else if (step === steps[1]) {
      setBackButton({ name: 'Categories', stepIdx: 0 });
      setContinueButton({
        name: 'Pricing',
        stepIdx: 2,
      });
    } else if (step === steps[2]) {
      setBackButton({ name: 'Description', stepIdx: 1 });
      setContinueButton({
        name: 'Shipping',
        stepIdx: 3,
      });
    } else if (step === steps[3]) {
      setBackButton({
        name: 'Pricing',
        stepIdx: 2,
      });
      setContinueButton({
        name: 'Publish Your Sale',
        stepIdx: -1,
      });
    }
  }, [step, steps]);

  const determineIfConfirmationModalShows = () => {
    const {
      productCondition,
      productConditionRating,
      productConditionNotes,
      productImages,
      productDocuments,
      ...rest // get description properties that don't have default values
    } = descriptionInfo;

    // if the user has started creating a listing, confirm that they want to navigate away before saving any progress (a draft can only be saved if there is description info)
    const descriptionHasValues = Object.values(rest).some(
      (value) => value !== '',
    );

    if (descriptionHasValues && step !== 'shipping') {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    // return to home
    navigate('/', { replace: true });
  };

  const handleContinue = () => {
    setStep(steps[continueButton.stepIdx]);
    // scroll to top of form; the block: 'nearest' ensures it scrolls the modal and not the window
    headerRef.current?.scrollIntoView({ block: 'nearest' });
  };

  const handleOnPublish = () => {
    setIsLoading(true);
    // remove the confirmation modal listener since the user is saving their info now
    setShowConfirmNavigatePopup(false);
    createListing();
  };

  const handleClickScroll = (fieldValidation: FieldValidation[]) => {
    if (validateFieldsOnClick) {
      const formIsValid = fieldValidation.every((field) => !field.isMissing);

      if (formIsValid) {
        setValidateFieldsOnClick(false);
      } else {
        const firstElement = document.querySelector('.invalidInput');
        if (firstElement) {
          // scroll to first invalid input
          firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // reset validateFieldsOnClick in order run this again on click
        setValidateFieldsOnClick(false);
      }
    }
  };

  const dropdownMenuOptions = [
    // removed for beta
    // only allow saving as draft after step 1
    // ...(stepIdx > 0
    //   ? [
    //       {
    //         label: 'Save Draft & Close',
    //         value: 'draft',
    //         onClick: () => {
    //           // TODO: api call to save as draft
    //           handleClose();
    //         },
    //       },
    //     ]
    //   : []),
    {
      label: 'Cancel & Close',
      value: 'cancel',
      onClick: handleClose,
    },
  ];

  const completedStepRollups = () => {
    const productListing = {
      category: categoryInfo,
      description: descriptionInfo,
      pricing: pricingInfo,
      shipping: shippingInfo,
    };

    return (
      <PreviousStepInfo
        steps={steps}
        setStep={setStep}
        stepIdx={stepIdx}
        productListing={productListing}
      />
    );
  };

  const screenContent = () => {
    const baseFieldValidationProps: FieldValidationBaseProps = {
      validateFieldsOnClick,
      setValidateFieldsOnClick,
      handleClickScroll,
    };
    switch (step) {
      case 'category':
        return (
          <CategoryScreen
            categoryInfo={categoryInfo}
            setCategoryInfo={setCategoryInfo}
            fieldValidationProps={{
              ...baseFieldValidationProps,
              setCategoryFieldsValidation,
            }}
          />
        );
      case 'product description':
        return (
          <DescriptionScreen
            descriptionInfo={descriptionInfo}
            setDescriptionInfo={setDescriptionInfo}
            fieldValidationProps={{
              ...baseFieldValidationProps,
              setDescriptionFieldsValidation,
            }}
          />
        );
      case 'pricing':
        return (
          <PricingScreen
            pricingInfo={pricingInfo}
            setPricingInfo={setPricingInfo}
            fieldValidationProps={{
              ...baseFieldValidationProps,
              setPricingFieldsValidation,
            }}
          />
        );
      case 'shipping':
        return (
          <ShippingScreen
            user={user}
            shippingInfo={shippingInfo}
            setShippingInfo={setShippingInfo}
            setSaveAddressToUser={setSaveAddressToUser}
            fieldValidationProps={{
              ...baseFieldValidationProps,
              setShippingFieldsValidation,
            }}
          />
        );
      default:
        return (
          <CategoryScreen
            categoryInfo={categoryInfo}
            setCategoryInfo={setCategoryInfo}
            fieldValidationProps={{
              ...baseFieldValidationProps,
              setCategoryFieldsValidation,
            }}
          />
        );
    }
  };

  // we only want to show the rollup on the category screen if the description name has been filled out
  const hasSavedStepsOnCategory =
    stepIdx === 0 ? !!descriptionInfo?.productName : true;

  return (
    <div>
      {isLoading && <Loading screenOverlay />}
      <ConfirmNavigationPopup
        open={showConfirmNavigatePopup}
        confirmNavigation={handleClose}
        cancelNavigation={() => setShowConfirmNavigatePopup(false)}
      />
      <Grid container className={styles.headerContainer} ref={headerRef}>
        <Grid item xs={11} md={10} lg={8} className={styles.header}>
          List Your
          <br />
          Product
        </Grid>
        <Grid item xs={1} className={styles.menuWrapper}>
          <IconDropdown options={dropdownMenuOptions} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={8}
          className={styles.stepTracker}
        >
          <HeaderStepTracker
            stepIdx={stepIdx}
            continueDisabled={continueDisabled}
            setStep={setStep}
            steps={steps}
          />
        </Grid>
        {hasSavedStepsOnCategory && (
          <Grid
            container
            item
            xs={12}
            sm={8}
            md={4}
            lg={3}
            className={styles.headerPreviousSteps}
          >
            {completedStepRollups()}
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center" className={styles.bodyContainer}>
        <Grid
          container
          item
          xs={12}
          sm={8}
          md={6}
          lg={4}
          className={styles.formSection}
        >
          <div className={styles.contentWrapper}>{screenContent()}</div>
          <div className={styles.bottomButtonsContainer}>
            <ContinueButton
              step={step}
              validationFields={{
                categoryFieldsValidation,
                descriptionFieldsValidation,
                pricingFieldsValidation,
                shippingFieldsValidation,
              }}
              buttonProps={{
                continueDisabled,
                setContinueDisabled,
                handleContinue,
                handleOnPublish,
                handleDisabledClick: () => {
                  // if the user clicks the button when it's "disabled", we want to trigger the components form validation
                  setValidateFieldsOnClick(true);
                },
              }}
            />
            <BackButton
              text={`Back to ${backButton.name}`}
              onClick={() => {
                if (step === steps[0]) {
                  if (determineIfConfirmationModalShows()) {
                    setShowConfirmNavigatePopup(true);
                  } else {
                    handleClose();
                  }
                } else {
                  setStep(steps[backButton.stepIdx]);
                  // scroll to top of form; the block: 'nearest' ensures it scrolls the modal and not the window
                  headerRef.current?.scrollIntoView({ block: 'nearest' });
                }
              }}
            />
          </div>
        </Grid>
        {hasSavedStepsOnCategory && (
          <Grid
            container
            item
            xs={12}
            md={4}
            lg={3}
            className={styles.desktopPreviousSteps}
          >
            {completedStepRollups()}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ProductListingScreen;
