import React from 'react';
import formStyles from 'style/formStyles.module.scss';

type Props = {
  value: string | undefined | null;
  onChange: (phoneNumber: string) => void;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  label?: string; // default is Phone Number
  isInvalid?: boolean; // used to set invalid styling
  assistiveText?: string; // text below the input to assist the user
};

// use this component if you want to handle validation in the parent component
function PhoneInputNoValidation({
  className,
  containerClassName,
  value,
  onChange,
  label = 'Phone Number',
  isInvalid,
  assistiveText,
  ...props
}: Props) {
  const formatPhoneNumber = (val: string | undefined | null) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!val) return '';

    // clean the input for any non-digit values.
    let phoneNumber = val.replace(/[^\d]/g, '');

    // remove the country code if the user added it
    if (phoneNumber.charAt(0) === '1') {
      phoneNumber = phoneNumber.substring(1);
    }

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const showAssistiveText =
    (assistiveText && assistiveText !== '') || isInvalid;

  // for css color if the input contains a value
  const filledClassName = value && formStyles.filled;
  // for css transform if the input contains a value
  const transformLabel = value && formStyles.transform;
  // for css if a label is present
  const inputWithLabelClassName = label && formStyles.defaultInputWithLabel;
  // for css if the user provides an invalid value
  const invalidClassName = isInvalid ? formStyles.invalidUserInput : '';

  return (
    <div className={`${formStyles.outerWrapper} ${containerClassName}`}>
      <div
        className={`
          ${formStyles.defaultInputWrapper}
          ${filledClassName}
          ${invalidClassName}
        `}
      >
        <label
          className={`${formStyles.defaultInputLabel} ${transformLabel}`}
          htmlFor={label}
        >
          {label}
        </label>
        <input
          className={`
            ${formStyles.defaultInput}
            ${className}
            ${inputWithLabelClassName}
          `}
          type="tel"
          name="phone"
          value={formatPhoneNumber(value)}
          onChange={(e) => {
            // unformat the phone number
            let phoneNumber = e.target.value.replace(/[^\d]/g, '');
            // remove the country code else Stripe will complain
            if (phoneNumber.charAt(0) === '1') {
              phoneNumber = phoneNumber.substring(1);
            }

            onChange(phoneNumber);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
      {showAssistiveText && (
        <div
          className={`${formStyles.assistiveText} ${
            isInvalid && formStyles.redAssistiveText
          }`}
        >
          {assistiveText || 'Invalid phone number'}
        </div>
      )}
    </div>
  );
}

export default PhoneInputNoValidation;
