import React from 'react';
import { Listing } from 'api';
import Grid from '@mui/material/Grid';
import Table from 'components/Table/Table';
import styles from './categoryTable.module.scss';

interface Props {
  categoryName: any;
  tableData: Listing[] | [];
  className?: string;
}

function CategoryTable({ tableData, categoryName, className }: Props) {
  return (
    <Table
      tableName={
        <Grid container className={styles.tableHeader}>
          <div className={styles.latestIn}>Latest In</div> &nbsp;
          <Grid item xs={9} className={styles.category}>
            {categoryName.toLowerCase()}
          </Grid>
        </Grid>
      }
      startingView="list"
      data={tableData}
      sortTable
      className={`${styles.table} ${className}`}
      emptyTableMessage="No results found"
    />
  );
}

export default CategoryTable;
