import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import { useIsMobile, useIdentity } from 'hooks';
import MenuDropdown from 'components/MenuDropdown/MenuDropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import { TextLink } from 'components/Buttons';
import colorVars from 'style/index.module.scss';
import styles from './accountScreen.module.scss';

type Section = {
  name: string;
  showMenu: boolean;
  setShowMenu: () => void;
  options: {
    name: string;
    urlPath: string;
  }[];
};

function AccountScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileScreen = useIsMobile();
  const { businessName } = useIdentity();

  const [selectedScreen, setSelectedScreen] = useState({
    label: '',
    value: '',
  });

  // for showing and hiding the section menus
  const [showProfileMenu, setShowProfileMenu] = useState(true);
  const [showBusinessMenu, setShowBusinessMenu] = useState(true);

  // removed for MVP: add back Ratings at the end of myProfile
  const myProfile: Section = {
    name: 'My Profile',
    showMenu: showProfileMenu,
    setShowMenu: () => setShowProfileMenu(!showProfileMenu),
    options: [
      {
        name: 'User Details',
        urlPath: 'user_details',
      },
      // { removed for beta
      //   name: 'Communication Preferences',
      //   urlPath: 'communication_preferences',
      // },
      {
        name: 'Stripe',
        urlPath: 'stripe',
      },
      {
        name: 'Yard Terms',
        urlPath: '/terms_and_conditions',
      },
    ],
  };
  // removed for MVP: add back Storefront at the beginning of myProfile
  const myBusiness: Section = {
    name: 'My Business',
    showMenu: showBusinessMenu,
    setShowMenu: () => setShowBusinessMenu(!showBusinessMenu),
    options: [
      {
        name: 'Business Details',
        urlPath: 'business_details',
      },
      {
        name: 'Sales History',
        urlPath: '/orders/sales',
      },
      // { removed for beta
      //   name: 'My Terms',
      //   urlPath: 'seller_terms',
      // },
      {
        name: 'My Listings',
        urlPath: '/selling/my_yard_listings',
      },
    ],
  };

  const allOptions = [myProfile, myBusiness]; // removed for MVP: notificationMessage

  const navMenus = () =>
    allOptions.map((sectionMenu: Section) => {
      const { name, showMenu, setShowMenu, options } = sectionMenu;

      return (
        <MenuDropdown
          className={styles.menuDropdownContainer}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuTitle={name}
          key={name}
          menuClassName={styles.menuDropdownMenu}
          menuOptions={options.map((option) => {
            const { name: optionName, urlPath } = option;
            const selectedClassName =
              selectedScreen.label === option.name && styles.selectedMenuOption;

            return (
              <TextLink
                className={`${styles.menuLink} ${selectedClassName}`}
                key={optionName}
                text={optionName}
                onClick={() => navigate(urlPath)}
                textColor="leather"
              />
            );
          })}
        />
      );
    });

  const dropdownOptions = allOptions.map((group) => ({
    label: group.name,
    options: group.options.map((option) => {
      const optionName = option.name;
      return {
        label: optionName,
        value: option.urlPath,
      };
    }),
  }));

  const dropdownMenu = () => (
    <Dropdown
      containerClassName={styles.mobileDropdownMenu}
      buttonClassName={styles.mobileDropdown}
      options={dropdownOptions}
      defaultValue={selectedScreen}
      onChange={(option: any) => navigate(option.value)}
      selectedValueTextColor={colorVars.offWhiteMaterial}
      menuWidth="100%"
      dropdownIndicatorType="pageNavigation"
    />
  );

  useEffect(() => {
    const urlPaths = location.pathname.split('/');
    const screenUrl = urlPaths.length === 3 ? urlPaths[2] : urlPaths[1];
    let screen = { label: '', value: '' };

    if (screenUrl !== 'account') {
      dropdownOptions.forEach((group) => {
        group.options.forEach((option) => {
          if (option.value === screenUrl) {
            screen = option;
          }
        });
      });
    }

    setSelectedScreen(screen);
    // TODO: investigate why adding dropdownOptions as a dependency
    // causes update recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const displayedMenu = () => {
    // no menu should be displayed if on mobile AccountHome
    if (mobileScreen && selectedScreen.label !== '') {
      return (
        <div className={styles.mobileDropdownContainer}>
          <div className={styles.dotBackground} />
          {dropdownMenu()}
          <div className={styles.dotBackground} />
        </div>
      );
    } else if (!mobileScreen) {
      return <div className={styles.menus}>{navMenus()}</div>;
    }

    return null;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>My Account</h1>
          <div className={styles.businessName}>{businessName}</div>
        </div>
        <div className={styles.bodyContainer}>
          {displayedMenu()}
          <div className={styles.pageContainer}>
            {selectedScreen.label !== '' && (
              <div className={styles.pageHeader}>{selectedScreen.label}</div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountScreen;
