import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { isEmail } from 'validator';
import { useGetUsersResetPasswordQuery } from 'api';
import { TextLink, Button } from 'components/Buttons';
import FormContainer from 'components/FormContainer/FormContainer';
import { TextInput } from 'components/TextInput/TextInput';
import SnackbarDefault from 'components/Snackbar/Snackbar';
import styles from './forgotPasswordScreen.module.scss';

type Props = {
  setCurrentContent: any;
  setResetEmail: any;
};

function ForgotPasswordScreen({ setCurrentContent, setResetEmail }: Props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const validEmail = isEmail(email);

  const [showSnackbar, setShowSnackbar] = useState(false);

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');

  const { isError } = useGetUsersResetPasswordQuery(
    { email: resetPasswordEmail },
    { skip: resetPasswordEmail === '' },
  );

  if (!isError && resetPasswordEmail !== '') {
    setResetEmail(resetPasswordEmail);
    setCurrentContent('reset-request-sent');
  } else if (isError && !showSnackbar) {
    setShowSnackbar(true);
  }

  return (
    <div className={styles.wrapper}>
      <SnackbarDefault
        open={showSnackbar}
        message="An error occurred, please try again"
        onClose={() => {
          setShowSnackbar(false);
        }}
      />
      <FormContainer
        title="Forget password?"
        actionNeeded
        content={
          <div className={styles.formContentWrapper}>
            <div className={styles.text}>
              No problem. Enter the email address associated with your account,
              and we&apos;ll send you a link to reset your password.
            </div>
            <TextInput
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              invalidUserInput={invalidEmail}
              onBlur={() => {
                if (email !== '' && !validEmail) {
                  setInvalidEmail(true);
                } else if (validEmail && invalidEmail) {
                  setInvalidEmail(false);
                }
              }}
            />
            <div className={styles.buttonContainer}>
              <TextLink
                text="CANCEL"
                onClick={() => setCurrentContent('sign-in')}
              />
              <Button
                buttonColor="sand20"
                text="RESET PASSWORD"
                buttonClassName={styles.resetButton}
                roundedButton
                disabled={!validEmail}
                onClick={() => {
                  setResetPasswordEmail(email);
                }}
              />
            </div>
          </div>
        }
      />
      <div className={styles.footer}>
        <div className={`${styles.text} ${styles.bottomText}`}>
          If you&apos;re having trouble logging in or forgot your email address,
          email The Yard and a member of our team will reach out to help.
        </div>
        <TextLink
          className={styles.emailLink}
          textColor="leather"
          onClick={() => {
            navigate('/help');
          }}
        >
          Email The Yard for other issues.
        </TextLink>
      </div>
    </div>
  );
}

export default ForgotPasswordScreen;
