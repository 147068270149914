import React, { useState, useEffect } from 'react';
import { User, usePutUsersByUserIdMutation } from 'api';
import { useGetUserDetails } from 'hooks';
import { Button } from 'components/Buttons';
import TextArea from 'components/TextArea';
import SnackbarDefault from 'components/Snackbar/Snackbar';
import Loading from 'components/Loading';
import styles from './sellerTerms.module.scss';

type Props = {
  userData: User;
};

function BusinessTermsContent({ userData }: Props) {
  const { id: userId, userSettings } = userData;
  const savedTerms = userSettings?.sellerTerms ?? '';
  const [updateTerms] = usePutUsersByUserIdMutation();
  const [terms, setTerms] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (savedTerms) setTerms(savedTerms);
  }, [savedTerms]);

  const saveTerms = async () => {
    try {
      const payload: any = await updateTerms({
        userId,
        body: {
          userSettings: {
            sellerTerms: terms,
          },
        },
      }).unwrap();

      if (payload) {
        setTerms(payload.userSettings.sellerTerms);
      }
    } catch (err) {
      setShowSnackbar(true);
    }
  };

  return (
    <div>
      <SnackbarDefault
        open={showSnackbar}
        message="An error occurred, please try again"
        onClose={() => {
          setShowSnackbar(false);
        }}
      />
      <div className={styles.form}>
        <div className={styles.formHeader}>Seller Terms</div>
        <div className={styles.formDescription}>
          If you&apos;ve got business hours, or other things that a buyer should
          know, here&apos;s the place to put them.
          <br />
          <br />
          No matter what, you&apos;re covered under the Yard terms and Stripe
          terms.
        </div>
        <TextArea
          className={styles.textArea}
          placeholder="Terms"
          value={terms}
          onChange={(e) => setTerms(e.target.value)}
        />
        <div className={styles.buttonContainer}>
          <Button
            buttonClassName={`${styles.button} ${styles.cancelButton}`}
            title="Cancel"
            onClick={() => setTerms(savedTerms)}
            disabled={savedTerms === terms}
            buttonType="text"
            textColor="black"
            roundedButton
          />
          <Button
            buttonClassName={styles.button}
            title="Save"
            onClick={() => saveTerms()}
            disabled={savedTerms === terms}
            buttonColor="black"
            roundedButton
          />
        </div>
      </div>
    </div>
  );
}

function BusinessTerms() {
  const [userData, setUserData] = useState<User>();
  const getUserResponse = useGetUserDetails();

  useEffect(() => {
    if (getUserResponse?.success) {
      const { user } = getUserResponse.data;
      setUserData(user);
    }
  }, [getUserResponse]);

  if (!userData) {
    return <Loading />;
  }

  return <BusinessTermsContent userData={userData} />;
}

export default BusinessTerms;
