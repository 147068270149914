import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useGetUsersByUserIdVerifyQuery } from 'api';
import { setStripeAccountVerified } from 'slices/accountSlice';
import { useIdentity } from 'hooks';
import Loading from 'components/Loading';
import styles from './stripeAccountRedirect.module.scss';

function StripeAccountRedirect() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useIdentity();

  const { currentData, refetch } = useGetUsersByUserIdVerifyQuery({
    userId,
  });

  const [refetchCount, setRefetchCount] = useState<number>(0);
  const [hideLoading, setHideLoading] = useState<boolean>(false);
  const [displayedMessage, setDisplayedMessage] = useState<string>(
    'Verifying your Stripe account...',
  );

  const finalAction = useCallback(
    async (isVerified: boolean) => {
      dispatch(setStripeAccountVerified(isVerified));

      const message = isVerified
        ? 'Your Stripe account has been verified!'
        : 'Your Stripe account could not be verified. Please confirm that you have completed the onboarding process.';
      setDisplayedMessage(message);
      setHideLoading(true);

      // give the user a couple seconds to read the message
      const timeout = isVerified ? 2000 : 3000;
      setTimeout(() => {
        navigate('/');
      }, timeout);
    },
    [dispatch, navigate],
  );

  const refetchData = useCallback(async () => {
    setTimeout(() => {
      setRefetchCount(refetchCount + 1);
      refetch();
    }, 3000);
  }, [refetch, setRefetchCount, refetchCount]);

  useEffect(() => {
    if (currentData?.success) {
      const verified = currentData?.data?.verified || false;

      if (verified || refetchCount === 5) {
        finalAction(verified);
      } else if (!verified && refetchCount < 5) {
        refetchData();
      }
    }
  }, [currentData, refetchCount, refetchData, finalAction]);

  return (
    <div className={styles.container}>
      {displayedMessage}
      {!hideLoading && <Loading screenOverlay />}
    </div>
  );
}

export default StripeAccountRedirect;
