import React, { useState } from 'react';
import { Outlet } from 'react-router';
import { BetaPasswordModal } from 'components/Popups';

export default function BetaPasswordProtectedRoute() {
  const [correctPassword, setCorrectPassword] = useState(false);

  return correctPassword ? (
    <Outlet />
  ) : (
    <BetaPasswordModal setCorrectPassword={setCorrectPassword} />
  );
}
