import React from 'react';
import Grid from '@mui/material/Grid';
import styles from './cardComponent.module.scss';

export type ContainerStates = 'pending' | 'focus' | 'actionNeeded' | 'complete';

type Props = {
  title: string;
  status: string;
  icon: string | JSX.Element;
  containerState: ContainerStates; // https://zpl.io/Q0AyRpW
  children: JSX.Element;
  redStatus?: boolean;
};

function CardComponent({
  title,
  status,
  icon,
  containerState,
  children,
  redStatus,
}: Props) {
  const redStatusClassName = redStatus ? styles.redStatus : '';
  const statusIcon =
    typeof icon === 'string' ? <img src={icon} alt="status icon" /> : icon;

  let containerClassName = styles.pendingState;

  switch (containerState) {
    case 'pending':
      containerClassName = styles.pendingState;
      break;
    case 'focus':
      containerClassName = styles.focusState;
      break;
    case 'actionNeeded':
      containerClassName = styles.actionNeeded;
      break;
    default:
      // complete state
      containerClassName = styles.completeState;
  }

  return (
    <div className={containerClassName}>
      <Grid item xs={12} container className={styles.headerContainer}>
        <div className={styles.header}>{title}</div>
        <div className={`${styles.statusContainer} ${redStatusClassName}`}>
          {status} &nbsp;
          {statusIcon}
        </div>
      </Grid>

      <Grid item xs={12} container className={styles.bodyContainer}>
        {children}
      </Grid>
    </div>
  );
}

export default CardComponent;
