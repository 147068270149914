import React, { useState, useEffect } from 'react';
import { Listing } from 'api';
import { ReactComponent as EllipsisIcon } from 'assets/icons/ellipsisIcon.svg';
import { BuyNowButton, AddToCartButton } from 'components/Buttons';
import { COLORS } from 'style/colors';
import styles from './listItem.module.scss';

type Props = {
  listing: Listing;
};

function Menu({ listing }: Props) {
  const optionRef = React.useRef<any>(null);
  const [displayMenu, setDisplayMenu] = useState(false);
  const displayClassName = displayMenu ? styles.showMenu : styles.hideMenu;
  const pendingSale = listing.remainingQty === 0 && !listing.sold;
  const buyNowDisabledClassName =
    pendingSale || listing.sold || listing.deleted ? styles.disabled : '';

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (
        displayMenu &&
        optionRef.current &&
        !optionRef.current.contains(event.target)
      ) {
        setDisplayMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [displayMenu]);

  return (
    <div
      className={styles.iconMenu}
      onClick={(e: any) => {
        e.stopPropagation();
        setDisplayMenu(!displayMenu);
      }}
    >
      <EllipsisIcon fill={COLORS.sand20} className={styles.icon} />
      <div className={`${styles.menu} ${displayClassName}`} ref={optionRef}>
        <AddToCartButton
          listing={listing}
          buttonType="dropdownOption"
          dropdownOptionProps={{
            optionClassName: `${styles.option} ${styles.firstOption}`,
            closeMenu: () => setDisplayMenu(false),
          }}
        />
        <BuyNowButton
          listing={listing}
          buttonType="dropdownOption"
          dropdownOptionProps={{
            optionClassName: `${styles.option} ${styles.lastOption} ${buyNowDisabledClassName}`,
            closeMenu: () => setDisplayMenu(false),
          }}
        />
      </div>
    </div>
  );
}

export default Menu;
