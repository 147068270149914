/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';
import { isEmail } from 'validator';
import {
  usePostUsersByUserIdProfileImageMutation,
  usePutUsersByUserIdProfileImageMutation,
  usePutUsersByUserIdMutation,
  User,
  useGetUsersResetPasswordQuery,
} from 'api';
import { useDropzone } from 'react-dropzone';
import ReactLoading from 'react-loading';
import { useModal } from 'react-modal-hook';
import { useGetUserDetails, useApiResponseMessage } from 'hooks';
import { TextLink, Button, IconButton } from 'components/Buttons';
import TextInput from 'components/TextInput/TextInput';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as DefaultUserIcon } from 'assets/icons/userIcon.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/cameraIcon.svg';
import Loading from 'components/Loading';
import styles from './userDetails.module.scss';
import DeleteAccountModal from '../DeleteAccountModal';

type DetailsProps = {
  userData: User;
};

function Details({ userData }: DetailsProps) {
  const { id: userId, userSettings } = userData;
  const { apiResponseMessage } = useApiResponseMessage();

  const [getUploadUrl] = usePostUsersByUserIdProfileImageMutation();
  const [setFilename] = usePutUsersByUserIdProfileImageMutation();
  const [uploading, setUploading] = useState(false);
  let profilePicture = userSettings?.profileImageUrl ?? null;

  const [updateUser] = usePutUsersByUserIdMutation();
  const {
    name: userName = '',
    email: userEmail = '',
    phone: userPhone = '',
  } = userData || {};
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const validEmail = email && isEmail(email);
  const [invalidPhone, setInvalidPhone] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setName(userName);
    setEmail(userEmail);
    setPhone(userPhone);
  }, [userName, userEmail, userPhone]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        setUploading(true);
        const profileImageTarget = await getUploadUrl({
          userId,
          body: {
            mimeType: acceptedFiles[0].type,
          },
        }).unwrap();

        try {
          const res = await fetch(profileImageTarget.data.url, {
            method: 'put',
            headers: {
              'Content-Type': acceptedFiles[0].type,
            },
            body: acceptedFiles[0],
          });

          if (res.status === 200) {
            const payload = await setFilename({
              userId,
              body: {
                fileName: profileImageTarget.data.fileName,
              },
            }).unwrap();

            // This variable is updated from useGetUsersByUserIdQuery on
            // each render. Setting it here is so it will show on the page
            // as soon as it is uploaded
            // eslint-disable-next-line react-hooks/exhaustive-deps
            profilePicture = payload.data.url;
          }
          setUploading(false);
        } catch (err) {
          setUploading(false);
        }
      }
    },
    [getUploadUrl, setFilename, userId],
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: ['image/jpeg', 'image/png'],
      multiple: false,
    });

  const style = `baseStyle ${isFocused ? 'focusedStyle' : ''} ${
    isDragAccept ? 'acceptStyle' : ''
  }${isDragReject ? 'rejectStyle' : ''}`;

  const updateUserDetails = async () => {
    try {
      const payload: any = await updateUser({
        userId,
        body: {
          ...(userName !== name && { name }),
          ...(userPhone !== phone && { phone: phone?.replace(/[^\d]/g, '') }),
          ...(userEmail !== email && { email }),
        },
      }).unwrap();

      if (payload.success) {
        apiResponseMessage('User details updated');
      }
    } catch (err: any) {
      let message = 'An error occurred, please try again';
      if (err.data?.data[0]?.msg) {
        message = err.data?.data[0]?.msg;
      }
      apiResponseMessage(message);
    }
  };

  const clearChanges = () => {
    setName(userName);
    setEmail(userEmail);
    setPhone(userPhone);
  };

  const userMadeChanges = () => {
    if (
      userEmail !== email?.trim() ||
      userPhone !== phone ||
      userName !== name?.trim()
    ) {
      if (!hasChanges) {
        setHasChanges(true);
      }
    } else if (hasChanges) {
      setHasChanges(false);
    }
  };

  useEffect(userMadeChanges, [
    userEmail,
    email,
    userPhone,
    phone,
    userName,
    name,
    hasChanges,
  ]);

  const [showDeleteAccountModal, hideDeleteAccountModal] = useModal(
    () => (
      <DeleteAccountModal
        closeDeleteAccountModal={() => hideDeleteAccountModal()}
      />
    ),
    [],
  );

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');

  const { isError, currentData: resetData } = useGetUsersResetPasswordQuery(
    { email },
    { skip: resetPasswordEmail === '' },
  );

  useEffect(() => {
    if (resetData?.success) {
      setResetPasswordEmail('');
      apiResponseMessage('Please check your email for the reset password link');
    } else if (isError) {
      setResetPasswordEmail('');
      apiResponseMessage('An error occurred, please try again');
    }
  }, [resetData, isError, apiResponseMessage]);

  return (
    <div className="screen">
      <div className={styles.formContainer}>
        <div className={styles.uploadContainer}>
          <div {...getRootProps({ className: style })}>
            {!uploading && (
              <Avatar
                className={styles.avatar}
                sx={{ width: '122px', height: '122px' }}
              >
                {profilePicture ? (
                  <img
                    className={styles.image}
                    alt="Profile"
                    src={profilePicture}
                  />
                ) : (
                  <DefaultUserIcon className={styles.defaultImage} />
                )}
              </Avatar>
            )}
            {uploading && (
              <ReactLoading
                type="spinningBubbles"
                height="100%"
                width="100%"
                color="black"
              />
            )}
            <input {...getInputProps()} />
            <IconButton className={styles.cameraIcon} icon={CameraIcon} />
          </div>
        </div>
        <TextInput
          containerClassName={styles.input}
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          invalidUserInput={!validEmail}
        />
        <PhoneInput
          containerClassName={styles.input}
          label="Personal phone number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          validateMobile
          setIsInvalid={(invalid: boolean) => setInvalidPhone(invalid)}
        />
        <TextInput
          containerClassName={`${styles.input} ${styles.nameInput}`}
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className={styles.nameExplination}>
          Add your name for correspondence
        </div>
        <div className={styles.buttonsContainer}>
          <TextLink
            className={styles.cancelButton}
            text="Cancel"
            onClick={() => clearChanges()}
            disabled={!hasChanges}
          />
          <Button
            containerClassName={styles.actionButton}
            title="SAVE"
            onClick={() => updateUserDetails()}
            disabled={!hasChanges || invalidPhone || !validEmail}
            buttonColor="black"
            roundedButton
          />
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formHeader}>Change Your Password</div>
        <div className={styles.bodyText}>
          This only affects your Yard login.
        </div>
        <div className={`${styles.buttonsContainer} password`}>
          <Button
            title="CHANGE PASSWORD"
            onClick={() => setResetPasswordEmail(email)}
            buttonColor="black"
            roundedButton
          />
        </div>
      </div>
      {/* removed for MVP: <div>
        <div className={styles.yardCertifiedHeaderContainer}>
          <div className={styles.formHeader}>
            Want to be Yard Certified?
          </div>
          &nbsp;
          <YardLogo />
        </div>
        <div className={styles.bodyText}>
          You may also apply with The Yard and retain your anonymity (if
          desired),—while showing to users that we have validated your
          authenticity.
        </div>
        <TextLink
          className={styles.contactUs}
          text="Contact Us"
          onClick={() => navigate('/help')}
          textColor="leather"
        />
      </div> */}
      <div className={styles.deleteAccountContainer}>
        <TextLink
          className={styles.deleteAccount}
          text="DELETE MY YARD ACCOUNT"
          onClick={() => showDeleteAccountModal()}
        />
      </div>
    </div>
  );
}

function UserDetails() {
  const userData = useGetUserDetails();

  if (!userData) {
    return <Loading />;
  }

  return <Details userData={userData.data.user} />;
}

export default UserDetails;
