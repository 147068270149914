import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ListingFile } from 'api';
import { useAppSelector } from 'hooks';
import { setSelectedFilters } from 'slices/shoppingSlice';
import Grid from '@mui/material/Grid';
// import TextLink from 'components/Button/TextLink'; // removed for beta
import styles from './featuredItems.module.scss';

type Props = {
  category: string;
  subcategories: {
    name: string;
    number: string;
    count: number;
    images: ListingFile[];
  }[];
};

function FeaturedItems({ category, subcategories }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxFiltersAndSort = useAppSelector((state) => state.shopping);
  const { selectedFilters = {} } = reduxFiltersAndSort;

  const setSubcategoryFilter = (selectedSubCategory: string) => {
    const subcategoryName = selectedSubCategory.toLowerCase();
    dispatch(
      setSelectedFilters({
        ...selectedFilters,
        subcategory: [subcategoryName],
      }),
    );
    navigate(`/shopping/${category}/${subcategoryName}`);
  };

  // removed for beta
  // const handleViewAllSubcategories = () => {
  //   // TODO: send user to subcategories screen (doesn't exist yet)
  // };

  const card = (subcategory: any) => {
    const { name, number, images, count } = subcategory;
    const displayedCount = count > 1 ? `${count - 1}+` : count;
    const numberOfImages = images.length;

    const displayedImages = () => {
      if (numberOfImages === 2) {
        return (
          <div className={styles.multipleImagesWrapper}>
            {images.map((image: ListingFile, idx: number) => {
              const imageClassName =
                idx === 0 ? styles.firstImage : styles.lastImage;
              const randomNum = Math.floor(Math.random() * 1000);
              return (
                <img
                  src={image.url}
                  alt={image.fileName}
                  key={`${image.url}-${randomNum}`}
                  className={`${styles.twoImage} ${imageClassName}`}
                />
              );
            })}
          </div>
        );
      } else if (numberOfImages > 2) {
        const shuffledArray = images.sort(() => 0.5 - Math.random()); // shuffles array so we can get random images
        const threeRandomImages = shuffledArray.slice(0, 3); // display the first 3 images
        return (
          <div className={styles.multipleImagesWrapper}>
            {threeRandomImages.map((image: ListingFile, idx: number) => {
              let imageClassName = styles.firstImage;
              if (idx === 1) imageClassName = styles.secondImage;
              if (idx === 2) imageClassName = styles.lastImage;
              const randomNum = Math.floor(Math.random() * 1000);
              return (
                <img
                  src={image.url}
                  alt={image.fileName}
                  key={`${image.url}-${randomNum}`}
                  className={`${styles.threeImage} ${imageClassName}`}
                />
              );
            })}
          </div>
        );
      } else {
        return (
          <img
            src={images[0].url}
            alt={images[0].fileName}
            key={images[0].url}
            className={styles.singleImage}
          />
        );
      }
    };

    return (
      <Grid
        container
        className={styles.featuredCard}
        key={number}
        onClick={() => setSubcategoryFilter(name)}
      >
        <div className={styles.quantity}>{displayedCount}</div>
        <Grid container item xs={12} className={styles.imageContainer}>
          {displayedImages()}
        </Grid>
        <Grid item xs={12} className={styles.cardBottomContainer}>
          <div className={styles.subcategoryNumber}>{number}</div>
          <div className={styles.subcategoryName}>{name.toLowerCase()}</div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={styles.container}>
      <Grid container className={styles.headerContainer}>
        <div className={styles.featuredIn}>Featured In</div>
        <div className={styles.category}>&nbsp;{category}</div>
        {/* removed for beta */}
        {/* <TextLink
          text="See all subcategories"
          textColor="leather"
          onClick={handleViewAllSubcategories}
          className={styles.seeAllLink}
        /> */}
      </Grid>
      <Grid container sx={{ flexGrow: 1 }} spacing={1}>
        {subcategories.map((subcategory) => (
          <Grid
            item
            xs={6}
            md={3}
            key={subcategory.number}
            className={styles.cardWrapper}
          >
            {card(subcategory)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default FeaturedItems;
