import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes, Navigate } from 'react-router';
import { ModalProvider } from 'react-modal-hook';
import { BrowserHistory, createBrowserHistory } from 'history';
import AuthenticatedRoute from 'AuthenticatedRoute';
import VerifiedRoute from 'VerifiedRoute';
import BetaPasswordProtectedRoute from 'BetaPasswordProtectedRoute';
/* Help Screens */
import HelpScreen from 'screens/HelpScreen/HelpScreen';
import SupportFormScreen from 'screens/HelpScreen/Support/SupportForm';
/* Shopping Screens */
import ShoppingScreen from 'screens/ShoppingScreens/ShoppingScreen';
import CategoryScreen from 'screens/ShoppingScreens/CategoryScreen';
import SubcategoryScreen from 'screens/ShoppingScreens/SubcategoryScreen';
import SearchResultsScreen from 'screens/ShoppingScreens/SearchResultsScreen';
/* Account Screens */
import {
  AccountScreen,
  AccountHome,
  BusinessDetails,
  Stripe,
  UserDetails,
  // CommunicationPreferences, // removed for beta
  // SellerTerms, // removed for beta
} from 'screens/UserAccount';
/* Info Screens */
import {
  InterestedInSellingScreen,
  FAQsScreen,
  AboutScreen,
} from 'screens/InfoScreens';
import PrivacyPolicy from 'screens/PrivacyPolicyScreen';
import TermsAndConditions from 'screens/TermsAndConditionsScreen';
/* Listing Product Screens */
import ProductListingScreen from 'screens/ProductListing/ProductListingScreen';
import ProductListedModal from 'screens/ProductListing/ProductListed';
/* Single Page Screens */
import HomeScreen from 'screens/HomeScreen';
import ProductScreen from 'screens/ProductScreen/ProductScreen';
import MyListingsScreen from 'screens/MyListingsScreen';
import MyOrdersScreen from 'screens/Orders/MyOrdersScreen';
import OrderDetails from 'screens/OrderDetails';
import ShoppingCart from 'screens/ShoppingCart';
import Storefront from 'screens/Storefront';
import VerifyScreen from 'screens/VerifyScreen';
import CheckoutScreen from 'screens/CheckoutScreen';
import NotFound404 from 'screens/NotFound404';
import StripeAccountRedirect from 'screens/StripeAccountRedirect/StripeAccountRedirect';
import CreateAccount from 'screens/CreateAccount/CreateAccount';
/* Components */
import Header from 'components/Header';
import HomeFooter from 'components/Footer/HomeFooter';
import SignInModal from 'components/SignInModal/SignInModal';
import ResetPasswordModal from 'components/ResetPasswordModal/ResetPasswordModal';
import { Components, TestPage } from 'screens/Components';
import ApiResponseHandler from 'ApiResponseHandler';
import ScrollToTop from './ScrollToTop';

// TODO: jsx-a11y rules need to be re-enabled and errors fixed

export default function App() {
  const historyRef = useRef<BrowserHistory>();
  const location = useLocation();

  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window });
  }

  // hide the header at checkout, create listing, and order details
  const showHeader =
    !location.pathname.includes('checkout') &&
    !location.pathname.includes('product_listing') &&
    !location.pathname.includes('purchase_details') &&
    !location.pathname.includes('create_account');

  // hide the footer at checkout and create listing
  const showFooter =
    !location.pathname.includes('checkout') &&
    !location.pathname.includes('product_listing') &&
    !location.pathname.includes('create_account');

  return (
    <ModalProvider>
      <div className="app-container">
        {showHeader && <Header />}
        <div className="body">
          <ApiResponseHandler />
          <ScrollToTop />
          <Routes>
            <Route index element={<HomeScreen />} />
            <Route path="help" element={<HelpScreen />} />
            <Route path="privacy_policy" element={<PrivacyPolicy />} />
            <Route
              path="terms_and_conditions"
              element={<TermsAndConditions />}
            />
            <Route path="sign_in" element={<SignInModal />} />
            <Route element={<BetaPasswordProtectedRoute />}>
              <Route
                path="sign_in/create_account"
                element={<CreateAccount />}
              />
              <Route path="create_account" element={<CreateAccount />} />
            </Route>
            <Route path="resetPassword" element={<ResetPasswordModal />} />
            <Route path="shopping" element={<ShoppingScreen />}>
              <Route
                path=":categoryName/:subcategoryName"
                element={<SubcategoryScreen />}
              />
              <Route path=":categoryName" element={<CategoryScreen />} />
              <Route
                path="search_results/:keyword"
                element={<SearchResultsScreen />}
              />
              <Route path="search_results" element={<SearchResultsScreen />} />
            </Route>
            <Route
              path="interested_in_selling"
              element={<InterestedInSellingScreen />}
            />
            <Route
              path="product/:category/:subcategory/:listingId"
              element={<ProductScreen />}
            />
            <Route path="product/:listingId" element={<ProductScreen />} />
            <Route path="storefront" element={<Storefront />} />
            <Route path="faqs" element={<FAQsScreen />} />
            <Route path="about" element={<AboutScreen />} />
            <Route path="verify" element={<VerifyScreen />} />
            <Route element={<AuthenticatedRoute />}>
              <Route element={<VerifiedRoute />}>
                <Route path="checkout/:orderId" element={<CheckoutScreen />} />
                <Route
                  path="product_listing"
                  element={<ProductListingScreen />}
                />
              </Route>
              <Route path="shopping_cart" element={<ShoppingCart />} />
              <Route path="support_ticket" element={<SupportFormScreen />} />
              <Route path="product_listed" element={<ProductListedModal />} />
              <Route path="account" element={<AccountScreen />}>
                <Route path="user_details" element={<UserDetails />} />
                <Route path="stripe" element={<Stripe />} />
                <Route path="business_details" element={<BusinessDetails />} />
                {/* <Route
                  path="communication_preferences"
                  element={<CommunicationPreferences />}
                /> */}
                {/* <Route path="seller_terms" element={<SellerTerms />} /> */}
                {/* <Route path="ratings" element={<Ratings />} /> */}
                {/* <Route path="notifications" element={<Notifications />} /> */}
                {/* <Route path="messaging" element={<Messaging />} /> */}
                <Route index element={<AccountHome />} />
              </Route>
              <Route path="selling">
                <Route
                  path="purchase_details/:orderId"
                  element={<OrderDetails />}
                />
                <Route path="my_yard_listings" element={<MyListingsScreen />} />
              </Route>
              <Route
                path="orders/purchase_details/:orderId"
                element={<OrderDetails />}
              />
              <Route
                path="orders"
                element={<Navigate replace to="purchases" />}
              />
              <Route path="orders/*" element={<MyOrdersScreen />}>
                <Route path=":tab" element={<MyOrdersScreen />} />
              </Route>
              <Route
                path="verifying_stripe_account"
                element={<StripeAccountRedirect />}
              />
            </Route>
            <Route path="dev">
              <Route path="components" element={<Components />} />
              <Route path="test-page" element={<TestPage />} />
            </Route>
            <Route path="404" element={<NotFound404 type="url" />} />
            <Route path="*" element={<Navigate to="404" replace />} />
          </Routes>
        </div>
        {showFooter && <HomeFooter />}
      </div>
    </ModalProvider>
  );
}
