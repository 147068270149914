import React, { useState, useEffect } from 'react';
import { useGetUsersByUserIdOnboardQuery } from 'api';
import { useIdentity } from 'hooks';
import Loading from 'components/Loading';
import { Button } from 'components/Buttons';
import DialogPopup from 'components/DialogPopup';
import styles from './unverifiedStripeAccountPopup.module.scss';

type Props = {
  onClose?: () => void;
};

function UnverifiedStripeAccountPopup({ onClose }: Props) {
  const [isOpen, setIsOpen] = useState(true);
  const { userId, stripeAccountVerified } = useIdentity();

  // generate the link on click to prevent stale link
  const [generateStripeLink, setGenerateStripeLink] = useState<boolean>(false);
  const [stripeAccountSetupLink, setStripeAccountSetupLink] =
    useState<string>();

  const { data: onboardQueryData } = useGetUsersByUserIdOnboardQuery(
    { userId },
    { skip: !generateStripeLink },
  );

  useEffect(() => {
    if (onboardQueryData) {
      setStripeAccountSetupLink(onboardQueryData?.data?.link.url);
    }
  }, [onboardQueryData]);

  useEffect(() => {
    if (stripeAccountSetupLink) {
      window.open(stripeAccountSetupLink, '_self');
    }
  });

  const content = () => (
    <>
      {generateStripeLink && <Loading screenOverlay />}
      <span>You must complete your Stripe account to perform this action</span>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => setGenerateStripeLink(true)}
          text="COMPLETE YOUR STRIPE ACCOUNT"
          buttonColor="sand20"
        />
      </div>
    </>
  );

  const handleDialogClose = () => {
    if (onClose) {
      onClose();
    } else {
      setIsOpen(false);
    }
  };

  if (stripeAccountVerified) {
    return null;
  }

  return (
    <DialogPopup
      open={isOpen}
      title="Complete Setup"
      content={content()}
      onClose={handleDialogClose}
    />
  );
}

export default UnverifiedStripeAccountPopup;
