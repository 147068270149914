const useFormatPrice = (price?: number | string) => {
  if (!price) {
    return '$0.00';
  }
  if (typeof price === 'string' && price === 'TBD') {
    return price;
  }

  const numberPrice = typeof price === 'number' ? price : parseFloat(price);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(numberPrice);
};

export default useFormatPrice;
