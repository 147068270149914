import React, { useState, useEffect } from 'react';
import { usePostOrdersByOrderIdAcceptMutation, Order } from 'api';
import { useApiResponseMessage } from 'hooks';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import Loading from 'components/Loading';
import DialogPopup from 'components/DialogPopup';
import RejectOrderModal from '../RejectOrderModal/RejectOrderModal';
import styles from './inspectOrderDialog.module.scss';

type Props = {
  order: Order;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => any;
};

function InspectOrderDialog({ order, isOpen, setIsOpen }: Props) {
  const { apiResponseMessage } = useApiResponseMessage();
  const [acceptOrder] = usePostOrdersByOrderIdAcceptMutation();

  const [showRejectOrderModal, setShowRejectOrderModal] = useState(false);
  const [successfulApiCall, setSuccessfulApiCall] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id: orderId } = order;

  const handleAccept = async () => {
    try {
      setLoading(true);

      const payload: any = await acceptOrder({
        orderId,
      }).unwrap();

      if (payload.success) {
        setLoading(false);
        apiResponseMessage('Order accepted');

        setSuccessfulApiCall(true);
      }
    } catch (error) {
      setLoading(false);
      apiResponseMessage('There was an error accepting the order.');
    }
  };

  const bodyText = (
    <>
      {loading && <Loading screenOverlay />}
      <div className={styles.bodyText}>
        Do the materials match what you ordered? If so accept the order. You
        have 3 days since receiving shipment to do this before the order is
        automatically accepted.
      </div>
    </>
  );

  const actionButtons = (
    <Grid container justifyContent="space-between">
      <Grid item xs={5}>
        <Button
          text="REJECT"
          onClick={() => setShowRejectOrderModal(true)}
          buttonType="secondary"
          buttonColor="black"
          roundedButton
          containerClassName={styles.buttonContainer}
          buttonClassName={styles.rejectButton}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          text="ACCEPT"
          onClick={handleAccept}
          buttonColor="black"
          roundedButton
          containerClassName={styles.buttonContainer}
        />
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (successfulApiCall && isOpen) {
      setIsOpen(false);
    }
    return () => {
      setShowRejectOrderModal(false);
      setSuccessfulApiCall(false);
      setLoading(false);
    };
  }, [successfulApiCall, isOpen, setIsOpen]);

  if (showRejectOrderModal) {
    return (
      <RejectOrderModal
        order={order}
        onClose={() => {
          setShowRejectOrderModal(false);
        }}
        onSuccessfulSubmit={() => {
          setSuccessfulApiCall(true);
        }}
      />
    );
  }

  return (
    <DialogPopup
      title="Inspect Your Order"
      open={isOpen}
      content={bodyText}
      actionButtons={actionButtons}
      onClose={() => setIsOpen(false)}
    />
  );
}

export default InspectOrderDialog;
