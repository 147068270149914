import React from 'react';
import { useNavigate } from 'react-router';
import { Order } from 'api';
import Grid from '@mui/material/Grid';
import { OrderMenu } from 'components/OrderComponents';
// icons
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import { ReactComponent as ShippingIcon } from 'assets/icons/statuses/shippingStatusIcon.svg';
import { ReactComponent as CanceledIcon } from 'assets/icons/circleCancelIcon.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/statuses/processingStatusIcon.svg';
import { ReactComponent as OrderedIcon } from 'assets/icons/statuses/orderedStatusIcon.svg';
import { ReactComponent as InspectIcon } from 'assets/icons/searchIcon.svg';
import { ReactComponent as CompleteIcon } from 'assets/icons/statuses/completeStatusIcon.svg';
import { ReactComponent as DraftIcon } from 'assets/icons/statuses/draftStatusIcon.svg';
import { ReactComponent as DisputeIcon } from 'assets/icons/statuses/disputeStatusIcon.svg';
import { ReactComponent as StepOneIndicator } from 'assets/icons/statuses/stepIndicators/stepOneIndicator.svg';
import { ReactComponent as StepThreeIndicator } from 'assets/icons/statuses/stepIndicators/stepThreeIndicator.svg';
import { ReactComponent as StepFourIndicator } from 'assets/icons/statuses/stepIndicators/stepFourIndicator.svg';
import { ReactComponent as StepFiveIndicator } from 'assets/icons/statuses/stepIndicators/stepFiveIndicator.svg';
import styles from './orderItem.module.scss';

type Props = {
  order: Order;
  orderType: 'sales' | 'purchases';
};

function OrderItem({ order, orderType }: Props) {
  const navigate = useNavigate();
  const {
    id: orderId,
    number,
    status: orderStatus,
    buyer,
    listing,
    seller,
  } = order;
  const isBuyer = orderType === 'purchases';
  const user = isBuyer ? seller : buyer;
  const yardOrderNumber = number;

  // what status to display in the UI
  let uiOrderStatus: string = orderStatus.replaceAll('-', ' ') || '';
  // what icon to display
  let icon: JSX.Element;
  // background-color of icon container
  let colorClassName: string;
  // what step indicator to display (if any)
  let stepIndicator: JSX.Element;

  switch (orderStatus) {
    case 'DRAFT':
      icon = <DraftIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />;
      colorClassName = styles.draftIconContainer;
      break;
    case 'PAID':
      uiOrderStatus = isBuyer ? 'waiting' : 'ship now';

      icon = (
        <ShippingIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />
      );
      stepIndicator = <StepOneIndicator className={styles.stepIndicatorSize} />;
      colorClassName = isBuyer
        ? styles.iconSandBackground
        : styles.iconBlackBackground;
      break;
    case 'SHIPPED':
      uiOrderStatus = isBuyer ? 'inspect materials' : 'buyer inspecting';

      icon = (
        <InspectIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />
      );
      stepIndicator = (
        <StepThreeIndicator className={styles.stepIndicatorSize} />
      );
      colorClassName = isBuyer
        ? styles.iconBlackBackground
        : styles.iconSandBackground;
      break;

    case 'PENDING-PAYOUT':
    case 'PENDING-REFUND':
      uiOrderStatus =
        orderStatus === 'PENDING-REFUND' ? 'refund pending' : 'payout pending';
      icon = (
        <ProcessingIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />
      );
      stepIndicator = (
        <StepFourIndicator className={styles.stepIndicatorSize} />
      );
      colorClassName = styles.iconSandBackground;
      break;
    case 'DISPUTED':
    case 'PAYOUT-FAILED':
      uiOrderStatus = orderStatus === 'DISPUTED' ? 'disputed' : 'payout failed';
      icon = (
        <DisputeIcon className={`${styles.iconSize} ${styles.offWhiteIcon}`} />
      );
      colorClassName = styles.disputeIconContainer;
      break;
    case 'RATE-ORDER':
      // this status doesn't currently exist in the API, but it's going to be soon
      icon = <StarIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />;
      stepIndicator = (
        <StepFiveIndicator className={styles.stepIndicatorSize} />
      );
      colorClassName = styles.iconSandBackground;
      break;
    case 'COMPLETED':
    case 'REFUNDED':
      uiOrderStatus = orderStatus === 'COMPLETED' ? 'complete' : 'refunded';
      icon = (
        <CompleteIcon className={`${styles.iconSize} ${styles.sandIcon}`} />
      );
      colorClassName = styles.iconWhiteBackground;
      break;
    case 'CANCELED':
      icon = (
        <CanceledIcon className={`${styles.iconSize} ${styles.sandIcon}`} />
      );
      colorClassName = styles.iconWhiteBackground;
      break;
    default:
      uiOrderStatus = orderStatus.replaceAll('-', ' ') || '';
      icon = (
        <OrderedIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />
      );
      colorClassName = styles.iconSandBackground;
  }

  const listingIcon = () => {
    const indicatorClass = stepIndicator && styles.iconWithStepIndicator;

    if (indicatorClass) {
      return (
        <div className={indicatorClass}>
          <div className={styles.stepIndicatorIcon}>{stepIndicator}</div>
          <div
            className={`
              ${styles.iconContainer}
              ${styles.iconWithIndicator}
              ${colorClassName}
            `}
          >
            {icon}
          </div>
        </div>
      );
    }

    return (
      <div className={`${styles.iconContainer} ${colorClassName}`}>{icon}</div>
    );
  };

  const formatDate = (date: string | Date) => {
    const value = date instanceof Date ? date : new Date(date);
    return value.toLocaleDateString().replaceAll('/', '.');
  };

  const displayedDate = () => {
    const orderCreateAt = order?.createdAt || '';
    const orderUpdatedAt = order?.updatedAt;

    if (orderStatus === 'COMPLETED' && orderUpdatedAt) {
      return <>Sold {formatDate(orderUpdatedAt)}</>;
    }

    return <>Ordered {formatDate(orderCreateAt)}</>;
  };

  const displayedStatus = uiOrderStatus.toUpperCase();
  // for coloring the draft items
  const draftClass = orderStatus === 'DRAFT' && styles.draftContainer;
  // for coloring the status
  const orderStatusClass = `${uiOrderStatus.replaceAll(' ', '-')}Status`;

  return (
    <div className={`${styles.orderContainer} ${draftClass}`}>
      <Grid container justifyContent="space-between" className={styles.topRow}>
        <div className={styles.topLeft}>
          {listingIcon()}
          <div className={styles.productNameContainer}>
            <div
              className={styles.orderNumber}
              onClick={() => navigate(`/orders/purchase_details/${orderId}`)}
            >
              {yardOrderNumber}
            </div>
            <div className={styles.productName}>{listing?.productName}</div>
          </div>
        </div>
        <div className={styles.topRight}>
          <div className={styles.sellerInfoContainer}>
            {user && (
              <>
                <div className={styles.sellerName}>{user.name}</div>
                {user.rating.averageRating > 0 && (
                  <div className={styles.sellerRating}>
                    {user.rating.averageRating}
                    <StarIcon className={styles.starIcon} />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.listingPrice}>${order.total}</div>
        </div>
      </Grid>
      <div className={styles.lineSeperator} />
      <div className={styles.bottomRow}>
        <div className={styles.bottomLeft}>
          <div className={`${styles.orderStatus} ${styles[orderStatusClass]}`}>
            {displayedStatus}
          </div>
          <div className={styles.listingDate}>{displayedDate()}</div>
        </div>
        <OrderMenu order={order} isBuyer={isBuyer} />
      </div>
    </div>
  );
}

export default OrderItem;
