/* @TODO: NT - this is a work around to handle the current application structure without a major refactor
  Ideally, the breadcrumbs component would be part of each screen and be able to easily inherit a high-level background color.
  This allows us to do that, but it is brittle and a little tedious to change. If new background colors are added, this will
  need to grow in complexity.
*/
function shouldUseSecondaryBackground(location: string) {
  const accountScreens = 'account';
  const ordersScreen = 'orders';
  const myListingsScreen = 'my_yard_listings';
  const shoppingCart = 'shopping_cart';
  const productScreen = 'product';
  const shoppingScreens = 'shopping';

  if (
    location.includes(ordersScreen) ||
    location.includes(shoppingCart) ||
    location.includes(shoppingScreens) ||
    location.includes(myListingsScreen) ||
    location.includes(productScreen)
  ) {
    return 'grey';
  }

  if (location.includes(accountScreens)) {
    return 'offWhite';
  }

  return 'white';
}

export default shouldUseSecondaryBackground;
