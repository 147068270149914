import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { store } from './store';
import muiTheme from './muiTheme';
import App from './App';
import ZendeskChatWidget from './ZendeskChatWidget';
import './style/style.scss';
import './style/index.module.scss';
import './style/normalize.css';
import './style/fonts/Roboto-Regular.ttf';
import './style/fonts/Roboto-Bold.ttf';
import './style/fonts/Roboto-Italic.ttf';
import './style/fonts/ParalucentStencil-Medium.woff';

const persistor = persistStore(store);

// Setup error reporting
Sentry.init({
  dsn: 'https://6dc8f3d6712a03410b6869e8a26dcabc@o4505308972384256.ingest.sentry.io/4505625797591040',
  environment: process.env.REACT_APP_NODE_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
              <ZendeskChatWidget />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
