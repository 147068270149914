import React, { useEffect, useCallback } from 'react';

type RequiredInputs = {
  // key = input name, any is the value
  [key: string]: any;
};

type Props = {
  children: React.ReactNode;
  wrapperId: string;
  requiredInputs: RequiredInputs[];
  defaultInvalidInputs: RequiredInputs; // used to reset invalidInputsOnBlur to false
  formIsValid: boolean;
  invalidInputsOnBlur: RequiredInputs;
  setInvalidInputsOnBlur: (invalidInputs: any) => void;
};

function FormValidationWrapper({
  children,
  wrapperId,
  requiredInputs,
  defaultInvalidInputs,
  formIsValid,
  invalidInputsOnBlur,
  setInvalidInputsOnBlur,
}: Props) {
  const inputsWrapper = document.getElementById(wrapperId);

  const handleInputsOnBlur = useCallback(() => {
    const inputs = Object.assign({}, ...requiredInputs);

    if (
      formIsValid &&
      JSON.stringify(inputs) !== JSON.stringify(defaultInvalidInputs)
    ) {
      // reset invalidInputsOnBlur to the defaultInvalidInputs false
      setInvalidInputsOnBlur(defaultInvalidInputs);
    } else if (JSON.stringify(inputs) !== JSON.stringify(invalidInputsOnBlur)) {
      setInvalidInputsOnBlur(inputs);
    }
  }, [
    requiredInputs,
    invalidInputsOnBlur,
    setInvalidInputsOnBlur,
    defaultInvalidInputs,
    formIsValid,
  ]);

  useEffect(() => {
    const handleFocusOut = (event: any) => {
      if (event.relatedTarget === null) {
        handleInputsOnBlur();
      }
    };

    inputsWrapper?.addEventListener('focusout', handleFocusOut);
    return () => inputsWrapper?.removeEventListener('focusout', handleFocusOut);
  }, [handleInputsOnBlur, inputsWrapper]);

  return <div id={wrapperId}>{children}</div>;
}

export default FormValidationWrapper;
