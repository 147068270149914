/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { IPageBodyTextProps } from '../types';
import PageBodyTextDesktop from './PageBodyTextDesktop';
import PageBodyTextMobile from './PageBodyTextMobile';

function PageBodyText({ align, partial, text, image }: IPageBodyTextProps) {
  const isDesktop = useMediaQuery('(min-width:900px)');

  if (isDesktop) {
    return (
      <PageBodyTextDesktop
        align={align}
        partial={partial}
        text={text}
        image={image}
      />
    );
  }

  return (
    <PageBodyTextMobile
      align={align}
      partial={partial}
      text={text}
      image={image}
    />
  );
}

export default PageBodyText;
