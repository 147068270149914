import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'components/Buttons';
import TooltipWrapper from 'components/TooltipWrapper';
import { FieldValidation } from './types';
import styles from './createAccount.module.scss';

type Props = {
  step: 'user_details' | 'business_info' | 'finished' | string;
  userFieldsValidation: FieldValidation[];
  businessFieldsValidation: FieldValidation[];
  nextButtonDisabled: boolean;
  setNextButtonDisabled: (disabled: boolean) => void;
  handleContinueClick: () => void; // used when all fields are valid
  handleDisabledClick: () => void; // used when some fields are invalid
};

function ContinueButton({
  step,
  userFieldsValidation,
  businessFieldsValidation,
  nextButtonDisabled,
  setNextButtonDisabled,
  handleContinueClick,
  handleDisabledClick,
}: Props) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [missingInfo, setMissingInfo] = useState<string[]>([]);
  const missingInfoAsString = missingInfo.join(', ');
  const [invalidInfo, setInvalidInfo] = useState<string[]>([]);
  const invalidInfoAsString = invalidInfo.join(', ');

  const handleSetInvalidFields = useCallback(
    (fields: FieldValidation[]) => {
      const latestMissingInfo: string[] = [];
      const latestInvalidInfo: string[] = [];

      fields.forEach((field) => {
        const { displayName, isMissing, isInvalid } = field;

        if (isMissing) {
          latestMissingInfo.push(displayName);
        } else if (isInvalid) {
          latestInvalidInfo.push(displayName);
        }
      });

      const hasMissingInfo = latestMissingInfo.length > 0;
      const hasInvalidInfo = latestInvalidInfo.length > 0;

      if ((hasMissingInfo || hasInvalidInfo) && formIsValid) {
        setFormIsValid(false);
      }

      if (
        hasMissingInfo &&
        JSON.stringify(latestMissingInfo) !== JSON.stringify(missingInfo)
      ) {
        setMissingInfo(latestMissingInfo);
      } else if (!hasMissingInfo && missingInfo.length > 0) {
        setMissingInfo([]);
      }

      if (
        hasInvalidInfo &&
        JSON.stringify(latestInvalidInfo) !== JSON.stringify(invalidInfo)
      ) {
        setInvalidInfo(latestInvalidInfo);
      } else if (!hasInvalidInfo && invalidInfo.length > 0) {
        setInvalidInfo([]);
      }
    },
    [formIsValid, missingInfo, invalidInfo],
  );

  const handleContinueButton = () => {
    if (nextButtonDisabled && formIsValid) {
      setNextButtonDisabled(false);
    } else if (!nextButtonDisabled && !formIsValid) {
      setNextButtonDisabled(true);
    }
  };

  useEffect(handleContinueButton, [
    formIsValid,
    nextButtonDisabled,
    setNextButtonDisabled,
  ]);

  const handleValidForm = useCallback(() => {
    if (!formIsValid) {
      setFormIsValid(true);
    }
    if (missingInfo.length > 0) {
      setMissingInfo([]);
    }
    if (invalidInfo.length > 0) {
      setInvalidInfo([]);
    }
  }, [formIsValid, missingInfo, invalidInfo]);

  const handleUserDetails = useCallback(() => {
    const hasInvalidFields =
      userFieldsValidation.filter((field) => field.isMissing || field.isInvalid)
        .length > 0;

    if (!hasInvalidFields) {
      handleValidForm();
    } else {
      handleSetInvalidFields(userFieldsValidation);
    }
  }, [userFieldsValidation, handleValidForm, handleSetInvalidFields]);

  const handleBusinessDetails = useCallback(() => {
    const hasInvalidFields =
      businessFieldsValidation.filter(
        (field) => field.isMissing || field.isInvalid,
      ).length > 0;

    if (!hasInvalidFields) {
      handleValidForm();
    } else {
      handleSetInvalidFields(businessFieldsValidation);
    }
  }, [businessFieldsValidation, handleValidForm, handleSetInvalidFields]);

  useEffect(() => {
    if (step === 'user_details') {
      handleUserDetails();
    } else if (step === 'business_info') {
      handleBusinessDetails();
    }
  }, [
    step,
    userFieldsValidation,
    businessFieldsValidation,
    handleUserDetails,
    handleBusinessDetails,
  ]);

  const tooltip = () => {
    const missingInfoText = `The following information is required to continue: \n ${missingInfoAsString}`;
    const invalidInfoText = `The following information is invalid: ${invalidInfoAsString}`;

    let text: string | JSX.Element = '';
    if (missingInfo.length > 0 && invalidInfo.length > 0) {
      text = (
        <span style={{ whiteSpace: 'pre-line' }}>
          {`${missingInfoText} \n \n  ${invalidInfoText}`}
        </span>
      );
    } else if (invalidInfo.length > 0) {
      text = invalidInfoText;
    } else if (missingInfo.length > 0) {
      text = missingInfoText;
    }

    return text;
  };

  return (
    <TooltipWrapper tooltipText={tooltip()} invalidHighlight maxWidth="300">
      <span>
        <Button
          containerClassName={styles.nextButton}
          title={step === 'business_info' ? 'FINISH' : 'NEXT'}
          buttonColor="sand20"
          onClick={() => {
            if (nextButtonDisabled) {
              handleDisabledClick();
            } else {
              handleContinueClick();
            }
          }}
        />
      </span>
    </TooltipWrapper>
  );
}

export default ContinueButton;
