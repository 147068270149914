import React, { useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { BackButton } from 'components/Buttons';
import styles from './formScreenLayout.module.scss';

type Props = {
  children: JSX.Element; // this should be the form
  onCancel: (value?: boolean) => any;
};

export default function FormScreenLayout({ children, onCancel }: Props) {
  // used for scrolling to top of page on page change
  const headerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // scroll to top of form;
    headerRef.current?.scrollIntoView();
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      className={styles.wrapper}
      ref={headerRef}
    >
      <Grid item xs={12} md={6}>
        <BackButton
          className={styles.backButtonLink}
          text="Back to Help and Support"
          onClick={onCancel}
        />
        {children}
      </Grid>
    </Grid>
  );
}
