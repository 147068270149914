import React, { useState, useEffect } from 'react';
import { useGetUsersByUserIdOnboardQuery } from 'api';
import Loading from 'components/Loading';
import { Button } from 'components/Buttons';

type Props = {
  userId: string;
};

function CompleteSetupButton({ userId }: Props) {
  const [stripeAccountSetupLink, setStripeAccountSetupLink] =
    useState<string>();
  const [buttonClicked, setButtonClicked] = useState(false);

  const { currentData } = useGetUsersByUserIdOnboardQuery(
    { userId },
    { skip: !buttonClicked },
  );

  useEffect(() => {
    if (currentData) {
      setStripeAccountSetupLink(currentData?.data?.link.url);
    }
  }, [currentData]);

  useEffect(() => {
    if (stripeAccountSetupLink) {
      // open the stripe setup in current tab so that the returnUrl and refreshUrl work properly
      window.open(stripeAccountSetupLink, '_self');
      // reset the button state so that the button can be clicked again
      setButtonClicked(false);
    }
  }, [stripeAccountSetupLink]);

  if (buttonClicked && !stripeAccountSetupLink) {
    return <Loading screenOverlay />;
  }

  return (
    <Button
      title="COMPLETE YOUR STRIPE ACCOUNT"
      onClick={() => setButtonClicked(true)}
      buttonColor="sand20"
    />
  );
}

export default CompleteSetupButton;
