import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { usePostOrdersByOrderIdCancelMutation, Order } from 'api';
import { useApiResponseMessage } from 'hooks';
import { Button, BackButton } from 'components/Buttons';
import Loading from 'components/Loading';
import SavedPaymentMethods, { PaymentDetails } from './SavedPaymentMethods';
import styles from '../stripeElements.module.scss';

type Props = {
  order: Order;
  clientSecret: string;
  setSavedPaymentSelected: (selected: boolean) => void;
  // used at Checkout to show a cancel modal if the user tries to navigate back
  navigationProps?: {
    setIsNavigatingBack: (show: boolean) => void;
    confirmNavigation: () => void;
  };
};

function CheckoutForm({
  order,
  clientSecret,
  setSavedPaymentSelected,
  navigationProps,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { apiResponseMessage } = useApiResponseMessage();
  const { id: orderId } = order;

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentDetails | null>(null);

  const baseReturnUrl = process.env.REACT_APP_BASE_RETURN_URL;

  useEffect(() => {
    if (selectedPaymentMethod) {
      setSavedPaymentSelected(true);
    } else {
      setSavedPaymentSelected(false);
    }
  }, [selectedPaymentMethod, setSavedPaymentSelected]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const urlClientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!urlClientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(urlClientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleErrors = (error: any) => {
    setIsLoading(false);
    /* This point will only be reached if there is an immediate error when
      confirming the payment. Otherwise, your customer will be redirected to
      your `return_url`. For some payment methods like iDEAL, your customer will
      be redirected to an intermediate site first to authorize the payment, then
      redirected to the `return_url`. */
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error.message || 'An unexpected error occurred.');
    } else {
      setMessage('An unexpected error occurred.');
    }
  };

  const handleSuccess = () => {
    // if the user has submitted payment, then they are moving forward in the flow so we don't want to show the confirm navigation modal when the page unloads
    if (navigationProps) {
      navigationProps.setIsNavigatingBack(false);
      navigationProps.confirmNavigation();
    }
    /* the api saves the order in 2 steps, so we want to give it a little 
      time to fully update before redirecting to the order details page
      1. update the `status`
      2. update the `paid` and `paymentMethodId`
    */
    setTimeout(() => {
      navigate(`/orders/purchase_details/${orderId}`, { replace: true });
    }, 1000);
  };

  const handleConfirmedPayment = (paymentIntent: any, error: any) => {
    // some bank accounts return a 'processing' status since they aren't immediately completed
    // so we want to treat that as a success and send the user to the order details page
    if (
      paymentIntent?.status === 'succeeded' ||
      paymentIntent?.status === 'processing'
    ) {
      handleSuccess();
    } else {
      handleErrors(error);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (selectedPaymentMethod) {
      switch (selectedPaymentMethod.type) {
        case 'card': {
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: selectedPaymentMethod.id },
          );

          handleConfirmedPayment(paymentIntent, error);
          break;
        }
        case 'us_bank_account': {
          const { error, paymentIntent } =
            await stripe.confirmUsBankAccountPayment(clientSecret, {
              payment_method: selectedPaymentMethod.id,
            });

          handleConfirmedPayment(paymentIntent, error);
          break;
        }
        default: {
          setMessage(
            'An unexpected error occurred. Please choose a different payment method.',
          );
        }
      }
    } else {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${baseReturnUrl}/orders/purchase_details/${orderId}`,
        },
        // this is so we can use our handleSuccess func instead of the default return_url
        redirect: 'if_required',
      });

      handleConfirmedPayment(paymentIntent, error);
    }
  };

  const paymentElementOptions = {
    layout: 'tabs' as const,
  };

  const [cancelOrder] = usePostOrdersByOrderIdCancelMutation();
  const cancelCheckout = async () => {
    try {
      const payload = await cancelOrder({
        orderId,
        body: { reason: 'Canceled At Checkout Payment Modal' },
      }).unwrap();

      if (payload.success) {
        navigate(-1);
      }
    } catch (error) {
      apiResponseMessage('There was an error canceling your order');
    }
  };

  const selectedSavedPaymentClassName = selectedPaymentMethod
    ? styles.selectedSavedPaymentMethod
    : '';

  return (
    <>
      {isLoading && <Loading className={styles.loading} />}
      <form
        className={`${styles.paymentForm} ${selectedSavedPaymentClassName}`}
        onSubmit={handleSubmit}
      >
        <SavedPaymentMethods
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
        {!selectedPaymentMethod && (
          <PaymentElement
            className={styles.paymentElement}
            options={paymentElementOptions}
          />
        )}
        <div className={styles.bottomButtons}>
          <Button
            type="submit"
            disabled={isLoading || !stripe || !elements}
            buttonColor="black"
            roundedButton
            buttonClassName={styles.submitButton}
            rippleClassName={styles.submitButton}
            text="PAY NOW"
          />
          {/* Show any error or success messages */}
          {message && <div className={styles.paymentMessage}>{message}</div>}
          <BackButton
            className={styles.cancelButton}
            text="Cancel Checkout"
            onClick={cancelCheckout}
          />
        </div>
      </form>
    </>
  );
}

export default CheckoutForm;
