import React from 'react';
import { useModal } from 'react-modal-hook';
import { useIdentity, useIsMobile } from 'hooks';
import { IconButton } from 'components/Buttons';
import { TextLink } from 'components/Links';
import { ReactComponent as MenuIcon } from 'assets/icons/hamburgerMenuIcon.svg';
import colorVars from 'style/index.module.scss';
import MainMenu from '../MainMenu';
import styles from './topHeader.module.scss';

function TopHeader() {
  const { loggedIn } = useIdentity();
  const isMobileScreen = useIsMobile();

  const iconStyle = {
    width: '26px',
    height: '26px',
    fill: colorVars.sandBright20,
  };

  const [showMainMenu, hideMainMenu] = useModal(
    () => <MainMenu closeMenu={() => hideMainMenu()} loggedIn={loggedIn} />,
    [loggedIn],
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className="top-header__menu-container">
          <IconButton
            className={styles.menu}
            labelClassName={styles.menuLabel}
            textColor="sand-bright"
            icon={MenuIcon}
            onClick={showMainMenu}
            filledOrBorder="none"
            roundedAvatar={false}
            iconStyle={iconStyle}
            label={isMobileScreen ? '' : 'Menu'}
            labelPosition="right"
          />
        </div>
        <div className="top-header__right-side-container">
          {!isMobileScreen && (
            <div className="top-header__links">
              <TextLink
                className={`${styles.link} ${styles.rightMargin}`}
                textColor="sandBrightTwenty"
                to="/faqs"
              >
                Frequently Asked Questions
              </TextLink>
              {/* <TextLink // Removed for MVP
                className={`${styles.link} ${styles.rightMargin}`}
                textColor="sandBrightTwenty"
                to="/help"
              >
                Contact The Yard
              </TextLink> */}
              <TextLink
                className={`${styles.link} ${styles.noMargin}`}
                textColor="sandBrightTwenty"
                to="/help"
              >
                Get Help
              </TextLink>
            </div>
          )}
          {/* @TODO: NT - Reenable for notifications.
          <IconButton
            className="top-header__notification-icon"
            icon={NotificationIcon}
            onClick={() => navigate('/account/notifications')}
            filledOrBorder="none"
            roundedAvatar={false}
            iconStyle={iconStyle}
          />
          */}
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
