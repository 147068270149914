import React from 'react';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import Card from '../components/Card';
import styles from './getInTouch.module.scss';
import parentStyles from '../helpScreen.module.scss';

type Props = {
  setDisplayGeneralInquiryForm: (value: boolean) => any;
};

function GetInTouch({ setDisplayGeneralInquiryForm }: Props) {
  return (
    <Card>
      <Grid container>
        <div className={parentStyles.sectionTitle}>Get in Touch</div>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={5.5} className={styles.section}>
            <div className={styles.title}>Contact form</div>
            <div className={styles.info}>
              The best way to get in touch with us is through our contact form.
            </div>
            <Button
              containerClassName={styles.contactUsButtonWrapper}
              title="CONTACT US"
              onClick={() => setDisplayGeneralInquiryForm(true)}
              buttonColor="sand"
            />
          </Grid>
          <Grid item xs={12} sm={5.5} className={styles.section}>
            <div className={styles.title}>Corporate office</div>
            <div className={styles.info}>
              CHECKTHEYARD, INC
              <br />
              PO Box 540730
              <br />
              NSL, UT 84054
            </div>
          </Grid>
          <Grid item xs={12} sm={5.5} className={styles.section}>
            <div className={styles.title}>Support lines</div>
            <div className={styles.info}>(855) 4-THEYARD (M-F 8am-5pm MST)</div>
          </Grid>
          <Grid item xs={12} sm={5.5} className={styles.section}>
            <div className={styles.title}>Email</div>
            <div className={`${styles.info} ${styles.emailLinks}`}>
              Help:{' '}
              <a href="mailto:help@checktheyard.com">help@checktheyard.com</a>
              <br />
              Other inquiries:{' '}
              <a href="mailto:info@checktheyard.com">info@checktheyard.com</a>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default GetInTouch;
