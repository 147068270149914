import React from 'react';
import classNames from 'classnames';
import { ITypography } from './types';
import styles from './typography.module.scss';

function Title({ children }: ITypography) {
  return <h1 className={classNames(styles.title)}>{children}</h1>;
}

export default Title;
