import React from 'react';
import { useParams } from 'react-router-dom';
import { SubcategoryTable } from '../components';

function SubcategoryScreen() {
  const params = useParams();
  const { subcategoryName = '' } = params;

  return <SubcategoryTable subcategory={subcategoryName} />;
}

export default SubcategoryScreen;
