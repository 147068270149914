import React from 'react';
import Page from 'components/Page';
import TextLink from 'components/Links/TextLink';

function FAQsScreen() {
  const FAQInfo = {
    heading: 'FAQ',

    sections: [
      {
        id: 'how-yard-works',
        type: 'subheader',
        text: 'How does The Yard work?',
      },
      {
        id: 'how-yard-works-text',
        type: 'text',
        text: 'The Yard is an online marketplace that connects sellers of construction materials (“firsts” or “seconds”) to potential buyers and vice versa. All users of The Yard can buy and sell. The Yard allows sellers to create unlimited product listings and buyers to browse and communicate with sellers about items for their next construction-related project. The Yard helps facilitate the purchasing of items, messaging of sellers, shipping of items as well as confirmation of successful receipt of items.',
      },
      {
        id: 'types-sold',
        type: 'subheader',
        text: 'What types of products can be sold on The Yard?',
      },
      {
        id: 'types-sold-text',
        type: 'text',
        text: 'Construction- and builder-grade materials. This can include brand new products from the manufacturer or existing products that can range from masonry, electrical, plumbing and more.',
      },
      {
        id: 'get-help',
        type: 'subheader',
        text: 'How can I get help/support?',
      },
      {
        id: 'get-help-text',
        type: 'text',
        text: [
          <>
            You can request support by visiting{' '}
            <TextLink to="/help" textColor="leather">
              https://dev.theyard-devnow.com/help
            </TextLink>
          </>,
        ],
      },
      {
        id: 'issues-my-order',
        type: 'subheader',
        text: 'What if I have issues with my order?',
      },
      {
        id: 'issues-my-order-text',
        type: 'text',
        text: [
          'If you have an issue with your order, you can reject the acceptance of the shipment at which point the seller will be notified and can provide a response. Alternatively, you can also directly message the seller to resolve your issue.',
        ],
      },
      {
        id: 'what-region',
        type: 'subheader',
        text: 'In what region The Yard currently operate?',
      },
      {
        id: 'what-region-text',
        type: 'text',
        text: [
          'The Yard is currently in a beta phase focused on the state of Utah, United States, with plans to serve the 48 contiguous states. To be notified as we expand our reach, email help@theyard.com',
        ],
      },
    ],
  };

  return <Page content={FAQInfo} />;
}

export default FAQsScreen;
