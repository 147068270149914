import React from 'react';
import Page from 'components/Page';

function InterestedInSellingScreen() {
  const pageContent = {
    heading: 'What is The Yard?',
    sections: [
      {
        id: 'page-subheader',
        type: 'subheader',
        text: 'Your marketplace to buy, sell, and ship contractor-grade materials from a network of people like you.',
      },
      {
        id: 'trusted-resource',
        type: 'subheader',
        text: 'A trusted resource',
      },
      {
        id: 'trusted-resource-text',
        type: 'text',
        text: 'The Yard was built by leaders in the construction industry who understand the business. We’ve worked hard to create a reliable marketplace where you can find quality materials through a process you can trust. ',
      },
      {
        id: 'easy-to-use',
        type: 'subheader',
        text: 'Easy to use',
      },
      {
        id: 'easy-to-use-text',
        type: 'text',
        text: 'We’ve taken the modern e-commerce technology and applied it to the construction industry. Searching and listing items, payment, fulfillment, and professional shipping (including LTL and freight) are as smooth as they are secure.',
      },
      {
        id: 'community-of-builders',
        type: 'subheader',
        text: 'A community of builders',
      },
      {
        id: 'community-of-builders-text',
        type: 'text',
        text: 'Businesses of every size are welcome at The Yard. Whether you’re buying or selling, you’re helping out someone in the industry. And if you’re an individual with a project of your own, you’re also welcome to shop The Yard.',
      },
      {
        id: 'how-it-works',
        type: 'subheader',
        text: 'How it works',
      },
      {
        id: 'how-it-works-text',
        type: 'text',
        text: 'List the materials you have to sell, and browse our inventory for what you need. Once you’re ready to make your secure transaction, The Yard will walk you through the steps, down to shipping details—making it as simple and painless as possible. ',
      },
    ],
  };

  return <Page content={pageContent} />;
}

export default InterestedInSellingScreen;
