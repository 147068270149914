import React, { useState, useEffect } from 'react';
import { Listing } from 'api';
// import { Button } from 'components/Buttons';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import { ReactComponent as DefaultUserIcon } from 'assets/icons/userIcon.svg';
import styles from './sellerSection.module.scss';

interface Props {
  listing: Listing;
}

function SellerSection({ listing }: Props) {
  const { seller } = listing;
  const { userSettings, address } = seller;
  const [showSellerTerms, setShowSellerTerms] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | null>();

  useEffect(() => {
    const profileImage = userSettings.profileImageUrl ?? null;
    setProfilePicture(profileImage);
  }, [userSettings.profileImageUrl]);

  // const hiddenBusiness = {
  //   name: 'Yard Business',
  // };

  const displayDate = (date: any) => {
    const options = {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    } as const;

    return new Date(date).toLocaleDateString('en-US', options);
  };

  return (
    <Grid container columns={11} className={styles.sellerContainer}>
      <DialogPopup
        open={showSellerTerms}
        onClose={() => setShowSellerTerms(false)}
        title="Seller Terms"
        content={seller.userSettings.sellerTerms || ''}
      />
      <Grid item xs={2} sm={1} md={1} className={styles.imageContainer}>
        <Avatar className={styles.imageAvatar}>
          {profilePicture ? (
            <img
              className={styles.image}
              alt="Profile"
              src={profilePicture}
              onError={() => setProfilePicture(null)}
            />
          ) : (
            <DefaultUserIcon />
          )}
        </Avatar>
      </Grid>
      <Grid item xs={9} sm={6} md={6} className={styles.infoContainer}>
        <Grid container direction="row">
          <div className={styles.soldBy}>Sold by</div>
          &nbsp;
          <div className={styles.sellerName}>{address.name}</div>
        </Grid>
        <Grid container className={styles.addressAndMember}>
          <div className={styles.address}>
            {address.city}, {address.state}
          </div>
          <div className={styles.memberSince}>
            Member since {displayDate(seller.createdAt)}
          </div>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={styles.ordersAndRating}
        >
          <div className={styles.sellerRating}>
            {seller.rating.averageRating}{' '}
            <StarIcon className={styles.sixteenStar} />
            <div className={styles.ratingsCount}>
              ({seller.rating.totalRatings})
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={3.5}
        container
        className={styles.buttonContainer}
      >
        {/* removed for beta */}
        {/* {seller.userSettings.sellerTerms && (
          <Button
            containerClassName={styles.viewSellerTerms}
            title="VIEW SELLER TERMS"
            buttonType="secondary"
            buttonColor="black"
            onClick={() => setShowSellerTerms(true)}
          />
        )} */}
      </Grid>
    </Grid>
  );
}

export default SellerSection;
