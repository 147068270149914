const packageTypes = [
  {
    label: 'Aframe',
    value: 'aframe',
  },
  {
    label: 'Bags',
    value: 'bags',
  },
  {
    label: 'Bales',
    value: 'bales',
  },
  {
    label: 'Barrel',
    value: 'barrel',
  },
  {
    label: 'Bins',
    value: 'bins',
  },
  {
    label: 'Boxes',
    value: 'boxes',
  },
  {
    label: 'Bundle',
    value: 'bundle',
  },
  {
    label: 'Carton',
    value: 'carton',
  },
  {
    label: 'Case',
    value: 'case',
  },
  {
    label: 'Chest',
    value: 'chest',
  },
  {
    label: 'Container',
    value: 'container',
  },
  {
    label: 'Crate',
    value: 'crate',
  },
  {
    label: 'Cylinder',
    value: 'cylinder',
  },
  {
    label: 'Drum',
    value: 'drum',
  },
  {
    label: 'Hopper',
    value: 'hopper',
  },
  {
    label: 'Package',
    value: 'package',
  },
  {
    label: 'Pails',
    value: 'pails',
  },
  {
    label: 'Pallet',
    value: 'pallet',
  },
  {
    label: 'Rack',
    value: 'rack',
  },
  {
    label: 'Reel',
    value: 'reel',
  },
  {
    label: 'Roll',
    value: 'roll',
  },
  {
    label: 'Skid',
    value: 'skid',
  },
  {
    label: 'Totes',
    value: 'totes',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export default packageTypes;
