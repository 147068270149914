import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDeleteAuthLogoutMutation, api } from 'api';
import {
  clearLoginCredentials,
  clearAccountRelatedInformation,
} from 'slices/accountSlice';

const useLogOut = () => {
  const [deleteAuthLogout] = useDeleteAuthLogoutMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = async () => {
    await deleteAuthLogout();
    dispatch(clearLoginCredentials());
    dispatch(clearAccountRelatedInformation());
    // clear all user related data from the cache
    dispatch(
      api.util.invalidateTags([
        'UserDetails',
        'UserListings',
        'UserOrders',
        'UserCart',
        'PaymentMethods',
        'RecentlyViewedListings',
      ]),
    );
    // after logout, send user to the home screen
    navigate('/');
  };

  return { logOut };
};

export default useLogOut;
