/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { Body1, Box } from 'parts';
import Backdrop from 'components/Backdrop';
import { IPageBodyTextProps, IPageBodyImageProps } from '../types';

function PageBodyTextMobileImage({ image }: IPageBodyImageProps) {
  return (
    <Box mt={2}>
      <Backdrop caption={image.caption || image.alt}>
        <img src={image.src} alt={image.alt} />
      </Backdrop>
    </Box>
  );
}

function PageBodyTextMobile({ text, image }: IPageBodyTextProps) {
  const isText = typeof text === 'string';

  return (
    <Box
      mt={5}
      maxWidth="920px"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      {isText ? (
        <Body1>{text}</Body1>
      ) : (
        <Body1>
          {text.map((item: any, index) => (
            <Fragment key={index}>{item}</Fragment>
          ))}
        </Body1>
      )}
      {image && <PageBodyTextMobileImage image={image} />}
    </Box>
  );
}

export default PageBodyTextMobile;
