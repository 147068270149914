import React from 'react';
import { TextLink } from 'components/Buttons';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/leftArrowIcon.svg';
import styles from './backButton.module.scss';

type Props = {
  text?: string; // default is Back
  onClick: () => any;
  className?: string;
};

export default function BackButton({
  text = 'Back',
  onClick,
  className,
}: Props) {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <LeftArrowIcon />
      <TextLink text={text} className={styles.link} onClick={onClick} />
    </div>
  );
}
