import React from 'react';
import Grid from '@mui/material/Grid';
import styles from './timeline.module.scss';

type Log = {
  status?: string;
  message?: string;
  createdAt?: string;
};

type Props = {
  logs: Log[] | undefined;
  sellerName: string;
  buyerName: string;
  isBuyer: boolean;
};

// possibly use this npm timeline package: https://www.npmjs.com/package/react-timeline

function Timeline({ logs, sellerName, buyerName, isBuyer }: Props) {
  if (!logs) return null;

  const formatDate = (date: string) => {
    const newDate = new Date(date).toLocaleDateString();
    return newDate.replaceAll('/', '.');
  };
  // TODO: need to add other statuses
  const orderCreated =
    logs.find((log) => log.status === 'SUBMITTED')?.createdAt || '';

  const orderPaid = logs.find((log) => log.status === 'PAID')?.createdAt;

  const orderShipped = logs.find((log) => log.status === 'SHIPPED')?.createdAt;

  const orderAccepted = logs.find(
    (log) => log.status === 'ACCEPTED',
  )?.createdAt;

  const orderCanceled = logs.find(
    (log) => log.status === 'CANCELED',
  )?.createdAt;

  const orderRefunded = logs.find(
    (log) => log.status === 'REFUNDED',
  )?.createdAt;

  const paymentFailed = logs.find(
    (log) => log.status === 'PAYMENT-FAILED',
  )?.createdAt;

  return (
    <Grid container item xs={12} sm={10} md={5} className={styles.wrapper}>
      <div className={styles.header}>Order Timeline</div>
      <Grid item xs={12} container className={styles.row}>
        <Grid item xs={3}>
          {formatDate(orderCreated)}
        </Grid>
        <Grid item xs={8}>
          Order placed
        </Grid>
      </Grid>
      {paymentFailed && (
        <Grid item xs={12} container className={styles.row}>
          <Grid item xs={3}>
            {formatDate(paymentFailed)}
          </Grid>
          <Grid item xs={8}>
            Payment failed (Stripe)
          </Grid>
        </Grid>
      )}
      {orderPaid && (
        <Grid item xs={12} container className={styles.row}>
          <Grid item xs={3}>
            {formatDate(orderPaid)}
          </Grid>
          <Grid item xs={8}>
            Payment funded (Stripe)
          </Grid>
        </Grid>
      )}
      {orderShipped && (
        <Grid item xs={12} container className={styles.row}>
          <Grid item xs={3}>
            {formatDate(orderShipped)}
          </Grid>
          <Grid item xs={8}>
            Order shipped
            <br />
            {sellerName} shipped item
          </Grid>
        </Grid>
      )}
      {orderAccepted && (
        <Grid item xs={12} container className={styles.row}>
          <Grid item xs={3}>
            {formatDate(orderAccepted)}
          </Grid>
          <Grid item xs={8}>
            Order accepted
            <br />
            {isBuyer ? 'You have' : `${buyerName} has`} accepted the order
          </Grid>
        </Grid>
      )}
      {orderCanceled && (
        <Grid item xs={12} container className={styles.row}>
          <Grid item xs={3}>
            {formatDate(orderCanceled)}
          </Grid>
          <Grid item xs={8}>
            Order canceled
          </Grid>
        </Grid>
      )}
      {orderRefunded && (
        <Grid item xs={12} container className={styles.row}>
          <Grid item xs={3}>
            {formatDate(orderRefunded)}
          </Grid>
          <Grid item xs={8}>
            Payment refunded (Stripe)
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default Timeline;
