import React from 'react';
import { useLocation } from 'react-router';
import { useIsMobile } from 'hooks';
import Grid from '@mui/material/Grid';
import { TextLink } from 'components/Links';
import Breadcrumbs from 'components/Breadcrumbs';
import BuySellToggle from 'components/Toggle/BuySellToggle';
import getBackroundColor from './getBackroundColor';
import styles from './bottomHeader.module.scss';

type BottomHeaderProps = {
  isInSellMode: boolean;
  setSellMode: () => void;
};

function BottomHeader({ isInSellMode, setSellMode }: BottomHeaderProps) {
  const location = useLocation();
  const isMobileScreen = useIsMobile();
  const background = getBackroundColor(location.pathname);

  return (
    <Grid container direction="column" className={styles.container}>
      <div
        className={`${styles.innerContainer} ${
          !isInSellMode && styles.collapse
        }`}
      >
        <Grid
          item
          xs={12}
          md={10}
          className={`${styles.wrapper} ${!isInSellMode && styles.collapse}`}
        >
          {!isMobileScreen && isInSellMode && (
            <>
              <div className={styles.firstLinkChunk}>
                <TextLink to="/selling/my_yard_listings">Listings</TextLink>
                <TextLink to="/orders">Orders</TextLink>
                {/* removed for MVP: <TextLink
            text="Storefront"
            className="subheader__selling-button"
            onClick={() => navigate('/storefront')}
          /> */}
              </div>
              <div>
                <TextLink to="/interested_in_selling">How to Sell</TextLink>
                <TextLink
                  to="/product_listing"
                  className={styles.createListingLink}
                >
                  Create New Listing
                </TextLink>
              </div>
            </>
          )}
          <BuySellToggle checked={isInSellMode} onChange={setSellMode} />
        </Grid>
      </div>
      <Breadcrumbs background={background} />
    </Grid>
  );
}

export default BottomHeader;
