import React, { useState, useEffect } from 'react';
import { User } from 'api';
import {
  useGetUserDetails,
  useCreateZendeskRequest,
  useApiResponseMessage,
} from 'hooks';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
import Dropdown, { ListObj } from 'components/Dropdown/Dropdown';
import { Button, TextLink } from 'components/Buttons';
import FormContainer from 'components/FormContainer';
import styles from './supportForms.module.scss';

type Props = {
  onCancel: () => any;
};

export default function SupportTicketForm({ onCancel }: Props) {
  const { submitRequest } = useCreateZendeskRequest();
  const { apiResponseMessage } = useApiResponseMessage();
  const getUserResponse = useGetUserDetails();
  const [userData, setUserData] = useState<User>();

  useEffect(() => {
    if (getUserResponse?.success) {
      const { user } = getUserResponse.data;
      setUserData(user);
    }
  }, [getUserResponse]);

  const [contactReason, setContactReason] = useState<ListObj>();
  const [orderNumber, setOrderNumber] = useState('');
  const [description, setDescription] = useState('');

  const orderNumberRequired = contactReason?.value === 'issue_with_an_order';
  const missingOrderNumber = orderNumberRequired
    ? orderNumber.length < 3
    : false;

  const submitDisabled = missingOrderNumber || description.length < 5;

  const contactReasons = [
    {
      label: 'Issue with an order',
      value: 'issue_with_an_order',
    },
    {
      label: 'Issue with creating a listing',
      value: 'issue_with_creating_a_listing',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  const submitInquiry = async () => {
    try {
      const customFields = [
        {
          id: 11981484679707,
          value: contactReason?.value,
        },
      ];

      if (orderNumberRequired) {
        customFields.push({
          id: 11919210732827,
          value: orderNumber,
        });
      }

      const ticketInfo = {
        ticketFormId: 11919238719643,
        requester: {
          name: userData?.name || 'Unknown',
          email: userData?.email || 'Unknown',
        },
        subject: (contactReason?.label as string) || 'Other',
        commentBody: description,
        customFields,
      };
      const response = await submitRequest(ticketInfo);

      if (response.status === 201) {
        apiResponseMessage('Your support request has been submitted');
        onCancel();
      }
    } catch (error) {
      apiResponseMessage('An error occurred, please try again');
    }
  };

  return (
    <FormContainer
      title="Support Ticket"
      content={
        <div>
          <Dropdown
            containerClassName={styles.input}
            label="Why are you contacting us?"
            options={contactReasons}
            value={contactReason}
            onChange={(option: ListObj) => setContactReason(option)}
            menuWidth="100%"
          />
          {orderNumberRequired && (
            <TextInput
              containerClassName={styles.input}
              label="Order Number*"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
            />
          )}
          <TextArea
            placeholder="Description*"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className={styles.buttonContainer}>
            <TextLink
              className={styles.cancelButton}
              text="Cancel"
              onClick={() => onCancel()}
            />
            <Button
              rippleClassName={styles.actionButton}
              buttonClassName={styles.actionButton}
              text="SEND"
              onClick={() => submitInquiry()}
              disabled={submitDisabled}
              buttonColor="black"
              roundedButton
            />
          </div>
        </div>
      }
    />
  );
}
