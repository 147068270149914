const addressTypes = [
  { label: 'Business', value: 'Business' },
  {
    label: 'Business requiring liftgate',
    value: 'Business requiring liftgate',
  },
  { label: 'Construction site', value: 'Construction site' },
  { label: 'Residential', value: 'Residential' },
  {
    label: 'Residential requiring liftgate',
    value: 'Residential requiring liftgate',
  },
];

export default addressTypes;
