import { useState, useEffect } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { useGetConfigQuery } from 'api';

type TicketInfo = {
  ticketFormId: number;
  requester: {
    name: string;
    email: string;
  };
  subject: string;
  commentBody: string;
  customFields?: {
    id: number;
    value: any;
  }[];
};

export default function useCreateZendeskRequest() {
  const { data: config } = useGetConfigQuery();
  const [zendeskBaseUrl, setZendeskBaseUrl] = useState('');
  const [createTestTickets, setCreateTestTickets] = useState(false);

  useEffect(() => {
    if (config?.data) {
      const { ZENDESK_BASE_URL, ZENDESK_DEVELOPMENT_TEST_TICKETS } =
        config.data;
      const testTickets = ZENDESK_DEVELOPMENT_TEST_TICKETS === 'true';

      setZendeskBaseUrl(ZENDESK_BASE_URL);
      setCreateTestTickets(testTickets);
    }
  }, [config]);

  const submitRequest = async (ticketInfo: TicketInfo) => {
    try {
      const {
        ticketFormId,
        requester,
        subject,
        commentBody,
        customFields = [],
      } = ticketInfo;

      // add the test_development tag to tickets created in development in order to filter them in zendesk
      const tags = createTestTickets ? ['test_development'] : [];

      const requestObject = {
        request: {
          requester,
          ticket_form_id: ticketFormId,
          subject,
          comment: {
            body: commentBody,
          },
          custom_fields: customFields,
          tags,
        },
      };

      const request = await axios.post(
        `${zendeskBaseUrl}/requests`,
        requestObject,
      );

      return request;
    } catch (error: any) {
      // Log the error to Sentry
      const errorObject = new Error(`Zendesk: ${error.message}`);

      const { config: errorConfig, request: errorRequest, ...rest } = error;

      let requestData = errorConfig.data;
      const dataObj = JSON.parse(errorConfig.data) || requestData;

      // make the data readable since nested items are not displayed in Sentry
      if (typeof dataObj === 'object') {
        /* eslint-disable @typescript-eslint/naming-convention */
        const {
          comment,
          requester,
          tags,
          custom_fields = [],
          ...restOfRequest
        } = dataObj.request;

        const customFields =
          custom_fields.length > 0
            ? custom_fields
                .map((field: any) => `id: ${field.id} - value: ${field.value}`)
                .join(', ')
            : 'none';

        requestData = {
          ...restOfRequest,
          requesterName: requester.name,
          requesterEmail: requester.email,
          commentBody: comment.body,
          tags: tags.join(', '),
          customFields,
        };
      }

      const extra = {
        error: {
          ...rest,
          url: errorConfig?.url,
          method: errorConfig?.method,
          requestData,
        },
      };

      Sentry.captureException(errorObject, {
        level: 'error',
        tags: { type: 'create-zendesk-request' },
        extra,
      });

      throw error;
    }
  };

  return { submitRequest };
}
