import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import TextInput from 'components/TextInput';
import packageTypes from 'constants/packageTypes';
import Checkbox from 'components/Checkbox/Checkbox';
import TooltipWrapper from 'components/TooltipWrapper';
import shippingClasses from 'constants/shippingClasses';
import Dropdown, { ListObj } from 'components/Dropdown/Dropdown';
import { ReactComponent as HelpIcon } from 'assets/icons/helpIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { COLORS } from 'style/colors';
import styles from './packageInfo.module.scss';
import { PackageDetails, PackageValidation } from '../../types';

type Props = {
  keyId: number;
  packageDetails: PackageDetails;
  setPackageDetails: (val: PackageDetails) => void;
  deletePackage: (id: number) => void;
  validateFieldsOnClick: boolean;
  packageValidation: PackageValidation[];
  setPackageValidation: (fields: PackageValidation[]) => void;
};

type PackageField = {
  [key: string]: string | boolean | number;
};

// generate an array of numbers 1-50 for the handling units dropdown
const handlingUnitOptions = () => {
  const options = [];
  for (let i = 1; i < 51; i += 1) {
    const option = { label: `${i}`, value: `${i}` };
    options.push(option);
  }

  return options;
};

function PackageInfo({
  keyId,
  packageDetails,
  setPackageDetails,
  deletePackage,
  validateFieldsOnClick,
  packageValidation,
  setPackageValidation,
}: Props) {
  const [unitOption, setUnitOption] = useState<ListObj>();
  const [shipmentTypeOption, setShipmentTypeOption] =
    useState<ListObj | null>();
  const [shippingClassOption, setShippingClassOption] = useState<ListObj>();

  const {
    handlingUnits,
    shipmentType,
    pieces,
    description,
    stackable,
    length,
    width,
    height,
    weight,
    class: shippingClass,
    nmfc,
    hazardous,
  } = packageDetails;

  const saveDetails = (field: PackageField) => {
    setPackageDetails({
      ...packageDetails,
      ...field,
    });
  };

  // several of the inputs should only accept a number, but I need the input to be a string so I can add the " (inches) or lbs to the end, so this only allows the user to type numbers and decimals
  const validNumber = (value: string) => value.match(/^-?\d*\.?\d*$/);

  // used if the user leaves the page and comes back, this will set the previous values
  const setPreviousPackageDetails = () => {
    if (handlingUnits && handlingUnits.toString() !== unitOption?.value) {
      const unitOptions = handlingUnitOptions();
      const findUnitOption = unitOptions.find(
        (option) => option.value === handlingUnits.toString(),
      ) || {
        label: handlingUnits.toString(),
        value: handlingUnits.toString(),
      };

      setUnitOption(findUnitOption);
    }

    if (shipmentType && shipmentType !== shipmentTypeOption?.value) {
      const shipmentOption = packageTypes.find(
        (option) => option.value === shipmentType,
      ) || { label: shipmentType, value: shipmentType };

      setShipmentTypeOption(shipmentOption);
    }

    if (shippingClass && shippingClass !== shippingClassOption?.value) {
      const findShippingClassOption = shippingClasses.find(
        (option) => option.value === shippingClass,
      ) || { label: shippingClass, value: shippingClass };

      setShippingClassOption(findShippingClassOption);
    }
  };

  useEffect(setPreviousPackageDetails, [
    handlingUnits,
    shipmentType,
    shippingClass,
    unitOption?.value,
    shipmentTypeOption?.value,
    shippingClassOption?.value,
  ]);

  const requiredFieldText = 'This field is required';

  const defaultInvalidInputs = {
    units: false,
    shipmentType: false,
    pieces: false,
    description: false,
    length: false,
    width: false,
    height: false,
    weight: false,
    hazardous: false,
  };
  const [invalidInputsOnClick, setInvalidInputsOnClick] =
    useState(defaultInvalidInputs);

  const fieldValidation = useMemo(
    () => [
      {
        displayName: `Package ${keyId} Handling Units`,
        isMissing: !handlingUnits,
      },
      {
        displayName: `Package ${keyId} Shipment Type`,
        isMissing: !shipmentType,
      },
      {
        displayName: `Package ${keyId} Pieces`,
        isMissing: !pieces,
      },
      {
        displayName: `Package ${keyId} Description`,
        isMissing: !description,
      },
      {
        displayName: `Package ${keyId} Length`,
        isMissing: !length,
      },
      {
        displayName: `Package ${keyId} Width`,
        isMissing: !width,
      },
      {
        displayName: `Package ${keyId} Height`,
        isMissing: !height,
      },
      {
        displayName: `Package ${keyId} Weight`,
        isMissing: !weight,
      },
      {
        displayName: `Package ${keyId} Hazardous`,
        isMissing: false,
        isInvalid: hazardous,
      },
    ],
    [
      handlingUnits,
      shipmentType,
      pieces,
      description,
      length,
      width,
      height,
      weight,
      hazardous,
      keyId,
    ],
  );

  useEffect(() => {
    if (validateFieldsOnClick) {
      const invalidValues = {
        units: fieldValidation[0].isMissing,
        shipmentType: fieldValidation[1].isMissing,
        pieces: fieldValidation[2].isMissing,
        description: fieldValidation[3].isMissing,
        length: fieldValidation[4].isMissing,
        width: fieldValidation[5].isMissing,
        height: fieldValidation[6].isMissing,
        weight: fieldValidation[7].isMissing,
        hazardous,
      };
      setInvalidInputsOnClick(invalidValues);
    }
  }, [validateFieldsOnClick, fieldValidation, hazardous]);

  useEffect(() => {
    const validationExists = packageValidation.find(
      (validation) => validation.id === keyId,
    );
    // if the validation for this package exists, update the fields, otherwise add a new validation object
    if (validationExists && validationExists.fields !== fieldValidation) {
      const packageValidationArray = packageValidation.map((validation) => {
        if (validation.id === keyId) {
          return {
            id: keyId,
            fields: fieldValidation,
          };
        }
        return validation;
      });
      setPackageValidation(packageValidationArray);
    } else if (!validationExists) {
      const packageValidationArray = [
        ...packageValidation,
        {
          id: keyId,
          fields: fieldValidation,
        },
      ];
      setPackageValidation(packageValidationArray);
    }
  }, [
    fieldValidation,
    setPackageValidation,
    invalidInputsOnClick,
    keyId,
    packageValidation,
  ]);

  const invalidClassName = (isInvalid: boolean) =>
    isInvalid ? 'invalidInput' : '';

  const unitsAndShipmentType = () => (
    <Grid container columnSpacing={1} className={styles.inputRow}>
      <Grid item xs={6}>
        <Dropdown
          label="Handling Units*"
          options={handlingUnitOptions()}
          value={unitOption}
          onChange={(option: ListObj) => {
            setUnitOption(option);
            saveDetails({ handlingUnits: Number(option.value) });

            if (invalidInputsOnClick.units && option) {
              setInvalidInputsOnClick({
                ...invalidInputsOnClick,
                units: false,
              });
            }
          }}
          invalidUserInput={invalidInputsOnClick.units}
          assistiveText={invalidInputsOnClick.units ? requiredFieldText : ''}
          containerClassName={invalidClassName(!handlingUnits)}
        />
      </Grid>
      <Grid item xs={6} className={styles.stackableWrapper}>
        <Checkbox
          label="Stackable?"
          checked={stackable}
          onClick={() => {
            saveDetails({ stackable: !stackable });
          }}
        />
        <TooltipWrapper
          tooltipText="Are these handling units stackable? This could effect the price of shipping."
          placement="right"
        >
          <HelpIcon className={styles.helpIcon} fill={COLORS.sand20} />
        </TooltipWrapper>
      </Grid>
    </Grid>
  );

  const typeAndPieces = () => (
    <Grid container columnSpacing={1} className={styles.inputRow}>
      <Grid item xs={7}>
        <Dropdown
          label="Shipment Type*"
          options={packageTypes}
          value={shipmentTypeOption}
          onChange={(option: ListObj) => {
            setShipmentTypeOption(option);

            if (option.value) {
              saveDetails({ shipmentType: option.value });
            }

            if (invalidInputsOnClick.shipmentType && option) {
              setInvalidInputsOnClick({
                ...invalidInputsOnClick,
                shipmentType: false,
              });
            }
          }}
          invalidUserInput={invalidInputsOnClick.shipmentType}
          assistiveText={
            invalidInputsOnClick.shipmentType ? requiredFieldText : ''
          }
          containerClassName={invalidClassName(!shipmentType)}
        />
      </Grid>
      <Grid item xs={5}>
        <TextInput
          label="Pieces*"
          value={pieces}
          type="number"
          onChange={(e) => {
            saveDetails({ pieces: Number(e.target.value) });

            if (invalidInputsOnClick.pieces && e.target.value !== '') {
              setInvalidInputsOnClick({
                ...invalidInputsOnClick,
                pieces: false,
              });
            }
          }}
          invalidUserInput={invalidInputsOnClick.pieces}
          assistiveText={invalidInputsOnClick.pieces ? requiredFieldText : ''}
          containerClassName={invalidClassName(!pieces)}
        />
      </Grid>
    </Grid>
  );

  // used to determine if the input should display " or lbs when not in focus
  // used for length, width, height, and weight inputs
  const [inputInFocus, setInputInFocus] = useState({
    lengthInput: false,
    widthInput: false,
    heightInput: false,
    weightInput: false,
  });

  const dimensions = () => {
    const { lengthInput, widthInput, heightInput } = inputInFocus;
    // display '"' if the input isn't in focus and there is a value
    const displayedLength = !lengthInput && length ? `${length}"` : length;

    const displayedWidth = !widthInput && width ? `${width}"` : width;

    const displayedHeight = !heightInput && height ? `${height}"` : height;

    return (
      <>
        <div className={styles.dimensionsText}>Unit Dimensions, in inches</div>
        <Grid container columnSpacing={1} className={styles.row}>
          <Grid item xs={12} sm={4}>
            <TextInput
              containerClassName={`${styles.input} ${invalidClassName(
                !length,
              )}`}
              label="Length*"
              value={displayedLength}
              onChange={(e) => {
                const val = e.target.value;
                if (validNumber(val)) {
                  saveDetails({ length: Number(val.replace('"', '')) });

                  if (invalidInputsOnClick.length && val !== '') {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      length: false,
                    });
                  }
                }
              }}
              onFocus={(e) => {
                e.stopPropagation();
                setInputInFocus({ ...inputInFocus, lengthInput: true });
              }}
              onBlur={(e) => {
                e.stopPropagation();
                setInputInFocus({ ...inputInFocus, lengthInput: false });
              }}
              invalidUserInput={invalidInputsOnClick.length}
              assistiveText={
                invalidInputsOnClick.length ? requiredFieldText : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              containerClassName={`${styles.input} ${invalidClassName(!width)}`}
              label="Width*"
              value={displayedWidth}
              onChange={(e) => {
                const val = e.target.value;
                if (validNumber(val)) {
                  saveDetails({ width: Number(val.replace('"', '')) });

                  if (invalidInputsOnClick.width && val !== '') {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      width: false,
                    });
                  }
                }
              }}
              onFocus={(e) => {
                e.stopPropagation();
                setInputInFocus({ ...inputInFocus, widthInput: true });
              }}
              onBlur={(e) => {
                e.stopPropagation();
                setInputInFocus({ ...inputInFocus, widthInput: false });
              }}
              invalidUserInput={invalidInputsOnClick.width}
              assistiveText={
                invalidInputsOnClick.width ? requiredFieldText : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              containerClassName={`${styles.input} ${invalidClassName(
                !height,
              )}`}
              label="Height*"
              value={displayedHeight}
              onChange={(e) => {
                const val = e.target.value;
                if (validNumber(val)) {
                  saveDetails({ height: Number(val.replace('"', '')) });

                  if (invalidInputsOnClick.height && val !== '') {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      height: false,
                    });
                  }
                }
              }}
              onFocus={(e) => {
                e.stopPropagation();
                setInputInFocus({ ...inputInFocus, heightInput: true });
              }}
              onBlur={(e) => {
                e.stopPropagation();
                setInputInFocus({ ...inputInFocus, heightInput: false });
              }}
              invalidUserInput={invalidInputsOnClick.height}
              assistiveText={
                invalidInputsOnClick.height ? requiredFieldText : ''
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const weightAndClass = () => {
    // display 'lbs' if the input isn't in focus and there is a value
    const displayedWeight =
      !inputInFocus.weightInput && weight ? `${weight} lbs` : weight;

    return (
      <Grid container columnSpacing={1} className={styles.row}>
        <Grid item xs={12} sm={4}>
          <TextInput
            containerClassName={`${styles.input} ${invalidClassName(!weight)}`}
            label="Weight*"
            value={displayedWeight}
            onChange={(e) => {
              const val = e.target.value;
              if (validNumber(val)) {
                saveDetails({ weight: Number(val.replace(' lbs', '')) });

                if (invalidInputsOnClick.weight && val !== '') {
                  setInvalidInputsOnClick({
                    ...invalidInputsOnClick,
                    weight: false,
                  });
                }
              }
            }}
            onFocus={(e) => {
              e.stopPropagation();
              setInputInFocus({ ...inputInFocus, weightInput: true });
            }}
            onBlur={(e) => {
              e.stopPropagation();
              setInputInFocus({ ...inputInFocus, weightInput: false });
            }}
            invalidUserInput={invalidInputsOnClick.weight}
            assistiveText={invalidInputsOnClick.weight ? requiredFieldText : ''}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={styles.input}>
          <Dropdown
            label="Class"
            options={shippingClasses}
            value={shippingClassOption}
            onChange={(option: ListObj) => {
              setShippingClassOption(option);

              if (option.value) {
                saveDetails({ class: option.value });
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={styles.input}>
          <TextInput
            label="NMFC"
            value={nmfc}
            onChange={(e) => {
              saveDetails({ nmfc: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const hazardousSection = () => (
    <Grid container columnSpacing={1} className={styles.row}>
      <Grid
        item
        xs={12}
        className={`${styles.input} ${invalidClassName(hazardous)}`}
      >
        <Checkbox
          label="Contains Hazardous Materials"
          checked={hazardous}
          onClick={() => saveDetails({ hazardous: !hazardous })}
        />
      </Grid>
      {hazardous && (
        <span className={styles.hazardousNotAllowed}>
          At this time, Check The Yard does not allow sales of hazardous
          materials
        </span>
      )}
    </Grid>
  );

  return (
    <div className={styles.wrapper}>
      {keyId !== 1 && (
        // only show the delete button if it is not the first package
        <div className={styles.deletePackageButton}>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => {
              deletePackage(keyId);
            }}
          />
        </div>
      )}
      {unitsAndShipmentType()}
      {typeAndPieces()}
      <div className={styles.inputRow}>
        <TextInput
          label="Description*"
          value={description}
          onChange={(e) => {
            saveDetails({ description: e.target.value });

            if (invalidInputsOnClick.description && e.target.value) {
              setInvalidInputsOnClick({
                ...invalidInputsOnClick,
                description: false,
              });
            }
          }}
          invalidUserInput={invalidInputsOnClick.description}
          assistiveText={
            invalidInputsOnClick.description ? requiredFieldText : ''
          }
          containerClassName={invalidClassName(!description)}
        />
      </div>
      {dimensions()}
      {weightAndClass()}
      {hazardousSection()}
    </div>
  );
}

export default PackageInfo;
