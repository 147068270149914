import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  usePostUsersMutation,
  usePostAuthLoginMutation,
  UserRoleType,
} from 'api';
import { YardApiError } from 'api/types';
import { useBlocker, useApiResponseMessage } from 'hooks';
import { setLoginCredentials, setAccountInfo } from 'slices/accountSlice';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import { Button, TextLink, BackButton } from 'components/Buttons';
import StepTracker, { Step } from 'components/StepTracker/StepTracker';
import { ReactComponent as StoreIcon } from 'assets/icons/statuses/arrivalStatusIcon.svg';
import { ReactComponent as SupervisorAccountIcon } from 'assets/icons/socialPeopleIcon.svg';
import { COLORS } from 'style/colors';
import UserDetailsScreen from './UserDetailsScreen/UserDetailsScreen';
import BusinessDetailsScreen from './BusinessDetailsScreen/BusinessDetailsScreen';
import WelcomeScreen from './WelcomeScreen/WelcomeScreen';
import ContinueButton from './ContinueButton';
import { UserDetailsObj, BusinessDetailsObj, FieldValidation } from './types';
import styles from './createAccount.module.scss';

function CreateAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { apiResponseMessage } = useApiResponseMessage();

  const headerRef = useRef<HTMLDivElement>(null); // used for scrolling to top of page on page change
  // need to know if coming from the sign in modal for back button functionality
  const fromSignIn = location.pathname.includes('sign_in');
  const [isLoading, setIsLoading] = useState(false);

  const [login] = usePostAuthLoginMutation();
  const [createUser] = usePostUsersMutation();

  // this is passed to the WelcomeScreen for the stripe onboarding api call
  const [userId, setUserId] = useState('');

  // for managing what step the user is on
  const [step, setStep] = useState('user_details');
  // for setting Next button func & icons
  const steps = useMemo(
    () => ['user_details', 'business_info', 'finished'],
    [],
  );
  const stepIdx = steps.findIndex((s) => s === step);
  const lastStep = step === 'finished';

  // user detail form inputs
  const [user, setUser] = useState<UserDetailsObj>({
    email: '',
    phone: '',
    name: '',
    password: '',
    confirmPassword: '',
    isOver18: false,
  });
  // business form inputs
  const [businessDetails, setBusinessDetails] = useState<BusinessDetailsObj>({
    type: null,
    industry: null,
    authorizedOnBehalfOfBusiness: false,
    hideBusiness: false,
    address: null,
    business: null,
    validAddress: false,
    agreeYardTerms: false,
    agreeStripeTerms: false,
  });

  // used to display the tooltip and handle disabling the continue button
  const [userFieldsValidation, setUserFieldsValidation] = useState<
    FieldValidation[]
  >([]);
  const [businessFieldsValidation, setBusinessFieldsValidation] = useState<
    FieldValidation[]
  >([]);

  // since we validate fields on click, we need to know when to validate
  const [validateFieldsOnClick, setValidateFieldsOnClick] = useState(false);

  // this is no longer actually disabling the button, but instead used in the handleContinueButton to determine what to do & in the header icons to determine if the user is allowed to progress
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  /* if the user clicks the browser back button while on the business 
  details step, we want to send them back to the user details step 
  instead of out of the create account flow */
  const isOnBusinessInfoStep = step === 'business_info';
  const [submitting, setSubmitting] = useState(false);
  const blockWhen = isOnBusinessInfoStep && !submitting;
  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      // in if condition we are checking next location and current location are equals or not
      if (blockWhen && nextLocation.location.pathname !== location.pathname) {
        setStep('user_details');
        return false;
      }
      return true;
    },
    [blockWhen, location],
  );

  useBlocker(handleBlockedNavigation, blockWhen);

  // determine what form is displayed
  const displayedContent = () => {
    switch (step) {
      case 'user_details':
        return (
          <UserDetailsScreen
            user={user}
            setUser={setUser}
            validateFieldsOnClick={validateFieldsOnClick}
            setValidateFieldsOnClick={setValidateFieldsOnClick}
            setUserFieldsValidation={setUserFieldsValidation}
          />
        );
      case 'business_info':
        return (
          <BusinessDetailsScreen
            businessDetails={businessDetails}
            setBusinessDetails={setBusinessDetails}
            validateFieldsOnClick={validateFieldsOnClick}
            setValidateFieldsOnClick={setValidateFieldsOnClick}
            setBusinessFieldsValidation={setBusinessFieldsValidation}
          />
        );
      case 'finished':
        return <WelcomeScreen userId={userId} />;
      default:
        return (
          <UserDetailsScreen
            user={user}
            setUser={setUser}
            validateFieldsOnClick={validateFieldsOnClick}
            setValidateFieldsOnClick={setValidateFieldsOnClick}
            setUserFieldsValidation={setUserFieldsValidation}
          />
        );
    }
  };

  // api call to create a Yard account
  const createYardAccount = useCallback(async () => {
    if (businessDetails.address && businessDetails.type) {
      try {
        setIsLoading(true);

        const updatedUser = {
          email: user.email,
          phone: user.phone.replace(/\D/g, ''),
          password: user.password,
          name: user.name,
          isOver18: user.isOver18,
        };

        const { business, address, type, industry } = businessDetails;
        const isIndividual = type === 'individual';

        const userAddress = {
          ...address,
          name: isIndividual ? user.name : address.name,
          country: 'US',
        };

        const newUser = {
          role: 'user' as UserRoleType,
          active: true,
          ...updatedUser,
          address: userAddress,
          ...(!isIndividual && business && { business }),
          type,
          industry: industry || 'Individual',
          rating: {
            averageRating: 0,
            totalRatings: 0,
          },
          userSettings: {
            hideBusiness: businessDetails.hideBusiness,
            authorizedOnBehalfOfBusiness:
              businessDetails.authorizedOnBehalfOfBusiness,
          },
          id: '',
        };

        const payload = await createUser({ user: newUser });

        if ('error' in payload) {
          const errorObject = payload.error as YardApiError;
          const generalErrorMessage = 'An error occurred, please try again.';
          let errorMessage = errorObject?.data?.message || generalErrorMessage;

          if (
            errorMessage.includes(
              `User with email: ${user.email} already exists`,
            )
          ) {
            errorMessage = 'This email is already associated with an account.';
          }

          apiResponseMessage(errorMessage, 6000);

          // need to reset this on error incase they try to use the browser back button to return to user details
          setSubmitting(false);
        } else {
          // user is created, set the login credentials
          const credentials = await login({
            body: { email: user.email, password: user.password },
          }).unwrap();

          dispatch(
            setLoginCredentials({
              accessToken: credentials.accessToken,
              refreshToken: credentials.refreshToken,
              userId: credentials.userId,
            }),
          );
          dispatch(
            setAccountInfo({
              accountType: type || null,
              businessName: business ? business.name : user.name,
            }),
          );

          setUserId(credentials.userId);
          setStep(steps[stepIdx + 1]);
          // scroll to top of the form
          headerRef.current?.scrollIntoView({ block: 'nearest' });
        }
      } catch (err) {
        apiResponseMessage(
          'An error occurred, please try again. If it continues, please contact support.',
          6000,
        );
      }
    }
    setIsLoading(false);
  }, [
    businessDetails,
    createUser,
    dispatch,
    login,
    stepIdx,
    steps,
    user,
    apiResponseMessage,
  ]);

  const handleContinueButton = useCallback(() => {
    if (!nextButtonDisabled) {
      // once info is filled out, create their Yard account
      if (step === 'business_info') {
        setSubmitting(true);
        createYardAccount();
      } else {
        setStep(steps[stepIdx + 1]);
        // scroll to top of the form
        headerRef.current?.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [createYardAccount, nextButtonDisabled, step, stepIdx, steps]);

  const handleEnterKey = () => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleContinueButton();
      }
    };
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  };

  useEffect(handleEnterKey, [handleContinueButton]);

  let businessOnClick = null;
  if (step === 'user_details' && !nextButtonDisabled) {
    businessOnClick = () => setStep('business_info');
  }
  // step names within StepTracker
  const stepNames: Step[] = [
    { stepName: 'User Details', onClick: () => setStep('user_details') },
    {
      stepName: 'About Your Business',
      ...(businessOnClick && { onClick: businessOnClick }),
    },
    { stepName: 'Registration Complete' },
  ];

  const headerIcons = [
    <SupervisorAccountIcon fill={COLORS.offWhite} />,
    <StoreIcon fill={COLORS.offWhite} />,
  ];

  return (
    <Grid container className={styles.container}>
      {isLoading && <Loading screenOverlay />}
      <Grid container className={styles.headerContainerStyling}>
        <Grid
          item
          xs={12}
          md={10}
          container
          className={styles.headerContainer}
          ref={headerRef}
        >
          <Grid
            item
            xs={lastStep ? 8 : 7}
            sm={lastStep ? 5 : 3}
            md={lastStep ? 5 : 3}
            className={styles.header}
          >
            {!lastStep ? 'Create Account' : 'Welcome to The Yard!'}
          </Grid>
          <Grid item xs={12} className={styles.stepTrackerWrapper}>
            <StepTracker
              steps={stepNames}
              icon={headerIcons[stepIdx]}
              activeStep={stepIdx}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        container
        className={styles.bodyContainer}
      >
        {displayedContent()}
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={6}
        lg={5}
        container
        className={styles.footer}
      >
        {!lastStep ? (
          <ContinueButton
            step={step}
            userFieldsValidation={userFieldsValidation}
            businessFieldsValidation={businessFieldsValidation}
            nextButtonDisabled={nextButtonDisabled}
            setNextButtonDisabled={setNextButtonDisabled}
            handleContinueClick={handleContinueButton}
            handleDisabledClick={() => {
              // if the user clicks the button when it's "disabled", we want to trigger the components form validation
              setValidateFieldsOnClick(true);
            }}
          />
        ) : (
          <Button
            containerClassName={styles.nextButton}
            title="Start Shopping"
            buttonColor="sand20"
            onClick={() => {
              navigate('/');
            }}
          />
        )}
        <TextLink
          text="Other issues? Contact us"
          className={styles.contactUsLink}
          onClick={() => {
            navigate('/help');
          }}
        />
        {!lastStep && (
          <BackButton
            text={step === 'user_details' ? 'Back' : 'Back to user details'}
            onClick={() => {
              if (step === 'user_details') {
                if (fromSignIn) {
                  navigate('/sign_in', { replace: true });
                } else {
                  navigate(-1);
                }
              } else {
                setStep('user_details');
                // scroll to top of form; the block: 'nearest' ensures it scrolls the modal and not the window
                headerRef.current?.scrollIntoView({ block: 'nearest' });
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default CreateAccount;
