import { useEffect, useState } from 'react';
import { useGetPayoutMethodsQuery } from 'api';
import amexLogo from 'assets/paymentTypeLogos/amex_logo.png';
import discoverLogo from 'assets/paymentTypeLogos/discover_logo.jpg';
import mastercardLogo from 'assets/paymentTypeLogos/mastercard_logo.svg';
import visaLogo from 'assets/paymentTypeLogos/visa_logo.png';
import achLogo from 'assets/paymentTypeLogos/ach_icon.svg';
import defaultCardIcon from 'assets/paymentTypeLogos/default_card_icon.svg';

type CardBrands = 'Visa' | 'Mastercard' | 'Amex' | 'Discover' | string; // catch all for other card brands

type Payment = {
  id: string;
  object: 'card' | 'us_bank_account' | 'bank_account' | string;
  last4: string;
  brand?: CardBrands;
};

const useGetPayoutDetails = () => {
  const [payoutMethod, setPayoutMethod] = useState<Payment | any>();

  const { currentData } = useGetPayoutMethodsQuery();

  useEffect(() => {
    if (currentData?.data) {
      setPayoutMethod(currentData.data.payoutMethod);
    }
  }, [currentData]);

  if (!currentData) {
    return {
      id: '',
      type: '',
      icon: '',
      last4: '',
    };
  }

  const last4 = payoutMethod?.last4 || '';
  let icon = defaultCardIcon;

  const getCardIcon = () => {
    const cardBrand = payoutMethod?.brand.toLowerCase();

    if (cardBrand === 'visa') {
      return visaLogo;
    } else if (cardBrand === 'mastercard') {
      return mastercardLogo;
    } else if (cardBrand === 'amex') {
      return amexLogo;
    } else if (cardBrand === 'discover') {
      return discoverLogo;
    } else {
      return defaultCardIcon;
    }
  };

  switch (payoutMethod?.object) {
    case 'card': {
      icon = getCardIcon();
      break;
    }
    case 'bank_account':
    case 'us_bank_account': {
      icon = achLogo;
      break;
    }
    default:
      break;
  }

  return {
    id: payoutMethod?.id,
    type: payoutMethod?.object,
    icon,
    last4,
  };
};

export default useGetPayoutDetails;
