import React from 'react';
import Grid from '@mui/material/Grid';
import { TextLink } from 'components/Buttons';
import styles from './previousStepInfo.module.scss';

type Props = {
  productListing: any;
  steps: string[];
  stepIdx: number;
  setStep: (step: string) => void;
};

function PreviousStepInfo({ productListing, steps, stepIdx, setStep }: Props) {
  let categoryScreen;
  let descriptionScreen;
  let pricingScreen;
  let shippingScreen;

  if (productListing) {
    const { category, description, pricing, shipping } = productListing;

    if (category && stepIdx !== 0) {
      const { categoryName, fullCategoryNumber, subCategoryName } = category;

      categoryScreen = {
        section: 'MATERIAL CATEGORY',
        header: categoryName,
        subHeader: `${fullCategoryNumber} ${subCategoryName}`,
        stepIndex: 0,
      };
    }

    if (description && description.productName && stepIdx !== 1) {
      const {
        productName,
        productCondition,
        productConditionRating,
        productConditionNotes,
      } = description;
      let subHeader = productConditionNotes;

      if (productCondition && productCondition.toLowerCase() === 'as-is') {
        subHeader = productConditionRating?.name || '';
      }

      descriptionScreen = {
        section: 'PRODUCT INFORMATION',
        header: productName,
        subHeader: productCondition ? `${productCondition}: ${subHeader}` : '',
        stepIndex: 1,
      };
    }

    if (pricing && pricing.basePrice && stepIdx !== 2) {
      const { soldAs, basePrice, quantity, minimumOrder } = pricing;
      let header = `${basePrice}`;
      let subHeader = 'For the Lot';

      if (soldAs === 'perunit') {
        header = `${basePrice} per unit`;
        subHeader = `Min: ${minimumOrder} of ${quantity}`;
      }

      pricingScreen = {
        section: 'PRICING',
        header: `$${header}`,
        subHeader,
        stepIndex: 2,
      };
    }

    if (shipping && shipping.packageDetails.length > 0 && stepIdx !== 3) {
      const { shipFromAddress, packageDetails } = shipping;
      const shipFromText = shipFromAddress.address;
      const packageInfo = `${packageDetails[0].handlingUnits} ${packageDetails[0].shipmentType}`;

      shippingScreen = {
        section: 'SHIPPING',
        header: shipFromText,
        subHeader: packageInfo,
        stepIndex: 3,
      };
    }
  }

  const allSteps = [
    categoryScreen,
    descriptionScreen,
    pricingScreen,
    shippingScreen,
  ];
  const previousSteps = allSteps.filter((stepObj) => stepObj);

  if (previousSteps.length < 1) {
    return null;
  }

  return (
    <Grid container className={styles.previousStepContainer}>
      {previousSteps.map((previousStep, idx) => {
        const lineSeperation = idx > 0 ? styles.lineSeperation : '';
        return (
          <div
            className={`${styles.stepContainer} ${lineSeperation}`}
            key={previousStep?.section}
          >
            <div className={styles.stepInfo}>
              <div className={styles.sectionName}>{previousStep?.section}</div>
              <div className={styles.stepHeader}>{previousStep?.header}</div>
              <div className={styles.stepSubHeader}>
                {previousStep?.subHeader}
              </div>
            </div>
            <TextLink
              className={styles.changeButton}
              text="Change"
              textColor="leather"
              onClick={() => {
                if (previousStep?.stepIndex) {
                  setStep(steps[previousStep.stepIndex]);
                }
              }}
            />
          </div>
        );
      })}
    </Grid>
  );
}

export default PreviousStepInfo;
