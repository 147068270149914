import React, { MouseEventHandler } from 'react';
import styles from './textLink.module.scss';

type Props = {
  className?: string;
  text?: string | React.ReactNode; // React.ReactNode is used for breadcrumbs
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  textColor?:
    | 'black'
    | 'sand20'
    | 'leather'
    | 'sand'
    | 'brightSand20'
    | 'blue'
    | 'white'; // default it black
  href?: string; // use for a tag
  newTab?: boolean; // if href is used, do you want to open in a new tab
};

function TextLink({
  className,
  text,
  children,
  onClick,
  textColor = 'black',
  disabled = false,
  href,
  newTab,
  ...props
}: Props) {
  const textClass = styles[`${textColor}`];

  if (href) {
    return (
      <a
        href={href}
        target={newTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
        className={`${styles.textLink} ${className} ${textClass}`}
        onMouseDown={(e) => {
          // this is needed to remove the :focus styling after click
          e.preventDefault();
        }}
      >
        {children || text}
      </a>
    );
  }

  return (
    <button
      type="button"
      className={`${styles.textLink} ${className} ${textClass}`}
      onClick={onClick}
      disabled={disabled}
      onMouseDown={(e) => {
        // this is needed to remove the :focus styling after click
        e.preventDefault();
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children || text}
    </button>
  );
}

export default TextLink;
