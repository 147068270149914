import React from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/downArrowIcon.svg';
import { ReactComponent as UpArrowIcon } from '../../assets/icons/upArrowIcon.svg';
import styles from './menuDropdown.module.scss';

interface Link {
  text: string;
  onClick: () => any;
}

interface Props {
  showMenu: boolean;
  setShowMenu: () => void; // the setShowMenu function
  menuTitle: string;
  menuOptions: any; // this is so each section can control what the options are and styling. Example in UserAccount/AccountMenu
  className?: string;
  menuClassName?: string; // for the menu container
  titleLink?: Link | Link[]; // can be one link or an array of links
  actionNeeded?: boolean; // for action needed styling
  count?: string | number; // for displaying a count next to the title, doesn't show if there is no count
}

function MenuDropdown({
  showMenu,
  setShowMenu,
  menuTitle,
  menuOptions,
  className = '',
  menuClassName,
  titleLink,
  actionNeeded = false,
  count,
}: Props) {
  const menuStyle = showMenu ? styles.showMenu : styles.hideMenu;
  const actionNeededStyle = actionNeeded ? styles.actionNeeded : '';

  const link = (text: string, onClick: () => any) => (
    <div key={text} className={styles.headerLink} onClick={onClick}>
      {text}
    </div>
  );

  const headerLinks = () => {
    if (titleLink) {
      if (Array.isArray(titleLink)) {
        return titleLink.map((linkObj) => link(linkObj.text, linkObj.onClick));
      }

      return link(titleLink.text, titleLink.onClick);
    }
    return null;
  };

  return (
    <Grid container className={`${styles.wrapper} ${className}`}>
      <Grid
        item
        xs={12}
        container
        className={`${styles.sectionHeader} ${menuStyle} ${actionNeededStyle}`}
      >
        <Grid item xs={11} className={styles.headerTextContianer}>
          <div className={styles.headerCountContainer}>
            <div className={styles.header}>{menuTitle}</div>
            {count !== undefined && (
              <div className={styles.count}>({count})</div>
            )}
          </div>
          {titleLink && headerLinks()}
        </Grid>
        <Grid
          item
          xs={1}
          onClick={() => setShowMenu()}
          className={styles.arrowContainer}
        >
          {showMenu ? <DownArrowIcon /> : <UpArrowIcon />}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${styles.menuOptionsContainer} ${menuStyle} ${actionNeededStyle} ${menuClassName}`}
      >
        {menuOptions}
      </Grid>
    </Grid>
  );
}

export default MenuDropdown;
