import React, { useState, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import { Order, useGetUsersByUserIdDashboardQuery } from 'api';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import { Button, TextLink } from 'components/Buttons';
import { RatingModal } from 'components/OrderComponents';
import styles from './orderBanner.module.scss';

type Props = {
  order: Order;
  isBuyer: boolean;
  setShowInspectOrderDialog: (isOpen: boolean) => any;
};

function OrderBanner({ order, isBuyer, setShowInspectOrderDialog }: Props) {
  const { status: orderStatus, buyer, ratings = [], seller, logs = [] } = order;
  const sellerId = seller?.id || '';

  const canceledDueToPaymentFailure = logs.some(
    (log) => log.status === 'PAYMENT-FAILED',
  );

  const [stripeDashboardLink, setStripeDashboardLink] = useState<string>();
  // generate the link on click to prevent stale link
  const [generateStripeLink, setGenerateStripeLink] = useState<boolean>(false);
  const skipStripeDashboardLinkQuery =
    !generateStripeLink || isBuyer || sellerId === '';
  const { data: dashboardQueryData } = useGetUsersByUserIdDashboardQuery(
    { userId: sellerId },
    { skip: skipStripeDashboardLinkQuery },
  );

  useEffect(() => {
    if (dashboardQueryData) {
      setStripeDashboardLink(dashboardQueryData?.data?.link.url);
    }
  }, [dashboardQueryData]);

  useEffect(() => {
    if (stripeDashboardLink) {
      // open the stripe dashboard in a new tab
      window.open(stripeDashboardLink, '_blank');
      // reset the button state so that the button can be clicked again
      setGenerateStripeLink(false);
    }
  }, [stripeDashboardLink]);

  const nameOfUserBeingRated = isBuyer ? seller?.name : buyer?.name;
  const sellerHasRatedTheOrder = ratings.find(
    (rating) => rating?.role === 'SELLER',
  );
  const buyerHasRatedTheOrder = ratings.find(
    (rating) => rating?.role === 'BUYER',
  );

  const [showRatingModal, hideRatingModal] = useModal(
    () => (
      <RatingModal
        orderId={order.id}
        targetName={nameOfUserBeingRated || ''}
        closeRatingModal={() => hideRatingModal()}
      />
    ),
    [],
  );

  let color = styles.transparent;
  let boldText = 'Order placed';
  let text = isBuyer ? 'Waiting for the seller to ship.' : 'Seller text here';
  let viewOnStripeColor: 'leather' | 'sand' = 'leather';
  let underlineLink;
  let ctaButtonTitle;
  let ctaButtonAction;
  let hideBanner = false;
  let textOnlyBanner = false;

  switch (orderStatus) {
    case 'PAID':
      if (isBuyer) {
        color = styles.transparent;
        boldText = 'Order confirmed';
        text = 'Waiting for the seller to ship.';
        viewOnStripeColor = 'leather';
      } else {
        color = styles.black;
        boldText = "We've received payment";
        text = 'Check The Yard will reach out to you to setup shipping.';
        viewOnStripeColor = 'sand';
        underlineLink = styles.underlineLink;
      }
      break;
    case 'SHIPPED':
    case 'AWAITING-PICKUP':
      viewOnStripeColor = 'sand';
      underlineLink = styles.underlineLink;
      ctaButtonAction = () => setShowInspectOrderDialog(true);

      if (isBuyer) {
        color = styles.black;
        boldText = 'Inspect Order';
        text = 'Once you receive this order, please inspect it.';
        ctaButtonTitle = 'INSPECT ORDER';
      } else {
        color = styles.transparent;
        boldText = 'Order shipped';
        text = 'Waiting for the buyer to receive the order.';
        viewOnStripeColor = 'leather';
        underlineLink = '';
      }

      break;
    case 'PAYOUT-FAILED':
      if (!isBuyer) {
        color = styles.black;
        boldText = 'Payout Failed';
        text =
          'There was an issue with your payout. Please go to your Stripe dashboard to resolve the issue.';
        ctaButtonTitle = 'STRIPE DASHBOARD';
      }
      break;
    case 'COMPLETED':
      if (
        (!sellerHasRatedTheOrder && !isBuyer) ||
        (!buyerHasRatedTheOrder && isBuyer)
      ) {
        color = styles.black;
        boldText = 'Rate Order';
        text = 'Let us know what you think.';
        viewOnStripeColor = 'sand';
        ctaButtonTitle = 'RATE ORDER';
        ctaButtonAction = showRatingModal;
      } else {
        color = styles.transparent;
        boldText = 'Order Complete';
        text = 'There is nothing left for you to do.';
        viewOnStripeColor = 'leather';
      }
      break;
    case 'CANCELED':
      color = styles.transparent;
      boldText = 'Order canceled';
      text = canceledDueToPaymentFailure
        ? 'This order has been canceled due to issues with the selected payment method.'
        : 'This order has been canceled.';
      textOnlyBanner = canceledDueToPaymentFailure;
      break;
    case 'DISPUTED':
      color = styles.black;
      boldText = 'Order disputed';
      text =
        'This order has been disputed. Someone will be in touch with you for next steps.';
      break;
    case 'PENDING-REFUND':
      color = styles.transparent;
      boldText = 'Refunded in progress';
      text = isBuyer
        ? 'Your refunded for this order is in progress.'
        : 'The buyer is being refunded for this order.';
      break;
    case 'REFUNDED':
      color = styles.transparent;
      boldText = 'Order refunded';
      text = isBuyer
        ? 'Your refunded for this order is complete.'
        : 'The buyer has been refunded for this order.';
      break;
    default:
      hideBanner = true;
  }

  if (hideBanner) {
    return null;
  }

  if (generateStripeLink && !stripeDashboardLink) {
    return <Loading screenOverlay />;
  }

  if (textOnlyBanner) {
    // give text the full length of the banner
    return (
      <Grid container className={`${styles.wrapper} ${color}`}>
        <Grid item xs={12} container className={styles.textWrapper}>
          <span className={styles.boldText}>{boldText} &nbsp; </span>
          {text}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={`${styles.wrapper} ${color}`}>
      <Grid
        item
        xs={12}
        md={ctaButtonTitle ? 6.5 : 8}
        container
        className={styles.textWrapper}
      >
        <span className={styles.boldText}>{boldText} &nbsp; </span>
        {text}
      </Grid>
      <Grid
        item
        xs={12}
        md={ctaButtonTitle ? 5.5 : 4}
        container
        className={styles.buttonWrapper}
      >
        {!isBuyer && (
          <TextLink
            textColor={viewOnStripeColor}
            onClick={() => {
              setGenerateStripeLink(true);
            }}
            className={underlineLink}
          >
            View on Stripe
          </TextLink>
        )}
        {ctaButtonTitle && (
          <Button
            buttonColor="black"
            text={ctaButtonTitle}
            onClick={ctaButtonAction}
            buttonClassName={styles.ctaButton}
            containerClassName={styles.button}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default OrderBanner;
