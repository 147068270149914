import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { COLORS } from 'style/colors';

type Props = {
  children: JSX.Element;
  tooltipText: string | JSX.Element;
  placement?: 'top' | 'right' | 'bottom' | 'left'; // default is top
  invalidHighlight?: boolean;
  maxWidth?: string;
};

function TooltipWrapper({
  children,
  tooltipText,
  placement = 'top',
  invalidHighlight,
  maxWidth = '200px',
}: Props) {
  return (
    <Tooltip
      arrow
      title={tooltipText}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            enterTouchDelay: 400,
            backgroundColor: invalidHighlight
              ? COLORS.midnightBlack
              : COLORS.white,
            color: invalidHighlight ? COLORS.white : COLORS.midnightBlack,
            fontSize: '.8em',
            fontFamily: 'Roboto',
            maxWidth,
            padding: '8px',
            whiteSpace: 'pre-line',
            boxShadow:
              '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
          },
        },
        arrow: {
          sx: {
            color: invalidHighlight ? COLORS.midnightBlack : COLORS.white,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipWrapper;
