import React from 'react';
import { useNavigate } from 'react-router';
import { useLogOut } from 'hooks';
import { TextLink } from 'components/Buttons';
import SideMenu from 'components/SideMenu/SideMenu';
import styles from './accountMenu.module.scss';

type ListObj = {
  name: string;
  screen: string;
};

type Props = {
  closeMenu: () => void;
};

function AccountMenu({ closeMenu }: Props) {
  const navigate = useNavigate();
  const { logOut } = useLogOut();

  const orderOptions: ListObj[] = [
    {
      name: 'My Orders',
      screen: '/orders',
    },
    {
      name: 'Shopping List',
      screen: '/shopping_cart',
    },
  ];

  const profileOptions: ListObj[] = [
    {
      name: 'User Details',
      screen: '/account/user_details',
    },
    {
      name: 'Stripe',
      screen: '/account/stripe',
    },
    {
      name: 'Yard Terms',
      screen: '/terms_and_conditions',
    },
  ];

  const businessOptions: ListObj[] = [
    // { Removed for MVP
    //   name: 'View Storefront',
    //   screen: '/storefront',
    // },
    {
      name: 'Business Details',
      screen: '/account/business_details',
    },
    {
      name: 'Sales History',
      screen: '/orders/sales',
    },
    // { // removed for beta
    //   name: 'My Terms',
    //   screen: '/account/seller_terms',
    // },
    {
      name: 'My Listings',
      screen: '/selling/my_yard_listings',
    },
  ];

  // not part of MVP
  // const notificationOptions: ListObj[] = [
  //   {
  //     name: 'Notifications',
  //     screen: '/account/notifications',
  //   },
  //   {
  //     name: 'Messaging',
  //     screen: '/account/messaging',
  //   },
  //   {
  //     name: 'Help Tickets',
  //     screen: 'zendesk redirect',
  //   },
  // ];

  const listOptions = (list: ListObj[]) =>
    list.map((option) => {
      const { screen, name } = option;

      return (
        <TextLink
          key={name}
          className={styles.option}
          text={name}
          onClick={() => {
            navigate(screen);
            closeMenu();
          }}
        />
      );
    });

  const accountHeader = () => (
    <TextLink
      className={styles.accountHome}
      onClick={() => {
        navigate('/account');
        closeMenu();
      }}
      text="Account Home"
    />
  );

  const accountMenu = () => (
    <div className={styles.contentWrapper}>
      <div>
        <div>
          <h6 className={`${styles.sectionHeader} ${styles.topSection}`}>
            My Orders and Purchases
          </h6>
          <div className={styles.optionsContainer}>
            {listOptions(orderOptions)}
          </div>
        </div>
        <div>
          <h6 className={styles.sectionHeader}>My Profile</h6>
          <div className={styles.optionsContainer}>
            {listOptions(profileOptions)}
          </div>
        </div>
        <div>
          <h6 className={styles.sectionHeader}>My Business</h6>
          <div className={styles.optionsContainer}>
            {listOptions(businessOptions)}
          </div>
        </div>
        {/* <div className="account-menu__account-container">
          <h6 className={styles.sectionHeader}>
            Notifications/Messaging
          </h6>
          <div className={styles.optionsContainer}>
            {listOptions(notificationOptions)}
          </div>
        </div> */}
        <TextLink
          className={styles.logOut}
          text="Log Out"
          textColor="leather"
          onClick={() => {
            logOut();
            closeMenu();
          }}
        />
      </div>
    </div>
  );

  return (
    <SideMenu
      rightSide
      menuHeader={accountHeader()}
      menuContent={accountMenu()}
      closeMenuAction={() => closeMenu()}
      modalClassName={styles.sideMenuModal}
      buttonContainerClassName={styles.modalButtonContainer}
    />
  );
}

export default AccountMenu;
