import React from 'react';

export default function ZendeskChatWidget() {
  return (
    <script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=404b0b77-f86a-419e-85a7-9bac8e975779"
    />
  );
}
