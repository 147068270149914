import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Credentials {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

interface AccountState {
  teaserVideoHidden: boolean;
  stripeAccountVerified: boolean;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  businessName: string;
  accountType: 'company' | 'individual' | null;
  credentials: Credentials | null;
  closeBanner: boolean;
}

export interface LoginCredentials {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

interface AccountDetails {
  businessName: string;
  accountType: 'company' | 'individual' | null;
}

const initialState: AccountState = {
  credentials: null,
  teaserVideoHidden: false,
  stripeAccountVerified: false,
  businessName: '',
  accountType: null,
  loading: 'idle',
  closeBanner: false, // this is for hiding Banner when the Cancel button is clicked
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    hideTeaserVideo: (state) => {
      state.teaserVideoHidden = true;
    },
    showTeaserVideo: (state) => {
      state.teaserVideoHidden = false;
    },
    hideBanner: (state) => {
      state.closeBanner = true;
    },
    setBusinessName: (state, action: PayloadAction<string>) => {
      state.businessName = action.payload;
    },
    setAccountInfo: (state, action: PayloadAction<AccountDetails>) => {
      state.businessName = action.payload.businessName;
      state.accountType = action.payload.accountType;
    },
    setStripeAccountVerified: (state, action: PayloadAction<boolean>) => {
      state.stripeAccountVerified = action.payload;
    },
    clearAccountRelatedInformation: (state) => {
      state.stripeAccountVerified = false;
      state.businessName = '';
      state.accountType = null;
    },
    clearLoginCredentials: (state) => {
      state.credentials = null;
    },
    tokenReceived: (state, action: PayloadAction<LoginCredentials>) => {
      if (state.credentials) {
        state.credentials.accessToken = action.payload.accessToken;
        state.credentials.refreshToken = action.payload.refreshToken;
      }
    },
    setLoginCredentials: (state, action: PayloadAction<LoginCredentials>) => {
      state.credentials = {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId,
      };
    },
  },
});

export const selectIsLoggedIn = (state: AccountState) =>
  state.credentials !== null;
export const selectUserId = (state: AccountState) => state.credentials?.userId;

export const {
  hideTeaserVideo,
  showTeaserVideo,
  setStripeAccountVerified,
  setLoginCredentials,
  clearLoginCredentials,
  tokenReceived,
  hideBanner,
  setAccountInfo,
  setBusinessName,
  clearAccountRelatedInformation,
} = accountSlice.actions;
export default accountSlice.reducer;
