import React from 'react';
import Grid from '@mui/material/Grid';
import styles from './card.module.scss';

interface Props {
  children?: JSX.Element;
  halfCard?: boolean; // should the card be half the width of a full card, default is false
}

function Card({ children, halfCard = false }: Props) {
  const halfCardClassName = halfCard && styles.halfCard;

  return (
    <Grid
      item
      xs={12}
      sm={halfCard ? 11.5 : 10}
      md={halfCard ? 11.5 : 8}
      container
      className={`${styles.cardContainer} ${halfCardClassName}`}
    >
      {children}
    </Grid>
  );
}

export default Card;
