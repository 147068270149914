import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Order, usePostOrdersByOrderIdRejectMutation } from 'api';
import { useApiResponseMessage, useCreateZendeskRequest } from 'hooks';
import Loading from 'components/Loading';
import TextArea from 'components/TextArea';
import { Button, TextLink } from 'components/Buttons';
import StandardModal from 'components/StandardModal';
import { COLORS } from 'style/colors';
import styles from './rejectOrderModal.module.scss';

type Props = {
  order: Order;
  onClose: () => any;
  onSuccessfulSubmit: () => any;
};

export default function RejectOrderModal({
  order,
  onClose,
  onSuccessfulSubmit,
}: Props) {
  const navigate = useNavigate();

  const { submitRequest } = useCreateZendeskRequest();
  const { apiResponseMessage } = useApiResponseMessage();
  const [rejectOrder] = usePostOrdersByOrderIdRejectMutation();
  const { number: orderNumber, buyer, listing, id: orderId } = order;

  const [rejectionReason, setRejectionReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitDisabled = rejectionReason.length < 5;

  const handleReject = async () => {
    try {
      const payload = await rejectOrder({
        orderId,
      }).unwrap();

      if (payload.success) {
        apiResponseMessage('You have rejected the order.');

        onSuccessfulSubmit();
      }
    } catch (error) {
      setIsLoading(false);

      apiResponseMessage('There was an error updating the order.');
    }
  };

  const submitInquiry = async () => {
    try {
      setIsLoading(true);

      const ticketInfo = {
        ticketFormId: 16605537296027,
        requester: {
          name: buyer?.name || 'Unknown',
          email: buyer?.email || 'Unknown',
        },
        subject: `Reject Order ${orderNumber}`,
        commentBody: `Order ${orderNumber} has been rejected by the buyer. The reason for rejection is: ${rejectionReason}`,
        customFields: [
          {
            id: 11919210732827, // order number
            value: orderNumber,
          },
          {
            id: 14922639632027, // listing title
            value: `${listing?.number} ${listing?.productName}`,
          },
          {
            id: 16605486064411, // rejection reason
            value: rejectionReason,
          },
          {
            id: 14994790904603, // buyer name
            value: buyer?.name,
          },
          {
            id: 14994824448283, // buyer email
            value: buyer?.email,
          },
          {
            id: 14994762304795, // buyer phone
            value: buyer?.phone,
          },
        ],
      };

      const response = await submitRequest(ticketInfo);

      if (response.status === 201) {
        handleReject();
      }
    } catch (error) {
      setIsLoading(false);

      apiResponseMessage(
        'There was an error rejecting the order. Please try again or reach out to support if the problem persists.',
        6000,
      );
    }
  };

  const rejectionForm = (
    <div>
      <div className={styles.header}>
        Open a <br /> Dispute
      </div>
      <div className={styles.contentWrapper}>
        {isLoading && <Loading screenOverlay />}
        <div className={styles.subheaderText}>
          Sorry that something&apos;s gone wrong. Let us know what the problem
          is and we will reach out to you about next steps.
        </div>
        <TextArea
          label="Reason for rejecting*"
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
        <div className={styles.buttonContainer}>
          <Button
            rippleClassName={styles.rejectButton}
            buttonClassName={styles.rejectButton}
            text="REJECT ORDER"
            onClick={submitInquiry}
            disabled={submitDisabled}
            buttonColor="red"
          />
          <div className={styles.cancelButtonWrapper}>
            <TextLink
              className={styles.cancelButton}
              text="CANCEL"
              onClick={onClose}
            />
          </div>
          <div className={styles.contactUsWrapper}>
            <TextLink
              className={styles.contactUsLink}
              text="Contact us if you have questions."
              onClick={() => navigate('/help')}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <StandardModal
      modalContent={rejectionForm}
      isOpen
      closeModalAction={onClose}
      fullScreenModal={false}
      closeIconColor={COLORS.white}
      modalClassName={styles.modal}
      contentWrapperClassName={styles.modalContentWrapper}
      buttonContainerClassName={styles.modalButtonContainer}
    />
  );
}
