import React from 'react';
import classNames from 'classnames';
import { ITypography } from './types';
import styles from './typography.module.scss';

function SpecializedCaption({ children, bold }: ITypography) {
  const boldClass = bold && styles.bold;

  return (
    <span className={classNames(styles.specializedCaption, boldClass)}>
      {children}
    </span>
  );
}

export default SpecializedCaption;
