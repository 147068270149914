import React, { ChangeEventHandler } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setKeywords, clearAllFilters } from 'slices/shoppingSlice';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import styles from './searchInput.module.scss';

type Props = {
  className?: string;
  inputClassName?: string;
  value?: string | undefined;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  headerSearch?: boolean; // default is false
};

export function SearchInput({
  className,
  inputClassName,
  placeholder,
  onChange,
  value,
  headerSearch = false,
  ...props
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    if (headerSearch) {
      // if the user uses the header search bar, clear any filters
      dispatch(clearAllFilters());
    }
    dispatch(setKeywords(value || ''));
    navigate('/shopping/search_results');
  };

  return (
    <div className={`${className} ${styles.wrapper}`}>
      <input
        className={`${inputClassName} ${styles.input}`}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick();
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <SearchIcon className={styles.icon} onClick={handleClick} />
    </div>
  );
}

export default SearchInput;
