import React, { useState, useEffect, useCallback } from 'react';
import { Address } from 'api';
import { useIdentity } from 'hooks';
import { Box } from 'parts';
import Dropdown from 'components/Dropdown';
import TextArea from 'components/TextArea';
import { isPostalCode } from 'validator';
import { Button, TextLink } from 'components/Buttons';
import TextInput from 'components/TextInput';
import TooltipWrapper from 'components/TooltipWrapper';
import StateDropdown from 'components/FormComponents/StateDropdown';
import FormValidationWrapper from 'components/FormValidationWrapper';
import addressTypes from 'constants/addressTypes';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import styles from './accountAddressForm.module.scss';

type DropdownOption = {
  label: string;
  value: string;
};

type PhoneProps = {
  savedPhoneNumber: string | null; // the phone number saved in the user's account, used to undo changes
  phoneNumber: string | null; // the phone number state in the parent component
  setPhoneNumber: (data: string) => void; // set the phone number state in the parent component
};

type Props = {
  shippingAddress: Address | any;
  saveAddress: (data: any) => void; // save the address to the parent component
  shippingType: 'shipping' | 'delivery' | 'userAddress'; // for the header of the bottom section 'Shipping Details' or 'Delivery Details'
  isValidAddress: boolean;
  setIsValidAddress: (data: boolean) => void;
  setHasUnsavedChanges?: (data: boolean) => void;
  dropdownLabel?: string; // default is 'Saved Addresses'
  headerText?: string; // used if there is a header above the address dropdown
  showSaveButton?: boolean; // used in create listing
  prepopulateAddress?: boolean; // default is true, but at checkout we don't want to prepopulate the address
  disableSavedInputs?: boolean; // default is true
  // since the phone number isn't attached to the Address, we need to handle it separately. Used in Account > Business Details
  phoneProps?: PhoneProps | null;
};

const defaultInvalidInputs = {
  name: false,
  address: false,
  city: false,
  state: false,
  zip: false,
  phone: false,
  type: false,
};

function AccountAddressForm({
  shippingAddress,
  saveAddress,
  isValidAddress,
  setIsValidAddress,
  setHasUnsavedChanges,
  dropdownLabel = 'Saved Addresses',
  headerText,
  shippingType,
  showSaveButton = false,
  prepopulateAddress = true,
  disableSavedInputs = true,
  phoneProps,
}: Props) {
  // if this form is being used in Account > Business Details, hide delivery instructions
  const isUserAddress = shippingType === 'userAddress';
  const { savedPhoneNumber, phoneNumber, setPhoneNumber } = phoneProps || {};
  // unformat the phone number for comparing changes
  const unformattedPhone = (phone: string) => phone.replace(/[^\d]/g, '');
  // should the name input label be 'Name' or 'Company'
  const { userAccountType } = useIdentity();
  const isIndividual = userAccountType === 'individual';

  const [name, setName] = useState<string | null>();
  const [address, setAddress] = useState<string | null>();
  const [address2, setAddress2] = useState<string | null>();
  const [city, setCity] = useState<string | null>();
  const [state, setState] = useState<string | null>();
  const [zip, setZip] = useState<string | null>();
  const [type, setType] = useState<DropdownOption | null>();
  const [instructions, setDeliveryInstructions] = useState<string | null>();

  const isValidZipCode = zip && isPostalCode(zip, 'US');
  // since the phone input has it's own validation, we use this to track if it's invalid here
  const [invalidPhone, setInvalidPhone] = useState(false);
  // used for determining if the Save button should be disabled
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  // for form validation
  const requiredFieldText = 'This field is required';
  const [invalidInputsOnBlur, setInvalidInputsOnBlur] =
    useState(defaultInvalidInputs);

  const updateInvalidPhone = () => {
    // if the invalidInputsOnBlur.phone is true, we need to set invalidPhone to true
    if (!invalidPhone && invalidInputsOnBlur.phone) {
      setInvalidPhone(true);
    }
  };
  useEffect(updateInvalidPhone, [invalidPhone, invalidInputsOnBlur.phone]);

  const requiredInputs = [
    { name: !name },
    { address: !address },
    { city: !city },
    { state: !state },
    { zip: !isValidZipCode },
    { type: !type },
    ...(phoneProps ? [{ phone: !phoneNumber || invalidPhone }] : []),
  ];

  const addressOption = (addressObj: Address) => {
    const label = `${addressObj.address} ${addressObj?.address2 || ''}`;
    const addCountry = {
      ...addressObj,
      country: 'US',
    };
    const value = JSON.stringify(addCountry);

    return { label, value };
  };

  const [shippingAddressOption, setShippingAddressOption] =
    useState<DropdownOption>(addressOption(shippingAddress));

  const [selectedAddressOption, setSelectedAddressOption] =
    useState<DropdownOption>();

  const isNewAddress = selectedAddressOption?.value === 'newAddress';

  useEffect(() => {
    if (prepopulateAddress && !selectedAddressOption) {
      setSelectedAddressOption(shippingAddressOption);
    }
  }, [prepopulateAddress, selectedAddressOption, shippingAddressOption]);

  const [hideForm, setHideForm] = useState(!prepopulateAddress);

  const showFormOnNewAddress = () => {
    if (hideForm && selectedAddressOption?.value === 'newAddress') {
      setHideForm(false);
    }
  };

  useEffect(showFormOnNewAddress, [selectedAddressOption, hideForm]);

  const [shippingOptions, setShippingOptions] = useState<DropdownOption[]>([
    { label: 'New Address', value: 'newAddress' },
    shippingAddressOption,
  ]);

  const updateStateWithSavedAddress = useCallback((savedAddress: Address) => {
    const option = addressOption(savedAddress);
    setShippingAddressOption(option);

    setName(savedAddress.name);
    setAddress(savedAddress.address);
    setAddress2(savedAddress.address2);
    setCity(savedAddress.city);
    setState(savedAddress.state);
    setZip(savedAddress.zip);
    setDeliveryInstructions(savedAddress?.instructions);

    const savedType = savedAddress.type
      ? addressTypes.find(
          (addressType) => addressType.value === savedAddress.type,
        )
      : undefined;
    setType(savedType);
  }, []);

  useEffect(() => {
    if (prepopulateAddress && !selectedAddressOption) {
      updateStateWithSavedAddress(shippingAddress);
    }
  }, [
    shippingAddress,
    prepopulateAddress,
    selectedAddressOption,
    updateStateWithSavedAddress,
  ]);

  const clearAddress = () => {
    setName(null);
    setAddress(null);
    setAddress2(null);
    setCity(null);
    setState(null);
    setZip(null);
    setType(null);
    setDeliveryInstructions(null);
  };

  const [lastSavedAddress, setLastSavedAddress] =
    useState<Address>(shippingAddress);

  const handleSaveAddress = useCallback(() => {
    const createNewAddressOptionOnSave = (newAddressObj: Address) => {
      const newAddressOption = addressOption(newAddressObj);

      // add the new address as an option in the dropdown if it doesn't already exist
      // else update the existing address option
      if (isNewAddress) {
        setSelectedAddressOption(newAddressOption);
        setShippingOptions([...shippingOptions, newAddressOption]);
      } else {
        const selectedAddressOptionIndex = shippingOptions.findIndex(
          (option) => option.value === selectedAddressOption?.value,
        );

        const shippingOptionsCopy = [...shippingOptions];
        shippingOptionsCopy[selectedAddressOptionIndex] = newAddressOption;
        setSelectedAddressOption(newAddressOption);
        setShippingOptions(shippingOptionsCopy);
      }
    };

    // if statement is for making TS happy for createNewAddressOptionOnSave
    if (!name || !address || !city || !state || !zip) {
      return;
    }

    const newAddress = {
      name,
      address,
      ...(address2 && { address2 }),
      city,
      state,
      zip,
      country: 'US',
      type: type?.value,
      ...(instructions && { instructions }),
    };

    const addressChanged =
      JSON.stringify(newAddress) !== JSON.stringify(lastSavedAddress);

    const phoneChanged =
      phoneNumber &&
      savedPhoneNumber &&
      unformattedPhone(phoneNumber) !== unformattedPhone(savedPhoneNumber);

    if (addressChanged || phoneChanged) {
      saveAddress(newAddress);
    }

    setLastSavedAddress(newAddress);
    createNewAddressOptionOnSave(newAddress);
    setUnsavedChanges(false);
  }, [
    name,
    address,
    address2,
    city,
    state,
    zip,
    type,
    instructions,
    phoneNumber,
    savedPhoneNumber,
    isNewAddress,
    selectedAddressOption,
    shippingOptions,
    lastSavedAddress,
    saveAddress,
    setUnsavedChanges,
    setLastSavedAddress,
  ]);

  useEffect(() => {
    // only 'auto-save' if there isn't a save button
    if (!showSaveButton && name && address && city && state && zip) {
      handleSaveAddress();
    }
  }, [
    name,
    address,
    address2,
    city,
    state,
    zip,
    type,
    instructions,
    savedPhoneNumber,
    shippingOptions,
    showSaveButton,
    handleSaveAddress,
  ]);

  const handleSelectedAddressChange = (selectedOption: DropdownOption) => {
    if (selectedOption && selectedOption.value !== 'newAddress') {
      const selectedAddress = JSON.parse(selectedOption.value);
      updateStateWithSavedAddress(selectedAddress);

      if (setPhoneNumber && savedPhoneNumber) {
        setPhoneNumber(savedPhoneNumber);
      }
      // if the user selects a saved address, highlight any missing required fields
      setInvalidInputsOnBlur({
        name: !selectedAddress.name,
        address: !selectedAddress.address,
        city: !selectedAddress.city,
        state: !selectedAddress.state,
        zip: !selectedAddress.zip,
        type: !selectedAddress.type,
        phone: invalidPhone,
      });
    } else {
      clearAddress();
      setUnsavedChanges(false);
      setInvalidInputsOnBlur(defaultInvalidInputs);
      if (setPhoneNumber) {
        setPhoneNumber('');
      }
    }
  };

  const allRequiredFieldsFilled = useCallback(() => {
    const phoneValidation = phoneProps ? !invalidPhone : true;

    if (
      name &&
      address &&
      city &&
      state &&
      isValidZipCode &&
      type &&
      phoneValidation
    ) {
      return true;
    }
    return false;
  }, [
    name,
    address,
    city,
    state,
    type,
    isValidZipCode,
    invalidPhone,
    phoneProps,
  ]);

  const validAddress = () => {
    if (allRequiredFieldsFilled()) {
      setIsValidAddress(true);
    } else if (isValidAddress) {
      setIsValidAddress(false);
    }
  };

  useEffect(validAddress, [
    name,
    address,
    city,
    state,
    zip,
    type,
    phoneNumber,
    invalidPhone,
    isValidAddress,
    setIsValidAddress,
    allRequiredFieldsFilled,
  ]);

  const handleCancelChanges = () => {
    const lastSavedAddressIndex = shippingOptions.findIndex(
      (option) => option.value === JSON.stringify(lastSavedAddress),
    );
    setSelectedAddressOption(shippingOptions[lastSavedAddressIndex]);

    setName(lastSavedAddress.name);
    setAddress(lastSavedAddress.address);
    setAddress2(lastSavedAddress.address2);
    setCity(lastSavedAddress.city);
    setState(lastSavedAddress.state);
    setZip(lastSavedAddress.zip);
    setDeliveryInstructions(lastSavedAddress?.instructions);

    const savedType = lastSavedAddress.type
      ? addressTypes.find(
          (addressType) => addressType.value === lastSavedAddress.type,
        )
      : undefined;
    setType(savedType);

    setInvalidInputsOnBlur({
      name: !lastSavedAddress.name,
      address: !lastSavedAddress.address,
      city: !lastSavedAddress.city,
      state: !lastSavedAddress.state,
      zip: !lastSavedAddress.zip,
      type: !lastSavedAddress.type,
      phone: invalidPhone,
    });

    // reset the phone number to the saved number if the user cancels changes
    if (setPhoneNumber && savedPhoneNumber) {
      setPhoneNumber(savedPhoneNumber);
    }
    setUnsavedChanges(false);
  };

  const onChangeWithSaveButton = () => {
    if (showSaveButton && selectedAddressOption?.value) {
      if (!isNewAddress) {
        // check to see if the user selected a different saved address
        const selectedDifferentAddress =
          lastSavedAddress &&
          selectedAddressOption?.value !== JSON.stringify(lastSavedAddress);

        // I decided to manually check for address changes since undefined values or missing country messes up the comparison
        const selectedObj = JSON.parse(selectedAddressOption.value);

        let unsavedAddressChanges = false;

        const unsavedInstructions =
          instructions &&
          selectedObj?.instructions &&
          instructions !== selectedObj.instructions;

        const unsavedAddressLine2 =
          address2 &&
          selectedObj?.address2 &&
          address2 !== selectedObj.address2;

        if (
          selectedObj.name !== name ||
          selectedObj.address !== address ||
          unsavedAddressLine2 ||
          selectedObj.city !== city ||
          selectedObj.state !== state ||
          selectedObj.zip !== zip ||
          selectedObj.type !== type?.value ||
          unsavedInstructions ||
          selectedDifferentAddress
        ) {
          unsavedAddressChanges = true;
        } else {
          unsavedAddressChanges = false;
        }

        const unsavedPhoneChanges =
          phoneNumber &&
          savedPhoneNumber &&
          unformattedPhone(phoneNumber) !== unformattedPhone(savedPhoneNumber);

        if (!unsavedChanges && (unsavedAddressChanges || unsavedPhoneChanges)) {
          setUnsavedChanges(true);
        } else if (
          unsavedChanges &&
          !unsavedAddressChanges &&
          !unsavedPhoneChanges
        ) {
          setUnsavedChanges(false);
        }
      } else if (!unsavedChanges) {
        setUnsavedChanges(true);
      }
    }
  };

  useEffect(onChangeWithSaveButton, [
    name,
    address,
    address2,
    city,
    state,
    zip,
    type,
    instructions,
    savedPhoneNumber,
    showSaveButton,
    unsavedChanges,
    selectedAddressOption,
    phoneNumber,
    shippingAddress,
    isNewAddress,
    lastSavedAddress,
  ]);

  useEffect(() => {
    // send unsavedChanges to parent component
    if (setHasUnsavedChanges) {
      setHasUnsavedChanges(unsavedChanges);
    }
  }, [unsavedChanges, setHasUnsavedChanges]);

  const disableInput = disableSavedInputs && !isNewAddress;

  const saveButton = () => {
    const fields = [
      { isMissing: !name, name: 'Company/Name' },
      { isMissing: !address, name: 'Address' },
      { isMissing: !city, name: 'City' },
      { isMissing: !state, name: 'State' },
      { isMissing: !zip, name: 'Zip' },
      { isMissing: !type, name: 'Type of Address' },
      { isMissing: phoneProps ? !phoneNumber : false, name: 'Phone Number' },
    ];
    const missingInfo: string[] = [];
    fields.forEach((field) => {
      if (field.isMissing) {
        missingInfo.push(field.name);
      }
    });

    const invalidInfo: string[] = [];
    const invalidFields = [
      { isInvalid: !isValidZipCode, name: 'Zip' },
      { isInvalid: phoneProps ? invalidPhone : false, name: 'Phone Number' },
    ];
    invalidFields.forEach((field) => {
      if (field.isInvalid) {
        invalidInfo.push(field.name);
      }
    });

    const saveButtonDisabled =
      !unsavedChanges ||
      !isValidAddress ||
      missingInfo.length > 0 ||
      invalidInfo.length > 0;

    const missingInfoText = `The following information is required to continue: ${missingInfo.join(
      ', ',
    )}`;
    const invalidInfoText = `The following information is invalid: ${invalidInfo.join(
      ', ',
    )}`;

    let tooltip: string | JSX.Element = '';
    if (missingInfo.length > 0 && invalidInfo.length > 0) {
      tooltip = (
        <span style={{ whiteSpace: 'pre-line' }}>
          {`${missingInfoText} \n \n  ${invalidInfoText}`}
        </span>
      );
    } else if (invalidInfo.length > 0) {
      tooltip = invalidInfoText;
    } else if (missingInfo.length > 0) {
      tooltip = missingInfoText;
    }

    return (
      <div className={styles.bottomButtonsWrapper}>
        <TextLink
          text="CANCEL"
          onClick={handleCancelChanges}
          disabled={!unsavedChanges}
          className={styles.cancelButton}
        />
        <TooltipWrapper tooltipText={tooltip}>
          <span>
            <Button
              onClick={handleSaveAddress}
              text="Save"
              buttonColor="black"
              roundedButton
              disabled={saveButtonDisabled}
              containerClassName={styles.saveButton}
            />
          </span>
        </TooltipWrapper>
      </div>
    );
  };

  const addressDetails = () => {
    const typeOfAddressDropdown = () => (
      <Box mb={9}>
        <Dropdown
          label="Type of Address*"
          options={addressTypes}
          value={type || ''}
          onChange={(option: DropdownOption) => {
            setType(option);
            if (invalidInputsOnBlur.type && option) {
              setInvalidInputsOnBlur({
                ...invalidInputsOnBlur,
                type: false,
              });
            }
          }}
          dropdownType="form"
          assistiveText="What type of location is this?"
          invalidUserInput={invalidInputsOnBlur.type}
          key={`address_type_key__${JSON.stringify(type)}`} // This will force Select to re-render itself when the selection is updated
        />
      </Box>
    );

    if (isUserAddress) {
      return typeOfAddressDropdown();
    }

    return (
      <div className={styles.addressTypeSection}>
        <div className={styles.headerText}>{shippingType} Details</div>
        {typeOfAddressDropdown()}
        <Box mb={9}>
          <TextArea
            label="Delivery Instructions"
            value={instructions || ''}
            onChange={(e) => setDeliveryInstructions(e.target.value)}
          />
        </Box>
      </div>
    );
  };

  const dropdownClassName = !hideForm && styles.backgroundColor;
  const dropdownBorderRadiusClassName =
    isUserAddress && styles.dropdownWrapperBorderRadius;
  const showAddressInputs = isNewAddress || isUserAddress;
  // if we are on the Account > Business Details screen & the user is an individual, hide the name input
  const hideNameInput = isUserAddress && isIndividual;
  let showHideFormText = hideForm ? 'Show details' : 'Hide details';
  if (!isValidAddress) showHideFormText = '';

  const zipCodeAssistiveText = () => {
    if (invalidInputsOnBlur.zip) {
      if (!isValidZipCode) {
        return 'Invalid zip code';
      }
      return requiredFieldText;
    }
    return '';
  };

  const phoneNumberAssistiveText = () => {
    if (invalidInputsOnBlur.phone) {
      if (!phoneNumber) {
        return requiredFieldText;
      }
      return 'Invalid phone number';
    }
    return '';
  };

  return (
    <>
      <FormValidationWrapper
        wrapperId="address-form"
        requiredInputs={requiredInputs}
        defaultInvalidInputs={defaultInvalidInputs}
        formIsValid={allRequiredFieldsFilled()}
        invalidInputsOnBlur={invalidInputsOnBlur}
        setInvalidInputsOnBlur={setInvalidInputsOnBlur}
      >
        <div
          className={`${styles.dropdownWrapper} ${dropdownClassName} ${dropdownBorderRadiusClassName}`}
        >
          {headerText && <div className={styles.headerText}>{headerText}</div>}
          <Dropdown
            label={selectedAddressOption ? dropdownLabel : ''}
            placeholder="Select an address"
            options={shippingOptions}
            value={selectedAddressOption}
            onChange={(option: DropdownOption) => {
              setSelectedAddressOption(option);
              handleSelectedAddressChange(option);
            }}
            dropdownType="form"
            assistiveText={isUserAddress ? '' : showHideFormText}
            assistiveTextOnClick={() => setHideForm(!hideForm)}
          />
        </div>
        {!hideForm && (
          <div className={styles.inputsWrapper}>
            <form autoComplete="on">
              {!hideNameInput && (
                <Box mb={9}>
                  <TextInput
                    value={name}
                    name="name"
                    disabled={disableInput && !invalidInputsOnBlur.name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (invalidInputsOnBlur.name && e.target.value) {
                        setInvalidInputsOnBlur({
                          ...invalidInputsOnBlur,
                          name: false,
                        });
                      }
                    }}
                    label={isIndividual ? 'Name*' : 'Company*'}
                    invalidUserInput={invalidInputsOnBlur.name}
                    assistiveText={
                      invalidInputsOnBlur.name ? requiredFieldText : ''
                    }
                  />
                </Box>
              )}
              {showAddressInputs && (
                <>
                  <Box mb={9}>
                    <TextInput
                      name="address"
                      label="Address*"
                      value={address}
                      disabled={disableInput && !invalidInputsOnBlur.address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        if (invalidInputsOnBlur.address && e.target.value) {
                          setInvalidInputsOnBlur({
                            ...invalidInputsOnBlur,
                            address: false,
                          });
                        }
                      }}
                      invalidUserInput={invalidInputsOnBlur.address}
                      assistiveText={
                        invalidInputsOnBlur.address ? requiredFieldText : ''
                      }
                    />
                  </Box>
                  <Box mb={9}>
                    <TextInput
                      value={address2}
                      disabled={disableInput}
                      onChange={(e) => setAddress2(e.target.value)}
                      name="address2"
                      label="Address Line 2"
                    />
                  </Box>
                </>
              )}
              <Box mb={9}>
                <TextInput
                  name="city"
                  value={city}
                  disabled={disableInput && !invalidInputsOnBlur.city}
                  onChange={(e) => {
                    setCity(e.target.value);
                    if (invalidInputsOnBlur.city && e.target.value) {
                      setInvalidInputsOnBlur({
                        ...invalidInputsOnBlur,
                        city: false,
                      });
                    }
                  }}
                  label="City*"
                  invalidUserInput={city === '' || invalidInputsOnBlur.city}
                  assistiveText={
                    invalidInputsOnBlur.city ? requiredFieldText : ''
                  }
                />
              </Box>
              <Box mb={9} display="flex" justifyContent="space-between">
                <Box width="30%" mr={2} minWidth="110px">
                  <StateDropdown
                    value={state || ''}
                    disabled={disableInput && !invalidInputsOnBlur.state}
                    onChange={(value) => {
                      setState(value.value);
                      if (invalidInputsOnBlur.state && value.value) {
                        setInvalidInputsOnBlur({
                          ...invalidInputsOnBlur,
                          state: false,
                        });
                      }
                    }}
                    showAsterisk
                    invalidUserInput={invalidInputsOnBlur.state}
                    assistiveText={
                      invalidInputsOnBlur.state ? requiredFieldText : ''
                    }
                  />
                </Box>
                <Box width="70%">
                  <TextInput
                    name="zip"
                    value={zip}
                    disabled={disableInput && !invalidInputsOnBlur.zip}
                    onChange={(e) => {
                      const val = e.target.value;
                      setZip(val);

                      if (invalidInputsOnBlur.zip && isPostalCode(val, 'US')) {
                        setInvalidInputsOnBlur({
                          ...invalidInputsOnBlur,
                          zip: false,
                        });
                      }
                    }}
                    label="Zip*"
                    invalidUserInput={invalidInputsOnBlur.zip}
                    assistiveText={zipCodeAssistiveText()}
                  />
                </Box>
              </Box>
              {phoneProps && (
                <Box mb={9}>
                  <PhoneInput
                    required
                    value={phoneNumber || ''}
                    label="Phone Number*"
                    onChange={(e) => {
                      if (setPhoneNumber) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    onBlurValidationOnly
                    isInvalid={invalidPhone}
                    setIsInvalid={(invalid: boolean) => {
                      setInvalidPhone(invalid);

                      if (invalidInputsOnBlur.phone && !invalid) {
                        setInvalidInputsOnBlur({
                          ...invalidInputsOnBlur,
                          phone: false,
                        });
                      }
                    }}
                    assistiveText={phoneNumberAssistiveText()}
                  />
                </Box>
              )}
            </form>
            {addressDetails()}
          </div>
        )}
      </FormValidationWrapper>
      {showSaveButton && !hideForm && saveButton()}
    </>
  );
}

export default AccountAddressForm;
