import React, { useState, useEffect } from 'react';
import { Listing, usePostCartMutation } from 'api';
import { useApiResponseMessage } from 'hooks';
import { Button, TextLink } from 'components/Buttons';
import TooltipWrapper from 'components/TooltipWrapper';
import styles from './addToCartButton.module.scss';

type Props = {
  listing: Listing;
  buttonType: 'textLink' | 'button' | 'removeButton' | 'dropdownOption';
  dropdownOptionProps?: {
    optionClassName: string;
    closeMenu: () => void;
  };
};

function AddToCartButton({ listing, buttonType, dropdownOptionProps }: Props) {
  const { apiResponseMessage } = useApiResponseMessage();

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { soldAs, listedQty, minimumOrder, id: listingId, deleted } = listing;

  const isSoldAsLot = soldAs.toLowerCase() === 'lot';
  const addToCartTooltip = deleted ? 'This item no longer exists' : '';
  const tooltipPlacement = buttonType === 'dropdownOption' ? 'left' : 'top';

  const setDefaultState = () => {
    if (isSoldAsLot) {
      setSelectedQuantity(listedQty);
    } else {
      setSelectedQuantity(minimumOrder);
    }
  };
  useEffect(setDefaultState, [isSoldAsLot, minimumOrder, listedQty]);

  const [updateCartApiCall] = usePostCartMutation();

  const updateCart = async (type: 'add' | 'remove') => {
    const addToCart = type === 'add';

    try {
      const payload: any = await updateCartApiCall({
        body: {
          quantity: addToCart ? selectedQuantity : 0,
          listingId,
        },
      }).unwrap();

      if (payload) {
        const responseMessage = addToCart
          ? 'Item added to your cart'
          : 'Item was removed from your cart';

        apiResponseMessage(responseMessage);
      }
    } catch (error: any) {
      const message =
        error.data?.message ||
        'There was an error adding this item to your cart';
      apiResponseMessage(message, 6000);
    }
  };

  const mainButton = () => {
    if (buttonType === 'button') {
      return (
        <Button
          text="ADD TO CART"
          onClick={() => updateCart('add')}
          buttonType="text"
          textColor="sand20"
          disabled={deleted}
        />
      );
    } else if (buttonType === 'removeButton') {
      return (
        <Button
          text="REMOVE"
          onClick={() => updateCart('remove')}
          buttonType="text"
          textColor="sand20"
        />
      );
    } else if (buttonType === 'dropdownOption' && dropdownOptionProps) {
      const { optionClassName, closeMenu } = dropdownOptionProps;

      return (
        <a
          className={optionClassName}
          onClick={(e) => {
            e.stopPropagation();
            updateCart('add');
            closeMenu();
          }}
        >
          Add to Cart
        </a>
      );
    }

    return (
      <TextLink
        className={styles.textLink}
        textColor="sand20"
        text="ADD TO CART"
        onClick={() => updateCart('add')}
        disabled={deleted}
      />
    );
  };

  return (
    <TooltipWrapper tooltipText={addToCartTooltip} placement={tooltipPlacement}>
      <span>{mainButton()}</span>
    </TooltipWrapper>
  );
}

export default AddToCartButton;
