import React, { useState, useEffect, useMemo } from 'react';
import categoryIndex from 'constants/categories.json';
import Dropdown from 'components/Dropdown/Dropdown';
import FormContainer from 'components/FormContainer/FormContainer';
import SubcategoryDropdown from './SubcategoryDropdown';
import styles from './category.module.scss';
import {
  Category as CategoryType,
  FieldValidation,
  FieldValidationBaseProps,
} from '../types';

type SubcategoryObj = {
  number: string;
  name: string;
};

type CategoryObj = {
  name: string;
  subcategories: SubcategoryObj[];
};

type CategoryDropdownOption = {
  label: string;
  value: string;
};

type DropdownOption = {
  label: string;
  value: string;
  individualCategoryNumber: string | null; // null is used for users input
  fullCategoryNumber: string | null; // null is used for users input
  __isNew__?: boolean; // returned from react-select on users input
};

interface FieldValidationProps extends FieldValidationBaseProps {
  setCategoryFieldsValidation: (fields: FieldValidation[]) => void;
}

type Props = {
  categoryInfo: CategoryType | undefined;
  setCategoryInfo: (val: CategoryType) => void;
  fieldValidationProps: FieldValidationProps;
};

const getDisplayedCategoryNumberAndName = (
  decimalCategory: DropdownOption | undefined,
  secondSubCategory: DropdownOption | undefined,
  subCategory: DropdownOption | undefined,
) => {
  let number = '';
  let name = '';

  if (decimalCategory) {
    number = decimalCategory.fullCategoryNumber || '';
    name = decimalCategory.value;
  } else if (secondSubCategory) {
    number = secondSubCategory.fullCategoryNumber || '';
    name = secondSubCategory.value;
  } else if (subCategory) {
    number = subCategory.fullCategoryNumber || '';
    name = subCategory.value;
  }

  return `${number} ${name}`;
};

function Category({
  categoryInfo,
  setCategoryInfo,
  fieldValidationProps,
}: Props) {
  const {
    validateFieldsOnClick,
    setValidateFieldsOnClick,
    setCategoryFieldsValidation,
    handleClickScroll,
  } = fieldValidationProps;
  const [selectedCategory, setSelectedCategory] = useState<CategoryObj>();

  // the category dropdown value
  const [selectedCategoryOption, setSelectedCategoryOption] =
    useState<CategoryDropdownOption>();
  // the first sub category dropdown value
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<DropdownOption>();
  // the second sub category dropdown value
  const [selectedSecondSubCategory, setSelectedSecondSubCategory] =
    useState<DropdownOption>();
  // the decimal sub category dropdown value
  const [selectedDecimalCategory, setSelectedDecimalCategory] =
    useState<DropdownOption>();

  // the second sub category dropdown options
  const [subCategoryOptions, setSubCategoryOptions] = useState<
    DropdownOption[]
  >([]);
  // the second sub category dropdown options
  const [secondSubCategoryOptions, setSecondSubCategoryOptions] = useState<
    DropdownOption[]
  >([]);
  // the decimal sub category dropdown options
  const [decimalSubCategoryOptions, setDecimalCategoryOptions] = useState<
    DropdownOption[]
  >([]);

  // for saving the category info to the parent component
  const saveCategoryInfo = () => {
    const subCategory =
      selectedDecimalCategory ||
      selectedSecondSubCategory ||
      selectedSubCategory;

    const newSelectedCategory: CategoryType = {
      categoryName: selectedCategoryOption?.value || '',
      subCategoryName: subCategory?.value || '',
      fullCategoryNumber: subCategory?.fullCategoryNumber || '',
    };

    if (JSON.stringify(categoryInfo) !== JSON.stringify(newSelectedCategory)) {
      setCategoryInfo(newSelectedCategory);
    }
  };

  useEffect(saveCategoryInfo, [
    selectedCategoryOption,
    selectedSubCategory,
    selectedSecondSubCategory,
    selectedDecimalCategory,
    categoryInfo,
    setCategoryInfo,
  ]);

  // capitalize the first letter of each word
  const capitalizeFirstLetter = (str: string) => {
    const displayName = str
      .toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    return displayName;
  };

  // beta is only for electrical, all other categories are coming soon
  const comingSoonCategories = categoryIndex.filter(
    (categoryObj) => categoryObj.name !== 'ELECTRICAL',
  );

  const comingSoonOptions = [
    {
      label: 'More categories coming soon',
      options: comingSoonCategories.map((category) => ({
        label: capitalizeFirstLetter(category.name),
        value: category.name,
        disabled: true,
      })),
    },
  ];

  // beta is electrical only
  const betaCategory = useMemo(
    () =>
      categoryIndex.filter((categoryObj) => categoryObj.name === 'ELECTRICAL'),
    [],
  );

  const categoryOptions = useMemo(
    () =>
      betaCategory.map((categoryObj) => ({
        label: capitalizeFirstLetter(categoryObj.name),
        value: categoryObj.name,
      })),
    [betaCategory],
  );

  // Category Numbering: "02 30 40.45" : 02 = category, 30 = subcategory, 40 = second subcategory, 45 = decimal category
  const getCategoryNumbers = (option: DropdownOption) => {
    // get array of each category/subcategory numbers
    const selectedOptionCategoryNumbers =
      option.fullCategoryNumber?.split(' ') || [];

    const [categoryNumber, subCategoryNumber, secondSubCategoryNum] =
      selectedOptionCategoryNumbers;

    // check if the second subcategory has a decimal
    const containsDecimal = secondSubCategoryNum.includes('.');
    // if so, then remove the decimal from the second subcat number
    const secondSubCategoryNumber: string = containsDecimal
      ? secondSubCategoryNum.split('.')[0]
      : secondSubCategoryNum;

    const decimalCategoryNumber: string = secondSubCategoryNum.split('.')[1];

    return {
      categoryNumber,
      subCategoryNumber,
      secondSubCategoryNumber,
      containsDecimal,
      decimalCategoryNumber,
    };
  };

  // create subcat options from the selected category
  const getSubCategoryOptions = (selectedOption: CategoryObj) => {
    const options: DropdownOption[] = selectedOption.subcategories.map(
      (subCatObj: SubcategoryObj) => {
        const spacedNumbers = subCatObj.number.replace(/\d{2}(?!$)/g, '$& ');
        const categoryNumbersArr = spacedNumbers.split(' ') || [];
        const displayName = subCatObj.name
          .toLowerCase()
          .split(' ')
          .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');

        return {
          label: `${displayName} \n ${spacedNumbers}`,
          value: displayName,
          individualCategoryNumber: categoryNumbersArr[1],
          fullCategoryNumber: spacedNumbers,
        };
      },
    );

    setSubCategoryOptions(options);

    return options;
  };

  // create second subcat options from the selected subcategory
  const getSecondSubCategoryOptions = (selectedOption: any) => {
    const options: DropdownOption[] = [];
    const selectedOptionCategoryNumbers =
      selectedOption.fullCategoryNumber.split(' ');

    subCategoryOptions.forEach((subCategoryOption) => {
      const { categoryNumber, subCategoryNumber, secondSubCategoryNumber } =
        getCategoryNumbers(subCategoryOption);

      // only return second subcats that share the same category & subcategory
      if (
        categoryNumber === selectedOptionCategoryNumbers[0] &&
        subCategoryNumber === selectedOptionCategoryNumbers[1]
      ) {
        options.push({
          ...subCategoryOption,
          individualCategoryNumber: secondSubCategoryNumber,
        });
      }
    });

    setSecondSubCategoryOptions(options);
    return options;
  };

  // create decimal subcat options from the selected second subcategory
  const getDecimalSubCategoryOptions = (selectedOption: any) => {
    const options: DropdownOption[] = [];

    const selectedOptionCategoryNumbers =
      selectedOption.fullCategoryNumber.split(' ');
    const selectedSecondSubCatNumber =
      selectedOptionCategoryNumbers[2].split('.')[0];

    subCategoryOptions.forEach((subCategoryOption) => {
      const {
        categoryNumber,
        subCategoryNumber,
        secondSubCategoryNumber,
        containsDecimal,
        decimalCategoryNumber,
      } = getCategoryNumbers(subCategoryOption);

      // only return decimal subcats that share the same category, subcategory, and second subcategory
      if (
        containsDecimal &&
        categoryNumber === selectedOptionCategoryNumbers[0] &&
        subCategoryNumber === selectedOptionCategoryNumbers[1] &&
        secondSubCategoryNumber === selectedSecondSubCatNumber
      ) {
        options.push({
          ...subCategoryOption,
          individualCategoryNumber: decimalCategoryNumber,
        });
      }
    });

    setDecimalCategoryOptions(options);
    return options;
  };

  const handleChangeCategory = (option: CategoryDropdownOption) => {
    const catObj = categoryIndex.find((obj: any) => obj.name === option.value);

    if (catObj) {
      getSubCategoryOptions(catObj);
      setSelectedCategory(catObj);
      setSelectedCategoryOption(option);
      // clear all other dropdowns
      setSelectedSubCategory(undefined);
      setSelectedSecondSubCategory(undefined);
      setSelectedDecimalCategory(undefined);
    }
  };

  const handleChangeSubCategory = (option: DropdownOption) => {
    if (!option) {
      // if this input is cleared, clear all inputs below it
      setSelectedSubCategory(undefined);
      setSelectedSecondSubCategory(undefined);
      setSelectedDecimalCategory(undefined);
    }
    // eslint-disable-next-line no-underscore-dangle
    else if (option.__isNew__) {
      // if no option is found, create user's option
      const subCatObj = {
        label: `Use "${option.label}"`,
        value: `Use "${option.label}"`,
        individualCategoryNumber: null,
        fullCategoryNumber: null,
      };
      setSelectedSubCategory(subCatObj);
      setSelectedSecondSubCategory(undefined);
      setSelectedDecimalCategory(undefined);
    } else {
      const {
        categoryNumber,
        subCategoryNumber,
        secondSubCategoryNumber,
        containsDecimal,
      } = getCategoryNumbers(option);

      // find 'parent' subcategory to use for this input
      let subCatObj = subCategoryOptions.find(
        (subCategoryOption) =>
          subCategoryOption.fullCategoryNumber ===
          `${categoryNumber} ${subCategoryNumber} 00`,
      );

      // if the subcatory selected has a subcategory, find that and prepopulate it in the next subcategory dropdown
      if (secondSubCategoryNumber !== '00') {
        const secondSubCat = getSecondSubCategoryOptions(option).find(
          (subSubCat) => {
            if (containsDecimal) {
              return (
                subSubCat.fullCategoryNumber ===
                `${categoryNumber} ${subCategoryNumber} ${secondSubCategoryNumber}`
              );
            }
            return subSubCat.fullCategoryNumber === option.fullCategoryNumber;
          },
        );

        // if the "parent" doesn't exist, set this as the parent and clear any existing second subcategory
        if (!subCatObj) {
          subCatObj = secondSubCat;
          setSelectedSecondSubCategory(undefined);
        } else {
          setSelectedSecondSubCategory(secondSubCat);
        }
      } else {
        // if there isn't a second subcat, clear the previous second subcat
        setSelectedSecondSubCategory(undefined);
      }

      // if the sub-subcatory selected has a subcategory, find that and prepopulate it in the next subcategory dropdown
      if (subCatObj?.fullCategoryNumber && containsDecimal) {
        const decimalSubCat = getDecimalSubCategoryOptions(option).find(
          (decSubCat) =>
            decSubCat.fullCategoryNumber === option.fullCategoryNumber,
        );

        setSelectedDecimalCategory(decimalSubCat);
      } else {
        // if there isn't a decimal subcat, clear the previous decimal subcat
        setSelectedDecimalCategory(undefined);
      }

      setSelectedSubCategory(subCatObj);
    }
  };

  const handleChangeSecondSubCategory = (option: DropdownOption) => {
    // if this dropdown is cleared, clear all dropdowns below it
    if (!option) {
      setSelectedSecondSubCategory(undefined);
      setSelectedDecimalCategory(undefined);
    } else {
      const {
        categoryNumber,
        subCategoryNumber,
        secondSubCategoryNumber,
        containsDecimal,
      } = getCategoryNumbers(option);

      // find the existing option
      const secondSubCatObj = secondSubCategoryOptions.find(
        (subCat) =>
          subCat.fullCategoryNumber ===
          `${categoryNumber} ${subCategoryNumber} ${secondSubCategoryNumber}`,
      );

      // if the second subcatory selected has a decimal subcategory, find that and prepopulate it in the decimal subcategory dropdown
      if (secondSubCatObj?.fullCategoryNumber && containsDecimal) {
        const decimalSubCat = getDecimalSubCategoryOptions(option).find(
          (subSubCat) =>
            subSubCat.fullCategoryNumber === option.fullCategoryNumber,
        );

        setSelectedDecimalCategory(decimalSubCat);
      } else {
        // clear the decimal dropdown if the new option doesn't have a decimal
        setSelectedDecimalCategory(undefined);
      }

      setSelectedSecondSubCategory(secondSubCatObj);
    }
  };

  const handleChangeDecimalSubCategory = (option: DropdownOption) => {
    if (!option) {
      setSelectedDecimalCategory(undefined);
    } else {
      // find the existing option
      const subCatObj = decimalSubCategoryOptions.find(
        (subCat) => subCat.fullCategoryNumber === option.fullCategoryNumber,
      );

      setSelectedDecimalCategory(subCatObj);
    }
  };

  const populatePreviouslySelectedCategory = () => {
    // if the user finished this step, moved on, and has now returned, populated the previously selected category
    if (categoryInfo) {
      const { categoryName } = categoryInfo;

      const categoryOption = categoryOptions.find(
        (opt) => opt.value === categoryName,
      );

      if (categoryOption) {
        const catObj = categoryIndex.find(
          (obj: any) => obj.name === categoryOption.value,
        );
        setSelectedCategory(catObj);
        setSelectedCategoryOption(categoryOption);

        if (catObj) {
          getSubCategoryOptions(catObj);
        }
      }
    }
  };

  useEffect(populatePreviouslySelectedCategory, [
    categoryInfo,
    categoryOptions,
  ]);

  const populatePreviouslySelectedSubCategories = () => {
    // if the user finished this step, moved on, and has now returned, populated the previously selected subcategories
    if (categoryInfo?.subCategoryName) {
      const { categoryName, subCategoryName, fullCategoryNumber } =
        categoryInfo;

      const indexCategory = categoryIndex.find(
        (cat) => cat.name === categoryName,
      );

      if (indexCategory) {
        let subCategoryOption = getSubCategoryOptions(indexCategory).find(
          (option) => option.fullCategoryNumber === fullCategoryNumber,
        );

        if (!subCategoryOption) {
          // if no option is found, create user's option
          subCategoryOption = {
            __isNew__: true,
            label: subCategoryName || '',
            value: subCategoryName || '',
            individualCategoryNumber: null,
            fullCategoryNumber: null,
          };
        }

        setSelectedSubCategory(subCategoryOption);
      }
    }
  };

  useEffect(populatePreviouslySelectedSubCategories, [categoryInfo]);

  const defaultInvalidInputs = {
    category: false,
    subcategory: false,
  };
  const [invalidInputsOnClick, setInvalidInputsOnClick] =
    useState(defaultInvalidInputs);

  const fieldValidation = useMemo(
    () => [
      { displayName: 'Category', isMissing: !selectedCategory },
      { displayName: 'Subcategory', isMissing: !selectedSubCategory },
    ],
    [selectedCategory, selectedSubCategory],
  );

  useEffect(() => {
    if (validateFieldsOnClick) {
      const invalidValues = {
        category: fieldValidation[0].isMissing,
        subcategory: fieldValidation[1].isMissing,
      };
      setInvalidInputsOnClick(invalidValues);
    }
  }, [validateFieldsOnClick, fieldValidation]);

  useEffect(
    () => handleClickScroll(fieldValidation),
    [
      validateFieldsOnClick,
      fieldValidation,
      setValidateFieldsOnClick,
      handleClickScroll,
    ],
  );

  useEffect(
    () => setCategoryFieldsValidation(fieldValidation),
    [fieldValidation, setCategoryFieldsValidation],
  );

  const invalidClassName = (isInvalid: boolean) =>
    isInvalid ? 'invalidInput' : '';

  const categoryForm = () => (
    <FormContainer
      className={styles.formWrapper}
      title="Material Category"
      content={
        <>
          <div className={styles.categoryDropdownWrapper}>
            <div className={styles.categoryHeader}>Category</div>
            <Dropdown
              dropdownType="form"
              options={[...categoryOptions, ...comingSoonOptions]}
              placeholder="Category*"
              value={selectedCategoryOption}
              onChange={(option: any) => {
                handleChangeCategory(option);

                if (invalidInputsOnClick.category && option) {
                  setInvalidInputsOnClick({
                    ...invalidInputsOnClick,
                    category: false,
                  });
                }
              }}
              menuWidth="100%"
              isSearchable
              invalidUserInput={invalidInputsOnClick.category}
              assistiveText={
                invalidInputsOnClick.category ? 'This field is required' : ''
              }
              containerClassName={invalidClassName(!selectedCategory)}
            />
          </div>
          {selectedCategory && (
            <div>
              <div className={styles.categoryHeader}>Subcategory</div>
              <SubcategoryDropdown
                options={subCategoryOptions}
                isSearchable
                placeholder="Search for Sub Category*"
                value={selectedSubCategory}
                onChange={(option) => {
                  handleChangeSubCategory(option);

                  if (invalidInputsOnClick.subcategory && option) {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      subcategory: false,
                    });
                  }
                }}
                invalidUserInput={invalidInputsOnClick.subcategory}
                assistiveText={
                  invalidInputsOnClick.subcategory
                    ? 'This field is required'
                    : ''
                }
                containerClassName={invalidClassName(!selectedSubCategory)}
              />
              {selectedSecondSubCategory && (
                <SubcategoryDropdown
                  options={secondSubCategoryOptions}
                  placeholder="Search for Sub Category"
                  value={selectedSecondSubCategory}
                  onChange={handleChangeSecondSubCategory}
                />
              )}
              {selectedDecimalCategory && (
                <SubcategoryDropdown
                  options={decimalSubCategoryOptions}
                  placeholder="Search for Sub Category"
                  value={selectedDecimalCategory}
                  onChange={handleChangeDecimalSubCategory}
                />
              )}
            </div>
          )}
        </>
      }
    />
  );

  return (
    <div>
      <div>{categoryForm()}</div>
      {selectedSubCategory && (
        <div className={styles.fullCategoryContainer}>
          {getDisplayedCategoryNumberAndName(
            selectedDecimalCategory,
            selectedSecondSubCategory,
            selectedSubCategory,
          )}
        </div>
      )}
    </div>
  );
}

export default Category;
