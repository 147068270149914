import React, { useState, useEffect, useMemo } from 'react';
import { isPostalCode } from 'validator';
import Grid from '@mui/material/Grid';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { SwitchToggle } from 'components/Toggle/SwitchToggle';
import Checkbox from 'components/Checkbox/Checkbox';
import Dropdown from 'components/Dropdown';
import { AddressForm } from 'components/Forms';
import FormContainer from 'components/FormContainer/FormContainer';
import TextLink from 'components/Links/TextLink';
import { TextLink as TextLinkButton } from 'components/Buttons';
import { COLORS } from 'style/colors';
import formStyles from 'style/formStyles.module.scss';
import styles from './businessDetails.module.scss';
import { BusinessDetailsObj, FieldValidation } from '../types';

type Props = {
  businessDetails: BusinessDetailsObj;
  setBusinessDetails: (business: BusinessDetailsObj) => any;
  // when should the fields be validated
  validateFieldsOnClick: boolean;
  setValidateFieldsOnClick: (val: boolean) => void;
  // send the field name and whether it is missing or invalid to the continue button
  setBusinessFieldsValidation: (fields: FieldValidation[]) => void;
};

function BusinessDetailsScreen({
  businessDetails,
  setBusinessDetails,
  validateFieldsOnClick,
  setValidateFieldsOnClick,
  setBusinessFieldsValidation,
}: Props) {
  const {
    type,
    industry,
    authorizedOnBehalfOfBusiness,
    business,
    address,
    agreeYardTerms,
    agreeStripeTerms,
    validAddress,
  } = businessDetails;

  const [openDialog, setOpenDialog] = useState(false);
  const [hideBusiness, setHideBusiness] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(false);

  useEffect(() => {
    if (validAddress && !isValidAddress) {
      setBusinessDetails({
        ...businessDetails,
        validAddress: false,
      });
    } else if (!validAddress && isValidAddress) {
      setBusinessDetails({
        ...businessDetails,
        validAddress: true,
      });
    }
  }, [isValidAddress, validAddress, businessDetails, setBusinessDetails]);

  const accountTypeOptions = [
    { label: 'Company', value: 'company' },
    {
      label: 'Individual',
      value: 'individual',
    },
  ];

  const industryTypeOptions = [
    { label: 'Contractor', value: 'Contractor' },
    {
      label: 'Supplier/Manufacturer',
      value: 'Supplier/Manufacturer',
    },
    {
      label: 'Building Owner/End User',
      value: 'Building Owner/End User',
    },
  ];

  const isCompany = type === 'company';
  const isIndividual = type === 'individual';

  const defaultInvalidFields = {
    type: false,
    industry: false,
    address: false,
    agreeYardTerms: false,
    agreeStripeTerms: false,
    authorizedOnBehalfOfBusiness: false,
  };

  const [invalidInputsOnClick, setInvalidInputsOnClick] =
    useState(defaultInvalidFields);

  const validPhoneByLength =
    isCompany && business?.phone && business.phone.length === 10;
  const invalidValues = useMemo(
    () => ({
      type: !type,
      industry: isCompany ? !industry : false,
      address: !isValidAddress,
      business: isCompany ? !business?.name || !validPhoneByLength : false,
      agreeYardTerms: !agreeYardTerms,
      agreeStripeTerms: !agreeStripeTerms,
      authorizedOnBehalfOfBusiness: isCompany
        ? !authorizedOnBehalfOfBusiness
        : false,
    }),
    [
      type,
      business,
      isCompany,
      industry,
      isValidAddress,
      agreeYardTerms,
      agreeStripeTerms,
      validPhoneByLength,
      authorizedOnBehalfOfBusiness,
    ],
  );

  // send invalid and missing fields to the continue button
  const addressExists = address !== null;
  const zipExists = addressExists && address.zip;
  const invalidZip = !!(zipExists && !isPostalCode(address.zip, 'US'));

  const fieldValidation = useMemo(
    () => [
      { displayName: 'Type', isMissing: !type },
      { displayName: 'Industry', isMissing: isCompany ? !industry : false },
      {
        displayName: 'Company',
        isMissing: isCompany ? !business?.name : false,
      },
      { displayName: 'Complete Address', isMissing: !address },
      {
        displayName: 'Address',
        isMissing: !!(address && !address.address),
      },
      { displayName: 'City', isMissing: !!(address && !address.city) },
      { displayName: 'State', isMissing: !!(address && !address.state) },
      {
        displayName: 'Zip Code',
        isMissing: !!(address && !address.zip),
        isInvalid: invalidZip,
      },
      { displayName: 'Address Type', isMissing: !!(address && !type) },
      {
        displayName: 'Business Phone',
        isMissing: isCompany ? !business?.phone : false,
        isInvalid: isCompany && !validPhoneByLength,
      },
      {
        displayName: 'Agree to Check The Yard Terms',
        isMissing: !agreeYardTerms,
      },
      { displayName: 'Agree to Stripe Terms', isMissing: !agreeStripeTerms },
      {
        displayName: 'Authorized On Behalf Of Business',
        isMissing: isCompany ? !authorizedOnBehalfOfBusiness : false,
      },
    ],
    [
      type,
      isCompany,
      industry,
      address,
      business,
      invalidZip,
      agreeYardTerms,
      agreeStripeTerms,
      validPhoneByLength,
      authorizedOnBehalfOfBusiness,
    ],
  );

  useEffect(() => {
    if (validateFieldsOnClick) {
      setInvalidInputsOnClick(invalidValues);
    }
  }, [validateFieldsOnClick, invalidValues]);

  const handleClickScroll = () => {
    if (validateFieldsOnClick) {
      const formIsValid = fieldValidation.every(
        (field) => !field.isMissing && !field.isInvalid,
      );

      if (formIsValid) {
        setValidateFieldsOnClick(false);
      } else {
        const firstElement = document.querySelector('.invalidInput');
        if (firstElement) {
          // scroll to first invalid input
          firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // reset validateFieldsOnClick in order run this again on click
        setValidateFieldsOnClick(false);
      }
    }
  };

  useEffect(handleClickScroll, [
    validateFieldsOnClick,
    fieldValidation,
    setValidateFieldsOnClick,
  ]);

  useEffect(
    () => setBusinessFieldsValidation(fieldValidation),
    [fieldValidation, setBusinessFieldsValidation],
  );

  const checkboxAssistiveText = (invalidCheckbox: boolean) => (
    <div
      className={`${formStyles.assistiveText} ${
        invalidCheckbox && formStyles.redAssistiveText
      } ${styles.checkboxAssistiveText}`}
    >
      {invalidCheckbox ? 'You must agree to continue' : ''}
    </div>
  );

  const userAddress = address || {
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    type: '',
  };

  const phonePropsForAddress = isCompany
    ? {
        phoneNumber: business?.phone || null,
        setPhoneNumber: (businessPhone: string) => {
          setBusinessDetails({
            ...businessDetails,
            business: {
              // need to add name instead of just spreading business for ts
              name: business?.name || userAddress.name,
              phone: businessPhone,
            },
          });
        },
        savedPhoneNumber: null,
      }
    : null;

  // used for scrolling to the first invalid input on click
  const validateOnClickAddressProps = {
    invalidClassName: 'invalidInput',
    validateFieldsOnClick,
  };

  const invalidClassName = (isInvalid: boolean) =>
    isInvalid ? 'invalidInput' : '';
  const invalidCheckboxStyling = (invalidCheck: boolean) =>
    invalidCheck && styles.invalidCheckbox;

  return (
    <Grid item xs={12}>
      <FormContainer
        title="Address"
        content={
          <>
            <div className={styles.formInput}>
              <Dropdown
                value={accountTypeOptions.find(
                  (option) => option.value === type,
                )}
                label="Industry*"
                options={accountTypeOptions}
                onChange={(option: any) => {
                  const hasBusinessInfo = industry || business;
                  // if the user initially selected business and set info, then switched to individual, clear the business info
                  if (option.value === 'individual' && hasBusinessInfo) {
                    setBusinessDetails({
                      ...businessDetails,
                      type: option.value,
                      industry: null,
                      business: null,
                    });
                  } else {
                    setBusinessDetails({
                      ...businessDetails,
                      type: option.value,
                    });
                  }

                  if (invalidInputsOnClick.type) {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      type: false,
                    });
                  }
                }}
                containerClassName={`${
                  styles.industryDropdown
                } ${invalidClassName(!type)}`}
                dropdownType="form"
                invalidUserInput={invalidInputsOnClick.type}
              />
              {isCompany && (
                <Dropdown
                  value={industryTypeOptions.find(
                    (option) => option.value === industry,
                  )}
                  label="Business Type*"
                  options={industryTypeOptions}
                  onChange={(option: any) => {
                    setBusinessDetails({
                      ...businessDetails,
                      industry: option.value,
                    });

                    if (invalidInputsOnClick.industry) {
                      setInvalidInputsOnClick({
                        ...invalidInputsOnClick,
                        industry: false,
                      });
                    }
                  }}
                  containerClassName={`${
                    styles.industryDropdown
                  } ${invalidClassName(!industry)}`}
                  dropdownType="form"
                  invalidUserInput={invalidInputsOnClick.industry}
                />
              )}
              {isIndividual ? (
                <div className={styles.individualWelcome}>
                  Hello! Individuals or homeowners are more than welcome to buy
                  and sell on The Yard. Just a heads up, this is primarily a
                  site for contractors, so you may run into jargon or other
                  unfamiliar prompts. Reach out to The Yard if you need any
                  help.
                </div>
              ) : (
                <>
                  <TextLinkButton
                    text="What's this information used for?"
                    onClick={() => setOpenDialog(true)}
                    textColor="sand20"
                    className={styles.whatIsThisLink}
                  />
                  <DialogPopup
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    title="Title"
                    content={
                      <>
                        Some content about what we used this for.
                        <br />
                        <br />
                        Taco Tuesdays are the amazing, but Friyay is where
                        it&apos;s at.
                      </>
                    }
                  />
                </>
              )}
            </div>
            <AddressForm
              address={userAddress}
              setAddress={(newAddress) => {
                // if the user is a company, we need to update the business name that is set in the address form
                setBusinessDetails({
                  ...businessDetails,
                  address: newAddress,
                  ...(isCompany && {
                    business: {
                      ...businessDetails.business,
                      name: newAddress.name,
                    },
                  }),
                });
              }}
              accountType={type}
              isValidAddress={isValidAddress}
              setIsValidAddress={setIsValidAddress}
              phoneProps={phonePropsForAddress}
              validateOnClickProps={validateOnClickAddressProps}
            />
          </>
        }
        actionNeeded
      />
      <div className={styles.bottomAccountSection}>
        <div
          className={`${styles.checkboxWrapper} ${invalidCheckboxStyling(
            invalidInputsOnClick.agreeYardTerms,
          )} ${invalidClassName(!agreeYardTerms)}`}
        >
          <Checkbox
            className={styles.checkbox}
            labelClassName={styles.checkboxLabel}
            label={
              <>
                I agree to the Yard{' '}
                <TextLink
                  href="/privacy_policy"
                  textColor="leather"
                  className={styles.urlLink}
                >
                  privacy policy
                </TextLink>{' '}
                and{' '}
                <TextLink
                  href="/terms_and_conditions"
                  textColor="leather"
                  className={styles.urlLink}
                >
                  terms & conditions
                </TextLink>
                *
              </>
            }
            checked={agreeYardTerms}
            onClick={() => {
              setBusinessDetails({
                ...businessDetails,
                agreeYardTerms: !agreeYardTerms,
              });

              if (invalidInputsOnClick.agreeYardTerms) {
                setInvalidInputsOnClick({
                  ...invalidInputsOnClick,
                  agreeYardTerms: false,
                });
              }
            }}
            color={
              invalidInputsOnClick.agreeYardTerms
                ? COLORS.taurusRed40
                : COLORS.sand
            }
            invalid={invalidInputsOnClick.agreeYardTerms}
          />
          {checkboxAssistiveText(invalidInputsOnClick.agreeYardTerms)}
        </div>
        <div
          className={`${styles.checkboxWrapper} ${invalidCheckboxStyling(
            invalidInputsOnClick.agreeStripeTerms,
          )} ${invalidClassName(!agreeStripeTerms)}`}
        >
          <Checkbox
            className={styles.checkbox}
            labelClassName={styles.checkboxLabel}
            label={
              <>
                I agree to the Stripe{' '}
                <TextLink
                  href="https://stripe.com/privacy"
                  textColor="leather"
                  className={styles.urlLink}
                >
                  privacy policy
                </TextLink>{' '}
                and{' '}
                <TextLink
                  href="https://stripe.com/legal/connect-account"
                  textColor="leather"
                  className={styles.urlLink}
                >
                  connected account agreement
                </TextLink>
                *
              </>
            }
            checked={agreeStripeTerms}
            onClick={() => {
              setBusinessDetails({
                ...businessDetails,
                agreeStripeTerms: !agreeStripeTerms,
              });

              if (invalidInputsOnClick.agreeStripeTerms) {
                setInvalidInputsOnClick({
                  ...invalidInputsOnClick,
                  agreeStripeTerms: false,
                });
              }
            }}
            color={
              invalidInputsOnClick.agreeStripeTerms
                ? COLORS.taurusRed40
                : COLORS.sand
            }
            invalid={invalidInputsOnClick.agreeStripeTerms}
          />
          {checkboxAssistiveText(invalidInputsOnClick.agreeStripeTerms)}
        </div>
        {isCompany && (
          <>
            <div
              className={`${styles.checkboxWrapper} ${invalidCheckboxStyling(
                invalidInputsOnClick.authorizedOnBehalfOfBusiness,
              )} ${invalidClassName(!authorizedOnBehalfOfBusiness)}`}
            >
              <Checkbox
                className={styles.checkbox}
                labelClassName={styles.checkboxLabel}
                label="I'm authorized to act on behalf of the business*"
                checked={authorizedOnBehalfOfBusiness}
                onClick={() => {
                  setBusinessDetails({
                    ...businessDetails,
                    authorizedOnBehalfOfBusiness: !authorizedOnBehalfOfBusiness,
                  });

                  if (invalidInputsOnClick.authorizedOnBehalfOfBusiness) {
                    setInvalidInputsOnClick({
                      ...invalidInputsOnClick,
                      authorizedOnBehalfOfBusiness: false,
                    });
                  }
                }}
                color={
                  invalidInputsOnClick.authorizedOnBehalfOfBusiness
                    ? COLORS.taurusRed40
                    : COLORS.sand
                }
                invalid={invalidInputsOnClick.authorizedOnBehalfOfBusiness}
              />
              {checkboxAssistiveText(
                invalidInputsOnClick.authorizedOnBehalfOfBusiness,
              )}
            </div>
            {/* commenting this out since the functionality in the site isn't there */}
            {/* {!hideBusiness && (
                <SwitchToggle
                  className={styles.hideBusinessToggle}
                  isOn={hideBusiness}
                  onChange={() => setHideBusiness(!hideBusiness)}
                  label="My business needs to be anonymous"
                />
              )} */}
            {hideBusiness && (
              <FormContainer
                title={
                  <SwitchToggle
                    isOn={hideBusiness}
                    onChange={() => setHideBusiness(!hideBusiness)}
                    label="My business needs to be anonymous"
                  />
                }
                content={
                  <div className={styles.hideBusinessDetails}>
                    <div className={styles.hideBusinessTopSection}>
                      <span className={styles.hideBusinessSample}>SAMPLE</span>
                      <div className={styles.anonymousName}>
                        Yard Business #2416
                      </div>
                      <span className={styles.anonymousAddress}>
                        {businessDetails?.address && (
                          <>
                            {businessDetails.address.city},{' '}
                            {businessDetails.address.state}
                          </>
                        )}
                      </span>
                    </div>
                    <div className={styles.hideBusinessBody}>
                      Once you register, you&apos;ll be given an anonymous User
                      ID. This is how your business will display to other users
                      and on any of your listings. Anonymous usage may lower
                      your perceived trustworthiness from potential buyers or
                      sellers.
                      <br />
                      <br />
                      Please note: once any transaction begins (buying or
                      selling), your business information will be disclosed to
                      the other party.
                    </div>
                  </div>
                }
              />
            )}
          </>
        )}
      </div>
    </Grid>
  );
}

export default BusinessDetailsScreen;
