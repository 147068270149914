import React from 'react';
import { Box } from 'parts';
import { IPageHeadingProps } from './types';
import styles from './page.module.scss';

function PageHeaderBackdrop({ children }: IPageHeadingProps) {
  return (
    <Box position="relative">
      <div className={styles.backdropHeader}>{children}</div>
    </Box>
  );
}

export default PageHeaderBackdrop;
