import React from 'react';
import './_sideMenu.scss';
import Modal from 'react-modal';
import classNames from 'classnames';
import { useDisableBodyScroll } from 'hooks';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';

interface Props {
  menuHeader: JSX.Element;
  menuContent: JSX.Element;
  closeMenuAction: any;
  rightSide?: boolean; // default is false
  isOpen?: boolean;
  modalClassName?: string;
  buttonContainerClassName?: string;
}

function SideMenu({
  menuHeader,
  menuContent,
  closeMenuAction,
  rightSide = false,
  buttonContainerClassName = '',
  modalClassName,
  isOpen = true,
}: Props) {
  Modal.setAppElement('#root');
  // hide the scrollbar from the body, then reset it after the modal closes, else there are 2 scrollbars
  const { enableBodyScroll } = useDisableBodyScroll();

  const menuSide = rightSide && 'right-side-menu';

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeMenuAction}
      onAfterClose={enableBodyScroll}
      className={classNames('side-menu-modal', menuSide, modalClassName)}
      overlayClassName={classNames('side-menu-overlay')}
    >
      <div className={classNames('side-menu-container')}>
        <div
          className={classNames(
            'side-menu__top-button-container',
            buttonContainerClassName,
          )}
        >
          {menuHeader}
          <div
            className="side-menu__close-button cursor-pointer"
            onClick={closeMenuAction}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="side-menu__content">{menuContent}</div>
      </div>
    </Modal>
  );
}

export default SideMenu;
