import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetListingsUserByUserIdQuery, Listing } from 'api';
import { useIdentity, useApiResponseMessage } from 'hooks';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import Table from 'components/Table/Table';
import { Button } from 'components/Buttons';
import CtaItem from 'components/CtaItem/CtaItem';
import ListingItem from './ListingItem';
import styles from './myListings.module.scss';

function MyListingsScreen() {
  const navigate = useNavigate();
  const { apiResponseMessage } = useApiResponseMessage();
  const { stripeAccountVerified, businessName, userId } = useIdentity();

  const [tableData, setTableData] = useState<Listing[]>([]);

  const { currentData, isFetching, isError } = useGetListingsUserByUserIdQuery({
    userId,
  });

  const response = () => {
    if (currentData?.success) {
      setTableData(currentData.data);
    } else if (isError) {
      apiResponseMessage('An error occurred. Please try again.');
    }
  };
  useEffect(response, [currentData, isError, apiResponseMessage]);

  const interestedInSelling = () => (
    <div className={styles.ctaContainer}>
      <Grid item xs={12} sm={9} md={12}>
        <CtaItem
          containerClassName={styles.cta}
          headerText="Interested in selling?"
          subtext="Listing your items is easy—You provide the details, and we'll walk you through every step along the way."
          buttonText="GET STARTED"
          screenPath="/product_listing"
        />
      </Grid>
    </div>
  );

  const userListingsView = (pageData: Listing[]) => (
    <div className={styles.listingsTableContent}>
      {pageData.map((itemObj: any) => (
        <ListingItem listing={itemObj} key={itemObj.id} />
      ))}
    </div>
  );

  return (
    <Grid container>
      <Grid item xs={12} container className={styles.topSection}>
        <Grid container className={styles.headerWrapper}>
          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            container
            className={styles.innerHeaderWrapper}
          >
            <Grid item xs={6} md={3} className={styles.leftSideHeader}>
              <h1 className={styles.header}>My Yard Listings</h1>
              <div className={styles.businessName}>{businessName}</div>
            </Grid>
            <Grid
              container
              item
              xs={8}
              sm={6}
              md={5}
              className={styles.rightSideHeader}
            >
              <Button
                containerClassName={`${styles.headerButtons} ${styles.howToSell}`}
                buttonClassName={styles.headerButtons}
                text="HOW TO SELL"
                onClick={() => navigate('/interested_in_selling')}
                buttonType="text"
                textColor="black"
              />
              <Button
                containerClassName={styles.headerButtons}
                buttonClassName={styles.headerButtons}
                text="CREATE NEW LISTING"
                onClick={() => navigate('/product_listing')}
                buttonType="secondary"
                buttonColor="black"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} className={styles.tableWrapper}>
        {isFetching ? (
          <Loading />
        ) : (
          <Table
            tableName="My listings"
            data={tableData}
            startingView="custom"
            customTableView={(pageData: Listing[]) =>
              userListingsView(pageData)
            }
            onlyAllowStartingView
            pageSize={9}
            emptyTableMessage={
              <div className={styles.emptyTableContainer}>
                <span className={styles.emptyTableLargeText}>
                  You have no listings.
                </span>
                <br />
                <span>
                  Once you’ve listed materials on The Yard, they&apos;ll show up
                  here.
                </span>
                <br />
                {stripeAccountVerified ? (
                  <Button
                    text="List your first item"
                    onClick={() => navigate('/product_listing')}
                    buttonType="secondary"
                    buttonColor="sand20"
                  />
                ) : (
                  <span>Verify your Stripe account to start listing.</span>
                )}
              </div>
            }
          />
        )}
      </Grid>

      {tableData.length < 1 && (
        <Grid container justifyContent="center" className={styles.ctaWrapper}>
          {interestedInSelling()}
        </Grid>
      )}
    </Grid>
  );
}

export default MyListingsScreen;
