import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './textLink.module.scss';

type TextLinkProps = {
  className?: string;
  to?: string; // use for internal navigation
  href?: string; // use for external navigation (like to stripe)
  hashLink?: boolean; // used for anchors - if true, you must use href
  // @TODO: NT - Create a type or interface layer for colors.
  textColor?:
    | 'black'
    | 'sandTwenty'
    | 'sand'
    | 'leather'
    | 'sandBrightTwenty'
    | 'blue'
    | 'inactiveGrey'
    | 'custom'; // this is so I can use other styles/colors without fighting these colors (like the footer links)
  children: ReactNode;
};

function TextLink({
  className,
  children,
  to,
  textColor = 'black',
  href,
  hashLink,
}: TextLinkProps) {
  if (to) {
    return (
      <Link
        to={to}
        className={`${styles.link} ${className} ${styles[textColor]}`}
        onMouseDown={(e) => {
          // this is needed to remove the :focus styling after click
          e.preventDefault();
        }}
      >
        {children}
      </Link>
    );
  }
  return (
    <a
      href={href}
      className={`${styles.link} ${className} ${styles[textColor]}`}
      target={hashLink ? '_self' : '_blank'}
      rel="noopener noreferrer"
      onMouseDown={(e) => {
        // this is needed to remove the :focus styling after click
        e.preventDefault();
      }}
    >
      {children}
    </a>
  );
}
export default TextLink;
