import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useIsMobile } from 'hooks';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { TextLink, Button } from 'components/Buttons';
import StandardModal from 'components/StandardModal/StandardModal';
import { COLORS } from 'style/colors';
import styles from './deleteAccountModal.module.scss';

type Props = {
  closeDeleteAccountModal: () => void;
};

// TODO: REMOVE ESCROW - not sure if it will be replaced with something else
function DeleteAccountModal({ closeDeleteAccountModal }: Props) {
  const navigate = useNavigate();
  const mobileScreen = useIsMobile();
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [checked, setChecked] = useState(false);
  const redClassName = checked && styles.inTheRed;

  const deleteAccount = () => {
    // TODO: api call to delete account

    // on sucessful delete
    setAccountDeleted(true);
  };

  const deleteAccountScreen = () => (
    <div className={`${styles.wrapper} ${redClassName}`}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          Delete
          {mobileScreen ? <br /> : ' '}
          Your Account
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.textWrapper}>
          <div className={styles.textHeader}>
            We&apos;re sorry to see you go.
          </div>
          <span>Some things to be aware of:</span>
          <br />
          <span>
            - You&apos;ll still be liable for any orders that are in progress
            unless you cancel them manually via escrow.
          </span>
          <br />
          <span>
            - Your escrow.com account will remain open. If you wish to close
            that, you may do so at escrow.com.
          </span>
          {/* <br />
          <span>
            - To help prevent fraud, your username and business name will be
            blocked from registration for XX days—after that we will release
            them for new users.
          </span> */}
        </div>
        <div className={styles.actionContainer}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              labelClassName={styles.confirmCheckboxLabel}
              label="I'd like to delete my account"
              checked={checked}
              onClick={() => setChecked(!checked)}
              checkedColor={COLORS.white}
            />
          </div>
          <div className={styles.lineSeperator} />
          <div className={styles.deleteButtonWrapper}>
            <Button
              buttonClassName={styles.deleteButton}
              containerClassName={styles.deleteButtonContainer}
              title="DELETE MY ACCOUNT"
              disabled={!checked}
              onClick={() => deleteAccount()}
              buttonColor="white"
            />
          </div>
        </div>
        <TextLink
          className={styles.backButton}
          text="CANCEL AND RETURN TO MY ACCOUNT"
          onClick={() => closeDeleteAccountModal()}
        />
      </div>
      <TextLink
        className={styles.contactUsLink}
        text="Contact us if you have questions."
        onClick={() => navigate('/help')}
        textColor="leather"
      />
    </div>
  );

  const accountDeletedScreen = () => {
    // close modal after 3 seconds
    setTimeout(() => {
      closeDeleteAccountModal();
    }, 3000);

    return (
      <div className={styles.accountDeletedWrapper}>
        <div>
          <h1 className={styles.accountDeletedHeader}>Account Deleted</h1>
          <div className={styles.accountDeletedSubheader}>
            Thanks for your time at The Yard.
          </div>
        </div>
      </div>
    );
  };

  const modalClassName = accountDeleted
    ? ''
    : `${styles.deleteAccountModal} ${redClassName}`;

  return (
    <StandardModal
      modalClassName={modalClassName}
      buttonContainerClassName={styles.modalButtons}
      contentWrapperClassName={styles.deleteAccountContentWrapper}
      modalContent={
        accountDeleted ? accountDeletedScreen() : deleteAccountScreen()
      }
      closeModalAction={() => closeDeleteAccountModal()}
      closeIconColor={
        redClassName && !accountDeleted ? COLORS.white : COLORS.midnightBlack
      }
    />
  );
}

export default DeleteAccountModal;
