/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { Box, H4 } from 'parts';
import { IPageSubheadingProps } from './types';

function PageSubheader({ text, id }: IPageSubheadingProps) {
  const isText = typeof text === 'string';

  return (
    <Box id={id} mt="104px" maxWidth="920px" width="100%">
      {isText ? (
        <H4>{text}</H4>
      ) : (
        <H4>
          {text.map((item, index) => (
            <Fragment key={index}>{item}</Fragment>
          ))}
        </H4>
      )}
    </Box>
  );
}

export default PageSubheader;
