import React from 'react';
import {
  useIdentity,
  useGetPaymentDetails,
  useGetStripeAccountVerified,
} from 'hooks';
import Loading from 'components/Loading';
import StripeView from './StripeView';

function StripeController() {
  const stripeAccountVerified = useGetStripeAccountVerified();
  const { userId } = useIdentity();
  const paymentMethodDetails = useGetPaymentDetails();

  if (!paymentMethodDetails || stripeAccountVerified === undefined) {
    return <Loading />;
  }

  return (
    <StripeView
      userId={userId}
      paymentMethods={paymentMethodDetails}
      stripeAccountVerified={stripeAccountVerified}
    />
  );
}

export default StripeController;
