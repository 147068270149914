/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { Body1, Box } from 'parts';
import Backdrop from 'components/Backdrop';
import { useMediaQuery } from '@mui/material';
import { IPageBodyTextProps, IPageBodyImageProps } from '../types';

function PageBodyTextDesktopImage({ image }: IPageBodyImageProps) {
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const marginRight = image.align === 'left' ? 3 : 0;
  const marginLeft = image.align === 'right' ? 3 : 0;

  return (
    <Box
      my={3}
      ml={marginLeft}
      mr={marginRight}
      width="45%"
      position="relative"
    >
      <Backdrop
        align={isDesktop ? image.align : undefined}
        caption={image.caption || image.alt}
      >
        <img src={image.src} alt={image.alt} />
      </Backdrop>
    </Box>
  );
}

function PageBodyTextDesktop({
  align,
  partial,
  text,
  image,
}: IPageBodyTextProps) {
  let alignment = align === 'right' ? 'end' : 'start';
  alignment = image && image.align === 'left' ? 'end' : alignment;

  let width = partial || image ? '75%' : '100%';
  width = image ? '85%' : width;

  const isText = typeof text === 'string';

  return (
    <Box
      display="flex"
      justifyContent={alignment}
      mt={5}
      maxWidth="920px"
      width="100%"
    >
      {image && image.align === 'left' && (
        <PageBodyTextDesktopImage image={image} />
      )}
      <Box width={width}>
        {isText ? (
          <Body1>{text}</Body1>
        ) : (
          <Body1>
            {text.map((item: any, index) => (
              <Fragment key={index}>{item}</Fragment>
            ))}
          </Body1>
        )}
      </Box>
      {image && image.align === 'right' && (
        <PageBodyTextDesktopImage image={image} />
      )}
    </Box>
  );
}

export default PageBodyTextDesktop;
