/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@mui/material';
import { COLORS } from 'style/colors';

interface IDividerProps extends MuiDividerProps {
  fullDivider?: boolean;
}

function Divider(props: IDividerProps) {
  const { fullDivider } = props;

  const fullDividerStyles = {
    backgroundColor: COLORS.midnightBlack,
    height: '4px',
  };

  const defaultStyles = {
    borderColors: COLORS.metal,
  };

  return <MuiDivider sx={fullDivider ? fullDividerStyles : defaultStyles} />;
}

export default Divider;
