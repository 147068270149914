import React from 'react';
import { ListingFile } from 'api';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import documentIcon from 'assets/icons/myTermsIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/navigateForwardArrowIcon.svg';
import styles from './documentsSection.module.scss';

type Props = {
  productDocuments: ListingFile[] | [];
  sellerName: string;
};

function DocumentsSection({ productDocuments, sellerName }: Props) {
  const navigate = useNavigate();

  const documents = () => (
    <Grid item xs={12} md={11}>
      <div className={styles.documentsHeader}>Documents</div>
      <Grid item xs={12}>
        {productDocuments.map((productDocument) => (
          <a
            className={styles.removeATagCss}
            href={productDocument.url}
            key={productDocument.fileName}
            target="_blank"
            rel="noreferrer"
          >
            <Grid
              className={styles.documentRow}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={10} className={styles.documentLeftSide}>
                <img
                  src={documentIcon}
                  alt="document icon"
                  className={styles.documentIcon}
                />
                <Grid container>
                  <div className={styles.sellerName}>{sellerName}</div>
                  <div className={styles.documentName}>
                    {productDocument.fileName}
                  </div>
                </Grid>
              </Grid>
              <ArrowIcon />
            </Grid>
          </a>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <Grid container item xs={12} xl={11} className={styles.wrapper}>
      <Grid item xs={12} sm={6} md={5} className={styles.documentsWrapper}>
        {productDocuments.length > 0 && documents()}
      </Grid>
      <Grid item xs={8} sm={5} md={3} container direction="column">
        <div className={styles.contactHeader}>Contact The Yard</div>
        <div className={styles.contactText}>
          If you&apos;ve got an issue, a question, or anything of the like, get
          help.
        </div>
        <Grid container className={styles.buttonWrapper}>
          <Grid item xs={12}>
            <Button
              text="GET HELP"
              onClick={() => navigate('/help')}
              buttonClassName={styles.button}
              containerClassName={styles.buttonContainer}
              buttonType="secondary"
              buttonColor="black"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DocumentsSection;
