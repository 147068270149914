import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ApiErrorState {
  responseMessage: string | null;
  openDuration?: number;
}

const initialState: ApiErrorState = {
  responseMessage: null,
  openDuration: 4000,
};

export const apiResponseSlice = createSlice({
  name: 'apiError',
  initialState,
  reducers: {
    showResponseMessage: (state, action: PayloadAction<ApiErrorState>) => {
      state.responseMessage = action.payload.responseMessage;
      state.openDuration = action.payload.openDuration || 4000;
    },
    hideResponseMessage: (state) => {
      state.responseMessage = null;
      state.openDuration = 4000;
    },
  },
});

export const { showResponseMessage, hideResponseMessage } =
  apiResponseSlice.actions;
export default apiResponseSlice.reducer;
