import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

// eslint-disable-next-line import/prefer-default-export
export const emptySplitApi = createApi({
  baseQuery,
  endpoints: () => ({}),
}).enhanceEndpoints({
  addTagTypes: [
    'UserDetails',
    'UserListings',
    'Listings',
    'Listing',
    'RecentlyViewedListings',
    'UserCart',
    'UserOrders',
    'Order',
    'PaymentMethods',
  ],
});
