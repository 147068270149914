// eslint-disable-next-line import/prefer-default-export
export const COLORS = {
  taurusRed: '#ff0020',
  taurusRedBright20: '#de3146',
  taurusRed20: '#c00018',
  taurusRed40: '#9a0013',
  taurusRed60: '#78000f',
  taurusRed80: '#4c000a',
  midnightBlack: '#272727',
  midnightRollover: '#624f3d',
  midnightClick: '#795f46',
  midnightBlackier: '#111010',
  sand: '#c79769',
  sandRollover: '#b2875e',
  sandClick: '#a9815a',
  sandBright20: '#eec9a5',
  sandBrightRollover: '#c5a07c',
  sandBrightClick: '#b48f6c',
  sand20: '#9d7854',
  sand20Rollover: '#9b623b',
  sand20Click: '#995932',
  leather: '#994d23',
  metal: '#979797',
  metalBright50: '#c7c7c7',
  metalAlpha30: 'rgba(151, 151, 151, 0.3)',
  offWhite: '#eaeaea',
  offWhiteMaterial: '#fafafa',
  white: '#fff',
  fpo: 'rgba(231, 54, 229, 0.31)',
  containerStateOffWhiteAlpha60: 'rgba(234, 234, 234, 0.6)',
  escrowBlue: '#01426a',
  achGreen: '#3cb95d',
  inactiveGrey: '#6d6d6d',
  ripple: 'rgba(0, 0, 0, 0.3)',
};
