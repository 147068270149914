import React, { useState } from 'react';
import { usePostOrdersByOrderIdCancelMutation } from 'api';
import { useApiResponseMessage } from 'hooks';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import { TextLink, Button } from 'components/Buttons';
import TextArea from 'components/TextArea/TextArea';
import StandardModal from 'components/StandardModal';
import FormContainer from 'components/FormContainer';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/leftArrowIcon.svg';
import styles from './cancelOrderModal.module.scss';

type Props = {
  orderId: string;
  closeCancelOrderModal: () => any;
};

function CancelOrderModal({ orderId, closeCancelOrderModal }: Props) {
  const { apiResponseMessage } = useApiResponseMessage();
  const [cancelationReason, setCancelationReason] = useState('');
  const [loading, setLoading] = useState(false);

  const [cancelOrder] = usePostOrdersByOrderIdCancelMutation();

  const handleCancelTransaction = async () => {
    try {
      setLoading(true);
      const payload: any = await cancelOrder({
        orderId,
        body: cancelationReason ? { reason: cancelationReason } : {},
      }).unwrap();

      if (payload.success) {
        apiResponseMessage('Order canceled');

        // close modal
        closeCancelOrderModal();
      }
    } catch (error: any) {
      setLoading(false);

      let errorMessage =
        error.data?.message ||
        'An error occurred, please try again or contact support for assistance.';
      if (errorMessage === 'Cannot transition order from SHIPPED to CANCELED') {
        errorMessage =
          'Cannot cancel shipped orders. Please contact support for assistance.';
      }

      apiResponseMessage(errorMessage, 6000);
    }
  };

  const content = () => (
    <Grid container>
      <Grid container className={styles.headerWrapper}>
        <Grid item xs={8}>
          Cancel Your Order
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.formsWrapper}>
        <FormContainer
          className={styles.formContainer}
          title="What's your reason for canceling?"
          actionNeeded
          content={
            <TextArea
              value={cancelationReason}
              onChange={(e) => setCancelationReason(e.target.value)}
            />
          }
        />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={9}>
          <Button
            text="CANCEL ORDER"
            onClick={handleCancelTransaction}
            buttonColor="sand20"
            containerClassName={styles.saveButtonContainer}
            buttonClassName={styles.saveButton}
          />
        </Grid>
        <TextLink className={styles.backButton} onClick={closeCancelOrderModal}>
          <LeftArrowIcon />
          Cancel
        </TextLink>
      </Grid>
    </Grid>
  );

  return (
    <>
      {loading && <Loading screenOverlay />}
      <StandardModal
        modalContent={content()}
        closeModalAction={closeCancelOrderModal}
        fullScreenModal={false}
        onlyShowScrollWhenScrolling
        modalClassName={styles.modal}
        buttonContainerClassName={styles.modalButton}
      />
    </>
  );
}

export default CancelOrderModal;
