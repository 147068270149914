import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { isEmail } from 'validator';
import { useDispatch } from 'react-redux';
import { usePostAuthLoginMutation } from 'api';
import { setLoginCredentials } from 'slices/accountSlice';
import logo from 'assets/yardLogos/checkTheYardOnLight.svg';
import { TextLink, Button } from 'components/Buttons';
import { TextInput } from 'components/TextInput/TextInput';
import FormContainer from 'components/FormContainer/FormContainer';
import StandardModal from 'components/StandardModal/StandardModal';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import ResetRequestSentScreen from './ResetRequestSentScreen';
import SuccessfulSignin from './SuccessfulSignin';
import styles from './signInModal.module.scss';

type Props = {
  startingView?: 'sign-in' | 'forgot-password' | 'successful-signin' | string;
  successfulSignInCallback?: () => void;
};

enum AuthState {
  'none',
  'pending',
  'failure',
  'success',
}

function SignInModal({
  startingView = 'sign-in',
  successfulSignInCallback,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');

  const [login] = usePostAuthLoginMutation();

  // for setting displayed screen, back button screen, and displaying back button
  const [currentContent, setCurrentContent] = useState(startingView);
  const [showBackButton, setShowBackButton] = useState(false);
  const [backButtonScreen] = useState('sign-in');
  // use in ResetRequestSentScreen, passed from ForgotPasswordScreen
  const [resetEmail, setResetEmail] = useState('');
  // for the signInContent form
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authState, setAuthState] = useState(AuthState.none);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const signInApiCall = useCallback(async () => {
    if (!invalidEmail && password !== '' && authState !== AuthState.pending) {
      setAuthState(AuthState.pending);
      try {
        const credentials = await login({
          body: { email, password },
        }).unwrap();

        setUserId(credentials.userId);

        dispatch(
          setLoginCredentials({
            accessToken: credentials.accessToken,
            refreshToken: credentials.refreshToken,
            userId: credentials.userId,
          }),
        );
        // send user to the successful-signin screen that confirms their stripe account
        // and preforms the succesfulSignInCallback
        setCurrentContent('successful-signin');
      } catch {
        setAuthState(AuthState.failure);
      }
    }
  }, [authState, dispatch, email, invalidEmail, login, password]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (currentContent === 'sign-in' && event.key === 'Enter') {
        signInApiCall();
      }
    };
    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [signInApiCall, currentContent]);

  const signInContent = () => {
    const validEmail = isEmail(email);
    // disable the Sign in button if there is no password or valid email
    const disableSignIn =
      !validEmail || password === '' || authState === AuthState.pending;

    return (
      <div className={styles.wrapper}>
        <div className={styles.headerContainer}>
          <img className={styles.logo} src={logo} alt="The Yard logo" />
          <div className={styles.header}>Sign in or Create an Account</div>
        </div>
        <div className={styles.bodyWrapper}>
          <FormContainer
            title="Sign In"
            actionNeeded
            className={styles.formContainer}
            bodyClassName={styles.formBody}
            content={
              <>
                <TextInput
                  containerClassName={styles.input}
                  label="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  invalidUserInput={invalidEmail}
                  onBlur={() => {
                    if (email !== '' && !validEmail) {
                      setInvalidEmail(true);
                    } else if (validEmail && invalidEmail) {
                      setInvalidEmail(false);
                    }
                  }}
                />
                <TextInput
                  containerClassName={styles.input}
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  buttonColor="sand20"
                  text="Sign in"
                  containerClassName={styles.button}
                  disabled={disableSignIn}
                  onClick={() => signInApiCall()}
                />
                <div style={{ color: 'red' }}>
                  {authState === AuthState.failure ? 'Login failed' : ''}
                </div>
                <TextLink
                  text="Forgot password?"
                  textColor="sand20"
                  className={styles.forgotPasswordLink}
                  onClick={() => setCurrentContent('forgot-password')}
                />
              </>
            }
          />
          <FormContainer
            title="New to The Yard? Create an Account"
            className={styles.formContainer}
            hoverState
            content={
              <Button
                buttonType="secondary"
                buttonColor="sand20"
                text="Create an Account"
                containerClassName={styles.button}
                onClick={() => {
                  navigate('/sign_in/create_account', { replace: true });
                }}
              />
            }
          />

          <div className={styles.footer}>
            For masdfasdfasdfost of us, the{' '}
            <TextLink
              text="Some legal link thing"
              className={styles.legalLink}
              textColor="leather"
              onClick={() => {
                navigate('/help');
              }}
            />{' '}
            idea of astronomy is something we directly connect to “stargazing”,
            telescopes and seeing magnificent displays in the heavens.{' '}
            <TextLink
              text="Contact Us"
              className={styles.legalLink}
              textColor="leather"
              onClick={() => {
                navigate('/help');
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const displayedContent = () => {
    switch (currentContent) {
      case 'sign-in':
        if (showBackButton) {
          setShowBackButton(false);
        }
        return signInContent();
      case 'forgot-password':
        if (!showBackButton) {
          setShowBackButton(true);
        }
        return (
          <ForgotPasswordScreen
            setCurrentContent={setCurrentContent}
            setResetEmail={setResetEmail}
          />
        );
      case 'reset-request-sent':
        if (showBackButton) {
          setShowBackButton(false);
        }
        return <ResetRequestSentScreen resetEmail={resetEmail} />;
      case 'successful-signin':
        return (
          <SuccessfulSignin
            userId={userId}
            successfulSignInCallback={successfulSignInCallback}
          />
        );
      default:
        return signInContent();
    }
  };

  return (
    <StandardModal
      modalClassName={styles.signinModal}
      contentWrapperClassName={styles.signinModalContentWrapper}
      buttonContainerClassName={styles.modalButtonContainer}
      modalContent={displayedContent()}
      closeModalAction={() => {
        navigate(-1);
      }}
      showBackButton={showBackButton}
      backButtonAction={() => setCurrentContent(backButtonScreen)}
    />
  );
}

export default SignInModal;
