import React, { useState, useEffect } from 'react';
import { useParams, Route, Routes, Link } from 'react-router-dom';
import { useGetOrdersUserByUserIdQuery, Order } from 'api';
import { useIdentity } from 'hooks';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Loading from 'components/Loading';
import PurchasesTab from './PurchasesTab';
import SalesTab from './SalesTab';
import styles from './myOrdersScreen.module.scss';

function MyOrdersScreen() {
  const { businessName, userId } = useIdentity();
  const { tab } = useParams();

  const [soldOrders, setSoldOrders] = useState<Order[]>([]);
  const [purchasedOrders, setPurchasedOrders] = useState<Order[]>([]);

  const { currentData, isError, isFetching } = useGetOrdersUserByUserIdQuery({
    userId,
  });

  useEffect(() => {
    if (currentData?.success) {
      const sales = currentData?.data.sales || [];
      const purchases = currentData?.data.purchases || [];
      // copies needed for .sort()
      const salesCopy = [...sales];
      const purchasesCopy = [...purchases];

      // if there is more than one order, sort by updated date
      const salesByUpdatedDate =
        sales.length < 2
          ? sales
          : salesCopy.sort((a, b) => {
              if (!a?.updatedAt) return 1;
              if (!b?.updatedAt) return -1;

              return (
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
              );
            });

      const purchasesByUpdatedDate =
        purchases.length < 2
          ? purchases
          : purchasesCopy.sort((a, b) => {
              if (!a?.updatedAt) return 1;
              if (!b?.updatedAt) return -1;

              return (
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
              );
            });

      setSoldOrders(salesByUpdatedDate);
      setPurchasedOrders(purchasesByUpdatedDate);
    }
  }, [currentData]);

  const renderedComponent = (component: JSX.Element) => {
    if (isFetching) {
      return <Loading />;
    } else if (isError) {
      return <div>Something went wrong...</div>;
    } else {
      return component;
    }
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} container className={styles.topSection}>
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={10}
          className={styles.innerTopSection}
        >
          <Grid item xs={12} sm={10} md={10} className={styles.headerWrapper}>
            <h1 className={styles.header}>
              My
              <br />
              Orders
            </h1>
            <span className={styles.headerCompany}>{businessName}</span>
          </Grid>
          <Grid item xs={12} sm={10} className={styles.tabsWrapper}>
            <Tabs
              classes={{ root: styles.tabs, indicator: styles.tabIndicator }}
              value={tab}
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab
                classes={{ root: styles.tab, selected: styles.selectedTab }}
                label="Purchases"
                value="purchases"
                component={Link}
                to="/orders/purchases"
              />
              <Tab
                classes={{ root: styles.tab, selected: styles.selectedTab }}
                label="Sales"
                value="sales"
                component={Link}
                to="/orders/sales"
              />
            </Tabs>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} className={styles.tableWrapper}>
        <Routes>
          <Route
            path="purchases"
            element={renderedComponent(
              <PurchasesTab purchasedOrders={purchasedOrders} />,
            )}
          />
          <Route
            path="sales"
            element={renderedComponent(<SalesTab soldOrders={soldOrders} />)}
          />
        </Routes>
      </Grid>
    </Grid>
  );
}

export default MyOrdersScreen;
