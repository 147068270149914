import React from 'react';
import { useNavigate } from 'react-router';
import { useIdentity, useIsMobile } from 'hooks';
import Grid from '@mui/material/Grid';
import { IconButton, Button } from 'components/Buttons'; // @TODO: NT - I'd probably also remove webpack aliasing so you're not married to webpack
import CartIconButton from 'components/CartIconButton';
import { ReactComponent as UserIcon } from 'assets/icons/userIcon.svg'; // @TODO: NT - Fix imports
import { ReactComponent as OrdersIcon } from 'assets/icons/ordersIcon.svg';
import styles from './headerButtons.module.scss';

type HeaderButtonsProps = {
  showAccountMenu: () => void;
  isInSellMode?: boolean;
};

function SignInButtons({ showAccountMenu, isInSellMode }: HeaderButtonsProps) {
  const { loggedIn } = useIdentity();
  const navigate = useNavigate();
  const isMobileScreen = useIsMobile();
  const iconLabelColor = isInSellMode ? 'sand-bright' : 'black';

  if (loggedIn) {
    return (
      <div className={styles.conjoin}>
        <IconButton
          className={styles.buttonWrapper}
          avatarClassName={styles.iconAvatar}
          icon={UserIcon}
          iconClassName={styles.icon}
          onClick={() => showAccountMenu()}
          label={isMobileScreen ? '' : 'Account'}
          textColor={iconLabelColor}
        />
        {/* @TODO: NT - IconButton should be able to render as an anchor tag */}
        <IconButton
          className={styles.buttonWrapper}
          avatarClassName={styles.iconAvatar}
          icon={OrdersIcon}
          iconClassName={styles.icon}
          onClick={() => navigate('/orders')}
          label={isMobileScreen ? '' : 'Orders'}
          textColor={iconLabelColor}
        />
      </div>
    );
  }

  const buttonColor = isMobileScreen || isInSellMode ? 'brightSand20' : 'black';

  return (
    <div className={styles.signupContainer}>
      <Button
        containerClassName={styles.signup}
        buttonClassName={styles.signInButton}
        text="SIGN IN/SIGN UP"
        textColor={isInSellMode ? 'black' : 'white'}
        roundedButton
        buttonColor={buttonColor}
        onClick={(e) => {
          e.stopPropagation();
          navigate('/sign_in');
        }}
      />
      {!isMobileScreen && (
        //* @TODO: NT - This needs to be a text style button. The span is inaccessible to alternate inputs such as tab.
        <span
          className={`${styles.join} ${
            isInSellMode ? styles.joinSell : styles.joinBuy
          }`}
          onClick={() => navigate('/sign_in', { replace: true })}
        >
          Join the Yard
        </span>
      )}
    </div>
  );
}

function HeaderButtons({ showAccountMenu, isInSellMode }: HeaderButtonsProps) {
  const { loggedIn } = useIdentity();

  return (
    <Grid
      container
      columns={3}
      className={`${styles.buttons} ${
        loggedIn ? styles.loggedIn : styles.loggedOut
      }`}
    >
      <Grid item md={2}>
        <SignInButtons
          showAccountMenu={showAccountMenu}
          isInSellMode={isInSellMode}
        />
      </Grid>
      <Grid item md={1} className={styles.cartWrapper}>
        <CartIconButton location="header" isInSellMode={isInSellMode} />
      </Grid>
    </Grid>
  );
}

export default HeaderButtons;
