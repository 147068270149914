import React from 'react';
import Grid from '@mui/material/Grid';
import { Button, TextLink } from 'components/Buttons';
import DialogPopup from 'components/DialogPopup';
import styles from './confirmNavigationPopup.module.scss';

type Props = {
  open: boolean;
  confirmNavigation: any;
  cancelNavigation: any;
  headerTitle?: string; // title to display in the popup
  message?: string; // message to display in the popup
  confirmNavigationOnClick?: () => any; // action to take when the user confirms navigation
};

export default function ConfirmNavigationPopup({
  open,
  headerTitle = 'Are you sure?',
  message = 'Are you sure you want to leave without saving your changes?',
  cancelNavigation,
  confirmNavigation,
  confirmNavigationOnClick,
}: Props) {
  const actionButtons = () => (
    <Grid container justifyContent="space-between">
      <Grid item xs={5} container alignItems="center">
        <TextLink text="Cancel" onClick={cancelNavigation} textColor="sand20" />
      </Grid>
      <Grid item xs={5}>
        <Button
          roundedButton
          buttonColor="sand20"
          text="Confirm"
          onClick={() => {
            confirmNavigation();
            if (confirmNavigationOnClick) confirmNavigationOnClick();
          }}
          containerClassName={styles.buttonContainer}
        />
      </Grid>
    </Grid>
  );

  return (
    <DialogPopup
      open={open}
      onClose={cancelNavigation}
      title={headerTitle}
      content={message}
      actionButtons={actionButtons()}
    />
  );
}
