import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useModal } from 'react-modal-hook';
import { useScreenSize } from 'hooks';
import StandardModal from 'components/StandardModal/StandardModal';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/leftArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/rightArrowIcon.svg';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from './imageSection.module.scss';

type Props = {
  listingImages: {
    url: string;
    fileName: string;
    mimeType: string;
  }[];
};

type CarouselProps = {
  listingImages: {
    url: string;
    fileName: string;
    mimeType: string;
  }[];
  inModal?: boolean;
  showCarouselModal?: () => any;
  startingIndex: number;
  setStartingIndex: (imageIndex: number) => any;
};

function Carousel({
  listingImages,
  inModal = false,
  showCarouselModal,
  startingIndex,
  setStartingIndex,
}: CarouselProps) {
  const screenSize = useScreenSize();
  const containerClassName = inModal
    ? styles.modalImageContainer
    : styles.pdpImageContainer;

  const navigationButtons = () => {
    if (inModal) {
      return (
        <Grid
          container
          justifyContent="space-around"
          className={styles.navButtonsContainer}
        >
          <ButtonBack className={styles.navButton}>
            <LeftArrowIcon className={styles.icon} />
          </ButtonBack>
          <ButtonNext className={styles.navButton}>
            <RightArrowIcon className={styles.icon} />
          </ButtonNext>
        </Grid>
      );
    }

    return (
      <Grid container justifyContent="space-between">
        <div className={`${styles.buttonWrapper} ${styles.leftButton}`}>
          <ButtonBack className={styles.navButton}>
            <LeftArrowIcon className={styles.icon} />
          </ButtonBack>
        </div>
        <div className={`${styles.buttonWrapper} ${styles.rightButton}`}>
          <ButtonNext className={styles.navButton}>
            <RightArrowIcon className={styles.icon} />
          </ButtonNext>
        </div>
      </Grid>
    );
  };

  const naturalSlideHeight = () => {
    if (inModal) {
      return 300;
    } else if (screenSize === 'xs') {
      return 224;
    } else if (screenSize === 'md') {
      return 600;
    }
    return 600;
  };

  const naturalSlideWidth = () => {
    if (inModal) {
      return 300;
    } else if (screenSize === 'xs') {
      return 224;
    } else if (screenSize === 'md') {
      return 750;
    }
    return 750;
  };

  return (
    <Grid container className={containerClassName}>
      <div className={styles.carouselContainer}>
        <CarouselProvider
          naturalSlideWidth={naturalSlideWidth()}
          naturalSlideHeight={naturalSlideHeight()}
          totalSlides={listingImages.length}
          currentSlide={startingIndex}
          className={styles.carousel}
          infinite
          isIntrinsicHeight
          touchEnabled
        >
          <Slider
            className={styles.slider}
            classNameTrayWrap={styles.sliderTrayWrapper}
            classNameTray={styles.sliderTray}
          >
            {listingImages.map((image, idx) => (
              <Slide
                index={idx}
                key={image.url}
                className={styles.slide}
                innerClassName={styles.innerSlide}
                onClick={() => {
                  if (!inModal && showCarouselModal) {
                    setStartingIndex(idx);
                    showCarouselModal();
                  }
                }}
              >
                <img
                  className={styles.fullImage}
                  src={image.url}
                  alt={image.fileName}
                />
              </Slide>
            ))}
          </Slider>
          {listingImages.length > 1 && navigationButtons()}
        </CarouselProvider>
      </div>
    </Grid>
  );
}

function ImageSection({ listingImages }: Props) {
  const [startingIndex, setStartingIndex] = useState(0);

  const [showCarouselModal, hideCarouselModal] = useModal(
    () => (
      <StandardModal
        modalContent={
          <Carousel
            listingImages={listingImages}
            inModal
            startingIndex={startingIndex}
            setStartingIndex={setStartingIndex}
          />
        }
        modalClassName={styles.imageCarouselModal}
        closeModalAction={() => {
          hideCarouselModal();
        }}
        showCloseButton
        showBackButton={false}
      />
    ),
    [startingIndex, setStartingIndex, listingImages],
  );

  return (
    <Grid item xs={12} sm={10.5} md={11}>
      <div className={styles.imagesContainer}>
        <Carousel
          listingImages={listingImages}
          showCarouselModal={showCarouselModal}
          startingIndex={startingIndex}
          setStartingIndex={setStartingIndex}
        />
      </div>
    </Grid>
  );
}

export default ImageSection;
