import React from 'react';
import { useDispatch } from 'react-redux';
import { hideBanner } from 'slices/accountSlice';
import Grid from '@mui/material/Grid';
import { TextLink } from 'components/Buttons';
import styles from './banner.module.scss';

type Props = {
  bannerText: string;
  buttonText: string | JSX.Element;
  buttonAction?: any;
  href?: string;
  newTab?: boolean; // should the link open in a new tab, used with href
  showCancelButton?: boolean;
  className?: string;
  hideButton?: boolean;
};

function Banner({
  bannerText,
  buttonText,
  buttonAction,
  href,
  newTab = false,
  className,
  hideButton = false,
  showCancelButton = true,
}: Props) {
  const dispatch = useDispatch();
  const cancelClassName = hideButton ? styles.button : styles.cancelButton;

  const actionButton = () => {
    if (href) {
      return (
        <TextLink
          textColor="white"
          href={href}
          newTab={newTab}
          className={styles.button}
        >
          {buttonText}
        </TextLink>
      );
    }

    return (
      <TextLink
        textColor="white"
        onClick={buttonAction}
        className={styles.button}
      >
        {buttonText}
      </TextLink>
    );
  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Grid container className={styles.innerWrapper}>
        <Grid item xs={12} sm={7} container className={styles.textWrapper}>
          {bannerText}
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          {showCancelButton && (
            <TextLink
              textColor="white"
              onClick={() => dispatch(hideBanner())}
              className={cancelClassName}
            >
              CANCEL
            </TextLink>
          )}
          {!hideButton && actionButton()}
        </Grid>
      </Grid>
    </div>
  );
}

export default Banner;
