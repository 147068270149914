import React from 'react';
import { useNavigate } from 'react-router';
import { SupportTicketForm } from 'components/Forms';
import FormScreenLayout from '../components/FormScreenLayout';

export default function SupportForm() {
  const navigate = useNavigate();
  const onCancel = () => navigate('/help', { replace: true });

  return (
    <FormScreenLayout onCancel={onCancel}>
      <SupportTicketForm onCancel={onCancel} />
    </FormScreenLayout>
  );
}
