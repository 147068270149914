/* eslint-disable react/no-array-index-key */
import { Box, Divider, H2 } from 'parts';
import React, { Fragment } from 'react';
import { IPageSubheadingProps } from './types';

function PageCallout({ text }: IPageSubheadingProps) {
  const isText = typeof text === 'string';

  return (
    <Box mt="68px" maxWidth="1150px" width="100%">
      <Divider fullDivider />
      <Box my="88px">
        {isText ? (
          <H2>{text}</H2>
        ) : (
          <H2>
            {text.map((item, index) => (
              <Fragment key={index}>{item}</Fragment>
            ))}
          </H2>
        )}
      </Box>
      <Divider fullDivider />
    </Box>
  );
}

export default PageCallout;
