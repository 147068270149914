import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CategoryHeader from './CategoryScreen/CategoryHeader';
import SearchResultsHeader from './SearchResultsScreen/SearchResultsHeader';
import styles from './shoppingScreen.module.scss';

function Header() {
  const location = useLocation();
  const params = useParams();
  const { categoryName = '', subcategoryName = '' } = params;
  const onSubcategoryScreen = subcategoryName !== '';
  const onSearchResultsScreen = location.pathname.includes('/search_results');

  const subcategoryHeader = () => (
    <div className={styles.headerContainer}>
      <Grid container justifyContent="center" className={styles.maxWidth}>
        <Grid item xs={12} md={10} className={styles.title}>
          {subcategoryName}
        </Grid>
      </Grid>
    </div>
  );

  if (onSearchResultsScreen) {
    return <SearchResultsHeader />;
  } else if (onSubcategoryScreen) {
    return subcategoryHeader();
  } else {
    return <CategoryHeader categoryName={categoryName} />;
  }
}

export default Header;
