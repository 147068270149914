import React from 'react';
import styles from './radioButton.module.scss';

interface Props {
  label: string;
  checked: boolean;
  onClick: () => any;
}

function RadioButton({ label, checked, onClick }: Props) {
  return (
    <div className={styles.radioButtonContainer}>
      <label className={styles.label}>
        <input
          type="radio"
          checked={checked}
          onChange={onClick}
          className={styles.input}
        />
        {label}
      </label>
    </div>
  );
}

export default RadioButton;
