import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import { Button, TextLink } from 'components/Buttons';
import DialogPopup from 'components/DialogPopup';
import styles from './getHelpPopup.module.scss';

type Props = {
  message: string; // message to display in the popup
  headerTitle?: string; // title to display in the popup, defaults is 'Get Help'
  closePopup?: () => any; // if we need to close the popup from the parent component
};

function GetHelpPopup({
  headerTitle = 'Get Help',
  message,
  closePopup,
}: Props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const handleDialogClose = () => {
    if (closePopup) {
      closePopup();
    }
    setIsOpen(false);
  };

  const actionButtons = () => (
    <Grid container justifyContent="space-between">
      <Grid item xs={5} container alignItems="center">
        <TextLink
          text="Cancel"
          onClick={handleDialogClose}
          textColor="sand20"
        />
      </Grid>
      <Grid item xs={5}>
        <Button
          roundedButton
          buttonColor="sand20"
          text="Get Help"
          onClick={() => {
            navigate('/help');
          }}
          containerClassName={styles.buttonContainer}
        />
      </Grid>
    </Grid>
  );

  return (
    <DialogPopup
      open={isOpen}
      title={headerTitle}
      content={message}
      onClose={handleDialogClose}
      actionButtons={actionButtons()}
    />
  );
}

export default GetHelpPopup;
