/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@mui/material';
import { COLORS } from 'style/colors';

interface CheckboxPropsInterface extends MuiCheckboxProps {
  label?: string;
}

function CoreCheckbox(props: MuiCheckboxProps) {
  return (
    <MuiCheckbox
      {...props}
      sx={{
        color: COLORS.sand,
        '&.Mui-checked': {
          color: COLORS.midnightBlack,
        },
      }}
    />
  );
}

function Checkbox(props: CheckboxPropsInterface) {
  const { label } = props;

  if (label) {
    return (
      <FormControlLabel control={<CoreCheckbox {...props} />} label={label} />
    );
  }

  return <CoreCheckbox {...props} />;
}

export default Checkbox;
