import React, { ChangeEventHandler, FocusEventHandler, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as HelpIcon } from 'assets/icons/helpIcon.svg';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { COLORS } from 'style/colors';
import formStyles from 'style/formStyles.module.scss'; // used for global input styling
import styles from './textInput.module.scss';

type Props = {
  inputClassName?: string;
  containerClassName?: string;
  placeholder?: string;
  value?: string | number | undefined | null;
  invalidUserInput?: boolean; // use for red border when user input is invalid
  label?: string; // use this instead of placeholder if you want a floating label input
  assistiveText?: string; // text below the input to assist the user
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  type?: string;
  min?: number;
  step?: number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  showHelpIcon?: boolean; // default is false
  helpMessage?: {
    title: any;
    body: any;
  };
  showDollarSign?: boolean;
  name?: string;
  autoComplete?: string;
  wholeNumbersOnly?: boolean; // should the input only allow whole positive numbers, still need to use type=number with this
  preventScrolling?: boolean; // used to prevent scrolling on number inputs from changing the value - default is true
};

export function TextInput({
  inputClassName,
  containerClassName,
  placeholder,
  value,
  disabled = false,
  invalidUserInput,
  label,
  assistiveText,
  onChange,
  showHelpIcon = false,
  helpMessage,
  showDollarSign,
  wholeNumbersOnly = false,
  preventScrolling = true,
  ...props
}: Props) {
  const [showDialog, setShowDialog] = useState(false);
  // used for coloring the help icon
  const [inFocus, setInFocus] = useState(false);

  const showAssistiveText = assistiveText && assistiveText !== '';

  // for css color if the input contains a value
  const filledClassName = value && formStyles.filled;
  // for css transform if the input contains a value
  const transformLabel = value && formStyles.transform;
  // for css if a label is present
  const inputWithLabelClassName = label && formStyles.defaultInputWithLabel;
  // for css if the user provides an invalid value
  const invalidInput = invalidUserInput && formStyles.invalidUserInput;
  // for css of disabled input
  const disabledInput = disabled && formStyles.disabledInput;

  let helpIconColor = COLORS.sand20;
  if (invalidUserInput || inFocus) {
    helpIconColor = COLORS.midnightBlack;
  } else if (value) {
    helpIconColor = COLORS.sand;
  }

  const dollarSign = () => {
    if (showDollarSign && (inFocus || value)) {
      return <div className={styles.dollarSign}>$</div>;
    }
    return null;
  };

  const checkForNegativeOrDecimalKeys = (keyCode: number) => {
    // 109 = keypad subtract; 189 = dash
    const negativeKeys = keyCode === 109 || keyCode === 189;
    // 110 = keypad decimal; 190 = period
    const periodKeys = keyCode === 110 || keyCode === 190;
    // 69 = e; don't allow the user to use exponents ('e' = exponents in type=number inputs)
    const eKey = keyCode === 69;

    return negativeKeys || periodKeys || eKey;
  };

  return (
    <div className={`${styles.wrapper} ${containerClassName}`}>
      <div
        className={classNames(
          formStyles.defaultInputWrapper,
          filledClassName,
          invalidInput,
          disabledInput,
        )}
      >
        {label && (
          <label
            className={`${formStyles.defaultInputLabel} ${transformLabel}`}
            htmlFor={label}
          >
            {label}
          </label>
        )}
        {dollarSign()}
        <input
          className={classNames(
            inputClassName,
            formStyles.defaultInput,
            styles.input,
            inputWithLabelClassName,
            showDollarSign && styles.dollarInput,
          )}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          value={value || ''}
          onFocus={() => setInFocus(true)}
          onBlur={() => setInFocus(false)}
          onKeyDown={(e: any) => {
            if (wholeNumbersOnly) {
              const invalidKeys = checkForNegativeOrDecimalKeys(e.keyCode);
              if (invalidKeys) {
                e.preventDefault();
              }
            }
          }}
          onWheel={(e: any) => {
            if (preventScrolling && props.type === 'number') {
              e.target.blur();
            }
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        {showHelpIcon && (
          <HelpIcon
            className={styles.helpIcon}
            fill={helpIconColor}
            onClick={() => setShowDialog(true)}
          />
        )}
        {showDialog && (
          <DialogPopup
            open={showDialog}
            onClose={() => setShowDialog(false)}
            title={helpMessage?.title}
            content={helpMessage?.body}
          />
        )}
      </div>
      {showAssistiveText && (
        <div
          className={`${formStyles.assistiveText} ${
            invalidUserInput && formStyles.redAssistiveText
          }`}
        >
          {assistiveText}
        </div>
      )}
    </div>
  );
}

export default TextInput;
