import React from 'react';
import classNames from 'classnames';
import { ITypography } from './types';
import styles from './typography.module.scss';

function H4({ children, bold }: ITypography) {
  const boldClass = bold && styles.bold;

  return <h4 className={classNames(styles.h4, boldClass)}>{children}</h4>;
}

export default H4;
