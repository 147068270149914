import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Order } from 'api';
import StandardModal from 'components/StandardModal';
import { COLORS } from 'style/colors';
import robotoFont from 'style/fonts/Roboto-Regular.ttf';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import AddPaymentMethodForm from './AddPaymentMethodForm';
import styles from './stripeElements.module.scss';

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY || '';
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripePromise = loadStripe(stripeApiKey);

type Props = {
  clientSecret: string;
  closeModal?: () => void; // currently 'locking' the modal open so that the user can't enter a weird order state - only used for add payment
  order?: Order; // needed for checkout to create zendesk ticket
  isAddPayment?: boolean; // show the add payment method form instead of the checkout form
  // used in Checkout to show the confirm navigate popup if the user tries to leave the checkout screen
  navigationProps?: {
    setIsNavigatingBack: (show: boolean) => void;
    confirmNavigation: any;
  };
};

function StripePayment({
  clientSecret,
  closeModal,
  order,
  isAddPayment,
  navigationProps,
}: Props) {
  const baseUrl = process.env.REACT_APP_BASE_RETURN_URL;

  // appearance info: https://stripe.com/docs/elements/appearance-api
  const appearance = {
    theme: 'none' as const,
    labels: 'floating' as const,
    variables: {
      colorPrimary: COLORS.sand20,
      colorText: COLORS.midnightBlack,
      colorTextSecondary: COLORS.midnightBlack,
      colorTextPlaceholder: COLORS.midnightBlack,
      colorDanger: COLORS.taurusRed40,
      colorIconTabHover: COLORS.white,
      colorIconTabSelected: COLORS.white,
      fontFamily: 'Roboto-Regular, Roboto',
      fontSizeBase: '0.875rem',
      fontSizeXs: '0.75rem',
      fontSizeSm: '0.75rem',
      spacingGridRow: '48px',
      spacingGridColumn: '16px',
      spacingTab: '8px',
      borderRadius: '4px',
    },
    rules: {
      '.Tab': {
        border: `solid 2px ${COLORS.sand20}`,
        fontWeight: 'normal',
      },

      '.Tab:hover': {
        backgroundColor: COLORS.sand20Rollover,
        border: `solid 2px ${COLORS.sand20Rollover}`,
        color: COLORS.white,
        boxShadow:
          '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
      },

      '.Tab--selected': {
        borderColor: COLORS.sand20,
        backgroundColor: COLORS.sand20,
        color: COLORS.white,
      },

      '.TabLabel': {
        fontSize: '14px',
        letterSpacing: '0.44px',
        color: 'inherit',
      },

      '.Input': {
        border: `solid 2px ${COLORS.sand}`,
        backgroundColor: COLORS.offWhiteMaterial,
        fontSize: '14px',
        letterSpacing: '0.44px',
      },

      '.Input:focus': {
        outline: 'none',
        backgroundColor: COLORS.white,
        letterSpacing: '0.44px',
        boxShadow:
          '0 5px 5px -3px rgba(199, 151, 105, 0.2), 0 3px 14px 2px rgba(199, 151, 105, 0.22), 0 8px 10px 1px rgba(199, 151, 105, 0.19)',
      },

      '.Input--empty': {
        border: `solid 1px ${COLORS.sand20}`,
        backgroundColor: COLORS.containerStateOffWhiteAlpha60,
        letterSpacing: '0.44px',
      },

      '.Input--invalid': {
        border: `solid 2px ${COLORS.taurusRed40}`,
        letterSpacing: '0.44px',
      },

      '.Label--invalid': {
        color: COLORS.taurusRed40,
      },

      '.Label--resting': {
        color: COLORS.midnightBlack,
        fontSize: '14px',
        letterSpacing: '0.44px',
      },

      '.Label--floating': {
        color: COLORS.sand20,
        marginTop: '-5px',
      },

      '.Error': {
        paddingLeft: '5px',
        letterSpacing: '0.44px',
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
    fonts: [
      {
        family: 'Roboto-Regular',
        src: `url(${baseUrl}${robotoFont.toString()})`,
        weight: 'normal',
      },
    ],
  };

  // this is for styling the modal if a saved payment is selected at checkout
  const [savedPaymentSelected, setSavedPaymentSelected] = useState(false);
  const savedPaymentModalClassName =
    savedPaymentSelected && styles.savedPaymentSelected;

  return (
    <StandardModal
      modalClassName={`${styles.modal} ${savedPaymentModalClassName}`}
      contentWrapperClassName={styles.modalContentWrapper}
      modalContent={
        <div className={styles.elementsWrapper}>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              {!isAddPayment && order ? (
                <CheckoutForm
                  order={order}
                  clientSecret={clientSecret}
                  setSavedPaymentSelected={setSavedPaymentSelected}
                  navigationProps={navigationProps}
                />
              ) : (
                <AddPaymentMethodForm />
              )}
            </Elements>
          )}
        </div>
      }
      closeModalAction={() => {
        if (isAddPayment && closeModal) {
          closeModal();
        }
      }}
      fullScreenModal={false}
      showCloseButton={!!(isAddPayment && closeModal)}
    />
  );
}

export default StripePayment;
