import React, { useState, useEffect, useCallback } from 'react';
import TooltipWrapper from 'components/TooltipWrapper';
import { Button } from 'components/Buttons';
import { FieldValidation } from '../types';
import styles from '../productListingScreen.module.scss';

type Props = {
  step: 'category' | 'product description' | 'pricing' | 'shipping' | string;
  validationFields: {
    categoryFieldsValidation: FieldValidation[];
    descriptionFieldsValidation: FieldValidation[];
    pricingFieldsValidation: FieldValidation[];
    shippingFieldsValidation: FieldValidation[];
  };
  buttonProps: {
    continueDisabled: boolean;
    setContinueDisabled: (disabled: boolean) => void;
    handleContinue: () => void;
    handleOnPublish: () => void;
    handleDisabledClick: () => void; // used when some fields are invalid
  };
};

function ContinueButton({ step, validationFields, buttonProps }: Props) {
  const {
    categoryFieldsValidation,
    descriptionFieldsValidation,
    pricingFieldsValidation,
    shippingFieldsValidation,
  } = validationFields;
  const {
    continueDisabled,
    setContinueDisabled,
    handleContinue,
    handleOnPublish,
    handleDisabledClick,
  } = buttonProps;

  const [formIsValid, setFormIsValid] = useState(false);
  const [missingInfo, setMissingInfo] = useState<string[]>([]);
  const [invalidInfo, setInvalidInfo] = useState<string[]>([]);

  const missingInfoAsString = missingInfo.join(', ');
  const invalidInfoAsString = invalidInfo.join(', ');

  const handleSetInvalidFields = useCallback(
    (fields: FieldValidation[]) => {
      const latestMissingInfo: string[] = [];
      const latestInvalidInfo: string[] = [];

      fields.forEach((field) => {
        const { displayName, isMissing, isInvalid } = field;

        if (isMissing) {
          latestMissingInfo.push(displayName);
        } else if (isInvalid) {
          latestInvalidInfo.push(displayName);
        }
      });

      const hasMissingInfo = latestMissingInfo.length > 0;
      const hasInvalidInfo = latestInvalidInfo.length > 0;

      if ((hasMissingInfo || hasInvalidInfo) && formIsValid) {
        setFormIsValid(false);
      }

      if (
        hasMissingInfo &&
        JSON.stringify(latestMissingInfo) !== JSON.stringify(missingInfo)
      ) {
        setMissingInfo(latestMissingInfo);
      } else if (!hasMissingInfo && missingInfo.length > 0) {
        setMissingInfo([]);
      }

      if (
        hasInvalidInfo &&
        JSON.stringify(latestInvalidInfo) !== JSON.stringify(invalidInfo)
      ) {
        setInvalidInfo(latestInvalidInfo);
      } else if (!hasInvalidInfo && invalidInfo.length > 0) {
        setInvalidInfo([]);
      }
    },
    [formIsValid, missingInfo, invalidInfo],
  );

  const handleContinueButton = () => {
    if (continueDisabled && formIsValid) {
      setContinueDisabled(false);
    } else if (!continueDisabled && !formIsValid) {
      setContinueDisabled(true);
    }
  };

  useEffect(handleContinueButton, [
    formIsValid,
    continueDisabled,
    setContinueDisabled,
  ]);

  const handleValidForm = () => {
    if (!formIsValid) {
      setFormIsValid(true);
    }
    if (missingInfo.length > 0) {
      setMissingInfo([]);
    }
    if (invalidInfo.length > 0) {
      setInvalidInfo([]);
    }
  };

  const handleCategoryScreen = () => {
    const hasInvalidFields =
      categoryFieldsValidation.filter(
        (field) => field.isMissing || field.isInvalid,
      ).length > 0;

    if (hasInvalidFields) {
      handleSetInvalidFields(categoryFieldsValidation);
    } else {
      handleValidForm();
    }
  };

  const handleDescriptionScreen = () => {
    const hasInvalidFields =
      descriptionFieldsValidation.filter(
        (field) => field.isMissing || field.isInvalid,
      ).length > 0;

    if (hasInvalidFields) {
      handleSetInvalidFields(descriptionFieldsValidation);
    } else {
      handleValidForm();
    }
  };

  const handlePricingScreen = () => {
    const hasInvalidFields =
      pricingFieldsValidation.filter(
        (field) => field.isMissing || field.isInvalid,
      ).length > 0;

    if (hasInvalidFields) {
      handleSetInvalidFields(pricingFieldsValidation);
    } else {
      handleValidForm();
    }
  };

  const handleShippingScreen = () => {
    const hasInvalidFields =
      shippingFieldsValidation.filter(
        (field) => field.isMissing || field.isInvalid,
      ).length > 0;

    if (hasInvalidFields) {
      handleSetInvalidFields(shippingFieldsValidation);
    } else {
      handleValidForm();
    }
  };

  const lastStep = step === 'shipping';
  let buttonText = 'Description';

  switch (step) {
    case 'category':
      buttonText = 'Description';
      handleCategoryScreen();
      break;
    case 'product description':
      buttonText = 'Pricing';
      handleDescriptionScreen();
      break;
    case 'pricing':
      buttonText = 'Shipping';
      handlePricingScreen();
      break;
    case 'shipping':
      buttonText = 'Publish Your Sale';
      handleShippingScreen();
      break;
    default:
      break;
  }

  const tooltip = () => {
    const missingInfoText = `The following information is required to continue: \n ${missingInfoAsString}`;
    const invalidInfoText = `The following information is invalid: ${invalidInfoAsString}`;

    let text: string | JSX.Element = '';
    if (missingInfo.length > 0 && invalidInfo.length > 0) {
      text = (
        <span style={{ whiteSpace: 'pre-line' }}>
          {`${missingInfoText} \n \n  ${invalidInfoText}`}
        </span>
      );
    } else if (invalidInfo.length > 0) {
      text = invalidInfoText;
    } else if (missingInfo.length > 0) {
      text = missingInfoText;
    }

    return text;
  };

  return (
    <TooltipWrapper tooltipText={tooltip()}>
      <span>
        <Button
          rippleClassName={styles.continueButton}
          buttonClassName={styles.continueButton}
          buttonType="primary"
          buttonColor="sand20"
          title={!lastStep ? `Continue to ${buttonText}` : buttonText}
          onClick={() => {
            if (continueDisabled) {
              handleDisabledClick();
            } else if (!lastStep) {
              handleContinue();
            } else {
              handleOnPublish();
            }
          }}
        />
      </span>
    </TooltipWrapper>
  );
}

export default ContinueButton;
