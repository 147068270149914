import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'components/Buttons';
import TextLink from 'components/Links/TextLink';
import Grid from '@mui/material/Grid';
import Card from '../components/Card';
import styles from './FAQ.module.scss';
import parentStyles from '../helpScreen.module.scss';

function Faq() {
  const navigate = useNavigate();

  const listFAQs = [
    {
      to: '/faqs/#how-yard-works',
      name: 'How does The Yard work?',
    },
    {
      to: '/faqs/#types-sold',
      name: 'What types of products can be sold on The Yard?',
    },
    {
      to: '/faqs/#get-help',
      name: 'How can I get help/support?',
    },
    {
      to: '/faqs/#issues-my-order',
      name: 'What if I have issues with my order?',
    },
    {
      to: '/faqs/#what-region',
      name: 'In what region does The Yard currently operate?',
    },
  ];

  return (
    <Card>
      <Grid container>
        <div className={parentStyles.sectionTitle}>
          Frequently Asked Questions
        </div>
        <Grid container justifyContent="space-between">
          {listFAQs.map((question) => (
            <Grid
              item
              xs={12}
              sm={5.5}
              container
              key={question.name}
              className={styles.question}
            >
              <TextLink
                className={styles.questionLink}
                textColor="black"
                href={question.to}
                hashLink
              >
                {question.name}
              </TextLink>
            </Grid>
          ))}
        </Grid>
        <div className={styles.buttonContainer}>
          <Button
            title="SEE ALL QUESTIONS"
            buttonColor="sand"
            onClick={() => navigate('/faqs')}
          />
        </div>
      </Grid>
    </Card>
  );
}

export default Faq;
