import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import SearchInput from 'components/SearchInput/SearchInput'; // @TODO: NT - Fix export to reduce redundancy
import styles from './mainHeader.module.scss';
import YardLogo from './YardLogo';
import HeaderButtons from './HeaderButtons';

type MainHeaderProps = {
  isInSellMode: boolean;
  showAccountMenu: () => void;
};

function MainHeader({ isInSellMode, showAccountMenu }: MainHeaderProps) {
  // @TODO: NT - Remove use of state after removing state control from SearchInput
  const [searchWords, setSearchWords] = useState('');

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={`${styles.container} ${isInSellMode && styles.sell}`}
    >
      <div className={styles.wrapper}>
        <YardLogo dark={isInSellMode} />
        <HeaderButtons
          showAccountMenu={showAccountMenu}
          isInSellMode={isInSellMode}
        />
        <SearchInput
          className={styles.searchInput}
          placeholder="Search by keyword or CSI Code"
          value={searchWords}
          onChange={(e) => setSearchWords(e.target.value)}
          headerSearch
        />
      </div>
    </Grid>
  );
}

export default MainHeader;
