import React from 'react';
import { Order } from 'api';
import Grid from '@mui/material/Grid';
import { TextLink } from 'components/Links';
import processingStatusIcon from 'assets/icons/statuses/shippingStatuses/processingStatusCircle.svg';
import completeStatusIcon from 'assets/icons/circleCheckedIcon.svg';
import { ReactComponent as PaymentFailedStatusIcon } from 'assets/icons/statuses/disputeStatusIcon.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lockIcon.svg';
import stripeLogo from 'assets/stripeLogos/stripeWordmark-blurple-small.png';
import { COLORS } from 'style/colors';
import Prices from './Prices';
import BuyerPaymentInfo from './BuyerPaymentInfo';
import SellerPayoutInfo from './SellerPayoutInfo';
import CardComponent, { ContainerStates } from '../components/CardComponent';
import styles from './paymentCard.module.scss';

type Props = {
  order: Order;
  isBuyer: boolean;
};

function PaymentCard({ order, isBuyer }: Props) {
  const {
    status: orderStatus,
    buyer,
    seller,
    paid,
    stripe,
    refunded,
    logs = [],
  } = order;
  const billingAddress = isBuyer ? buyer?.address : seller?.address;

  const paymentFailed = logs.some((log) => log.status === 'PAYMENT-FAILED');

  const paymentProcessing =
    orderStatus === 'DRAFT' ||
    orderStatus === 'PENDING-PAYMENT' ||
    orderStatus === 'PAYMENT-FAILED' ||
    orderStatus === 'SUBMITTED';
  // if the orderStatus isn't in paymentProcessing or complete, then payment is sitting in ESCROW
  const buyerSentPayment = !paymentProcessing && orderStatus !== 'COMPLETE';

  const header = paid || paymentFailed ? 'Payment' : 'Payment...';
  // default values are for Buyer modules
  let status = paid ? 'Paid' : 'Processing';
  let statusIcon: string | JSX.Element = paid
    ? completeStatusIcon
    : processingStatusIcon;
  let containerState: ContainerStates = paymentProcessing
    ? 'focus'
    : 'complete';
  let priceContainerColor = paid ? styles.offWhite : styles.white;

  if (isBuyer && paymentFailed) {
    status = 'Payment failed';
    containerState = 'actionNeeded';
    statusIcon = <PaymentFailedStatusIcon fill={COLORS.taurusRed40} />;
  }

  if (!isBuyer) {
    // status, icon, and priceContainer color
    if (paymentProcessing) {
      status = 'Waiting for payment';
      statusIcon = processingStatusIcon;
      priceContainerColor = styles.offWhite;
    } else {
      status = 'Paid';
      statusIcon = completeStatusIcon;
      priceContainerColor = styles.white;
    }
    // card container states
    if (paymentProcessing) {
      containerState = 'pending';
    } else if (buyerSentPayment) {
      containerState = 'focus';
    } else {
      containerState = 'complete';
    }
  }

  const submittedLog = logs?.find((log) => log.status === 'SUBMITTED');
  const submittedOn = submittedLog?.createdAt || '';
  const paidLog = logs?.find((log) => log.status === 'PAID');
  const paidOn = paidLog?.createdAt || '';
  const refundedLog = logs?.find((log) => log.status === 'REFUNDED');
  const refundedOn = refundedLog?.createdAt || '';

  const dateFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  } as const;

  const paidDate = () => {
    if (!paidOn && !submittedOn) return null;

    const displayedDate = paidOn || submittedOn;
    const headerText = paidOn ? 'Paid' : 'Submitted';

    const formattedDate = new Date(displayedDate).toLocaleDateString(
      'en-us',
      dateFormatOptions,
    );

    return (
      <Grid container direction="column">
        <div className={styles.sectionHeader}>{headerText}</div>
        <div className={styles.paidDate}>{formattedDate}</div>
      </Grid>
    );
  };

  const refundedDate = () => {
    if (!refunded) return null;

    const formattedDate = new Date(refundedOn).toLocaleDateString(
      'en-us',
      dateFormatOptions,
    );

    return (
      <Grid container direction="column" className={styles.refundedDate}>
        <div className={styles.sectionHeader}>Refunded</div>
        <div className={styles.paidDate}>{formattedDate}</div>
      </Grid>
    );
  };

  const releasedFunds = (buyerRefunded: boolean) => (
    <Grid
      item
      xs={12}
      container
      direction="column"
      className={styles.releasedFunds}
    >
      <span className={styles.releasedFundsBold}>
        {buyerRefunded
          ? "We've refunded your account."
          : "We've released your funds."}
      </span>
      It may take 5-14 business days for funds to settle.
    </Grid>
  );

  const prices = () => {
    const buyerRefunded = !!(refunded && isBuyer);
    const sellerPaid = !!(!isBuyer && orderStatus === 'COMPLETED');
    let releaseFundsTextTimedOut = false;

    if (buyerRefunded || sellerPaid) {
      // since it takes 5-14 business days for funds to settle, we want to show the release funds text for 15 days past the paid/refunded date
      const startDate = buyerRefunded ? new Date(refundedOn) : new Date(paidOn);
      const timeoutDate = new Date(startDate.setDate(startDate.getDate() + 15));
      const today = new Date();
      releaseFundsTextTimedOut = today < timeoutDate;
    }

    const showReleaseFundsText =
      (sellerPaid || buyerRefunded) && releaseFundsTextTimedOut;

    return (
      <>
        {showReleaseFundsText && releasedFunds(buyerRefunded)}
        <Prices
          order={order}
          isBuyer={isBuyer}
          priceContainerColor={priceContainerColor}
          releaseFundsShown={showReleaseFundsText}
        />
      </>
    );
  };

  const questionsAboutYourOrder = () => (
    <Grid
      item
      xs={12}
      className={`${styles.sectionContainer} ${styles.questions}`}
    >
      Questions about your order? &nbsp;
      <TextLink to="/help" textColor="leather">
        Contact us
      </TextLink>
    </Grid>
  );

  const paymentSettlementMethod = () => {
    const paymentMethodId = stripe?.paymentMethodId || '';

    if (isBuyer) {
      const paymentMethod = paymentFailed
        ? stripe?.failedPaymentMethodId
        : paymentMethodId;
      // if there is no paymentMethodId, then hide this section
      return !paymentMethod ? null : (
        <BuyerPaymentInfo
          paymentMethodId={paymentMethod}
          paymentFailed={paymentFailed}
        />
      );
    }

    return <SellerPayoutInfo />;
  };

  const details = () => {
    // buyer view
    if (isBuyer) {
      return (
        <Grid container className={styles.container}>
          <Grid container direction="row">
            <Grid item xs={6}>
              {paidDate()}
            </Grid>
            <Grid item xs={6}>
              {refundedDate()}
            </Grid>
          </Grid>
          {prices()}
          {questionsAboutYourOrder()}
          {paymentSettlementMethod()}
          <Grid
            item
            xs={12}
            container
            direction="column"
            className={styles.sectionContainer}
          >
            <div
              className={`${styles.sectionHeader} ${styles.largeHeaderSpace}`}
            >
              Billing Address
            </div>
            <div className={styles.text}>
              {buyer?.name}
              <br />
              {billingAddress?.address}
              <br />
              {billingAddress?.city}, {billingAddress?.state}{' '}
              {billingAddress?.zip}
            </div>
          </Grid>
        </Grid>
      );
    }
    // seller view
    return (
      <Grid container className={styles.sellerContainer}>
        {paidDate()}
        {prices()}
        {questionsAboutYourOrder()}
        {!paymentProcessing && (
          <>
            {paymentSettlementMethod()}
            <Grid
              container
              justifyContent="flex-end"
              className={styles.logoWrapper}
            >
              <img
                src={stripeLogo}
                alt="stripe logo"
                className={styles.stripeLogo}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <div>
      <CardComponent
        title={header}
        status={refunded ? 'Refunded' : status}
        icon={statusIcon}
        containerState={containerState}
        redStatus={paymentFailed}
      >
        {details()}
      </CardComponent>
      <div className={styles.detailsFrom}>
        {isBuyer || paymentProcessing ? (
          'Payment details from Stripe'
        ) : (
          <Grid container alignItems="center" justifyContent="flex-end">
            Secured by Stripe
            <LockIcon fill={COLORS.metal} className={styles.lockIcon} />
          </Grid>
        )}
      </div>
    </div>
  );
}

export default PaymentCard;
