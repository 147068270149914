import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useScreenSize } from 'hooks';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import SearchInput from 'components/SearchInput/SearchInput';
import FilterSortMenu from '../FilterSortMenu/FilterSortMenu';
import styles from './searchBar.module.scss';

function SearchBar() {
  const screenSize = useScreenSize();
  const hideFilterMenu = screenSize === 'xs' || screenSize === 'sm';

  const [searchWords, setSearchWords] = useState('');

  const [showFilterModal, hideFilterModal] = useModal(
    () => <FilterSortMenu closeFilterModal={() => hideFilterModal()} />,
    [],
  );

  const filterSortButton = () => (
    <Button
      containerClassName={styles.filterButton}
      buttonClassName={styles.button}
      buttonColor="black"
      text="FILTER/SORT"
      onClick={showFilterModal}
      roundedButton
    />
  );

  return (
    <Grid className={styles.wrapper}>
      <div className={styles.imageBackground} />
      <Grid item xs={12} md={9} className={styles.inputButtonContainer}>
        <SearchInput
          className={styles.searchInput}
          inputClassName={styles.input}
          placeholder="Search in..."
          value={searchWords}
          onChange={(e) => setSearchWords(e.target.value)}
        />
        {hideFilterMenu && filterSortButton()}
      </Grid>
      <div className={styles.imageBackground} />
    </Grid>
  );
}

export default SearchBar;
