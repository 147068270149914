import React from 'react';
import { useNavigate } from 'react-router';
import { useLogOut } from 'hooks';
import { IconButton } from 'components/Buttons';
import { ReactComponent as UserIcon } from 'assets/icons/userIcon.svg';
import { ReactComponent as TermsCondIcon } from 'assets/icons/yardTermsIcon.svg';
// import { ReactComponent as RatingsIcon } from 'assets/icons/starIcon.svg'; removed for MVP: ratings section
// import { ReactComponent as StorefrontIcon } from 'assets/icons/statuses/arrivalStatusIcon.svg'; removed for MVP: storefront
import { ReactComponent as BusinessDetailsIcon } from 'assets/icons/socialPeopleIcon.svg';
import { ReactComponent as SalesIcon } from 'assets/icons/ordersIcon.svg';
// import { ReactComponent as MyTermsIcon } from 'assets/icons/myTermsIcon.svg';
import { ReactComponent as ListingsIcon } from 'assets/icons/statuses/listedStatusIcon.svg';
import { ReactComponent as StripeIcon } from 'assets/icons/paymentMethods/cardPaymentIcon.svg';
// import { ReactComponent as NotificationIcon } from 'assets/icons/notificationIcon.svg'; removed for MVP: notifications
// import { ReactComponent as MessagingIcon } from 'assets/icons/messagingIcon.svg'; removed for MVP: messaging
// import { ReactComponent as HelpTicketIcon } from 'assets/icons/helpTicketIcon.svg'; removed for MVP: help tickets
import { Box, Button } from 'parts';
import styles from './accountHome.module.scss';

function AccountHome() {
  const navigate = useNavigate();
  const { logOut } = useLogOut();

  return (
    <div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>My Profile</div>
        <div className={styles.sectionButtonsContainer}>
          <IconButton
            label="User Details"
            icon={UserIcon}
            onClick={() => navigate('user_details')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
          <IconButton
            label="Stripe"
            icon={StripeIcon}
            onClick={() => navigate('stripe')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
          <IconButton
            label="Yard Terms & Conditions"
            icon={TermsCondIcon}
            onClick={() => navigate('/terms_and_conditions')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
          {/* removed for MVP: <IconButton
            label="Ratings"
            icon={RatingsIcon}
            onClick={() => navigate('ratings')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          /> */}
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>My Business</div>
        <div className={styles.sectionButtonsContainer}>
          {/* removed for MVP: <IconButton
            label="Storefront"
            icon={StorefrontIcon}
            onClick={() => navigate('/storefront')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          /> */}
          <IconButton
            label="Business Details"
            icon={BusinessDetailsIcon}
            onClick={() => navigate('business_details')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
          <IconButton
            label="Sales History"
            icon={SalesIcon}
            onClick={() => navigate('/orders/sales')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
          {/* removed for beta */}
          {/* <IconButton
            label="My Terms"
            icon={MyTermsIcon}
            onClick={() => navigate('seller_terms')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          /> */}
          <IconButton
            label="My Lisitngs"
            icon={ListingsIcon}
            onClick={() => navigate('/selling/my_yard_listings')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
        </div>
      </div>
      {/* removed for MVP: <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>
          Notifications / Messaging
        </div>
        <div className={styles.sectionButtonsContainer}>
          <IconButton
            label="Notifications"
            icon={NotificationIcon}
            onClick={() => navigate('notifications')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
          <IconButton
            label="Messaging"
            icon={MessagingIcon}
            onClick={() => navigate('messaging')}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
          <IconButton
            label="Help Tickets"
            icon={HelpTicketIcon}
            onClick={() => {
              // TO DO: link to zendesk
            }}
            className={styles.iconButton}
            avatarClassName={styles.iconAvatar}
            filledOrBorder="none"
            shadowOutline
            iconStyle={{ width: '24px', height: '24px' }}
          />
        </div>
      </div> */}
      <Box my="100px" maxWidth="100px">
        <Button variant="text" onClick={() => logOut()}>
          Log Out
        </Button>
      </Box>
    </div>
  );
}

export default AccountHome;
