import React from 'react';
import classNames from 'classnames';
import './_toggle.scss';

interface Props {
  isOn: boolean;
  onChange: any;
  className?: string;
  label?: string;
}

export function SwitchToggle({ className, label, isOn, onChange }: Props) {
  let colorToggle = '';
  let buttonColor = '';
  if (isOn) {
    colorToggle = 'toggle-default__checked';
    buttonColor = 'toggle-default__button-checked';
  }

  return (
    <div className={classNames(className, 'switch-toggle-default')}>
      <div className="switch-toggle__toggle">
        <input
          className="switch-toggle__checkbox"
          id="react-switch-new"
          type="checkbox"
          checked={isOn}
          onChange={onChange}
        />
        <label
          className={`switch-toggle__label ${colorToggle}`}
          htmlFor="react-switch-new"
        >
          <span className={`switch-toggle__button ${buttonColor}`} />
        </label>
      </div>
      <div className="switch-toggle__text">{label}</div>
    </div>
  );
}

export default SwitchToggle;
