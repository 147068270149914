import { api } from './generatedApi';

export default api.enhanceEndpoints({
  endpoints: {
    // user
    getUsersByUserId: {
      providesTags: (result, error, { userId }) => [
        { type: 'UserDetails', userId },
      ],
    },
    putUsersByUserIdProfileImage: {
      invalidatesTags: (result, error, { userId }) => [
        { type: 'UserDetails', userId },
      ],
    },
    putUsersByUserId: {
      invalidatesTags: (result, error, { userId }) => [
        { type: 'UserDetails', userId },
      ],
    },
    postRatings: {
      invalidatesTags: (result, error, { rating }) =>
        error
          ? []
          : [
              { type: 'UserOrders', orderId: rating.orderId },
              { type: 'Order', orderId: rating.orderId },
              { type: 'UserDetails', userId: rating.target },
            ],
    },
    // listings
    getListingsUserByUserId: {
      providesTags: (result, error, { userId }) => [
        { type: 'UserListings', userId },
      ],
    },
    getListingsByListingId: {
      providesTags: (result, error, { listingId }) => [
        { type: 'Listing', listingId },
      ],
    },
    getListings: {
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Listings' as const, id }))]
          : [{ type: 'Listings' }],
    },
    getListingsRecent: {
      providesTags: () => [{ type: 'RecentlyViewedListings' }],
    },
    createListing: {
      invalidatesTags: () => [{ type: 'UserListings' }, { type: 'Listings' }],
    },
    putListingsByListingIdUpdate: {
      invalidatesTags: (result, error, { listingId }) => [
        { type: 'UserListings', listingId },
      ],
    },
    deleteListingsByListingIdDeleteListing: {
      invalidatesTags: (result, error, { listingId }) =>
        error
          ? []
          : [
              { type: 'UserListings', listingId },
              { type: 'Listings', listingId },
            ],
    },
    // cart
    getCart: {
      providesTags: () => [{ type: 'UserCart' }],
    },
    postCart: {
      invalidatesTags: () => [{ type: 'UserCart' }],
    },
    // orders
    getOrdersUserByUserId: {
      providesTags: (result, error, { userId }) => [
        { type: 'UserOrders', userId },
      ],
    },
    getOrdersByOrderId: {
      providesTags: (result, error, { orderId }) => [
        { type: 'Order', orderId },
      ],
    },
    postOrders: {
      invalidatesTags: (result, error, { body }) => [
        { type: 'Listings', listingId: body.listingId },
        { type: 'UserCart', listingId: body.listingId },
        { type: 'RecentlyViewedListings', listingId: body.listingId },
      ],
    },
    postOrdersByOrderIdSubmit: {
      invalidatesTags: (result, error, arg) => [
        { type: 'UserOrders', orderId: arg.orderId },
        { type: 'Listing', listingId: arg.order.listingId },
        { type: 'Listings', listingId: arg.order.listingId },
        { type: 'UserCart', listingId: arg.order.listingId },
      ],
    },
    postOrdersByOrderIdCancel: {
      invalidatesTags: (result, error, arg) => [
        { type: 'UserOrders', orderId: arg.orderId },
        { type: 'Order', orderId: arg.orderId },
        { type: 'Listings', listingId: result?.data.order.listingId },
        { type: 'Listing', listingId: result?.data.order.listingId },
        { type: 'UserCart' },
      ],
    },
    postOrdersByOrderIdAccept: {
      invalidatesTags: (result, error, { orderId }) => [
        { type: 'UserOrders', orderId },
        { type: 'Order', orderId },
      ],
    },
    postOrdersByOrderIdReject: {
      invalidatesTags: (result, error, { orderId }) => [
        { type: 'UserOrders', orderId },
        { type: 'Order', orderId },
      ],
    },
    // payment methods
    getPaymentMethods: {
      providesTags: () => [{ type: 'PaymentMethods' }],
    },
    postPaymentMethods: {
      invalidatesTags: [{ type: 'PaymentMethods' }],
    },
    postPaymentMethodsByPaymentMethodIdDefault: {
      invalidatesTags: (result, error, { paymentMethodId }) => [
        { type: 'PaymentMethods', paymentMethodId },
      ],
    },
    deletePaymentMethodsByPaymentMethodId: {
      invalidatesTags: (result, error, { paymentMethodId }) => [
        { type: 'PaymentMethods', paymentMethodId },
      ],
    },
  },
});

export * from './generatedApi';
