import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { useAppSelector, useScreenSize } from 'hooks';
import { setSelectedSort, setSelectedFilters } from 'slices/shoppingSlice';
import categoryIndex from 'constants/categories.json';
import Grid from '@mui/material/Grid';
import StandardModal from 'components/StandardModal';
import MenuDropdown from 'components/MenuDropdown/MenuDropdown';
import { Button } from 'components/Buttons';
import Checkbox from 'components/Checkbox/Checkbox';
import colorVars from 'style/index.module.scss';
import SortMenu from './SortMenu';
import styles from './filterSortMenu.module.scss';

type Option = {
  name: string;
};

type Filters = {
  category?: string[];
  subcategory?: string[];
};

type Props = {
  closeFilterModal?: () => any;
};

function FilterSortMenu({ closeFilterModal }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const screenSize = useScreenSize();
  const showModal = screenSize === 'xs' || screenSize === 'sm';

  const reduxFiltersAndSort = useAppSelector((state) => state.shopping);
  const { selectedFilters = {}, selectedSort } = reduxFiltersAndSort;
  const { category = [], subcategory = [] } = selectedFilters;

  const [categoryCount, setCategoryCount] = useState(0);
  const [subcategoryCount, setSubcategoryCount] = useState(0);
  const [openCategoriesMenu, setOpenCategoriesMenu] = useState(true);
  const [openSubcategoriesMenu, setOpenSubcategoriesMenu] = useState(false);

  const categoryOptions: Option[] = categoryIndex.map((categoryObj) => ({
    name: categoryObj.name.toLowerCase(),
  }));
  const [subcategoryOptions, setSubcategoryOptions] = useState<Option[]>([]);

  const createSubcategoryOptions = () => {
    const availableSubcategories = categoryIndex
      .filter((categoryObj) =>
        category.some(
          (selectedName: string) =>
            categoryObj.name === selectedName.toUpperCase(),
        ),
      )
      .map((fullCategory) =>
        fullCategory.subcategories.map((subCategory) => {
          const subcategoryName = subCategory.name.toLowerCase();
          return {
            name: subcategoryName,
          };
        }),
      )
      .flat();

    setSubcategoryOptions(availableSubcategories);

    if (availableSubcategories.length > 0) {
      setOpenSubcategoriesMenu(true);
    } else if (openSubcategoriesMenu) {
      setOpenSubcategoriesMenu(false);
    }
  };

  useEffect(createSubcategoryOptions, [category, openSubcategoriesMenu]);

  const saveSortBy = (sortType: string) => {
    dispatch(setSelectedSort(sortType));
  };

  const countFilters = () => {
    setCategoryCount(category.length);
    setSubcategoryCount(subcategory.length);
  };

  useEffect(countFilters, [category, subcategory]);

  const switchScreens = (
    latestCategory: string[],
    latestSubCategory: string[],
  ) => {
    const urlPath = location.pathname;
    const originalUrl = urlPath.replace(/%20/g, ' ');

    const displayCategoryScreen =
      latestCategory.length === 1 && latestSubCategory.length === 0;
    const displaySubcategoryScreen =
      latestCategory.length === 1 && latestSubCategory.length === 1;
    const displaySearchResultsScreen =
      latestCategory.length > 1 || latestSubCategory.length > 1;

    if (!urlPath.includes('/search_results')) {
      if (displayCategoryScreen) {
        // only navigate if they aren't already here
        if (originalUrl !== `/shopping/${latestCategory[0]}`) {
          navigate(`/shopping/${latestCategory[0]}`, { replace: true });
        }
      } else if (displaySubcategoryScreen) {
        // only navigate if they aren't already here
        if (
          originalUrl !==
          `/shopping/${latestCategory[0]}/${latestSubCategory[0]}`
        ) {
          navigate(`/shopping/${latestCategory[0]}/${latestSubCategory[0]}`);
        }
      } else if (displaySearchResultsScreen) {
        navigate('/shopping/search_results', { replace: true });
      }
    }
  };

  const saveFilters = (filters: Filters) => {
    dispatch(setSelectedFilters(filters));

    const { category: newCategory = [], subcategory: newSubcategory = [] } =
      filters;
    switchScreens(newCategory, newSubcategory);
  };

  const handleSelectAllAndClear = (
    menu: 'category' | 'subcategory',
    type: 'select' | 'clear',
  ) => {
    const selectAll = type === 'select';

    if (menu === 'category') {
      saveFilters({
        ...selectedFilters,
        category: selectAll ? categoryOptions.map((obj) => obj.name) : [],
      });
    } else {
      saveFilters({
        ...selectedFilters,
        subcategory: selectAll ? subcategoryOptions.map((obj) => obj.name) : [],
      });
    }
  };

  const handleCheck = (
    optionName: string,
    menu: 'category' | 'subcategory',
  ) => {
    const updatedCategories: string[] = [...category];
    const updatedSubcategories: string[] = [...subcategory];

    if (menu === 'category') {
      const existingIndex = category.findIndex(
        (catName) => catName === optionName,
      );

      if (existingIndex !== -1) {
        updatedCategories.splice(existingIndex, 1);
      } else {
        updatedCategories.push(optionName);
      }

      saveFilters({
        ...selectedFilters,
        category: updatedCategories,
      });
    } else {
      const existingIndex = subcategory.findIndex(
        (subcatName) => subcatName === optionName,
      );

      if (existingIndex !== -1) {
        updatedSubcategories.splice(existingIndex, 1);
      } else {
        updatedSubcategories.push(optionName);
      }

      saveFilters({
        ...selectedFilters,
        subcategory: updatedSubcategories,
      });
    }
  };

  const checkboxes = (options: Option[], type: 'category' | 'subcategory') =>
    options.map((option) => {
      // some subcategory names are the same, so pass key a random number as well
      const randomNumber = Math.random();
      const checked =
        type === 'category'
          ? category.includes(option.name) || false
          : subcategory.includes(option.name) || false;

      return (
        <Checkbox
          key={`${option.name}-${randomNumber}`}
          label={option.name}
          checked={checked}
          checkedColor={colorVars.midnightBlack}
          onClick={() => handleCheck(option.name, type)}
          className={styles.checkbox}
          labelClassName={styles.checkboxLabel}
        />
      );
    });

  const filters = () => (
    <Grid container justifyContent="center" className={styles.filtersContainer}>
      <Grid item xs={11} className={styles.menu}>
        <MenuDropdown
          menuTitle="Categories"
          count={categoryCount}
          showMenu={openCategoriesMenu}
          menuOptions={checkboxes(categoryOptions, 'category')}
          setShowMenu={() => setOpenCategoriesMenu(!openCategoriesMenu)}
          titleLink={[
            {
              text: 'Select All',
              onClick: () => handleSelectAllAndClear('category', 'select'),
            },
            {
              text: 'Clear',
              onClick: () => handleSelectAllAndClear('category', 'clear'),
            },
          ]}
        />
      </Grid>
      <Grid item xs={11} className={styles.menu}>
        <MenuDropdown
          menuTitle="Subcategories"
          count={subcategoryCount}
          menuClassName={styles.menuContainer}
          showMenu={openSubcategoriesMenu}
          menuOptions={
            subcategoryOptions.length > 0
              ? checkboxes(subcategoryOptions, 'subcategory')
              : 'Select a category to view subcategories'
          }
          setShowMenu={() => setOpenSubcategoriesMenu(!openSubcategoriesMenu)}
          titleLink={[
            {
              text: 'Select All',
              onClick: () => handleSelectAllAndClear('subcategory', 'select'),
            },
            {
              text: 'Clear',
              onClick: () => handleSelectAllAndClear('subcategory', 'clear'),
            },
          ]}
        />
      </Grid>
    </Grid>
  );

  const displayedContent = () => (
    <Grid container>
      <Grid item xs={12} className={styles.modalHeaderContainer}>
        <Grid item xs={5} className={styles.header}>
          Filter and Sort
        </Grid>
        <Grid item xs={11} className={styles.resultsButtonContainer}>
          <Button
            title="Show Results"
            onClick={closeFilterModal}
            buttonColor="black"
            containerClassName={styles.showResultsButton}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} className={styles.modalMenus}>
        <div className={styles.filterSortBy}>Filter by...</div>
        {filters()}
        <div className={styles.filterSortBy}>Sort by...</div>
        <SortMenu selectedSort={selectedSort} saveSortBy={saveSortBy} />
      </Grid>
    </Grid>
  );

  return showModal ? (
    <StandardModal
      modalContent={displayedContent()}
      closeModalAction={closeFilterModal}
      buttonContainerClassName={styles.modalTopButtons}
      closeIconColor={colorVars.white}
    />
  ) : (
    filters()
  );
}

export default FilterSortMenu;
