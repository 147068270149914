import React, { useState, useEffect } from 'react';
import { User, Address, DeliveryAddress } from 'api';
import { useLogOut } from 'hooks';
import Avatar from '@mui/material/Avatar';
import Loading from 'components/Loading';
import { TextLink } from 'components/Buttons';
import ShippingAddressForm from 'components/Forms/ShippingAddressForm/ShippingAddressFormOld'; // TODO: update to new form
import FormContainer from 'components/FormContainer/FormContainer';
import defaultUserIcon from 'assets/icons/userIcon.svg';
import styles from './contactInfoForm.module.scss';

type Props = {
  userData: User;
  setShipToAddress: (address: Address) => void;
  setShipToAddressIsValid: (isValid: boolean) => void;
  setSaveAddressToUser: (val: boolean) => void;
};

function ContactInfoForm({
  userData,
  setShipToAddress,
  setShipToAddressIsValid,
  setSaveAddressToUser,
}: Props) {
  const { logOut } = useLogOut();
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [profilePicture, setProfilePicture] = useState(defaultUserIcon);

  const [usersSavedAddresses, setUsersSavedAddresses] =
    useState<DeliveryAddress[]>();

  const {
    userSettings,
    name,
    business,
    deliveryAddresses = [],
    address,
  } = userData;

  const setUserData = () => {
    if (userSettings?.profileImageUrl) {
      setProfilePicture(userSettings?.profileImageUrl);
    }

    const defaultAddress = {
      address,
      default: true,
    };

    if (deliveryAddresses.length === 0) {
      setUsersSavedAddresses([defaultAddress]);
    } else {
      setUsersSavedAddresses(deliveryAddresses);
    }
  };

  useEffect(setUserData, [userSettings, address, deliveryAddresses]);

  useEffect(() => {
    // send isValidAddress to parent component
    setShipToAddressIsValid(isValidAddress);
  }, [isValidAddress, setShipToAddressIsValid]);

  const formContent = () => (
    <div>
      <div className={styles.userInfoWrapper}>
        <Avatar className={styles.avatar}>
          <img
            className={styles.image}
            alt="User Profile"
            src={profilePicture}
            onError={() => setProfilePicture(defaultUserIcon)}
          />
        </Avatar>
        <div className={styles.infoContainer}>
          <div className={styles.infoRow}>
            <div className={styles.name}>{business?.name || name}</div>
            <TextLink
              text="Log out"
              textColor="leather"
              onClick={() => logOut()}
              className={styles.link}
            />
          </div>
        </div>
      </div>
      {!usersSavedAddresses ? (
        <Loading />
      ) : (
        <ShippingAddressForm
          headerText="Shipping Address"
          shippingType="delivery"
          usersSavedAddresses={usersSavedAddresses}
          saveAddress={(shippingAddress) => {
            setShipToAddress(shippingAddress);
          }}
          isValidAddress={isValidAddress}
          setIsValidAddress={setIsValidAddress}
          prepopulateAddress={false}
          showSaveCheckbox
          setSaveAddressToUser={setSaveAddressToUser}
        />
      )}
    </div>
  );

  return (
    <FormContainer
      title="Contact Information"
      content={formContent()}
      restingState
      bodyClassName={styles.formContainerBody}
    />
  );
}

export default ContactInfoForm;
