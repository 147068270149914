import React from 'react';
import Grid from '@mui/material/Grid';
import TextLink from 'components/Links/TextLink';
import { ReactComponent as MyTermsIcon } from 'assets/icons/myTermsIcon.svg';
import Card from '../components/Card';
import styles from './terms.module.scss';
import parentStyles from '../helpScreen.module.scss';

const termsList = [
  {
    document: '/privacy_policy',
    name: 'Privacy Policy',
  },
  {
    document: '/terms_and_conditions',
    name: 'Yard Terms and Conditions',
  },
];

function Terms() {
  return (
    <Card halfCard>
      <Grid container>
        <div className={parentStyles.sectionTitle}>Terms and Conditions</div>
        <Grid container justifyContent="space-between">
          {termsList.map((item) => (
            <Grid
              item
              xs={12}
              container
              key={item.name}
              className={styles.documentContainer}
            >
              <MyTermsIcon className={styles.icon} />
              &nbsp;
              <div className={styles.documentName}>
                <TextLink textColor="black" href={item.document}>
                  {item.name}
                </TextLink>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
}

export default Terms;
