import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { useDisableBodyScroll } from 'hooks';
import IconDropdown from 'components/IconDropdown';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/leftArrowIcon.svg';
import colorVars from 'style/index.module.scss';
import styles from './standardModal.module.scss';

type MenuObj = {
  label: string;
  value: string;
  selected?: boolean;
  onClick?: any;
  disabled?: boolean;
};

type Props = {
  modalContent: JSX.Element;
  closeModalAction: any;
  isOpen?: boolean;
  showCloseButton?: boolean; // default is true
  closeIconColor?: string; // default is black
  menuCloseButton?: boolean; // default is false - if true, ellipsis dropdown menu is dispayed instead of 'X'
  menuOptions?: MenuObj[]; // if menuCloseButton, use this to pass what options should display in the menu
  showBackButton?: boolean;
  backButtonText?: string; // default is Back
  backButtonAction?: any;
  modalClassName?: string;
  contentWrapperClassName?: string;
  buttonContainerClassName?: string;
  nestedModal?: boolean; // used for determining showing body scrollbar
  fullScreenModal?: boolean; // default is true
  onlyShowScrollWhenScrolling?: boolean; // default is false
};

function StandardModal({
  modalContent,
  closeModalAction,
  backButtonAction,
  showCloseButton = true,
  closeIconColor = colorVars.midnightBlack,
  menuCloseButton = false,
  menuOptions = [],
  showBackButton = false,
  backButtonText = 'Back',
  buttonContainerClassName = '',
  modalClassName,
  contentWrapperClassName,
  nestedModal = false,
  isOpen = true,
  fullScreenModal = true,
  onlyShowScrollWhenScrolling = false,
}: Props) {
  Modal.setAppElement('#root');
  // hide the scrollbar from the body, then reset it after the modal closes, else there are 2 scrollbars
  const { enableBodyScroll } = useDisableBodyScroll();

  // some modals take the full screen when opened (like login), but others should be
  // a modal displayed over the current page, these classNames are for that styling
  let overlayClassName = '';
  let overScreenModalClassName = '';
  let standardModalClassName = styles.fullScreen;
  if (!fullScreenModal) {
    overlayClassName = styles.overlayScreen;
    overScreenModalClassName = styles.overScreen;
    standardModalClassName = '';
  }

  // this is for hiding the modal scrollbar when resting,
  // and displaying the scrollbar while activly scrolling
  const [showHideScrollClassName, setShowHideScrollClassName] = useState(
    styles.alwaysShowScroll,
  );

  const handleScroll = useCallback(() => {
    if (onlyShowScrollWhenScrolling) {
      // show the scrollbar, then hide it .5 seconds after scrolling is done
      setShowHideScrollClassName(styles.showScrollWhenScrolling);
      setTimeout(() => {
        setShowHideScrollClassName(styles.hideScrollWhenResting);
      }, 500);
    }
  }, [onlyShowScrollWhenScrolling]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    if (onlyShowScrollWhenScrolling) {
      setShowHideScrollClassName(styles.hideScrollWhenResting);
    }
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll, onlyShowScrollWhenScrolling]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalAction}
      onAfterClose={() => {
        // if it is a nested modal, we don't want to show the body scrollbar
        // until the parent modal is closed
        if (!nestedModal) {
          enableBodyScroll();
        }
      }}
      contentLabel="My dialog"
      className={`${styles.modal} ${modalClassName} ${overScreenModalClassName} ${showHideScrollClassName}`}
      overlayClassName={`${styles.modalOverlay} ${overlayClassName}`}
      closeTimeoutMS={500}
    >
      <div
        className={`${styles.modalContentWrapper} ${contentWrapperClassName} ${standardModalClassName}`}
      >
        {(showBackButton || showCloseButton) && (
          <div
            className={`${styles.topButtonContainer} ${buttonContainerClassName}`}
          >
            <div className={styles.buttonWrapper}>
              {showBackButton ? (
                <div className={styles.backButton} onClick={backButtonAction}>
                  <ChevronLeftIcon />
                  {` ${backButtonText}`}
                </div>
              ) : (
                <div />
              )}
              {showCloseButton ? (
                <div className={styles.closeButton} onClick={closeModalAction}>
                  {menuCloseButton ? (
                    <IconDropdown options={menuOptions} />
                  ) : (
                    <CloseIcon fill={closeIconColor} />
                  )}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        )}
        {modalContent}
      </div>
    </Modal>
  );
}

export default StandardModal;
