import React from 'react';
import { Listing } from 'api';
import Grid from '@mui/material/Grid';
import { ReactComponent as HazardousIcon } from 'assets/icons/hazardIcon.svg';
import { ReactComponent as PickupIcon } from 'assets/icons/pdpPickupIcon.svg';
import { ReactComponent as ForkliftIcon } from 'assets/icons/forkliftIcon.svg';
import styles from './goodToKnowSection.module.scss';
import parentStyles from '../productScreen.module.scss';

interface Props {
  listing: Listing;
}

function GoodToKnowSection({ listing }: Props) {
  const { pickupAllowed, properties } = listing;
  const equipmentRequired = false; // TDB on Banyan

  return (
    <Grid item xs={12} className={styles.goodToKnowContainer}>
      <div className={` ${styles.header} ${parentStyles.sectionHeader}`}>
        Good to know...
      </div>
      <Grid>
        {properties.containsHazardousMaterials && (
          <Grid
            item
            xs={8}
            className={`${styles.hazardousItem} ${styles.goodToKnowItem}`}
          >
            <HazardousIcon className={styles.hazardIcon} /> Hazardous
          </Grid>
        )}
        {equipmentRequired && (
          <Grid
            container
            direction="row"
            alignItems="center"
            className={styles.goodToKnowItem}
          >
            <ForkliftIcon className={styles.forkliftIcon} /> Equipment required
            to unload
          </Grid>
        )}
        {pickupAllowed && (
          <Grid
            container
            direction="row"
            alignItems="center"
            className={styles.goodToKnowItem}
          >
            <PickupIcon /> Available for Buyer Pick Up
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default GoodToKnowSection;
