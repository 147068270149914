import React from 'react';
import { Link } from 'react-router-dom';
import darkLogo from 'assets/yardLogos/checkTheYardOnDark.svg';
import lightLogo from 'assets/yardLogos/checkTheYardOnLight.svg';
import styles from './mainHeader.module.scss';

type YardLogoProps = {
  dark?: boolean;
};

function YardLogo({ dark }: YardLogoProps) {
  const to = '/';
  const label = 'Return to Home';
  const image = dark ? darkLogo : lightLogo;

  return (
    <Link to={to} aria-label={label} className={styles.logoWrapper}>
      <img src={image} alt="The Yard Logo" className={styles.logo} />
    </Link>
  );
}

export default YardLogo;
