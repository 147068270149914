// THIS FILE IS AUTO-GENERATED.  ANY CHANGES MADE HERE WILL BE LOST.
// eslint-disable-next-line import/newline-after-import
import { emptySplitApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHealthcheck: build.query<
      GetHealthcheckApiResponse,
      GetHealthcheckApiArg
    >({
      query: () => ({ url: `/healthcheck` }),
    }),
    getConfig: build.query<GetConfigApiResponse, GetConfigApiArg>({
      query: () => ({ url: `/config` }),
    }),
    postAuthLogin: build.mutation<
      PostAuthLoginApiResponse,
      PostAuthLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postAuthRefresh: build.mutation<
      PostAuthRefreshApiResponse,
      PostAuthRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/refresh`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteAuthLogout: build.mutation<
      DeleteAuthLogoutApiResponse,
      DeleteAuthLogoutApiArg
    >({
      query: () => ({ url: `/auth/logout`, method: 'DELETE' }),
    }),
    postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
      query: (queryArg) => ({
        url: `/users`,
        method: 'POST',
        body: queryArg.user,
      }),
    }),
    getUsersByUserId: build.query<
      GetUsersByUserIdApiResponse,
      GetUsersByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}` }),
    }),
    deleteUsersByUserId: build.mutation<
      DeleteUsersByUserIdApiResponse,
      DeleteUsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    putUsersByUserId: build.mutation<
      PutUsersByUserIdApiResponse,
      PutUsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getUsersByUserIdVerify: build.query<
      GetUsersByUserIdVerifyApiResponse,
      GetUsersByUserIdVerifyApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/verify` }),
    }),
    getUsersByUserIdOnboard: build.query<
      GetUsersByUserIdOnboardApiResponse,
      GetUsersByUserIdOnboardApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/onboard` }),
    }),
    getUsersByUserIdDashboard: build.query<
      GetUsersByUserIdDashboardApiResponse,
      GetUsersByUserIdDashboardApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/dashboard` }),
    }),
    getUsersResetPassword: build.query<
      GetUsersResetPasswordApiResponse,
      GetUsersResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/users/reset-password`,
        params: { email: queryArg.email },
      }),
    }),
    postUsersResetPassword: build.mutation<
      PostUsersResetPasswordApiResponse,
      PostUsersResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/users/reset-password`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postUsersByUserIdProfileImage: build.mutation<
      PostUsersByUserIdProfileImageApiResponse,
      PostUsersByUserIdProfileImageApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/profile-image`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putUsersByUserIdProfileImage: build.mutation<
      PutUsersByUserIdProfileImageApiResponse,
      PutUsersByUserIdProfileImageApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/profile-image`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    createListing: build.mutation<
      CreateListingApiResponse,
      CreateListingApiArg
    >({
      query: (queryArg) => ({
        url: `/listings/`,
        method: 'POST',
        body: queryArg.listing,
      }),
    }),
    getListings: build.query<GetListingsApiResponse, GetListingsApiArg>({
      query: (queryArg) => ({
        url: `/listings/`,
        params: {
          sortDirection: queryArg.sortDirection,
          sortBy: queryArg.sortBy,
          page: queryArg.page,
          perPage: queryArg.perPage,
          category: queryArg.category,
          subcategory: queryArg.subcategory,
          sellerUserId: queryArg.sellerUserId,
          keyword: queryArg.keyword,
        },
      }),
    }),
    getListingsByListingId: build.query<
      GetListingsByListingIdApiResponse,
      GetListingsByListingIdApiArg
    >({
      query: (queryArg) => ({ url: `/listings/${queryArg.listingId}` }),
    }),
    getListingsUserByUserId: build.query<
      GetListingsUserByUserIdApiResponse,
      GetListingsUserByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/listings/user/${queryArg.userId}` }),
    }),
    deleteListingsByListingIdDeleteListing: build.mutation<
      DeleteListingsByListingIdDeleteListingApiResponse,
      DeleteListingsByListingIdDeleteListingApiArg
    >({
      query: (queryArg) => ({
        url: `/listings/${queryArg.listingId}/deleteListing`,
        method: 'DELETE',
      }),
    }),
    putListingsByListingIdUpdate: build.mutation<
      PutListingsByListingIdUpdateApiResponse,
      PutListingsByListingIdUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/listings/${queryArg.listingId}/update`,
        method: 'PUT',
        body: queryArg.listing,
      }),
    }),
    createFileUrl: build.mutation<
      CreateFileUrlApiResponse,
      CreateFileUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/listings/files`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteListingFile: build.mutation<
      DeleteListingFileApiResponse,
      DeleteListingFileApiArg
    >({
      query: (queryArg) => ({
        url: `/listings/${queryArg.listingId}/files/${queryArg.fileId}`,
        method: 'DELETE',
        params: { fileType: queryArg.fileType },
      }),
    }),
    getListingsRecent: build.query<
      GetListingsRecentApiResponse,
      GetListingsRecentApiArg
    >({
      query: (queryArg) => ({
        url: `/listings/recent`,
        params: { page: queryArg.page, perPage: queryArg.perPage },
      }),
    }),
    addListingFile: build.mutation<
      AddListingFileApiResponse,
      AddListingFileApiArg
    >({
      query: (queryArg) => ({
        url: `/listings/${queryArg.listingId}/files`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postOrders: build.mutation<PostOrdersApiResponse, PostOrdersApiArg>({
      query: (queryArg) => ({
        url: `/orders`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postOrdersByOrderIdTaxes: build.mutation<
      PostOrdersByOrderIdTaxesApiResponse,
      PostOrdersByOrderIdTaxesApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/${queryArg.orderId}/taxes`,
        method: 'POST',
        body: queryArg.order,
      }),
    }),
    postOrdersByOrderIdSubmit: build.mutation<
      PostOrdersByOrderIdSubmitApiResponse,
      PostOrdersByOrderIdSubmitApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/${queryArg.orderId}/submit`,
        method: 'POST',
        body: queryArg.order,
      }),
    }),
    postOrdersByOrderIdCancel: build.mutation<
      PostOrdersByOrderIdCancelApiResponse,
      PostOrdersByOrderIdCancelApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/${queryArg.orderId}/cancel`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postOrdersByOrderIdAccept: build.mutation<
      PostOrdersByOrderIdAcceptApiResponse,
      PostOrdersByOrderIdAcceptApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/${queryArg.orderId}/accept`,
        method: 'POST',
      }),
    }),
    postOrdersByOrderIdReject: build.mutation<
      PostOrdersByOrderIdRejectApiResponse,
      PostOrdersByOrderIdRejectApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/${queryArg.orderId}/reject`,
        method: 'POST',
      }),
    }),
    getOrdersByOrderId: build.query<
      GetOrdersByOrderIdApiResponse,
      GetOrdersByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/orders/${queryArg.orderId}` }),
    }),
    getOrdersUserByUserId: build.query<
      GetOrdersUserByUserIdApiResponse,
      GetOrdersUserByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/orders/user/${queryArg.userId}` }),
    }),
    postPaymentMethods: build.mutation<
      PostPaymentMethodsApiResponse,
      PostPaymentMethodsApiArg
    >({
      query: () => ({ url: `/payment-methods`, method: 'POST' }),
    }),
    getPaymentMethods: build.query<
      GetPaymentMethodsApiResponse,
      GetPaymentMethodsApiArg
    >({
      query: () => ({ url: `/payment-methods` }),
    }),
    deletePaymentMethodsByPaymentMethodId: build.mutation<
      DeletePaymentMethodsByPaymentMethodIdApiResponse,
      DeletePaymentMethodsByPaymentMethodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/payment-methods/${queryArg.paymentMethodId}`,
        method: 'DELETE',
      }),
    }),
    postPaymentMethodsByPaymentMethodIdDefault: build.mutation<
      PostPaymentMethodsByPaymentMethodIdDefaultApiResponse,
      PostPaymentMethodsByPaymentMethodIdDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/payment-methods/${queryArg.paymentMethodId}/default`,
        method: 'POST',
      }),
    }),
    getPayoutMethods: build.query<
      GetPayoutMethodsApiResponse,
      GetPayoutMethodsApiArg
    >({
      query: () => ({ url: `/payout-methods` }),
    }),
    getCategories: build.query<GetCategoriesApiResponse, GetCategoriesApiArg>({
      query: (queryArg) => ({
        url: `/categories`,
        params: { activeOnly: queryArg.activeOnly },
      }),
    }),
    getRatingsUserByUserId: build.query<
      GetRatingsUserByUserIdApiResponse,
      GetRatingsUserByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/ratings/user/${queryArg.userId}` }),
    }),
    postRatings: build.mutation<PostRatingsApiResponse, PostRatingsApiArg>({
      query: (queryArg) => ({
        url: `/ratings`,
        method: 'POST',
        body: queryArg.rating,
      }),
    }),
    getCart: build.query<GetCartApiResponse, GetCartApiArg>({
      query: () => ({ url: `/cart` }),
    }),
    postCart: build.mutation<PostCartApiResponse, PostCartApiArg>({
      query: (queryArg) => ({
        url: `/cart`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetHealthcheckApiResponse =
  /** status 200 Check the health of the API */ Success & {
    data?: object;
  };
export type GetHealthcheckApiArg = void;
export type GetConfigApiResponse =
  /** status 200 Gets the application config data */ Success & {
    data?: {
      APPLICATION_FEE_RATE: number;
      ORDER_STATUSES: object;
      ORDER_STATUS_MAP: object;
      ZENDESK_BASE_URL: string;
      ZENDESK_DEVELOPMENT_TEST_TICKETS: string;
      API_VERSION: string;
      RELEASE_VERSION?: string;
    };
  };
export type GetConfigApiArg = void;
export type PostAuthLoginApiResponse = /** status 201 Created */ Authentication;
export type PostAuthLoginApiArg = {
  body: {
    email?: string;
    password?: string;
  };
};
export type PostAuthRefreshApiResponse = unknown;
export type PostAuthRefreshApiArg = {
  body: {
    refreshToken?: any;
  };
};
export type DeleteAuthLogoutApiResponse = unknown;
export type DeleteAuthLogoutApiArg = void;
export type PostUsersApiResponse =
  /** status 201 User Resource Response */ Success & {
    data: {
      user: User;
    };
  };
export type PostUsersApiArg = {
  /** Information about the listing to be created */
  user: User;
};
export type GetUsersByUserIdApiResponse =
  /** status 200 User Resource Response */ Success & {
    data: {
      user: User;
    };
  };
export type GetUsersByUserIdApiArg = {
  userId: string;
};
export type DeleteUsersByUserIdApiResponse =
  /** status 200 User Resource Response */ Success & {
    data: {
      user: User;
    };
  };
export type DeleteUsersByUserIdApiArg = {
  userId: string;
};
export type PutUsersByUserIdApiResponse =
  /** status 200 User Resource Response */ Success & {
    data: {
      user: User;
    };
  };
export type PutUsersByUserIdApiArg = {
  userId: string;
  /** Information about the listing to be created */
  body: {
    name?: string;
    phone?: string;
    email?: string;
    business?: Business;
    address?: Address;
    deliveryAddresses?: DeliveryAddress[];
    userSettings?: {
      hideBusiness?: boolean;
      sellerTerms?: string;
    };
  };
};
export type GetUsersByUserIdVerifyApiResponse =
  /** status 200 User Verification */ Success & {
    data?: {
      verified?: boolean;
      user?: User;
    };
  };
export type GetUsersByUserIdVerifyApiArg = {
  userId: string;
};
export type GetUsersByUserIdOnboardApiResponse =
  /** status 200 Onboard Link Response */ Success & {
    data: {
      link: StripeLink;
    };
  };
export type GetUsersByUserIdOnboardApiArg = {
  userId: string;
};
export type GetUsersByUserIdDashboardApiResponse =
  /** status 200 Onboard Link Response */ Success & {
    data: {
      link: StripeLink;
    };
  };
export type GetUsersByUserIdDashboardApiArg = {
  userId: string;
};
export type GetUsersResetPasswordApiResponse =
  /** status 200 Generic App Success */ Success;
export type GetUsersResetPasswordApiArg = {
  email: string;
};
export type PostUsersResetPasswordApiResponse =
  /** status 200 Generic App Success */ Success;
export type PostUsersResetPasswordApiArg = {
  body: {
    token?: string;
    password?: string;
  };
};
export type PostUsersByUserIdProfileImageApiResponse =
  /** status 200 URL Created */ Success & {
    data: {
      url: string;
      fileName?: string;
    };
  };
export type PostUsersByUserIdProfileImageApiArg = {
  userId: string;
  body: {
    mimeType?: string;
  };
};
export type PutUsersByUserIdProfileImageApiResponse =
  /** status 200 URL Created */ Success & {
    data: {
      url: string;
      fileName?: string;
    };
  };
export type PutUsersByUserIdProfileImageApiArg = {
  userId: string;
  body: {
    fileName?: string;
  };
};
export type CreateListingApiResponse =
  /** status 200 The newly created listing object is returned. */ Listing;
export type CreateListingApiArg = {
  /** Information about the listing to be created */
  listing: Listing;
};
export type GetListingsApiResponse =
  /** status 200 A list of listings. */ Listing[];
export type GetListingsApiArg = {
  sortDirection?: string;
  /** A field to sort results by */
  sortBy?: string;
  /** What page of results to get. Zero based. */
  page?: string;
  /** How many results per page */
  perPage?: string;
  /** A category to filter results by */
  category?: string;
  /** A subcategory to filter results by */
  subcategory?: string;
  sellerUserId?: string;
  /** A keyword to filter results by */
  keyword?: string;
};
export type GetListingsByListingIdApiResponse =
  /** status 200 The listing object is returned. */ Listing;
export type GetListingsByListingIdApiArg = {
  /** The listing ID */
  listingId: string;
};
export type GetListingsUserByUserIdApiResponse =
  /** status 200 Listing Resource Response */ Success & {
    data: Listing[];
  };
export type GetListingsUserByUserIdApiArg = {
  /** Id of the user to view listings */
  userId: string;
};
export type DeleteListingsByListingIdDeleteListingApiResponse =
  /** status 200 Listing Resource Response */ Success & {
    data: Listing[];
  };
export type DeleteListingsByListingIdDeleteListingApiArg = {
  listingId: string;
};
export type PutListingsByListingIdUpdateApiResponse =
  /** status 200 The updated listing object is returned. */ Listing;
export type PutListingsByListingIdUpdateApiArg = {
  listingId: string;
  /** Information about the listing to be updated. Not all fields have to be provided. */
  listing: Listing;
};
export type CreateFileUrlApiResponse = /** status 200 Created */ {
  url: string;
  fileName: string;
};
export type CreateFileUrlApiArg = {
  body: {
    mimeType?: string;
  };
};
export type DeleteListingFileApiResponse =
  /** status 200 Deleted. Returns remaining files for listing, if any. */ {
    productImages?: ListingFile[];
    productDocuments?: ListingFile[];
  };
export type DeleteListingFileApiArg = {
  listingId: string;
  fileId: string;
  fileType: ListingFileType;
};
export type GetListingsRecentApiResponse =
  /** status 200 A list of listings. */ Listing[];
export type GetListingsRecentApiArg = {
  /** What page of results to get. Zero based. */
  page?: string;
  /** How many results per page */
  perPage?: string;
};
export type AddListingFileApiResponse = /** status 200 Created */ ListingFile;
export type AddListingFileApiArg = {
  listingId: string;
  body: {
    fileName?: string;
    fileType?: ListingFileType;
    mimeType?: string;
  };
};
export type PostOrdersApiResponse =
  /** status 201 Order Resource Response */ Success & {
    data: {
      order: Order;
    };
  };
export type PostOrdersApiArg = {
  /** Information about the order to be created */
  body: {
    listingId: string;
    quantity: number;
  };
};
export type PostOrdersByOrderIdTaxesApiResponse =
  /** status 201 Order Resource Response */ Success & {
    data: {
      order: Order;
    };
  };
export type PostOrdersByOrderIdTaxesApiArg = {
  orderId: string;
  /** Information about the order to be submitted */
  order: Order;
};
export type PostOrdersByOrderIdSubmitApiResponse =
  /** status 201 Order Resource Response */ Success & {
    data: {
      order: Order;
    };
  };
export type PostOrdersByOrderIdSubmitApiArg = {
  orderId: string;
  /** Information about the order to be submitted */
  order: Order;
};
export type PostOrdersByOrderIdCancelApiResponse =
  /** status 201 Order Resource Response */ Success & {
    data: {
      order: Order;
    };
  };
export type PostOrdersByOrderIdCancelApiArg = {
  orderId: string;
  /** Reason for canceling the order */
  body: {
    reason?: string;
  };
};
export type PostOrdersByOrderIdAcceptApiResponse =
  /** status 201 Order Resource Response */ Success & {
    data: {
      order: Order;
    };
  };
export type PostOrdersByOrderIdAcceptApiArg = {
  orderId: string;
};
export type PostOrdersByOrderIdRejectApiResponse =
  /** status 201 Order Resource Response */ Success & {
    data: {
      order: Order;
    };
  };
export type PostOrdersByOrderIdRejectApiArg = {
  orderId: string;
};
export type GetOrdersByOrderIdApiResponse =
  /** status 200 Order Resource Response */ Success & {
    data: {
      order: Order;
    };
  };
export type GetOrdersByOrderIdApiArg = {
  orderId: string;
};
export type GetOrdersUserByUserIdApiResponse =
  /** status 200 Order Resource Response */ Success & {
    data: {
      sales?: Order[];
      purchases?: Order[];
    };
  };
export type GetOrdersUserByUserIdApiArg = {
  userId: string;
};
export type PostPaymentMethodsApiResponse =
  /** status 200 Payment Method Resource Response */ Success & {
    data: {
      setupIntent: object;
    };
  };
export type PostPaymentMethodsApiArg = void;
export type GetPaymentMethodsApiResponse =
  /** status 200 Payment Method Resource Response */ Success & {
    data: {
      paymentMethods: {
        default: object;
        all: object[];
      };
    };
  };
export type GetPaymentMethodsApiArg = void;
export type DeletePaymentMethodsByPaymentMethodIdApiResponse =
  /** status 200 Generic App Success */ Success;
export type DeletePaymentMethodsByPaymentMethodIdApiArg = {
  paymentMethodId: string;
};
export type PostPaymentMethodsByPaymentMethodIdDefaultApiResponse =
  /** status 200 Generic App Success */ Success;
export type PostPaymentMethodsByPaymentMethodIdDefaultApiArg = {
  paymentMethodId: string;
};
export type GetPayoutMethodsApiResponse =
  /** status 200 Payout Method Resource Response */ Success & {
    data: {
      payoutMethod: object;
    };
  };
export type GetPayoutMethodsApiArg = void;
export type GetCategoriesApiResponse =
  /** status 200 Categories are returned */ Category[];
export type GetCategoriesApiArg = {
  activeOnly?: boolean;
};
export type GetRatingsUserByUserIdApiResponse =
  /** status 200 Rating Resource Response */ Success & {
    data?: {
      rating?: Rating;
    };
  };
export type GetRatingsUserByUserIdApiArg = {
  userId: string;
};
export type PostRatingsApiResponse =
  /** status 200 Rating Resource Response */ Success & {
    data?: {
      rating?: Rating;
    };
  };
export type PostRatingsApiArg = {
  /** User rating */
  rating: Rating;
};
export type GetCartApiResponse =
  /** status 200 Cart Resource Response */ Success & {
    data: Cart[];
  };
export type GetCartApiArg = void;
export type PostCartApiResponse =
  /** status 200 Cart Resource Response */ Success & {
    data: Cart[];
  };
export type PostCartApiArg = {
  body: {
    quantity: number;
    listingId: string;
  };
};
export type Success = {
  success: boolean;
  message: string;
  data: object;
  meta?: object;
};
export type Authentication = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};
export type Business = {
  name: string;
  phone?: string;
};
export type Address = {
  name: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country?: string;
  type?: string;
  instructions?: string;
};
export type DeliveryAddress = {
  address: Address;
  default: boolean;
};
export type UserRoleType = 'user' | 'admin';
export type UserAccountType = 'company' | 'individual';
export type UserIndustry =
  | 'Contractor'
  | 'Supplier/Manufacturer'
  | 'Building Owner/End User'
  | 'Individual';
export type User = {
  id: string;
  active?: boolean;
  name: string;
  email: string;
  business?: Business;
  address: Address;
  deliveryAddresses?: DeliveryAddress[];
  role?: UserRoleType;
  rating: {
    averageRating: number;
    totalRatings: number;
  };
  userSettings: {
    sellerTerms?: string;
    profileImageUrl?: string;
    acceptedTermsDate?: string;
    authorizedOnBehalfOfBusiness?: boolean;
    hideBusiness?: boolean;
  };
  type?: UserAccountType;
  industry?: UserIndustry;
  verified?: boolean;
  stripe?: {
    connectAccountId?: string;
    customerId?: string;
  };
  phone: string;
  isOver18?: boolean;
  createdAt?: string;
};
export type Error = {
  success: boolean;
  message: string;
  code?: number;
  type?: string;
  data: object;
  meta?: object;
};
export type StripeLink = {
  object?: string;
  url: string;
  created: number;
  expires_at?: number;
};
export type ListingFile = {
  fileName: string;
  mimeType: string;
  url: string;
};
export type Package = {
  handlingUnits: number;
  stackable: boolean;
  shipmentType: string;
  pieces: number;
  description: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  class?: string;
  nmfc?: string;
  hazardous: boolean;
  unNumber?: string;
  packingGroup?: string;
  hazardClass?: string;
};
export type Rating = {
  orderId?: string;
  user?: string;
  role?: string;
  target?: string;
  rating?: number;
  reviewText?: string;
  yardRating?: number;
  yardReviewText?: string;
  yardCanFollowUp?: boolean;
};
export type Order = {
  _id?: string;
  __v?: number;
  id: string;
  status: string;
  listingId?: string;
  buyerUserId?: string;
  sellerUserId?: string;
  shipping?: {
    type?: string;
    shipped?: boolean;
    trackingCode?: string;
    fee?: number;
    invoice?: string;
    origin?: Address;
    destination?: Address;
    shippedOn?: string;
    packages?: Package[];
  };
  paymentMethodDescription: string;
  stripe?: {
    connectAccountId?: string;
    customerId?: string;
    paymentIntentId?: string;
    paymentMethodId?: string;
    failedPaymentMethodId?: string;
  };
  noteToSeller?: string;
  noteToBuyer?: string;
  quantity?: number;
  basePrice?: number;
  subTotal: number;
  salesTax: number;
  total: number;
  fees?: number;
  payout?: number;
  createdAt?: string;
  updatedAt?: string;
  number?: string;
  logs?: {
    status?: string;
    message?: string;
    createdAt?: string;
  }[];
  paid?: boolean;
  canceled?: boolean;
  refunded?: boolean;
  buyer?: User;
  seller?: User;
  ratings?: Rating[];
  listing?: Listing;
};
export type Listing = {
  _id?: string;
  number?: string;
  status: string;
  productCondition: string;
  soldAs: string;
  sold?: boolean;
  deleted: boolean;
  productDescription: string;
  category: string;
  subCategory: string;
  sellerUserId: string;
  productName: string;
  productLine?: string;
  productBrand: string;
  productColorOrStyle: string;
  productConditionRating?: number;
  productConditionNotes: string;
  productImages: ListingFile[];
  productDocuments?: ListingFile[];
  listedQty: number;
  remainingQty: number;
  minimumOrder: number;
  basePrice: number;
  fees?: number;
  totalPrice?: number;
  pickupAllowed: boolean;
  shippingAllowed?: boolean;
  shipFromAddress?: Address;
  properties: {
    containsHazardousMaterials: boolean;
    isHazardousMaterial?: boolean;
    isHealthHazard?: boolean;
    isFlammable?: boolean;
    isGeneralHazard?: boolean;
    isGas?: boolean;
    isCorrosive?: boolean;
    isExplosive?: boolean;
    isOxiders?: boolean;
    isEnvironmentalHazard?: boolean;
    isFatalOrDeadly?: boolean;
  };
  packages?: Package[];
  createdAt: string;
  updatedAt?: string;
  __v?: number;
  seller: User;
  orders?: Order[];
  id: string;
};
export type ListingFileType = 'image' | 'document';
export type Category = {
  _id?: string;
  id?: string;
  name?: string;
  number?: string;
  image?: string;
  subcategories?: {
    image?: string;
    name?: string;
    number?: string;
  }[];
};
export type Cart = {
  listing: string | Listing;
  quantity: number;
};
export const {
  useGetHealthcheckQuery,
  useGetConfigQuery,
  usePostAuthLoginMutation,
  usePostAuthRefreshMutation,
  useDeleteAuthLogoutMutation,
  usePostUsersMutation,
  useGetUsersByUserIdQuery,
  useDeleteUsersByUserIdMutation,
  usePutUsersByUserIdMutation,
  useGetUsersByUserIdVerifyQuery,
  useGetUsersByUserIdOnboardQuery,
  useGetUsersByUserIdDashboardQuery,
  useGetUsersResetPasswordQuery,
  usePostUsersResetPasswordMutation,
  usePostUsersByUserIdProfileImageMutation,
  usePutUsersByUserIdProfileImageMutation,
  useCreateListingMutation,
  useGetListingsQuery,
  useGetListingsByListingIdQuery,
  useGetListingsUserByUserIdQuery,
  useDeleteListingsByListingIdDeleteListingMutation,
  usePutListingsByListingIdUpdateMutation,
  useCreateFileUrlMutation,
  useDeleteListingFileMutation,
  useGetListingsRecentQuery,
  useAddListingFileMutation,
  usePostOrdersMutation,
  usePostOrdersByOrderIdTaxesMutation,
  usePostOrdersByOrderIdSubmitMutation,
  usePostOrdersByOrderIdCancelMutation,
  usePostOrdersByOrderIdAcceptMutation,
  usePostOrdersByOrderIdRejectMutation,
  useGetOrdersByOrderIdQuery,
  useGetOrdersUserByUserIdQuery,
  usePostPaymentMethodsMutation,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodsByPaymentMethodIdMutation,
  usePostPaymentMethodsByPaymentMethodIdDefaultMutation,
  useGetPayoutMethodsQuery,
  useGetCategoriesQuery,
  useGetRatingsUserByUserIdQuery,
  usePostRatingsMutation,
  useGetCartQuery,
  usePostCartMutation,
} = injectedRtkApi;
