import { createTheme } from '@mui/material/styles';
import colorVars from 'style/index.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: colorVars.midnightBlack,
    },
  },
  spacing: [4, 8, 12, 16, 20, 24, 28, 32, 40, 48],
});

export default theme;
