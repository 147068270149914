import React from 'react';
import { Listing } from 'api';
import Grid from '@mui/material/Grid';
import styles from './detailsSection.module.scss';
import parentStyles from '../productScreen.module.scss';

interface Props {
  listing: Listing;
}

function DetailsSection({ listing }: Props) {
  const details = [
    {
      name: 'Color/Finish',
      value: listing.productColorOrStyle,
    },
    {
      name: 'Manufacturer',
      value: listing.productBrand,
    },
    ...[
      listing.productLine && {
        name: 'Line',
        value: listing.productLine,
      },
    ],
    {
      name: 'Category',
      value: listing.category,
    },
    {
      name: 'Subcategory',
      value: listing.subCategory,
    },
  ].filter((object) => object);

  return (
    <Grid item xs={12} className={styles.detailsContainer}>
      <div className={parentStyles.sectionHeader}>Details</div>
      <Grid item xs={12}>
        {details.map((detail: any) => (
          <React.Fragment key={detail.name}>
            <Grid
              className={styles.detailRow}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className={styles.detailName}>{detail.name}</div>
              <Grid item xs={7} className={styles.detail}>
                {detail.value}
              </Grid>
            </Grid>
            <div className={styles.divider} />
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
}

export default DetailsSection;
