import React, { useState } from 'react';
import { ReactComponent as HelpIcon } from 'assets/icons/helpIcon.svg';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import colorVars from 'style/index.module.scss';
import styles from './formContainer.module.scss';

// container states: https://zpl.io/XYDy5lX
type Props = {
  title: string | JSX.Element;
  titleClassName?: string;
  content: any;
  actionNeeded?: boolean; // default is false; for setting the Action Needed container state style
  hoverState?: boolean; // default is false; for setting the Hover container state styling
  restingState?: boolean; // default is false; for setting the Resting container state styling
  className?: string;
  bodyClassName?: string;
  showHelpIcon?: boolean; // default is false
  helpMessage?: {
    title: any;
    body: any;
  };
};

function FormContainer({
  title,
  titleClassName,
  content,
  actionNeeded = false,
  hoverState = false,
  restingState = false,
  className,
  bodyClassName,
  showHelpIcon = false,
  helpMessage,
}: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  let formContainerStyle;
  if (actionNeeded) {
    formContainerStyle = styles.actionNeeded;
  } else if (hoverState) {
    formContainerStyle = styles.hoverState;
  } else if (restingState) {
    formContainerStyle = styles.restingState;
  }

  return (
    <div className={`${styles.wrapper} ${formContainerStyle} ${className}`}>
      <div className={`${styles.headerContainer} ${titleClassName}`}>
        <div className={styles.header}>{title}</div>
        {showHelpIcon && (
          <>
            <div
              className={styles.helpIcon}
              onClick={() => setOpenDialog(true)}
            >
              <HelpIcon fill={colorVars.sand} />
            </div>
            <DialogPopup
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              title={helpMessage?.title}
              content={helpMessage?.body}
            />
          </>
        )}
      </div>
      <div className={`${styles.bodyContainer} ${bodyClassName}`}>
        {content}
      </div>
    </div>
  );
}

export default FormContainer;
