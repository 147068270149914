import React from 'react';
import Slider from '@mui/material/Slider';
import TextArea from 'components/TextArea/TextArea';
import productConditionRatings from 'constants/productConditionRatings';
import FormContainer from 'components/FormContainer/FormContainer';
import styles from './components.module.scss';
import { BaseProps } from '../types';

type Props = {
  baseProps: BaseProps;
};

function ConditionForm({ baseProps }: Props) {
  const { descriptionInfo, setDescriptionInfo, invalidProps } = baseProps;
  const { productConditionRating, productConditionNotes } = descriptionInfo;
  const {
    invalidClassName,
    invalidInputsOnClick,
    setInvalidInputsOnClick,
    requiredFieldText,
  } = invalidProps;

  const ratingsSliderOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ];

  const contents = (
    <>
      <div className={styles.warning}>All items are sold as-is</div>
      <div className={styles.sliderContainer}>
        <Slider
          marks={ratingsSliderOptions}
          value={productConditionRating?.number || 1}
          onChange={(e, value) => {
            const number = typeof value === 'number' ? value : value[0];
            const rating = productConditionRatings[number - 1];
            // only update productConditionNotes with default value if there is no user value
            const updateConditionDescription = productConditionRatings.some(
              (option) => option.description === productConditionNotes,
            );

            setDescriptionInfo({
              ...descriptionInfo,
              productConditionRating: { number, name: rating.name },
              ...(updateConditionDescription && {
                productConditionNotes: rating.description,
              }),
            });
          }}
          step={null}
          max={5}
          min={1}
          valueLabelDisplay="auto"
          aria-label="condition slider"
          getAriaValueText={(value: number) => `${value}`}
        />
        <div className={styles.sliderValue}>
          {`${productConditionRating?.number}.`} {productConditionRating?.name}
        </div>
        <TextArea
          value={productConditionNotes || ''}
          onChange={(e: any) =>
            setDescriptionInfo({
              ...descriptionInfo,
              productConditionNotes: e.target.value,
            })
          }
          onBlur={() => {
            if (productConditionNotes === '') {
              const selectedRating = productConditionRating
                ? productConditionRatings[productConditionRating.number - 1]
                : productConditionRatings[0];

              setDescriptionInfo({
                ...descriptionInfo,
                productConditionNotes: selectedRating.description,
              });
            }
            if (
              invalidInputsOnClick.productConditionNotes &&
              productConditionNotes !== ''
            ) {
              setInvalidInputsOnClick({
                ...invalidInputsOnClick,
                productConditionNotes: false,
              });
            }
          }}
          assistiveText={
            invalidInputsOnClick.productConditionNotes ? requiredFieldText : ''
          }
          invalidUserInput={invalidInputsOnClick.productConditionNotes}
          containerClassName={invalidClassName(!productConditionNotes)}
        />
      </div>
    </>
  );

  return (
    <FormContainer
      className={styles.form}
      title="Condition*"
      content={contents}
    />
  );
}

export default ConditionForm;
