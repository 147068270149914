import React from 'react';
import Grid from '@mui/material/Grid';
import RadioButton from 'components/RadioButton/RadioButton';
import styles from './sortMenu.module.scss';
import parentStyles from '../filterSortMenu.module.scss';

type Props = {
  selectedSort: string | undefined;
  saveSortBy: (sort: string) => any;
};

function SortMenu({ selectedSort, saveSortBy }: Props) {
  return (
    <Grid item xs={11} className={`${parentStyles.menu} ${styles.sortByMenu}`}>
      <RadioButton
        label="Date"
        checked={selectedSort === 'date'}
        onClick={() => saveSortBy('date')}
      />
      <RadioButton
        label="Price"
        checked={selectedSort === 'price'}
        onClick={() => saveSortBy('price')}
      />
      <RadioButton
        label="Condition"
        checked={selectedSort === 'condition'}
        onClick={() => saveSortBy('condition')}
      />
    </Grid>
  );
}

export default SortMenu;
