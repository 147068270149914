import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Listing } from 'api';
import { useIsMobile } from 'hooks';
import Grid from '@mui/material/Grid';
import { ReactComponent as ListViewIcon } from 'assets/icons/tableViews/listViewIcon.svg';
import { ReactComponent as CardViewIcon } from 'assets/icons/tableViews/cardViewIcon.svg';
// import { ReactComponent as SingleRowViewIcon } from 'assets/icons/tableViews/singleRowViewIcon.svg'; // removed for beta
import { ReactComponent as LeftArrowIcon } from 'assets/icons/leftArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/rightArrowIcon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterTableIcon.svg';
import Dropdown from 'components/Dropdown/Dropdown';
import IconDropdown from 'components/IconDropdown';
import { IconButton } from 'components/Buttons';
import CardItem from 'components/CardItem/CardItem';
import colorVars from 'style/index.module.scss';
import ListItem from './ListItem/ListItem';
// import RowItem from './RowItem/RowItem'; // removed for beta
import styles from './table.module.scss';

type Props = {
  className?: string;
  tableName: string | JSX.Element;
  data: any;
  sortTable?: boolean; // default is false
  filterTable?: boolean; // default is false
  startingView: 'list' | 'card' | 'single-row' | 'custom';
  customTableView?: (pageData: any) => any; // used with 'custom' startingView
  initialSort?: 'date' | 'best-match' | 'price' | 'condition' | 'seller-rating'; // | 'distance'
  onlyAllowStartingView?: boolean; // use if the table is only allowed to be displayed in the startingView (hide table views) - default is true
  pageSize?: number; // default is 4
  subHeader?: string; // a name or link that will appear next to the table name
  subHeaderOnClick?: () => void; // if subheader is a link
  hideShowAllLink?: boolean; // default is false
  emptyTableMessage?: string | JSX.Element;
  homeScreenTable?: boolean; // default is false - home screen tables need to display category instead of subcategory
};

function Table({
  tableName,
  startingView,
  className = '',
  initialSort = 'date',
  data,
  sortTable = false,
  filterTable = false,
  onlyAllowStartingView = false,
  pageSize = 4,
  subHeader,
  subHeaderOnClick,
  hideShowAllLink = true,
  customTableView,
  emptyTableMessage,
  homeScreenTable = false,
}: Props) {
  const navigate = useNavigate();
  const isMobileScreen = useIsMobile();

  const [tableView, setTableView] = useState(startingView);

  const noData =
    data === undefined || !Array.isArray(data) || data.length === 0;
  const workingData = noData ? [] : data;

  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(workingData.length / pageSize);
  const pageData = workingData.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize,
  );

  const listView = () => (
    <Grid container>
      {pageData.map((listingObj: Listing) => (
        <ListItem
          listing={listingObj}
          key={listingObj.id}
          homeScreenTable={homeScreenTable}
        />
      ))}
    </Grid>
  );

  const cardView = () => (
    <Grid item xs={12} container>
      {pageData.map((itemObj: any) => (
        <CardItem listing={itemObj} key={itemObj.id} />
      ))}
    </Grid>
  );

  // removed for beta
  // For single-row scrolling on desktop
  // const [scrollX, setScrollX] = useState(0); // For detecting start scroll position
  // const [scrollEnd, setScrollEnd] = useState(false); // For detecting end of scrolling
  // const singleRowContainerRef = React.useRef<HTMLDivElement>(null);
  // // scroll left & right on button click
  // const scrollCheck = () => {
  //   if (singleRowContainerRef.current) {
  //     const { current } = singleRowContainerRef;
  //     setScrollX(current.scrollLeft);

  //     if (
  //       Math.floor(current.scrollWidth - current.scrollLeft) <=
  //       current.offsetWidth
  //     ) {
  //       setScrollEnd(true);
  //     } else {
  //       setScrollEnd(false);
  //     }
  //   }
  // };

  // const scrollTable = (shift: number) => {
  //   if (singleRowContainerRef.current) {
  //     singleRowContainerRef.current.scrollLeft += shift;
  //     scrollCheck();
  //   }
  // };

  // removed for beta
  // const singleRowView = () => (
  //   <div
  //     className={styles.singleRowContainer}
  //     ref={singleRowContainerRef}
  //     onScroll={scrollCheck}
  //   >
  //     {scrollX !== 0 && (
  //       <button
  //         type="button"
  //         className={`${styles.rowScrollButton} ${styles.leftRowScrollButton}`}
  //         onClick={() => scrollTable(-400)}
  //       >
  //         <LeftArrowIcon />
  //       </button>
  //     )}
  //     {workingData.map((itemObj: any) => (
  //       <RowItem productObj={itemObj} key={itemObj.item.product_id} />
  //     ))}
  //     {!scrollEnd && (
  //       <button
  //         type="button"
  //         className={`${styles.rowScrollButton} ${styles.rightRowScrollButton}`}
  //         onClick={() => scrollTable(+400)}
  //       >
  //         <RightArrowIcon />
  //       </button>
  //     )}
  //   </div>
  // );

  // which table view is displayed
  const tableContent = () => {
    switch (tableView) {
      case 'card':
        return cardView();
      case 'list':
        return listView();
      // case 'single-row': // removed for beta
      //   return singleRowView();
      case 'custom':
        if (customTableView) {
          return customTableView(pageData);
        } else {
          return <>You must pass customTableView as a prop</>;
        }
      default:
        return <div />;
    }
  };

  const emptyTable = () => {
    if (emptyTableMessage && typeof emptyTableMessage !== 'string') {
      return emptyTableMessage;
    }
    return (
      <div className={styles.emptyTableText}>
        {emptyTableMessage || 'Nothing to see.'}
      </div>
    );
  };

  // sorting the table
  const sortByOptions = [
    { label: 'Date', value: 'date' },
    { label: 'Price', value: 'price' },
    { label: 'Condition', value: 'condition' },
  ];
  const initialSortBy = sortByOptions.find((obj) => obj.value === initialSort);
  const [sortBy, setSortBy] = useState(initialSortBy);

  // filtering the table
  const filterOptions = [
    { label: 'Date', value: 'date' },
    { label: 'Price', value: 'price' },
    { label: 'Status', value: 'status' },
    { label: 'Category', value: 'category' },
    { label: 'Buyer Ranking', value: 'buyer_ranking' },
  ];

  const displayFilter = () => {
    if (isMobileScreen) {
      return (
        <IconButton
          icon={FilterIcon}
          onClick={() => {
            // TO DO: open filter modal
          }}
          filledOrBorder="border"
        />
      );
    }
    const icon = <IconButton icon={FilterIcon} filledOrBorder="border" />;

    return <IconDropdown options={filterOptions} icon={icon} />;
  };

  // this is to add cursor: pointer if the subheader is a link
  const subHeaderClass =
    subHeader && subHeaderOnClick ? styles.subHeaderLink : '';

  // for styling the sort by / filter / view options row
  let actionRowClass = styles.sortFilterViewContainer;
  if (filterTable && onlyAllowStartingView) {
    actionRowClass = '';
  } else if (!filterTable && !onlyAllowStartingView) {
    actionRowClass = styles.sortViewContainer;
  } else if (!filterTable && !sortTable && onlyAllowStartingView) {
    actionRowClass = styles.noActionRow;
  }

  return (
    <div className={`${styles.tableWrapper} ${className}`}>
      {tableName !== '' && (
        <div className={styles.header}>
          {tableName}
          {subHeader && (
            <span
              className={`${styles.subHeader} ${subHeaderClass}`}
              onClick={() => subHeaderOnClick && subHeaderOnClick()}
            >
              {subHeader}
            </span>
          )}
        </div>
      )}
      {noData ? (
        <div className={styles.emptyTableWrapper}>{emptyTable()}</div>
      ) : (
        <>
          <div className={actionRowClass}>
            <div className={styles.sortAndFilterWrapper}>
              {sortTable && (
                <div className={styles.sortByContainer}>
                  <div className={styles.sortBy}>Sort by</div>
                  <Dropdown
                    options={sortByOptions}
                    value={sortBy}
                    containerClassName={styles.sortByDropdownContainer}
                    onChange={(option: any) => setSortBy(option)}
                    dropdownType="select"
                  />
                </div>
              )}
              {filterTable && (
                <div className={styles.filterContainer}>
                  <div className={styles.filters}>Filters</div>
                  {displayFilter()}
                </div>
              )}
            </div>
            {!onlyAllowStartingView && (
              <div>
                <ListViewIcon
                  className={styles.headerIcon}
                  onClick={() => {
                    setTableView('list');
                  }}
                  style={{
                    fill:
                      tableView === 'list'
                        ? colorVars.midnightBlack
                        : colorVars.sand,
                    marginRight: '12px',
                  }}
                />
                <CardViewIcon
                  className={styles.headerIcon}
                  onClick={() => {
                    setTableView('card');
                  }}
                  style={{
                    fill:
                      tableView === 'card'
                        ? colorVars.midnightBlack
                        : colorVars.sand,
                    marginRight: '12px',
                  }}
                />
                {/* removed for beta */}
                {/* <SingleRowViewIcon
                  className={styles.headerIcon}
                  onClick={() => {
                    setTableView('single-row');
                  }}
                  style={{
                    fill:
                      tableView === 'single-row'
                        ? colorVars.midnightBlack
                        : colorVars.sand,
                  }}
                /> */}
              </div>
            )}
          </div>
          <div className={styles.content}>{tableContent()}</div>
          <div className={styles.bottomSection}>
            {tableView === 'single-row' ? null : (
              //   removed for beta
              //   <div className={styles.seeAllWrapper}>
              //     <button
              //       type="button"
              //       className={styles.seeAllButton}
              //       onClick={() => {
              //         navigate('/shopping');
              //       }}
              //     >
              //       SEE ALL
              //     </button>
              //   </div>
              // )
              <div className={styles.pageNavigationWrapper}>
                <button
                  type="button"
                  className={styles.pageNavigationButton}
                  onClick={() => {
                    if (pageNumber === 1) {
                      // if on the first page, send them to the last page
                      setPageNumber(totalPages);
                    } else {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <LeftArrowIcon />
                </button>
                <div className={styles.pageNavigationCenterInfo}>
                  <div className={styles.pageNumbers}>
                    {pageNumber} of {totalPages}
                  </div>
                  {!hideShowAllLink && (
                    <button
                      type="button"
                      className={styles.showAllLink}
                      onClick={() => {
                        navigate('/shopping');
                      }}
                    >
                      Show all
                    </button>
                  )}
                </div>
                <button
                  type="button"
                  className={styles.pageNavigationButton}
                  onClick={() => {
                    if (pageNumber === totalPages) {
                      // if on the last page, send them to the first page
                      setPageNumber(1);
                    } else {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <RightArrowIcon />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Table;
