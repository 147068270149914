import {
  createSlice,
  // createAsyncThunk
} from '@reduxjs/toolkit';
// import { listingsFetch } from 'api/mockApi';
import { Listing } from 'api';

interface ListingsState {
  data: Listing[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

// this currently returns fake data, uncomment once real data is used
// export const fetchListings = createAsyncThunk('listings/fetch', async () => {
//   const response = await listingsFetch();
//   return response;
// });

const initialState: ListingsState = {
  data: [],
  loading: 'idle',
};

export const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {},
  extraReducers: {
    // [fetchListings.pending.type]: (state) => {
    //   state.loading = 'pending';
    // },
    // [fetchListings.fulfilled.type]: (state, action) => {
    //   state.loading = 'idle';
    //   state.data = action.payload;
    // },
    // [fetchListings.rejected.type]: (state) => {
    //   state.loading = 'idle';
    // },
  },
});

export default listingsSlice.reducer;
