import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store';

export { default as useLogOut } from './useLogOut';
export { default as useBlocker } from './useBlocker';
export { default as useIdentity } from './useIdentity';
export { default as useDisableBodyScroll } from './useDisableBodyScroll';
export { default as useIsMobile } from './useIsMobile';
export { default as useScreenSize } from './useScreenSize';
export { default as useFormatPrice } from './useFormatPrice';
export { default as useGetUserDetails } from './useGetUserDetails';
export { default as useNavigationCallbackPrompt } from './useNavigationCallbackPrompt';
export { default as useGetPaymentDetails } from './useGetPaymentDetails';
export { default as useGetPayoutDetails } from './useGetPayoutDetails';
export { default as useGetStripeAccountVerified } from './useGetStripeAccountVerified';
export { default as useGetListingTotal } from './useGetListingTotal';
export { default as useApiResponseMessage } from './useApiResponseMessage';
export { default as useCreateZendeskRequest } from './useCreateZendeskRequest';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
