import React, { useState, useCallback } from 'react';
import { useCreateFileUrlMutation } from 'api';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/myTermsIcon.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/circleCancelIcon.svg';
import { useDropzone } from 'react-dropzone';
import ReactLoading from 'react-loading';
import colorVars from 'style/index.module.scss';
import styles from './components.module.scss';
import { Description as DescriptionType } from '../../types';

type SnackBar = {
  show: boolean;
  message: string;
};

type Props = {
  descriptionInfo: DescriptionType;
  setDescriptionInfo: (obj: DescriptionType) => any;
  setSnackBar: (obj: SnackBar) => any;
};

function AdditionalDocuments({
  descriptionInfo,
  setDescriptionInfo,
  setSnackBar,
}: Props) {
  const { productDocuments: additionalDocuments = [] } = descriptionInfo;
  const [getUploadUrl] = useCreateFileUrlMutation();
  const [documentUploading, setDocumentUploading] = useState(false);

  const removeDocument = (url: string) => {
    const removedDocument = additionalDocuments.filter(
      (docs) => docs.url !== url,
    );

    setDescriptionInfo({
      ...descriptionInfo,
      productDocuments: removedDocument,
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      const uploadUrl = await getUploadUrl({
        body: {
          mimeType: acceptedFiles[0]?.type,
        },
      }).unwrap();

      if (acceptedFiles && acceptedFiles.length > 0) {
        setDocumentUploading(true);
        try {
          const res = await fetch(uploadUrl.url, {
            method: 'put',
            headers: {
              'Content-Type': acceptedFiles[0].type,
            },
            body: acceptedFiles[0],
          });

          if (res.status === 200) {
            const amazonUrlTrimmed = uploadUrl.url.split('?X-')[0];
            const documentObjects = acceptedFiles.map((obj: any) => ({
              url: amazonUrlTrimmed,
              fileName: obj.name,
              mimeType: obj.type,
            }));

            setDescriptionInfo({
              ...descriptionInfo,
              productDocuments: [...additionalDocuments, ...documentObjects],
            });
          }

          setDocumentUploading(false);
        } catch (err) {
          setSnackBar({
            show: true,
            message: 'An error occurred, please try again',
          });
          setDocumentUploading(false);
        }
      } else {
        setSnackBar({
          show: true,
          message: 'Invalid document type. Must be .pdf, .doc, or .docx',
        });
        setDocumentUploading(false);
      }
    },
    [
      getUploadUrl,
      additionalDocuments,
      descriptionInfo,
      setSnackBar,
      setDescriptionInfo,
    ],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.doc', '.docx', '.pdf'],
    multiple: true,
    onDropRejected: () =>
      setSnackBar({
        show: true,
        message: 'Invalid document type. Must be .pdf, .doc, or .docx',
      }),
  });

  return (
    <div className={styles.additionalDocumentsContainer}>
      <div className={styles.header}>Additional documents</div>
      <div className={styles.documentExamples}>
        PDFs, specifications, warranty, etc
      </div>
      <Grid container justifyContent="flex-end">
        {additionalDocuments.length > 0 &&
          additionalDocuments.map((doc) => (
            <Grid
              container
              key={doc.url}
              className={styles.document}
              alignItems="center"
              justifyContent="space-between"
            >
              <div className={styles.documentNameContainer}>
                <DocumentIcon className={styles.documentIcon} /> {doc.fileName}
              </div>
              <RemoveIcon
                className={styles.removeIcon}
                onClick={() => removeDocument(doc.url)}
              />
            </Grid>
          ))}
        {/* eslint-disable react/jsx-props-no-spreading */}
        <div>
          {!documentUploading && (
            <div className={styles.addDocuments}>
              <Button
                {...getRootProps({ refKey: 'innerRef' })}
                buttonClassName={styles.addDocumentsButton}
                text="UPLOAD"
                icon={
                  <PlusIcon
                    fill={colorVars.white}
                    className={styles.plusIcon}
                  />
                }
                buttonColor="black"
                roundedButton
              />
            </div>
          )}
          {documentUploading && (
            <ReactLoading
              type="spinningBubbles"
              height="100%"
              width="100%"
              color="black"
            />
          )}
          <input {...getInputProps()} />
        </div>
      </Grid>
    </div>
  );
}

export default AdditionalDocuments;
