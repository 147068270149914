import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Filters {
  category?: string[];
  subcategory?: string[];
}

interface ShoppingState {
  selectedFilters?: Filters;
  selectedSort?: string;
  keywords?: string;
}

const initialState: ShoppingState = {
  selectedFilters: {
    category: [],
    subcategory: [],
  },
  selectedSort: '',
  keywords: '',
};

export const shoppingSlice = createSlice({
  name: 'shopping',
  initialState,
  reducers: {
    setSelectedFilters: (state, action: PayloadAction<Filters>) => {
      state.selectedFilters = action.payload;
    },
    setSelectedSort: (state, action: PayloadAction<string>) => {
      state.selectedSort = action.payload;
    },
    setKeywords: (state, action: PayloadAction<string>) => {
      state.keywords = action.payload;
    },
    clearAllFilters: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.selectedSort = '';
      state.keywords = '';
    },
  },
});

export const {
  setSelectedFilters,
  setSelectedSort,
  setKeywords,
  clearAllFilters,
} = shoppingSlice.actions;

export default shoppingSlice.reducer;
