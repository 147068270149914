import React, { useState, useEffect, useCallback } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams, useNavigate } from 'react-router-dom';
import {
  User,
  Order,
  Address,
  Listing,
  useGetOrdersByOrderIdQuery,
  usePutUsersByUserIdMutation,
  usePostOrdersByOrderIdTaxesMutation,
  usePostOrdersByOrderIdSubmitMutation,
  usePostOrdersByOrderIdCancelMutation,
} from 'api';
import { useNavigationCallbackPrompt, useApiResponseMessage } from 'hooks';
import Grid from '@mui/material/Grid';
import { Button, BackButton } from 'components/Buttons';
import { ConfirmNavigationPopup } from 'components/Popups/';
import TextArea from 'components/TextArea/TextArea';
import StripePayment from 'components/StripePayment';
import TooltipWrapper from 'components/TooltipWrapper';
import Loading from 'components/Loading';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReactComponent as PoweredByStripe } from 'assets/stripeLogos/poweredByStripe-blurple.svg';
import { ReactComponent as YardPrimary } from 'assets/yardLogos/yardPrimary.svg';
import PriceCard from './PriceCard';
import ProductImage from './ProductImage';
import ShippingCard from './ShippingCard';
import ContactInfoForm from './ContactInfoForm';
import styles from './checkoutScreen.module.scss';

type Props = {
  order: Order;
  listing: Listing;
  seller: User;
  buyer: User;
};

function CheckoutScreenContent({ order, listing, seller, buyer }: Props) {
  const navigate = useNavigate();
  const { apiResponseMessage } = useApiResponseMessage();

  // used for the Price card
  const [orderWithUpdatedTax, setOrderWithUpdatedTax] = useState<Order>(order);

  const [shipToAddress, setShipToAddress] = useState<Address>();
  const [shipToAddressIsValid, setShipToAddressIsValid] = useState(false);
  const [saveAddressToUser, setSaveAddressToUser] = useState(false);
  const [shipToAddressTaxCalculated, setShipToAddressTaxCalculated] = useState<
    Address | undefined
  >(order.shipping?.destination);
  const [noteToSeller, setNoteToSeller] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmNavigatePopup, setShowConfirmNavigatePopup] =
    useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useNavigationCallbackPrompt(showConfirmNavigatePopup);
  const [isNavigatingBack, setIsNavigatingBack] = useState(true);

  const determineIfConfirmationModalShows = () => {
    if (isNavigatingBack && !showConfirmNavigatePopup) {
      setShowConfirmNavigatePopup(true);
    } else if (!isNavigatingBack && showConfirmNavigatePopup) {
      setShowConfirmNavigatePopup(false);
    }
  };

  useEffect(determineIfConfirmationModalShows, [
    isNavigatingBack,
    showConfirmNavigatePopup,
  ]);

  const [updateUser] = usePutUsersByUserIdMutation();
  const updateUserDeliveryAddresses = async () => {
    try {
      if (buyer && shipToAddress) {
        const { id: userId, deliveryAddresses = [], address } = buyer;

        // check if user already has address in deliveryAddresses, new users should
        // always have address in deliveryAddresses, but existing users may not
        const deliveryAddressesContainAddress = deliveryAddresses.some(
          (da) => JSON.stringify(da.address) === JSON.stringify(address),
        );

        const addAddress = [];
        if (!deliveryAddressesContainAddress) {
          addAddress.push({
            address,
            default: true,
          });
        }

        await updateUser({
          userId,
          body: {
            ...buyer,
            deliveryAddresses: [
              ...deliveryAddresses,
              ...addAddress,
              {
                address: shipToAddress,
                default: false,
              },
            ],
          },
        });
      }
    } catch (err) {
      apiResponseMessage('There was an error saving your address');
    }
  };

  const [submittedOrder, setSubmittedOrder] = useState<Order>();
  const [clientSecret, setClientSecret] = useState('');
  // we want to lock the payment modal open so the user can't enter a bad order state
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [showPaymentModal, hidePaymentModal] = useModal(
    () => (
      <StripePayment
        clientSecret={clientSecret}
        order={submittedOrder || orderWithUpdatedTax}
        navigationProps={{
          setIsNavigatingBack,
          confirmNavigation,
        }}
      />
    ),
    [clientSecret],
  );

  useEffect(() => {
    if (clientSecret !== '') {
      showPaymentModal();
    }
  }, [clientSecret, showPaymentModal]);

  const [postOrdersByOrderIdTaxes] = usePostOrdersByOrderIdTaxesMutation();
  const calculateTaxApiCall = useCallback(async () => {
    setIsLoading(true);
    try {
      const addressWithCountry = shipToAddress && {
        ...shipToAddress,
        country: 'US',
      };

      const updatedOrder = {
        ...order,
        shipping: {
          ...order.shipping,
          type: 'SHIP',
          destination: addressWithCountry,
        },
      };

      const payload = await postOrdersByOrderIdTaxes({
        orderId: order.id,
        order: updatedOrder,
      }).unwrap();

      if (payload.success) {
        setOrderWithUpdatedTax(payload.data.order);
      }
    } catch (error) {
      apiResponseMessage('Error calculating tax');
    }
    setIsLoading(false);
  }, [order, postOrdersByOrderIdTaxes, shipToAddress, apiResponseMessage]);

  const calculateTax = () => {
    if (
      shipToAddressIsValid &&
      shipToAddress?.state &&
      shipToAddress?.zip &&
      shipToAddress?.city &&
      (shipToAddress?.city !== shipToAddressTaxCalculated?.city ||
        shipToAddress?.state !== shipToAddressTaxCalculated?.state ||
        shipToAddress?.zip !== shipToAddressTaxCalculated?.zip)
    ) {
      setShipToAddressTaxCalculated(shipToAddress);

      calculateTaxApiCall();
    }
  };

  useEffect(calculateTax, [
    shipToAddress,
    shipToAddressIsValid,
    shipToAddressTaxCalculated,
    setShipToAddressTaxCalculated,
    calculateTaxApiCall,
  ]);

  const [submitOrder] = usePostOrdersByOrderIdSubmitMutation();
  const checkout = async () => {
    setIsLoading(true);
    try {
      const updatedOrder = {
        ...orderWithUpdatedTax,
        noteToSeller,
      };

      const payload: any = await submitOrder({
        orderId: order.id,
        order: updatedOrder,
      }).unwrap();

      if (payload.success) {
        setIsLoading(false);
        setSubmittedOrder(payload.data.order);
        // set the client secret to trigger the payment modal
        setClientSecret(payload.data.paymentIntent.client_secret);

        if (saveAddressToUser) {
          updateUserDeliveryAddresses();
        }
      }
    } catch (err) {
      setIsLoading(false);
      apiResponseMessage(
        'Error submitting order. Please try again or contact support for assistance.',
      );
    }
  };

  const [cancelOrder] = usePostOrdersByOrderIdCancelMutation();
  const cancelCheckout = async () => {
    setIsLoading(true);
    try {
      const payload: any = await cancelOrder({
        orderId: order.id,
        body: { reason: 'Canceled At Checkout' },
      }).unwrap();

      if (payload.success) {
        navigate(-1);
      }
    } catch (err) {
      setIsLoading(false);
      apiResponseMessage(
        'Error canceling order. Please try again or contact support for assistance.',
      );
    }
  };

  const productImage = () => (
    <ProductImage
      productName={listing.productName || ''}
      image={listing.productImages[0].url || ''}
      listingId={listing.id || ''}
      category={listing.category}
      subCategory={listing.subCategory}
    />
  );

  const priceCard = () => {
    const sellerAddress = seller?.address;
    const sellerInfo = {
      name: seller?.name,
      address: `${sellerAddress?.city}, ${sellerAddress?.state}`,
      averageRating: seller?.rating.averageRating,
    };
    const {
      salesTax,
      total,
      status,
      basePrice = 0,
      fees = 0,
    } = orderWithUpdatedTax;
    // if the status is still draft, taxes have not been calculated yet so we show TBD
    const taxToBeDetermined = status === 'DRAFT' || !shipToAddress;
    const tax = taxToBeDetermined ? 'TBD' : salesTax;

    // the listing price that is displayed to the buyer is the base price + fees
    const productPrice = basePrice + fees;

    const displayedTotal = taxToBeDetermined ? productPrice : total;

    return (
      <PriceCard
        productPrice={productPrice}
        salesTax={tax}
        total={displayedTotal}
        seller={sellerInfo}
        pickUp={false}
        productImage={productImage()}
      />
    );
  };

  const header = () => (
    <Grid container className={`${styles.wrapper} ${styles.header}`}>
      <Grid container className={styles.maxWidth} justifyContent="space-around">
        <Grid
          item
          xs={12}
          sm={11}
          md={12}
          container
          className={styles.logoHeader}
        >
          <YardPrimary
            className={styles.yardLogo}
            onClick={() => navigate('/')}
          />
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => navigate(-1)}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10} className={styles.title}>
          Checkout
        </Grid>
        <Grid item md={10} className={styles.productImage}>
          {productImage()}
        </Grid>
        <Grid item xs={12} sm={10} className={styles.headerPriceCard}>
          {priceCard()}
        </Grid>
      </Grid>
    </Grid>
  );

  const footer = () => {
    let tooltipText = '';
    if (!shipToAddressIsValid) {
      tooltipText = 'Your shipping address is missing some info';
    }

    return (
      <Grid container item xs={12} className={styles.wrapper}>
        {isLoading && <Loading screenOverlay />}
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          className={styles.poweredByWrapper}
        >
          <PoweredByStripe className={styles.poweredByStripe} />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          className={styles.buttonContainer}
        >
          <Grid item xs={10}>
            <TooltipWrapper tooltipText={tooltipText}>
              <span>
                <Button
                  text="CHECKOUT"
                  buttonColor="sand20"
                  containerClassName={styles.checkoutButton}
                  onClick={checkout}
                  disabled={!shipToAddressIsValid}
                />
              </span>
            </TooltipWrapper>
            <BackButton
              className={styles.cancelButton}
              text="Cancel Checkout"
              onClick={cancelCheckout}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const body = () => (
    <Grid container className={`${styles.wrapper} ${styles.bodyContainer}`}>
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={4}
        className={styles.rightSideBody}
      >
        <Grid
          item
          xs={12}
          className={`${styles.bodyPriceCard} ${styles.section}`}
        >
          {priceCard()}
        </Grid>
        <Grid item xs={12} className={styles.section}>
          <ShippingCard />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={10} md={6}>
        <Grid item xs={12} className={styles.section}>
          <ContactInfoForm
            userData={buyer}
            setShipToAddress={setShipToAddress}
            setShipToAddressIsValid={setShipToAddressIsValid}
            setSaveAddressToUser={setSaveAddressToUser}
          />
        </Grid>
        <Grid item xs={12} container className={styles.noteContainer}>
          <Grid item xs={12} className={styles.addNote}>
            Add a note for the seller?
          </Grid>
          <TextArea
            value={noteToSeller}
            placeholder="Note (optional)"
            onChange={(e: any) => setNoteToSeller(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} className={styles.bodyFooter}>
          {footer()}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <ConfirmNavigationPopup
        /* @ts-ignore */
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        confirmNavigationOnClick={cancelCheckout}
        headerTitle="Cancel Checkout"
        message="Are you sure you want to cancel checkout?"
      />
      <Grid item xs={12} md={12}>
        {header()}
      </Grid>
      <div className={styles.maxWidth}>
        <Grid item xs={12}>
          {body()}
        </Grid>
        <Grid item xs={12} sm={10} className={styles.screenFooter}>
          {footer()}
        </Grid>
      </div>
    </Grid>
  );
}

function CheckoutScreen() {
  const params = useParams();
  const { orderId = '' } = params;
  const { currentData, isFetching } = useGetOrdersByOrderIdQuery({
    orderId,
  });

  const [initialOrder, setInintialOrder] = useState<Order>();
  const [listing, setListing] = useState<Listing>();
  const [buyer, setBuyer] = useState<User>();
  const [seller, setSeller] = useState<User>();

  useEffect(() => {
    if (currentData?.success && !initialOrder) {
      const { order } = currentData.data;
      setInintialOrder(order);
      setListing(order?.listing);
      setBuyer(order?.buyer);
      setSeller(order?.seller);
    }
  }, [currentData, initialOrder]);

  if (isFetching || !initialOrder) {
    return <Loading className={styles.loadingScreen} />;
  }

  // need this check to keep TS from complaining about 'possibly undefined' values
  if (initialOrder && listing && buyer && seller) {
    return (
      <CheckoutScreenContent
        order={initialOrder}
        listing={listing}
        buyer={buyer}
        seller={seller}
      />
    );
  }

  return <div>Product not found</div>;
}

export default CheckoutScreen;
