import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetListingsByListingIdQuery, Listing } from 'api';
import productConditionRatings from 'constants/productConditionRatings';
import { clearAllFilters } from 'slices/shoppingSlice';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import CtaItem from 'components/CtaItem/CtaItem';
import { ReactComponent as DefaultUserIcon } from 'assets/icons/userIcon.svg';
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import NotFound404 from 'screens/NotFound404';
import Loading from 'components/Loading';
import BuySection from './BuySection/BuySection';
import ImageSection from './ImageSection/ImageSection';
import MaterialsSection from './MaterialsSection/MaterialsSection';
import GoodToKnowSection from './GoodToKnowSection/GoodToKnowSection';
import ConditionSection from './ConditionSection/ConditionSection';
import DescriptionSection from './DescriptionSection/DescriptionSection';
import DetailsSection from './DetailsSection/DetailsSection';
import SellerSection from './SellerSection/SellerSection';
import styles from './productScreen.module.scss';

type Props = {
  listing: Listing;
};

function DisplayProduct({ listing }: Props) {
  const {
    seller,
    pickupAllowed,
    properties,
    productImages,
    soldAs,
    productDocuments = [],
    productCondition,
    productConditionRating,
    minimumOrder,
    number: listingNumber,
  } = listing;
  const { userSettings } = seller;
  const isSoldAsLot = soldAs.toLowerCase() === 'lot';
  const [profileImage, setProfileImage] = useState<string | null>();

  const conditionName =
    productCondition.toLowerCase() === 'as-is'
      ? productConditionRatings.find(
          (condition) => condition.rating === productConditionRating,
        )?.name
      : 'New';

  useEffect(() => {
    const profilePicture = userSettings.profileImageUrl ?? null;
    setProfileImage(profilePicture);
  }, [userSettings.profileImageUrl]);

  const bodyClassName = isSoldAsLot ? styles.lotMargin : styles.perUnitMargin;

  return (
    <Grid container className={styles.pdpContainer}>
      <Grid
        item
        xs={12}
        sm={11}
        md={10}
        xl={12}
        container
        className={styles.headerContainer}
      >
        <Grid item xs={12} md={10} xl={12}>
          <Grid item xs={8} md={5} className={styles.header}>
            {listing.productName}
          </Grid>
          <Grid item xs={12} md={10} xl={12} className={styles.listingNumber}>
            {listingNumber}
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            xl={12}
            container
            direction="row"
            alignItems="center"
            className={styles.sellerInfoContainer}
          >
            <>
              <div className={styles.soldBy}>Sold by</div>
              &nbsp;
              <div className={styles.sellerName}>{seller.name}</div>
            </>
            <Avatar className={styles.sellerImageContainer}>
              {profileImage ? (
                <img
                  className={styles.sellerImage}
                  alt="Profile"
                  src={profileImage}
                  onError={() => setProfileImage(null)}
                />
              ) : (
                <DefaultUserIcon />
              )}
            </Avatar>
            {seller.rating.averageRating !== 0 && (
              <>
                {seller.rating.averageRating} <StarIcon />
              </>
            )}
            {!isSoldAsLot && (
              <div className={styles.conditionRow}>
                <span className={styles.condition}>{conditionName}</span>
                <span className={styles.seperatorDot}>&nbsp;•&nbsp;</span>
                <span>{minimumOrder} per package</span>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={styles.imageAndActionContainer}>
        <Grid container className={styles.imageSectionWrapper}>
          <ImageSection listingImages={productImages} />
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          className={styles.buySectionWrapper}
        >
          <BuySection listing={listing} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={11}
        md={10}
        xl={12}
        container
        className={`${styles.bodyContainer} ${bodyClassName}`}
      >
        <Grid
          item
          xs={12}
          md={10}
          xl={12}
          className={styles.detailsAndDescription}
        >
          <DetailsSection listing={listing} />
          <DescriptionSection listing={listing} />
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          xl={12}
          container
          className={styles.sectionBoxes}
        >
          <ConditionSection listing={listing} />
          <Grid item xs={12} md={5} lg={5.2}>
            {productDocuments.length > 0 && (
              <MaterialsSection listing={listing} />
            )}
            {(pickupAllowed || properties?.containsHazardousMaterials) && (
              <GoodToKnowSection listing={listing} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={11}
        md={11}
        xl={12}
        className={styles.sellerSection}
      >
        <SellerSection listing={listing} />
      </Grid>
      <Grid item xs={12} sm={11} md={12} className={styles.pageDivider} />
      <Grid
        item
        xs={12}
        sm={10}
        md={11}
        xl={12}
        className={styles.ctaContainer}
      >
        <CtaItem
          containerClassName={styles.cta}
          headerText="Interested in selling?"
          subtext="Listing your items is easy—You provide the details, and we'll walk you through every step along the way."
          buttonText="GET STARTED"
          screenPath="/product_listing"
        />
      </Grid>
    </Grid>
  );
}

function ProductScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { listingId = '' } = params;
  const [listing, setListing] = useState<Listing>();

  useEffect(() => {
    // since breadcrumbs on this screen send the user to the category
    // & subcategory shopping screens, we want to clear the filters
    // when retuning to this screen
    dispatch(clearAllFilters());
  }, [dispatch]);

  const { currentData, isFetching, isError } = useGetListingsByListingIdQuery(
    { listingId },
    // forcing the query to always fetch when this component is mounted since I can't invalidate the cache after payment is posted
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (currentData) {
      setListing(currentData);
    }
  }, [currentData, setListing]);

  if (isFetching) {
    return <Loading />;
  } else if (isError && !listing) {
    return <NotFound404 type="listing" />;
  }

  return (
    <div className={styles.productScreenContainer}>
      {listing && <DisplayProduct listing={listing} />}
    </div>
  );
}

export default ProductScreen;
