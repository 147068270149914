import { Box, Title } from 'parts';
import React from 'react';
import { IPageHeadingProps } from './types';

function PageHeader({ children }: IPageHeadingProps) {
  return (
    <Box mt={5} maxWidth="920px" width="100%">
      <Title>{children}</Title>
    </Box>
  );
}

export default PageHeader;
