import React from 'react';
import Grid from '@mui/material/Grid';
import { Button, TextLink } from 'components/Buttons';
import styles from './cardButtons.module.scss';

type CtaButtonProps = {
  buttonText: string;
  onClick?: () => any;
  href?: string;
};

function CardButtons({ buttonText, onClick, href }: CtaButtonProps) {
  const actionButton = () => {
    if (href) {
      return (
        <Button
          containerClassName={styles.button}
          title={buttonText}
          buttonColor="sand20"
          href={href}
          newTab
        />
      );
    }

    return (
      <Button
        containerClassName={styles.button}
        title={buttonText}
        buttonColor="sand20"
        onClick={onClick}
      />
    );
  };

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      className={styles.buttonsContainer}
    >
      <Grid item xs={5}>
        <TextLink
          className={styles.whatIsThisLink}
          text="What's this?"
          textColor="sand20"
          onClick={() => {
            // TO DO
          }}
        />
      </Grid>
      <Grid item xs={7}>
        {actionButton()}
      </Grid>
    </Grid>
  );
}

export default CardButtons;
