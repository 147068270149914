import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Listing, usePostOrdersMutation } from 'api';
import { useApiResponseMessage } from 'hooks';
import { Button } from 'components/Buttons';
import TooltipWrapper from 'components/TooltipWrapper';

type Props = {
  listing: Listing;
  buttonType: 'text' | 'button' | 'dropdownOption';
  cartQuantity?: number; // passed from Cart to determine price
  dropdownOptionProps?: {
    optionClassName: string;
    closeMenu: () => void;
  };
};

function BuyNowButton({
  listing,
  buttonType,
  cartQuantity,
  dropdownOptionProps,
}: Props) {
  const navigate = useNavigate();
  const [createOrder] = usePostOrdersMutation();
  const { apiResponseMessage } = useApiResponseMessage();

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const {
    soldAs,
    listedQty,
    minimumOrder,
    remainingQty = 0,
    id: listingId,
    sold: isSold = false,
    deleted,
  } = listing;

  const pendingSale = remainingQty === 0 && !isSold;
  const isSoldAsLot = soldAs.toLowerCase() === 'lot';
  const buyNowDisabled = pendingSale || isSold || deleted;

  const setDefaultState = () => {
    if (isSoldAsLot) {
      setSelectedQuantity(listedQty);
    } else {
      setSelectedQuantity(cartQuantity || minimumOrder);
    }
  };

  useEffect(setDefaultState, [
    isSoldAsLot,
    minimumOrder,
    listedQty,
    cartQuantity,
  ]);

  const checkout = async () => {
    try {
      const payload = await createOrder({
        body: {
          listingId,
          quantity: selectedQuantity,
        },
      }).unwrap();

      if (payload.success) {
        const { id: orderId } = payload.data.order;
        navigate(`/checkout/${orderId}`);
      }
    } catch (error: any) {
      const message =
        error.data?.message ||
        'An unexpected error occurred. Please try again or contact support for help.';
      apiResponseMessage(message, 6000);
    }
  };

  const mainButton = () => {
    if (buttonType === 'dropdownOption' && dropdownOptionProps) {
      const { optionClassName, closeMenu } = dropdownOptionProps;

      return (
        <a
          className={optionClassName}
          onClick={(e) => {
            e.stopPropagation();
            if (!buyNowDisabled) {
              checkout();
              closeMenu();
            }
          }}
        >
          Buy Now
        </a>
      );
    }

    let buttonProps = {};
    if (buttonType === 'text') {
      buttonProps = {
        buttonType: 'text',
        textColor: 'sand20',
      };
    } else if (buttonType === 'button') {
      buttonProps = {
        buttonColor: 'sand20',
      };
    }

    return (
      <Button
        text="BUY NOW"
        onClick={(e) => {
          e.stopPropagation();
          checkout();
        }}
        disabled={buyNowDisabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
      />
    );
  };

  const tooltipPlacement = buttonType === 'dropdownOption' ? 'left' : 'top';
  let buyNowTooltip = '';
  if (pendingSale) {
    buyNowTooltip = 'This item is pending sale';
  } else if (isSold) {
    buyNowTooltip = 'This item is sold';
  } else if (deleted) {
    buyNowTooltip = 'This item no longer exists';
  }

  return (
    <TooltipWrapper tooltipText={buyNowTooltip} placement={tooltipPlacement}>
      <span>{mainButton()}</span>
    </TooltipWrapper>
  );
}

export default BuyNowButton;
