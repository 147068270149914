import React from 'react';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import categoryIndex from 'constants/categories.json';
import mechanicalImg from 'assets/images/mechanicalCategory.jpg';
import styles from './featuredCategories.module.scss';

type CategoryOption = {
  name: string;
  img: string;
  isComingSoon?: boolean;
};

function FeaturedCategoriesSection() {
  const navigate = useNavigate();

  const categoryImage = (category: string) => {
    const categoryIdx = categoryIndex.findIndex(
      (categoryObj) => categoryObj.name === category.toUpperCase(),
    );

    return categoryIndex[categoryIdx].image;
  };

  const categories: CategoryOption[] = [
    {
      name: 'Electrical',
      img: categoryImage('electrical'),
    },
    {
      name: 'Mechanical',
      img: mechanicalImg,
      isComingSoon: true,
    },
    {
      name: 'Finishes',
      img: categoryImage('finishes'),
      isComingSoon: true,
    },
    {
      name: 'Concrete/Masonry',
      img: categoryImage('masonry'),
      isComingSoon: true,
    },
  ];

  const comingSoonBadge = () => (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={styles.comingSoonBadgeWrapper}
    >
      <div className={styles.comingSoonBadge}>Coming Soon</div>
    </Grid>
  );

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={10} className={styles.header}>
        Categories
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        container
        spacing={2}
        className={styles.categoriesWrapper}
      >
        {categories.map((category) => {
          const comingSoonClassName =
            category.isComingSoon && styles.comingSoon;
          return (
            <Grid item xs={6} sm={3} md={2.5} key={category.name}>
              <Grid
                container
                direction="column"
                className={`${styles.itemWrapper} ${comingSoonClassName}`}
                onClick={() => {
                  if (!category.isComingSoon) {
                    const selectedCategory = category.name.toLowerCase();
                    navigate(`/shopping/${selectedCategory}`);
                  }
                }}
              >
                <Grid item xs={12} className={styles.imageWrapper}>
                  <img
                    src={category.img}
                    alt={category.name}
                    className={styles.image}
                  />
                </Grid>
                {category.isComingSoon && comingSoonBadge()}
                <Grid item xs={12} className={styles.nameWrapper}>
                  <div className={styles.categoryName}>{category.name}</div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default FeaturedCategoriesSection;
