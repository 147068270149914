import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YardApiError } from 'api/types';

interface ApiErrorState {
  errorMessage: YardApiError | null;
}

const initialState: ApiErrorState = {
  errorMessage: null,
};

export const apiErrorSlice = createSlice({
  name: 'apiError',
  initialState,
  reducers: {
    showErrorMessage: (state, action: PayloadAction<YardApiError>) => {
      state.errorMessage = action.payload;
    },
    hideErrorMessage: (state) => {
      state.errorMessage = null;
    },
  },
});

export const { showErrorMessage, hideErrorMessage } = apiErrorSlice.actions;
export default apiErrorSlice.reducer;
