import React from 'react';
import { useNavigate } from 'react-router';
import { useGetCategoriesQuery } from 'api';
import { useLogOut, useIdentity } from 'hooks';
import { TextLink, IconButton } from 'components/Buttons';
import SideMenu from 'components/SideMenu/SideMenu';
import CartIconButton from 'components/CartIconButton';
// icons and images
import { ReactComponent as UserIcon } from 'assets/icons/userIcon.svg';
import { ReactComponent as OrdersIcon } from 'assets/icons/ordersIcon.svg';
import { ReactComponent as YardPrimary } from 'assets/yardLogos/yardPrimary.svg';
import styles from './mainMenu.module.scss';

interface ListObj {
  name: string;
  screen: string;
  display: boolean; // used to determine options only displayed for logged in users
}

interface Props {
  loggedIn: boolean;
  closeMenu: () => void;
}

function MainMenu({ loggedIn, closeMenu }: Props) {
  const navigate = useNavigate();
  const { logOut } = useLogOut();

  const { businessName } = useIdentity();

  const { data: usedCategories = [] } = useGetCategoriesQuery({
    activeOnly: true,
  });

  const menuHeader = () => (
    <div className={styles.headerContainer}>
      {loggedIn ? (
        <div>
          <div className={styles.welcomeContainer}>
            <span>Welcome back, {businessName}</span>
            <TextLink
              className={styles.topLogOut}
              text="Log out"
              textColor="leather"
              onClick={() => {
                logOut();
                closeMenu();
              }}
            />
          </div>
          <div className={styles.shoppingContainer}>
            <IconButton
              className={styles.ordersButton}
              icon={OrdersIcon}
              onClick={() => {
                navigate('/orders');
                closeMenu();
              }}
              label="My Orders"
              labelPosition="right"
            />
            <CartIconButton location="mainMenu" onClick={() => closeMenu()} />
          </div>
        </div>
      ) : (
        <div>
          <IconButton
            icon={UserIcon}
            onClick={() => {
              closeMenu();
              navigate('/sign_in', { replace: true });
            }}
            label="Sign In or Create an Account"
            labelPosition="right"
          />
        </div>
      )}
    </div>
  );

  const categories =
    usedCategories.map((categoryObj: any) => {
      const name = categoryObj.name.toLowerCase();
      return {
        name,
        screen: `/shopping/${name}`,
        display: true,
      };
    }) ?? [];

  const buyingOptions: ListObj[] = [
    // { removed for MVP
    //   name: 'All Categories',
    //   screen: '/shopping',
    //   display: true,
    // },
    ...categories,
  ];

  const sellingOptions: ListObj[] = [
    {
      name: 'Manage My Listings',
      screen: '/selling/my_yard_listings',
      display: true,
    },
    {
      name: 'Orders',
      screen: '/orders',
      display: loggedIn,
    },
    // { // removed for MVP
    //   name: 'Storefront',
    //   screen: '/storefront',
    //   display: loggedIn,
    // },
    {
      name: 'How to Sell',
      screen: '/interested_in_selling',
      display: true,
    },
    {
      name: 'Create New Listing',
      screen: '/product_listing',
      display: loggedIn,
    },
  ];

  const accountOptions: ListObj[] = [
    {
      name: 'My Account',
      screen: '/account',
      display: true,
    },
    {
      name: 'My Orders',
      screen: '/orders',
      display: loggedIn,
    },
    // removed for beta
    // {
    //   name: 'Notifications and Messaging',
    //   screen: 'notifications',
    //   display: loggedIn,
    // },
  ];

  const listOptions = (list: ListObj[]) =>
    list.map((option) => {
      if (option.display) {
        const { screen, name } = option;

        return (
          <TextLink
            key={name}
            className={styles.menuOption}
            text={name}
            onClick={() => {
              navigate(screen);
              closeMenu();
            }}
          />
        );
      }
      return null;
    });

  const mainMenu = () => (
    <div className={styles.contentContainer}>
      <div className={styles.logoWrapper}>
        <YardPrimary className={styles.logo} />
      </div>
      <div className={styles.bodyContainer}>
        <TextLink
          className={styles.whatIsTheYard}
          text="What is The Yard?"
          onClick={() => {
            navigate('/about');
            closeMenu();
          }}
        />
        <div className={styles.buyingContainer}>
          <h1 className={styles.sectionHeader}>Buying</h1>
          <div className={styles.optionsContainer}>
            {listOptions(buyingOptions)}
            <div className={styles.moreCatsToCome}>
              More categories coming soon
            </div>
          </div>
        </div>
        <div className={styles.sellingContainer}>
          <h1 className={styles.sectionHeader}>Selling</h1>
          <div className={styles.optionsContainer}>
            {listOptions(sellingOptions)}
          </div>
        </div>
        {loggedIn && (
          <div className={styles.accountContainer}>
            <h4 className={styles.accountHeader}>{businessName}</h4>
            <div className={styles.optionsContainer}>
              {listOptions(accountOptions)}
              <TextLink
                className={styles.logout}
                text="Log Out"
                textColor="leather"
                onClick={() => {
                  logOut();
                  closeMenu();
                }}
              />
            </div>
          </div>
        )}
        <div className={styles.bottomLinks}>
          <TextLink
            className={styles.bottomLink}
            text="Frequently Asked Questions"
            onClick={() => {
              navigate('/faqs');
              closeMenu();
            }}
          />
          {/* <TextLink // removed for MVP
            className={styles.bottomLink}
            text="Contact the Yard"
            onClick={() => {
              navigate('/help');
              closeMenu();
            }}
          /> */}
          <TextLink
            className={styles.bottomLink}
            text="Get Help"
            onClick={() => {
              navigate('/help');
              closeMenu();
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <SideMenu
      menuHeader={menuHeader()}
      menuContent={mainMenu()}
      closeMenuAction={() => closeMenu()}
      buttonContainerClassName={loggedIn ? styles.loggedIn : ''}
    />
  );
}

export default MainMenu;
