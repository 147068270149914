import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/circleCancelIcon.svg';
import { COLORS } from 'style/colors';
import formStyles from 'style/formStyles.module.scss'; // used for global input styling
import styles from './subcategoryDropdown.module.scss';

type ListObj = {
  label: string;
  value: string;
  selected?: boolean;
  onClick?: any;
  disabled?: boolean;
  fullCategoryNumber: string | null;
  individualCategoryNumber: string | null;
};

type Props = {
  options: ListObj[];
  onChange: (option: ListObj) => void;
  buttonClassName?: string;
  containerClassName?: string;
  placeholder?: string;
  value: ListObj | undefined;
  isSearchable?: boolean; // default is false
  invalidUserInput?: boolean;
  assistiveText?: string; // text below the input to assist the user
};

// use our own search icon
function DropdownIndicator(props: any) {
  const { hasValue, isFocused } = props;
  const iconColor = isFocused ? COLORS.midnightBlack : COLORS.sand20;

  // hide search icon if there is a value
  if (hasValue) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <SearchIcon fill={iconColor} />
    </components.DropdownIndicator>
  );
}

// use our own clear input icon
function ClearIndicator(props: any) {
  const { isFocused } = props;
  const iconColor = isFocused ? COLORS.midnightBlack : COLORS.sand;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ClearIndicator {...props}>
      <ClearIcon fill={iconColor} />
    </components.ClearIndicator>
  );
}

// this is for displaying the category number before the name when listing a product
function SingleValue(props: any) {
  const { data } = props;
  const { individualCategoryNumber, value } = data;

  return (
    <components.ValueContainer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={styles.wrapper}
    >
      {individualCategoryNumber && (
        <div className={styles.individualCategoryNumber}>
          {individualCategoryNumber}
        </div>
      )}
      <div className={styles.categoryName}>{value}</div>
    </components.ValueContainer>
  );
}

// for displaying the category numbers under the name
function Option(props: any) {
  const { data } = props;
  const { fullCategoryNumber, value } = data;

  return (
    <components.Option
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={styles.optionContainer}
    >
      {fullCategoryNumber ? (
        <>
          <div className="category-dropdown__cat-name">{value}</div>
          <div className={styles.fullCategoryNumber}>{fullCategoryNumber}</div>
        </>
      ) : (
        data.label
      )}
    </components.Option>
  );
}

function Input(props: any) {
  const { hasValue } = props;
  // this is to hide the blicking cursor when there is a value in the input
  const hideSelector = hasValue && styles.hideCursor;

  return (
    <components.Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={`${styles.inputContainer} ${hideSelector}`}
    />
  );
}

function SubcategoryDropdown({
  options,
  buttonClassName = '',
  containerClassName = '',
  onChange,
  placeholder = '',
  value,
  isSearchable = false,
  invalidUserInput,
  assistiveText,
}: Props) {
  // for css color if the input contains a value
  const filledClassName = value && formStyles.filled;
  const invalidClassName =
    invalidUserInput && !value ? formStyles.invalidUserInput : '';

  const showAssistiveText = assistiveText && assistiveText !== '';

  // used for styling the different parts of Select
  const customStyles = {
    container: () => ({
      height: 'inherit',
      backgroundColor: 'inherit',
      borderRadius: 'inherit',
    }),
    control: () => ({
      height: '100%',
      backgroundColor: 'inherit',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 'inherit',
      borderRadius: 'inherit',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      paddingLeft: '6px',
    }),
    input: (provided: any) => ({
      ...provided,
      position: 'absolute',
    }),
    singleValue: (provided: any) => {
      const paddingTop = '2px';

      return { ...provided, paddingTop };
    },
    selectContainer: (provided: any) => ({
      ...provided,
      borderColor: 'inherit',
      borderRadius: 'inherit',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      display: 'flex',
      padding: '0 0 0 8px',
      alignItems: 'center',
      flex: '1 1 auto',
      height: '100%',
    }),
    indicatorSeparator: (base: any) =>
      // remove the line between value and the down arrow
      ({
        ...base,
        display: 'none',
      }),
    option: (provided: any, state: any) => {
      const defaults = {
        ...provided,
        minHeight: '48px',
        display: 'flex',
        alignItems: 'center',
      };
      if (state.isDisabled) {
        return {
          ...defaults,
          color: COLORS.metal,
          backgroundColor: COLORS.offWhite,
        };
      }
      return defaults;
    },
  };

  return (
    <div className={`${formStyles.outerWrapper} ${styles.inputWrapper}`}>
      <div
        className={`${formStyles.defaultInputWrapper} ${filledClassName} ${containerClassName} ${invalidClassName}`}
      >
        <CreatableSelect
          className={buttonClassName}
          isClearable
          isSearchable={isSearchable}
          options={options}
          value={value || null}
          styles={customStyles}
          isOptionDisabled={(option: any) => option.disabled}
          onChange={(newValue: any) => {
            onChange(newValue);
          }}
          placeholder={placeholder}
          components={{
            DropdownIndicator,
            ClearIndicator,
            SingleValue,
            Option,
            Input,
          }}
        />
      </div>
      {showAssistiveText && (
        <div
          className={`${formStyles.assistiveText} ${
            invalidUserInput && formStyles.redAssistiveText
          }`}
        >
          {assistiveText}
        </div>
      )}
    </div>
  );
}

export default SubcategoryDropdown;
