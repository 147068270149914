import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { GeneralInquiryForm } from 'components/Forms';
import Faq from './FAQ/FAQ';
import Terms from './Terms/Terms';
import GetInTouch from './GetInTouch/GetInTouch';
import Support from './Support/Support';
import FormScreenLayout from './components/FormScreenLayout';
import styles from './helpScreen.module.scss';

function HelpScreen() {
  const [displayGeneralInquiryForm, setDisplayGeneralInquiryForm] =
    useState(false);

  if (displayGeneralInquiryForm) {
    const onCancel = () => setDisplayGeneralInquiryForm(false);
    return (
      <FormScreenLayout onCancel={onCancel}>
        <GeneralInquiryForm onCancel={onCancel} />
      </FormScreenLayout>
    );
  }

  return (
    <div className={styles.container}>
      <Grid container direction="column" className={styles.header}>
        <div className={styles.title}>Help and Support</div>
        <div className={styles.subtitle}>
          We want you to have the best experience possible on The Yard. Let us
          know how we can help.
        </div>
      </Grid>
      <Grid container className={styles.halfCardsContainer}>
        <Grid item xs={12} sm={5} md={4} className={styles.supportContainer}>
          <Support />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <Terms />
        </Grid>
      </Grid>
      <Faq />
      <GetInTouch setDisplayGeneralInquiryForm={setDisplayGeneralInquiryForm} />
    </div>
  );
}

export default HelpScreen;
