import React from 'react';
import Page from 'components/Page';
import styles from './privacyPolicyScreen.module.scss';

function PrivacyPolicyScreen() {
  const boldText = (text: string) => (
    <span className={styles.boldText}>{text}</span>
  );

  const underlinedText = (text: string) => (
    <span className={styles.underlinedText}>{text}</span>
  );

  const italicizedText = (text: string) => (
    <span className={styles.italicizedText}>{text}</span>
  );

  const privacyPolicy = {
    heading: 'Privacy Policy',
    sections: [
      {
        id: 'initial-paragraph',
        type: 'text',
        text: [
          <>
            {boldText('Last Revised')}: July 18, 2023
            <br />
            <br />
            CHECKTHEYARD, INC. (“{boldText('The Yard')},” “{boldText('we')},” or
            “{boldText('us')}”) respects your privacy. This Privacy Policy
            describes the types of information we may collect from you when you
            visit all The Yard-owned websites and domains, including
            www.checktheyard.com (collectively, the “{boldText('Services')}”).
            This Privacy Policy describes (i) how we use the information we
            collect, (ii) with whom we share it, (iii) how we protect it, and
            (iv) the choices we offer you regarding our collection and use of
            such information. We are constantly working to improve our
            administrative, technical, and physical safeguards to protect your
            personal information.{' '}
            {boldText(
              'If you do not agree with this Privacy Policy, please do not access or use any part of the Services.',
            )}
          </>,
        ],
      },
      {
        id: 'step-1-info-we-collect-header',
        type: 'subheader',
        text: '1. Information We Collect',
      },
      {
        id: 'info-we-collect-text',
        type: 'text',
        text: [
          <>
            {underlinedText('Where We Collect It From')}. We may collect
            personal information about you, meaning information that can
            uniquely identify you, from various sources, such as:
            <ul>
              <li>
                Directly from you when you use the Services, such as when you
                register for an account, access certain features or content, or
                order products;
              </li>
              <li>
                Our parents, subsidiaries, affiliates, service providers,
                business partners, and other third parties.
              </li>
            </ul>
            {underlinedText('What We Collect')}. The personal information we
            collect about you may include, without limitation:
            <ul>
              <li>
                Contact information, such as name, postal address, email
                address, and phone number;
              </li>
              <li>
                Demographic information, such as date of birth, gender, and job
                information; and
              </li>
              <li>Login information, such as username and password.</li>
            </ul>
            {underlinedText('Automatic Collection')}. When you use the Services,
            we may also collect certain information by automated means, such as
            cookies. A “cookie” is a text file that websites send to a visitor’s
            computer or other Internet-connected device to uniquely identify the
            visitor’s browser or to store information or settings in the
            browser. We may also use third-party analytics tools (such as Google
            Analytics) that collect information about use of the Services and
            user traffic. The information we collect by automated means may
            include, without limitation:
            <ul>
              <li>
                Information about the devices you use to access the Services
                (such as the IP address and the type of the device, operating
                system, and web browser);
              </li>
              <li>
                Dates, times, and duration of use of the Services (including
                whether you are a repeat or first time visitor); and
              </li>
              <li>
                Information on actions taken when using the Services (such as
                page views and website navigation patterns).
              </li>
            </ul>
            {underlinedText('Statistical Information')}. The information we
            collect automatically is statistical data and does not include
            personal information. It helps us to improve and personalize our
            Services, including by enabling us to identify and authenticate
            users.
          </>,
        ],
      },
      {
        id: 'step-2-how-we-respond-header',
        type: 'subheader',
        text: '2. How We Respond to “Do Not Track” Signals.',
      },
      {
        id: 'step-2-how-we-respond-text',
        type: 'text',
        text: [
          <>
            {italicizedText(
              'A do not track signal (“DNT”) prevent web applications from tracking you, you can learn more about DNT at https://allaboutdnt.com.',
            )}{' '}
            Your web browser may let you choose your preference as to whether
            you want to allow the collection of information about your online
            activities over time and across different websites or online
            services. At this time, the Services do not respond to the
            preferences you may have set in your web browser regarding such
            collection of your information, and the Services may continue to
            collect information in the manner described in this Privacy Policy.
          </>,
        ],
      },
      {
        id: 'step-3-third-party-cookies-header',
        type: 'subheader',
        text: '3. Third-Party Use of Cookies and Other Tracking Technologies.',
      },
      {
        id: 'step-3-third-party-cookies-text',
        type: 'text',
        text: 'Some content or applications available through the Services, including advertisements, may be served by third parties. These third parties may use cookies to collect information about you when you use the Services, such as your IP address, pages you visit, and when you view a page or open an email in which a tag is embedded. We do not control these third parties’ tracking technologies or how they may be used. The information practices of these third parties are governed by their own privacy policies and are not covered by this Privacy Policy. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.',
      },
      {
        id: 'step-4-how-we-use-info-header',
        type: 'subheader',
        text: '4. How We Use the Information We Collect',
      },
      {
        id: 'step-4-how-we-use-info-text',
        type: 'text',
        text: [
          <>
            We may use information we obtain about you to:
            <ul>
              <li>
                Process orders you place and complete other transactions you
                have requested using the Services, and provide you with products
                and services you request;
              </li>
              <li>Send you information or materials you request;</li>
              <li>
                Respond to your questions and comments and provide customer
                support;
              </li>
              <li>
                Protect against and prevent fraud, unauthorized transactions,
                claims, and other liabilities, and manage risk exposure,
                including by identifying potential hackers and other
                unauthorized users;
              </li>
              <li>Enforce our Terms of Use and other agreements; and</li>
              <li>
                Comply with applicable legal requirements and industry
                standards.
              </li>
            </ul>
            {underlinedText('Other Uses')}. We also may use the information we
            obtain about you in other ways for which we provide specific notice
            at the time of collection.
          </>,
        ],
      },
      {
        id: 'step-5-discloser-of-info-header',
        type: 'subheader',
        text: '5. Disclosure of Information',
      },
      {
        id: 'step-5-discloser-of-info-text',
        type: 'text',
        text: [
          <>
            {underlinedText('Who We Disclose To')}. We may disclose aggregated
            information about our users, and information that does not identify
            any individual user, without restriction. We may disclose personal
            information about you:
            <ul>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business, including without limitation Stripe
                and ARL Network;
              </li>
              <li>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request;
              </li>
              <li>
                To enforce or apply our Terms of Use and other agreements;
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of The Yard, users of the
                Services, or others; and
              </li>
              <li>With your consent.</li>
            </ul>
            We also reserve the right to transfer your personal information to a
            buyer or other transferee in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, sale, or other transfer
            of some or all of our assets, equity or similar transaction. Should
            such a sale, merger, or transfer occur, we will use reasonable
            efforts to direct the transferee to use your personal information in
            a manner that is consistent with our Privacy Policy.
          </>,
        ],
      },
      {
        id: 'step-6-data-security-header',
        type: 'subheader',
        text: '6. Data Security.',
      },
      {
        id: 'step-6-data-security-text',
        type: 'text',
        text: 'We have implemented measures intended to protect your personal information from accidental loss and from unauthorized access, use, alteration and disclosure. All information you provide to us is stored on secure servers behind firewalls. Any payment transactions will be encrypted using SSL/TLS technology. Please note that the electronic transmission of information is not completely secure. We cannot guarantee that the security measures we have in place to safeguard personal information will never be defeated or fail, or that such measures will always be sufficient or effective. Any transmission of personal information is at your own risk.',
      },
      {
        id: 'step-7-your-choices-header',
        type: 'subheader',
        text: '7. Your Choices.',
      },
      {
        id: 'step-7-your-choices--text',
        type: 'text',
        text: [
          <>
            We offer you the following choices about the information we collect
            from you and how we communicate with you:
            <ul>
              <li>{boldText('Cookies')}</li>
              Most browsers will tell you how to stop accepting new cookies, how
              to be notified when you receive a new cookie, and how to disable
              existing cookies. Please note, however, that without cookies you
              may not be able to take full advantage of all of the features of
              the Services.
              <li>{boldText('Promotional Communications')}</li>
              If you do not want us to use your contact information to promote
              our products or services, you can opt out by logging into your
              account and adjusting your user preferences in your account
              setting. If you have received a promotional email from us, you can
              also opt out of receiving future promotional emails from us by
              following the unsubscribe instructions contained in such email.
              <li>{boldText('Web Analytics')}</li>
              You may opt out of the aggregation and analysis of data collected
              about you on our website by Google Analytics by visiting
              https://tools.google.com/dlpage/.
            </ul>
          </>,
        ],
      },
      {
        id: 'step-8-accessing-your-info-header',
        type: 'subheader',
        text: '8. Accessing and Changing Your Information.',
      },
      {
        id: 'step-8-accessing-your-info-text',
        type: 'text',
        text: 'You can review and change the personal information associated with your account by logging into your account and visiting My Account >> User Details.',
      },
      {
        id: 'step-9-under-13yo-header',
        type: 'subheader',
        text: '9. Children Under the Age of 13.',
      },
      {
        id: 'step-9-under-13yo-text',
        type: 'text',
        text: 'The Services are not directed to, and we do not knowingly collect or solicit personal information from, children under the age of 13. If we learn we have collected or received personal information from a child under the age of 13, we will delete that information. If you believe we might have any information from or about a child under the age of 13, please contact us at help@checktheyard.com.',
      },
      {
        id: 'step-10-changes-to-our-policy-header',
        type: 'subheader',
        text: '10. Changes to Our Privacy Policy.',
      },
      {
        id: 'step-10-changes-to-our-policy-text',
        type: 'text',
        text: 'We reserve the right to update or change this Privacy Policy at any time. If we make a material change to our Privacy Policy, we will provide notice to you (for example, by email and/or posting on our website) that our privacy practices have changed and will provide a link to the new policy. In the event we make a material change to how we use your personal information, we will provide you with an opportunity to opt out of such new or different use. The date this Privacy Policy was last revised is at the top of this page. We encourage you to review this Privacy Policy periodically to check for any updates or changes.',
      },
      {
        id: 'step-11-data-transfers-header',
        type: 'subheader',
        text: '11. Data Transfers.',
      },
      {
        id: 'step-11-data-transfers-text',
        type: 'text',
        text: 'We are based in the State of Utah in the United States. When we obtain information about you, we may transfer, process, and store such information outside of the country in which you reside, including in the United States. By using the Services, you consent to the transfer to and processing and storage of your information in countries outside of your country of residence, which may have different data protection laws than those in the country where you reside.',
      },
      {
        id: 'step-12-contact-us-header',
        type: 'subheader',
        text: '12. Contact Us.',
      },
      {
        id: 'step-12-contact-us-text',
        type: 'text',
        text: 'If you have any questions about this Privacy Policy or our information practices, please email us at help@checktheyard.com.',
      },
    ],
  };

  return <Page content={privacyPolicy} />;
}

export default PrivacyPolicyScreen;
