import React, { useState, useEffect } from 'react';
import { isEmail } from 'validator';
import { User } from 'api';
import {
  useGetUserDetails,
  useCreateZendeskRequest,
  useApiResponseMessage,
} from 'hooks';
import Loading from 'components/Loading';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
import { Button, TextLink } from 'components/Buttons';
import FormContainer from 'components/FormContainer';
import styles from './supportForms.module.scss';

type Props = {
  onCancel: () => any;
};

export default function GeneralInquiryForm({ onCancel }: Props) {
  const getUserResponse = useGetUserDetails();
  const { submitRequest } = useCreateZendeskRequest();
  const { apiResponseMessage } = useApiResponseMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<User>();
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [invalidInputsOnClick, setInvalidInputsOnClick] = useState({
    email: false,
    subject: false,
    description: false,
  });

  useEffect(() => {
    if (getUserResponse?.success) {
      const { user } = getUserResponse.data;
      setUserData(user);
      setEmail(user.email);
    }
  }, [getUserResponse]);

  const submitInquiry = async () => {
    try {
      setIsLoading(true);
      const ticketInfo = {
        ticketFormId: 12002668562459,
        requester: {
          name: userData?.name || 'Unknown',
          email,
        },
        subject,
        commentBody: description,
      };
      const response = await submitRequest(ticketInfo);

      if (response.status === 201) {
        apiResponseMessage('Your contact request has been submitted');

        onCancel();
      }
    } catch (error) {
      setIsLoading(false);
      apiResponseMessage('An error occurred, please try again');
    }
  };

  const checkFields = () => {
    const invalidFields = {
      email: !email || !isEmail(email),
      subject: !subject || subject.length < 3,
      description: !description || description.length < 5,
    };

    const hasInvalidFields = Object.values(invalidFields).some(
      (invalid) => invalid,
    );

    if (hasInvalidFields) {
      setInvalidInputsOnClick(invalidFields);
    } else {
      submitInquiry();
    }
  };

  const requiredFieldText = 'This field is required';

  return (
    <FormContainer
      title="Contact Us"
      content={
        <div>
          {isLoading && <Loading screenOverlay />}
          <TextInput
            containerClassName={styles.input}
            label="Email address*"
            value={email}
            onChange={(e) => {
              const { value } = e.target;
              setEmail(value);

              if (invalidInputsOnClick.email && isEmail(value)) {
                setInvalidInputsOnClick({
                  ...invalidInputsOnClick,
                  email: false,
                });
              }
            }}
            invalidUserInput={invalidInputsOnClick.email}
            assistiveText={invalidInputsOnClick.email ? requiredFieldText : ''}
          />
          <TextInput
            containerClassName={styles.input}
            label="Subject*"
            value={subject}
            onChange={(e) => {
              const { value } = e.target;
              setSubject(value);

              if (invalidInputsOnClick.subject && value.length >= 3) {
                setInvalidInputsOnClick({
                  ...invalidInputsOnClick,
                  subject: false,
                });
              }
            }}
            assistiveText="Why are you contacting us?"
            invalidUserInput={invalidInputsOnClick.subject}
          />
          <TextArea
            label="Description*"
            value={description}
            onChange={(e) => {
              const { value } = e.target;
              setDescription(value);

              if (invalidInputsOnClick.description && value.length >= 5) {
                setInvalidInputsOnClick({
                  ...invalidInputsOnClick,
                  description: false,
                });
              }
            }}
            invalidUserInput={invalidInputsOnClick.description}
            assistiveText={
              invalidInputsOnClick.description ? requiredFieldText : ''
            }
          />
          <div className={styles.buttonContainer}>
            <TextLink
              className={styles.cancelButton}
              text="Cancel"
              onClick={onCancel}
            />
            <Button
              rippleClassName={styles.actionButton}
              buttonClassName={styles.actionButton}
              text="SEND"
              onClick={checkFields}
              buttonColor="black"
              roundedButton
            />
          </div>
        </div>
      }
    />
  );
}
