import React, { useState, useEffect } from 'react';
import { User } from 'api';
// import { Button } from 'components/Buttons';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import defaultUserIcon from 'assets/icons/userIcon.svg';
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import styles from './userSection.module.scss';

type Props = {
  user: User;
  isBuyer: boolean;
};

function SellerSection({ user, isBuyer }: Props) {
  const { userSettings, address } = user;
  const [showSellerTerms, setShowSellerTerms] = useState(false);
  const [profilePicture, setProfilePicture] = useState(defaultUserIcon);

  const setUserProfilePicture = (isError: boolean = false) => {
    if (userSettings.profileImageUrl && !isError) {
      setProfilePicture(userSettings.profileImageUrl);
    } else if (profilePicture !== defaultUserIcon) {
      setProfilePicture(defaultUserIcon);
    }
  };
  useEffect(setUserProfilePicture, [
    profilePicture,
    userSettings.profileImageUrl,
  ]);

  // const hiddenBusiness = {
  //   name: 'Yard Business',
  // };

  const displayDate = (date: any) => {
    const options = {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    } as const;

    return new Date(date).toLocaleDateString('en-US', options);
  };

  return (
    <Grid container className={styles.wrapper}>
      <DialogPopup
        open={showSellerTerms}
        onClose={() => setShowSellerTerms(false)}
        title="Seller Terms"
        content={userSettings.sellerTerms || ''}
      />
      <Grid item xs={2} sm={1} md={1} className={styles.imageContainer}>
        <Avatar className={styles.imageAvatar}>
          <img
            className={styles.image}
            alt="Profile"
            src={profilePicture}
            onError={() => setUserProfilePicture(true)}
          />
        </Avatar>
      </Grid>
      <Grid
        item
        xs={10}
        sm={9}
        md={10}
        xl={11}
        container
        className={styles.rightOfImage}
      >
        <Grid item xs={9} sm={6} md={6} className={styles.infoContainer}>
          <Grid container direction="row" alignItems="baseline">
            <div className={styles.soldBy}>
              {isBuyer ? 'Sold' : 'Bought'} by
            </div>
            &nbsp;
            <div className={styles.userName}>{address?.name}</div>
          </Grid>
          <Grid container className={styles.addressAndMember}>
            <div className={styles.address}>
              {address?.city}, {address?.state}
            </div>
            <div className={styles.memberSince}>
              Member since {displayDate(user.createdAt)}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={styles.ordersAndRating}
          >
            <div className={styles.userRating}>
              {user.rating.averageRating}{' '}
              <StarIcon className={styles.sixteenStar} />
              <div className={styles.ratingsCount}>
                ({user.rating.totalRatings})
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          // className={styles.buttonContainer}
        >
          {/* removed for beta */}
          {/* {isBuyer && userSettings.sellerTerms && (
            <Grid item xs={8} sm={10} md={5}>
              <Button
                containerClassName={styles.viewSellerTerms}
                buttonClassName={styles.buttonColor}
                text="VIEW SELLER TERMS"
                buttonType="secondary"
                buttonColor="black"
                onClick={() => setShowSellerTerms(true)}
              />
            </Grid>
          )} */}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SellerSection;
