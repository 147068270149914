import React from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useAppSelector, useScreenSize } from 'hooks';
import { setSelectedFilters, setKeywords } from 'slices/shoppingSlice';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Buttons';
import Badge from './Badge/Badge';
import SearchResultsTable from './SearchResultsTable/SearchResultsTable';
import { FilterSortMenu } from '../components';
import styles from './searchResultsScreen.module.scss';

type Filters = {
  category?: string[];
  subcategory?: string[];
};

function SearchResultsScreen() {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();
  const hideFilterMenu = screenSize === 'xs' || screenSize === 'sm';

  const reduxFiltersAndSort = useAppSelector((state) => state.shopping);
  const { selectedFilters = {}, keywords = '' } = reduxFiltersAndSort;
  const numberOfFilters =
    (selectedFilters.category?.length || 0) +
    (selectedFilters.subcategory?.length || 0);

  const saveFilters = (filters: Filters) => {
    dispatch(setSelectedFilters(filters));
  };

  const [showFilterModal, hideFilterModal] = useModal(
    () => <FilterSortMenu closeFilterModal={() => hideFilterModal()} />,
    [],
  );

  const showBadges =
    keywords !== '' || (selectedFilters.category?.length || 0) > 0;

  const noFiltersOrKeywords =
    keywords === '' && selectedFilters.category?.length === 0;

  return (
    <Grid container className={styles.shoppingContainer}>
      {hideFilterMenu && (
        <Grid item xs={11.5} sm={8} className={styles.filterButtonContainer}>
          <Button
            containerClassName={styles.filteredButton}
            title={`Filtered (${numberOfFilters})`}
            onClick={() => showFilterModal()}
            buttonColor="black"
          />
        </Grid>
      )}
      <Grid container justifyContent="space-between">
        <Grid container item xs={12} className={styles.rightSideComponents}>
          {showBadges && (
            <Grid container className={styles.badgeContainer}>
              {selectedFilters.category?.map((filterName) => (
                <Badge
                  key={filterName}
                  label={filterName}
                  onClose={() => {
                    const removedFilter = selectedFilters.category?.filter(
                      (name) => name !== filterName,
                    );
                    saveFilters({
                      ...selectedFilters,
                      category: removedFilter,
                    });
                  }}
                />
              ))}
              {selectedFilters.subcategory?.map((filterName) => (
                <Badge
                  key={filterName}
                  label={filterName}
                  onClose={() => {
                    const removedFilter = selectedFilters.subcategory?.filter(
                      (name) => name !== filterName,
                    );
                    saveFilters({
                      ...selectedFilters,
                      subcategory: removedFilter,
                    });
                  }}
                />
              ))}
              {keywords !== '' && (
                <Badge
                  key={keywords}
                  label={keywords}
                  onClose={() => {
                    dispatch(setKeywords(''));
                  }}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            {noFiltersOrKeywords ? (
              <div className={styles.noFiltersOrKeywords}>
                Not sure where to start? Start your Yard search by typing
                <br />
                in a keyword or selecting from an existing category.
              </div>
            ) : (
              <SearchResultsTable />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchResultsScreen;
