import React, { ReactNode } from 'react';
import { SpecializedCaption } from 'parts';
import styles from './backdrop.module.scss';

type BackdropPropType = {
  children: ReactNode;
  align?: string | undefined;
  caption?: string;
};

function Backdrop({ children, align, caption }: BackdropPropType) {
  return (
    <div className={`${styles.wrapper} ${align && styles[align]}`}>
      <div className={styles.container}>{children}</div>
      {caption && <SpecializedCaption>{caption}</SpecializedCaption>}
    </div>
  );
}

export default Backdrop;
