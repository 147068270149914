import React from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { useIsMobile } from 'hooks';
import IconDropdown from 'components/IconDropdown';
import colorVars from 'style/index.module.scss';
import './_toggle.scss';

interface Props {
  className?: string;
  checked: boolean;
  onChange: () => void;
}

function BuySellToggle({ className, checked, onChange }: Props) {
  const navigate = useNavigate();
  const isMobileScreen = useIsMobile();

  const sellDropdown = () => {
    if (isMobileScreen && checked) {
      const options = [
        {
          label: 'Listings',
          value: 'my_listings',
          onClick: () => navigate('/selling/my_yard_listings'),
        },
        {
          label: 'Orders',
          value: 'my_orders',
          onClick: () => navigate('/orders'),
        },
        // { // removed for MVP
        //   label: 'Storefront',
        //   value: 'storefront',
        //   onClick: () => navigate('/storefront'),
        // },
        {
          label: 'How to Sell',
          value: 'how_to_sell',
          onClick: () => navigate('/interested_in_selling'),
        },
        {
          label: 'Create New Listing',
          value: 'create_listing',
          onClick: () => {
            navigate('/product_listing');
          },
        },
      ];

      return (
        <IconDropdown
          options={options}
          iconType="dropdown-arrow"
          containerClassName="buy-sell-toggle__sell-dropdown"
          menuClassName="buy-sell-toggle__sell-menu"
          iconColor={colorVars.white}
        />
      );
    }
    return <div />;
  };

  const buyColor = !checked
    ? 'buy-sell-switch__white-text'
    : 'buy-sell-switch__black-text';
  const sellColor = checked
    ? 'buy-sell-switch__white-text'
    : 'buy-sell-switch__black-text';

  return (
    <label className={classNames(className, 'buy-sell-switch-container')}>
      <input
        className="buy-sell-switch__checkbox"
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
          // onChange()
        }}
      />
      <div className="buy-sell-switch__button" />
      <div className="buy-sell-switch__labels-container">
        <div
          className={classNames('buy-sell-switch__buy-label', buyColor)}
          onClick={(e) => {
            e.stopPropagation();
            onChange();
          }}
        >
          BUYING
        </div>
        <div
          className={classNames('buy-sell-switch__sell-label', sellColor)}
          onClick={(e) => {
            e.stopPropagation();
            onChange();
          }}
        >
          SELLING
          {sellDropdown()}
        </div>
      </div>
    </label>
  );
}

export default BuySellToggle;
