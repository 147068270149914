import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Button } from 'components/Buttons';
import styles from './stripeElements.module.scss';

function AddPaymentMethodForm() {
  const baseReturnUrl = process.env.REACT_APP_BASE_RETURN_URL;
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${baseReturnUrl}/account/stripe`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment method, such as invalid card details.
      setErrorMessage(error.message || 'An unexpected error occurred.');
    }
  };

  const paymentElementOptions = {
    layout: 'tabs' as const,
  };

  return (
    <form className={styles.paymentForm} onSubmit={handleSubmit}>
      <PaymentElement
        className={styles.paymentElement}
        options={paymentElementOptions}
      />
      <div className={styles.bottomButtons}>
        <Button
          type="submit"
          text="SAVE"
          buttonColor="black"
          roundedButton
          buttonClassName={styles.submitButton}
          rippleClassName={styles.submitButton}
        />
        {/* Show any error messages */}
        {errorMessage && (
          <div className={styles.paymentMessage}>{errorMessage}</div>
        )}
      </div>
    </form>
  );
}

export default AddPaymentMethodForm;
