import React from 'react';
import { useAppSelector } from 'hooks';
import Grid from '@mui/material/Grid';
import styles from './searchResultsScreen.module.scss';

function SearchResultsScreen() {
  const reduxFiltersAndSort = useAppSelector((state) => state.shopping);
  const { selectedFilters = {}, keywords = '' } = reduxFiltersAndSort;
  const numberOfFilters =
    (selectedFilters.category?.length || 0) +
    (selectedFilters.subcategory?.length || 0);

  let headerText = '';
  if (selectedFilters.category || selectedFilters.subcategory) {
    const { category, subcategory } = selectedFilters;
    let firstFilter;

    if (category) {
      const [firstItem] = category;
      firstFilter = firstItem;
    } else if (subcategory) {
      const [firstItem] = subcategory;
      firstFilter = firstItem;
    }

    const andMore = numberOfFilters > 1 ? '& More' : '';

    headerText = `${firstFilter} ${andMore}`;
  }

  const noFiltersOrKeywords =
    keywords === '' && selectedFilters.category?.length === 0;

  return (
    <div>
      {noFiltersOrKeywords ? (
        <div className={styles.emptyHeader} />
      ) : (
        <div className={styles.headerContainer}>
          <Grid container justifyContent="center" className={styles.maxWidth}>
            <Grid item xs={12} md={10} className={styles.title}>
              {keywords || headerText}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default SearchResultsScreen;
