import React, { useLayoutEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import AccountMenu from './AccountMenu';
import BottomHeader from './BottomHeader';
import MainHeader from './MainHeader';
import TopHeader from './TopHeader';
import StripeAccountBanner from './StripeAccountBanner';

function Header() {
  const [isInSellMode, setIsInSellMode] = useState(false);
  const SELL_MODE_KEY = 'isInSellMode';

  // Set the user is their previous 'isInSellMode' state
  useLayoutEffect(() => {
    if (localStorage.getItem('isInSellMode') === 'true') {
      setIsInSellMode(true);
    }
  }, []);

  const handleSellModeToggle = () => {
    localStorage.setItem(SELL_MODE_KEY, Boolean(!isInSellMode).toString());
    setIsInSellMode(!isInSellMode);
  };

  const [showAccountMenu, hideAccountMenu] = useModal(
    () => <AccountMenu closeMenu={() => hideAccountMenu()} />,
    [],
  );

  return (
    <>
      <TopHeader />
      <MainHeader
        isInSellMode={isInSellMode}
        showAccountMenu={showAccountMenu}
      />
      <StripeAccountBanner />
      <BottomHeader
        isInSellMode={isInSellMode}
        setSellMode={handleSellModeToggle}
      />
    </>
  );
}

export default Header;
