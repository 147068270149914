import React from 'react';
import { useNavigate } from 'react-router';
import { TextLink, Button } from 'components/Buttons';
import styles from './resetRequestSentScreen.module.scss';

type Props = {
  resetEmail: string;
};

function ResetRequestSentScreen({ resetEmail }: Props) {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.header}>
          We&apos;ve sent a link to reset your password to{' '}
          <span className={styles.email}>{resetEmail}</span>
        </div>
        <div className={styles.text}>
          <p>
            A link to reset your password should show up in the next few
            minutes. You’ll be prompted to return to The Yard to reset your
            password before signing in again.
          </p>
          <p>
            Didn’t get an email? Check your spam folder. If it’s not there, feel
            free to submit another request or reach out to The Yard.
          </p>
        </div>
      </div>
      <TextLink
        className={styles.emailLink}
        textColor="leather"
        onClick={() => {
          navigate('/help');
        }}
      >
        Email The Yard for other issues.
      </TextLink>
      <div className={styles.closeButtonWrapper}>
        <Button
          text="CLOSE"
          onClick={() => navigate('/')}
          buttonType="secondary"
          buttonColor="sand20"
          buttonClassName={styles.closeButton}
          containerClassName={styles.buttonContainer}
        />
      </div>
    </div>
  );
}

export default ResetRequestSentScreen;
