import React from 'react';
import { useGetUsersByUserIdOnboardQuery } from 'api';
import Grid from '@mui/material/Grid';
import FormContainer from 'components/FormContainer/FormContainer';
import { Button } from 'components/Buttons';
import Loading from 'components/Loading';
import styles from './welcomeScreen.module.scss';

type Props = {
  userId: string;
};

function WelcomeScreen({ userId }: Props) {
  const { data, isFetching } = useGetUsersByUserIdOnboardQuery(
    { userId },
    { skip: userId === '' },
  );

  if (isFetching) {
    return <Loading />;
  }

  const stripeAccountLink = data?.data.link.url;

  return (
    <Grid item xs={12} className={styles.bottomMargin}>
      <div className={styles.topSectionContainer}>
        <div className={styles.subHeader}>
          Your registration is almost complete.
        </div>
        <div className={styles.subText}>
          You&apos;re all set to start shopping, but before you can officially
          sell or buy items, we still need a few things from you.
        </div>
      </div>
      <FormContainer
        className={styles.formContainer}
        title="Complete Your Stripe Account"
        actionNeeded
        content={
          <>
            <div className={styles.bodyText}>
              We&apos;re excited you&apos;re here. Click the button below to
              finish setting up your Stripe account before you can check out or
              sell on The Yard.
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                href={stripeAccountLink}
                title="COMPLETE STRIPE ACCOUNT"
                buttonColor="sand20"
              />
            </div>
          </>
        }
      />
    </Grid>
  );
}

export default WelcomeScreen;
