import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Address, User, usePutUsersByUserIdMutation } from 'api';
import { useGetUserDetails } from 'hooks';
import { setBusinessName } from 'slices/accountSlice';
import Loading from 'components/Loading';
import { AccountAddressForm } from 'components/Forms';
import SnackbarDefault, { SnackbarObj } from 'components/Snackbar/Snackbar';
import styles from './businessDetails.module.scss';

function BusinessDetails() {
  const dispatch = useDispatch();
  const getUserResponse = useGetUserDetails();
  const [userData, setUserData] = useState<User>();
  const [isCompany, setIsCompany] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  useEffect(() => {
    if (getUserResponse?.data.user) {
      const { user } = getUserResponse.data;
      const isCompanyAccount = user.type === 'company';
      const userPhoneNumber =
        user.business && user.business.phone ? user.business.phone : null;

      setUserData(user);
      setIsCompany(isCompanyAccount);
      setPhoneNumber(userPhoneNumber);
    }
  }, [getUserResponse]);

  const [isValidAddress, setIsValidAddress] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackbarObj>({
    open: false,
    message: '',
  });

  const [updateUser] = usePutUsersByUserIdMutation();

  const saveAddress = async (updatedAddress: Address) => {
    try {
      const userId = userData?.id || '';
      const unformattedPhone = phoneNumber?.replace(/[^\d]/g, '');

      const payload: any = await updateUser({
        userId,
        body: {
          ...userData,
          address: updatedAddress,
          ...(isCompany && {
            business: {
              name: updatedAddress.name,
              phone: unformattedPhone,
            },
          }),
        },
      }).unwrap();

      if (payload.success) {
        const { user: userPayload } = payload.data;
        const updatedPhone =
          userPayload.business && userPayload.business.phone
            ? userPayload.business.phone
            : null;

        if (isCompany && userPayload.business) {
          dispatch(setBusinessName(userPayload.business.name));
        }

        setUserData(userPayload);
        setPhoneNumber(updatedPhone);
        setSnackbarInfo({
          open: true,
          message: 'Address saved successfully',
        });
      }
    } catch (err) {
      // setShowSnackbar(true);
    }
  };

  if (!userData) {
    return <Loading />;
  }

  const savedPhoneNumber =
    userData.business && userData.business.phone
      ? userData.business.phone
      : null;

  /* if phoneProps is null, the phone input won't show. We only
  want to show the phone input if the user created a business account
  when signing up */
  const phoneProps = isCompany
    ? {
        savedPhoneNumber,
        phoneNumber,
        setPhoneNumber,
      }
    : null;

  return (
    <div className={styles.wrapper}>
      <SnackbarDefault
        open={snackbarInfo.open}
        message={snackbarInfo.message}
        onClose={() => {
          setSnackbarInfo({ ...snackbarInfo, open: false });
        }}
      />
      <AccountAddressForm
        shippingType="userAddress"
        shippingAddress={userData.address}
        saveAddress={(newAddress) => {
          saveAddress(newAddress);
        }}
        phoneProps={phoneProps}
        isValidAddress={isValidAddress}
        setIsValidAddress={setIsValidAddress}
        disableSavedInputs={false}
        showSaveButton
      />
    </div>
  );
}

export default BusinessDetails;
