import React from 'react';
import { Listing } from 'api';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import { ReactComponent as ShippingIcon } from 'assets/icons/statuses/shippingStatusIcon.svg';
import { ReactComponent as ListedIcon } from 'assets/icons/statuses/listedStatusIcon.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/statuses/processingStatusIcon.svg';
import { ReactComponent as CompleteIcon } from 'assets/icons/statuses/completeStatusIcon.svg';
import { ReactComponent as DraftIcon } from 'assets/icons/statuses/draftStatusIcon.svg';
import { ReactComponent as ArchivedIcon } from 'assets/icons/statuses/archivedStatusIcon.svg';
import { ReactComponent as DisputeIcon } from 'assets/icons/statuses/disputeStatusIcon.svg';
import { ReactComponent as StepOneIndicator } from 'assets/icons/statuses/stepIndicators/stepOneIndicator.svg';
import { ReactComponent as StepThreeIndicator } from 'assets/icons/statuses/stepIndicators/stepThreeIndicator.svg';
import DropdownMenu from './MenuOptions';
import styles from './listingItem.module.scss';

type Props = {
  listing: Listing;
};

function ListingsItem({ listing }: Props) {
  const navigate = useNavigate();
  const {
    orders = [],
    id: listingId,
    status,
    category,
    subCategory,
    number,
    sold,
  } = listing;

  const noActiveOrders =
    orders.length === 0 || orders.every((order) => order.canceled);
  const firstOpenOrder = orders.find((order) => !order.canceled && order.paid);
  const orderId = firstOpenOrder?.id;
  const orderStatus = firstOpenOrder?.status?.toLowerCase();
  const buyer = firstOpenOrder?.buyer;

  const listingStatus = status.toLowerCase();
  // since listings that have been ordered are marked as archived, we need to check if they are sold in order to display the correct status
  const listingArchived = listingStatus === 'archived' && !sold;
  // some listings are in a bad state due to orders being made but not cancelled or paid for properly
  const listingIsInBadState = !!(
    listingStatus === 'archived' &&
    sold &&
    !orderId
  );

  // if an order has any of these statuses, display the Disputed state
  const disputedOrderStatuses =
    orderStatus === 'payout-failed' || orderStatus === 'disputed';

  const listingIcon = () => {
    // by default, show the 'listed' icon
    let icon = (
      <ListedIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />
    );
    let colorClass = styles.iconGreyBackground;
    let stepIndicator;

    if (listingStatus === 'draft') {
      icon = <DraftIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />;
      colorClass = styles.draftIconContainer;
    } else if (listingArchived) {
      icon = (
        <ArchivedIcon className={`${styles.iconSize} ${styles.metalIcon}`} />
      );
      colorClass = styles.archiveIconContainer;
    } else if (orderStatus === 'paid') {
      // 'ordered'
      icon = (
        <ProcessingIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />
      );
      stepIndicator = <StepOneIndicator className={styles.stepIndicatorSize} />;
      colorClass = styles.iconSandBackground;
    } else if (orderStatus === 'shipped') {
      // 'ship'
      icon = (
        <ShippingIcon className={`${styles.iconSize} ${styles.whiteIcon}`} />
      );
      stepIndicator = (
        <StepThreeIndicator className={styles.stepIndicatorSize} />
      );
      colorClass = styles.iconBlackBackground;
    } else if (orderStatus === 'completed' || orderStatus === 'accepted') {
      icon = (
        <CompleteIcon className={`${styles.iconSize} ${styles.sandIcon}`} />
      );
      colorClass = styles.iconWhiteBackground;
    } else if (disputedOrderStatuses || listingIsInBadState) {
      icon = (
        <DisputeIcon className={`${styles.iconSize} ${styles.offWhiteIcon}`} />
      );
      colorClass = styles.disputeIconContainer;
    }

    const indicatorClass = stepIndicator && styles.iconWithStepIndicator;

    if (indicatorClass) {
      return (
        <div className={indicatorClass}>
          <div className={styles.stepIndicatorIcon}>{stepIndicator}</div>
          <div
            className={`
              ${styles.iconContainer}
              ${styles.iconWithIndicator}
              ${colorClass}
            `}
          >
            {icon}
          </div>
        </div>
      );
    }

    return (
      <div className={`${styles.iconContainer} ${colorClass}`}>{icon}</div>
    );
  };

  const formatDate = (date: string | Date) => {
    const value = date instanceof Date ? date : new Date(date);
    return value.toLocaleDateString().replaceAll('/', '.');
  };

  const displayedDate = () => {
    const listingCreatedAt = listing.createdAt;
    const listingUpdatedAt = listing?.updatedAt;
    const orderCreateAt = firstOpenOrder?.createdAt;
    const orderUpdatedAt = firstOpenOrder?.updatedAt;

    if (orderStatus === 'completed' && orderUpdatedAt) {
      return <>Sold {formatDate(orderUpdatedAt)}</>;
    }
    if (orderCreateAt) {
      return <>Ordered {formatDate(orderCreateAt)}</>;
    }
    if (listingStatus === 'open') {
      const listedDate = listingUpdatedAt || listingCreatedAt;
      return <>Listed {formatDate(listedDate)}</>;
    }
    return <>Created {formatDate(listingCreatedAt)}</>;
  };

  const uiListingStatus = listingStatus === 'open' ? 'listed' : listingStatus;

  let uiOrderStatus;
  switch (orderStatus) {
    case 'paid':
      uiOrderStatus = 'ordered';
      break;
    case 'pending-refund':
    case 'pending-payout':
      uiOrderStatus = 'processing';
      break;
    case 'shipped':
      uiOrderStatus = 'shipping';
      break;
    case 'completed':
      uiOrderStatus = 'complete';
      break;
    case 'canceled':
    case 'refunded':
      uiOrderStatus = uiListingStatus;
      break;
    default:
      uiOrderStatus = orderStatus?.replaceAll('-', ' ') || '';
      break;
  }

  const displayedStatus = listingArchived
    ? uiListingStatus.toUpperCase()
    : uiOrderStatus.toUpperCase() || uiListingStatus.toUpperCase();

  // for coloring the draft items
  const draftClass = listingStatus === 'draft' && styles.draftContainer;
  // for coloring archived products
  const archivedClass = listingArchived && styles.archivedProduct;
  // for coloring the status
  let orderStatusClass = `${uiListingStatus}Status`;
  if (orderStatus && !listingArchived) {
    orderStatusClass = `${uiOrderStatus}Status`;
  }

  return (
    <div className={`${styles.listingContainer} ${draftClass}`}>
      <Grid container justifyContent="space-between" className={styles.topRow}>
        <div className={styles.topLeft}>
          {listingIcon()}
          <div
            className={styles.productNameContainer}
            onClick={() =>
              navigate(
                `/product/${category.toLowerCase()}/${subCategory}/${listingId}`,
              )
            }
          >
            <div className={styles.listingNumber}>{number}</div>
            <div className={styles.productName}>{listing.productName}</div>
          </div>
        </div>
        <div className={`${styles.topRight} ${archivedClass}`}>
          <div className={styles.buyerInfoContainer}>
            {buyer && (
              <>
                <div className={styles.buyerName}>{buyer.name}</div>
                {buyer.rating.averageRating > 0 && (
                  <div className={styles.buyerRating}>
                    {buyer.rating.averageRating}
                    <StarIcon className={styles.starIcon} />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.listingPrice}>
            ${firstOpenOrder?.total || listing.totalPrice}
          </div>
        </div>
      </Grid>
      <div className={styles.lineSeparator} />
      <div className={styles.bottomRow}>
        <div className={styles.bottomLeft}>
          <div
            className={`${styles.listingStatus} ${styles[orderStatusClass]}`}
          >
            {displayedStatus}
          </div>
          <div className={styles.listingDate}>{displayedDate()}</div>
        </div>
        <DropdownMenu
          listing={listing}
          orderId={orderId}
          listingStatus={listingStatus}
          noOrders={noActiveOrders}
          category={category}
          subCategory={subCategory}
          listingIsInBadState={listingIsInBadState}
        />
      </div>
    </div>
  );
}

export default ListingsItem;
