import React, { useState, useEffect } from 'react';
import { useGetListingsRecentQuery, Listing } from 'api';
import Table from 'components/Table/Table';

function LastViewedItemsTable() {
  const [tableData, setTableData] = useState<Listing[]>([]);
  const { currentData, isError, isFetching } = useGetListingsRecentQuery(
    {
      page: '0',
      perPage: '99999',
    },
    // forcing the query to always fetch when this component is mounted since I can't invalidate the cache after viewing a product
    { refetchOnMountOrArgChange: true },
  );

  const response = () => {
    if (isError) {
      // TO DO: error message handler
    }

    if (!isFetching && currentData) {
      setTableData(currentData);
    }
  };
  useEffect(response, [isFetching, currentData, isError]);

  return (
    <Table
      tableName="Your Last Viewed Items"
      startingView="card"
      data={tableData}
    />
  );
}

export default LastViewedItemsTable;
