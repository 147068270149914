import React from 'react';
import Grid from '@mui/material/Grid';
import CtaItem from 'components/CtaItem/CtaItem';
import TextLink from 'components/Links/TextLink';
import styles from './ctaItemsSection.module.scss';

function CtaItemsSection() {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} className={styles.ctaItemWrapper}>
        <CtaItem
          headerText="Built for builders (by builders)"
          subtext="We know the challenges of buying and selling materials because we've been there. The Yard was built by people in the business who made it as simple as possible to buy and sell quality, construction-grade materials online."
          buttonText="LEARN MORE"
          numberOfBoldWords={2}
          href="/interested_in_selling/#community-of-builders"
        />
      </Grid>
      <Grid item xs={12} className={styles.ctaItemWrapper}>
        <CtaItem
          headerText="How it Works"
          subtext="List the materials you have to sell, and browse our inventory for what you need. Once you’re ready to make your secure transaction, The Yard will walk you through the steps, down to shipping details—making it as streamlined and painless as possible."
          buttonText="LEARN MORE"
          numberOfBoldWords={1}
          href="/interested_in_selling/#how-it-works"
        />
      </Grid>
      <Grid item xs={12} className={styles.ctaItemWrapper}>
        <CtaItem
          headerText="Interested in selling?"
          subtext="Throw out everything you thought you knew about reselling your materials—The Yard is taking construction out of the dark ages. All the details are covered by our team so you can focus on offloading your materials and getting back to work. "
          buttonText="GET STARTED"
          numberOfBoldWords={2}
          screenPath="/product_listing"
        />
      </Grid>
      <Grid container justifyContent="center" className={styles.linksContainer}>
        <Grid item xs={12} md={10} className={styles.linksWrapper}>
          <Grid item xs={12} sm={3} md={3}>
            <TextLink className={styles.link} to="/about">
              ABOUT THE YARD
            </TextLink>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <TextLink className={styles.link} to="/faqs">
              FREQUENTLY ASKED QUESTIONS
            </TextLink>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CtaItemsSection;
