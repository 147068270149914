import React from 'react';
import { Order } from 'api';
import Grid from '@mui/material/Grid';
import waitingStatusIcon from 'assets/icons/statuses/shippingStatuses/notStartedStatusDot.svg';
import processingStatusIcon from 'assets/icons/statuses/shippingStatuses/processingStatusCircle.svg';
import completeStatusIcon from 'assets/icons/circleCheckedIcon.svg';
import { ReactComponent as OpenInNewIcon } from 'assets/icons/openInNewIcon.svg';
import CardComponent, { ContainerStates } from '../components/CardComponent';
import CardButtons from '../components/CardButtons';
import styles from './shippingCard.module.scss';

type Props = {
  order: Order;
  isBuyer: boolean;
  setShowInspectOrderDialog: (isOpen: boolean) => any;
};

function ShippingCard({ order, isBuyer, setShowInspectOrderDialog }: Props) {
  const { status: orderStatus, shipping } = order;

  let header = 'Shipping';
  let status = 'Waiting';
  let statusIcon = waitingStatusIcon;
  let body = 'default';
  let containerState: ContainerStates = 'pending';

  switch (orderStatus) {
    case 'DRAFT':
    case 'PAYMENT-FAILED':
    case 'PENDING-PAYMENT':
    case 'SUBMITTED':
      header = 'Shipping';
      status = 'Waiting';
      statusIcon = waitingStatusIcon;
      containerState = 'pending';
      body = 'default';
      break;
    case 'PAID':
      header = 'Shipping...';
      status = isBuyer ? 'Waiting' : 'Ship Now';
      statusIcon = processingStatusIcon;
      containerState = isBuyer ? 'focus' : 'actionNeeded';
      body = 'waitingToShip';
      break;
    case 'SHIPPED':
      header = 'Shipping...';
      status = 'In Transit';
      statusIcon = processingStatusIcon;
      containerState = isBuyer ? 'actionNeeded' : 'focus';
      body = 'shipped';
      break;
    default:
      // default is all the steps after shipment (received, complete, disputed, etc)
      header = 'Shipping';
      status = 'Delivered';
      statusIcon = completeStatusIcon;
      containerState = 'complete';
      body = 'complete';
  }

  const viewShipping = () => {
    // TO DO: trackingId link
  };

  const formatDates = (date: string) => {
    const dateFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as const;

    const formattedDate = new Date(date).toLocaleDateString(
      'en-us',
      dateFormatOptions,
    );

    return formattedDate;
  };

  const shippingDetails = () => {
    const finalState = status === 'Delivered';
    const shippedDate = shipping?.shippedOn || '';
    // TODO: add received date
    return (
      <>
        <Grid item xs={12} container>
          <Grid item xs={5} container direction="column">
            <div className={styles.sectionHeader}>
              Shipped {!finalState && 'on'}
            </div>
            <div className={styles.text}>{formatDates(shippedDate)}</div>
          </Grid>
          {/* {finalState && (
            <Grid item xs={5} container direction="column">
              <div className={styles.sectionHeader}>Received</div>
              <div className={styles.text}>Feb 2, 2022</div>
            </Grid>
          )} */}
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          className={styles.shipping}
        >
          <div
            className={`${styles.largeHeaderMargin} ${styles.sectionHeader}`}
          >
            Tracking ID
          </div>
          <Grid
            container
            onClick={viewShipping}
            className={styles.trackingLink}
            alignItems="center"
          >
            <div className={styles.trackingId}>{shipping?.trackingCode}</div>
            <OpenInNewIcon />
          </Grid>
        </Grid>
      </>
    );
  };

  const defaultState = () => (
    <div className={`${styles.container} ${styles.text}`}>
      It&apos;s not time to ship yet.
      <br />
      {isBuyer ? (
        <>When the seller ships your order, we&apos;ll update you here.</>
      ) : (
        <>When we&apos;ve received payment we&apos;ll let you know.</>
      )}
    </div>
  );

  const waitingToShipState = () => {
    if (isBuyer) {
      return (
        <div className={`${styles.container} ${styles.text}`}>
          The seller is getting ready to ship your item.
          <br />
          We&apos;ll let you know when they do and add tracking information as
          we receive it.
        </div>
      );
    }

    return (
      <div className={`${styles.sellerShipNowContainer} ${styles.text}`}>
        <div className={`${styles.sectionHeader} ${styles.receivedPayment}`}>
          We&apos;ve received payment.
        </div>
        It&apos;s time for you to ship items.
        <br />
        Check The Yard will reach out to you to setup shipping.
      </div>
    );
  };

  const shippedState = () => {
    if (isBuyer) {
      return (
        <Grid container>
          <Grid item xs={12} container className={styles.sectionWrapper}>
            {shippingDetails()}
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            className={styles.question}
          >
            <div
              className={`${styles.largeHeaderMargin} ${styles.sectionHeader}`}
            >
              Have you received this order?
            </div>
            <div className={styles.text}>
              Inspect your order and let us know if you accept it.
            </div>
          </Grid>
          <CardButtons
            buttonText="INSPECT ORDER"
            onClick={() => setShowInspectOrderDialog(true)}
          />
        </Grid>
      );
    }

    return (
      <Grid container className={styles.container}>
        {shippingDetails()}
      </Grid>
    );
  };

  const details = () => {
    if (body === 'default') {
      return defaultState();
    } else if (body === 'waitingToShip') {
      return waitingToShipState();
    } else if (body === 'shipped') {
      return shippedState();
    }

    return (
      <Grid container className={styles.container}>
        {shippingDetails()}
      </Grid>
    );
  };

  return (
    <div className={styles.wrapper}>
      <CardComponent
        title={header}
        status={status}
        icon={statusIcon}
        containerState={containerState}
      >
        {details()}
      </CardComponent>
    </div>
  );
}

export default ShippingCard;
