import { configureStore } from '@reduxjs/toolkit';
// @ts-ignore
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { api } from './api';
import accountReducer from './slices/accountSlice';
import transactionReducer from './slices/transactionSlice';
import listingsReducer from './slices/listingsSlice';
import shoppingReducer from './slices/shoppingSlice';
import apiErrorReducer from './slices/apiErrorSlice';
import apiResponseReducer from './slices/apiResponseSlice';

const persistConfig = {
  key: 'account',
  storage: new CookieStorage(Cookies),
  whitelist: [
    'credentials',
    'stripeAccountVerified',
    'businessName',
    'accountType',
  ], // list of reducers that will be persisted
};

const persistedAccountReducer = persistReducer(persistConfig, accountReducer);

const persistShoppingConfig = {
  key: 'shopping',
  storage: new CookieStorage(Cookies),
  whitelist: ['selectedFilters'],
};

const persistedShoppingReducer = persistReducer(
  persistShoppingConfig,
  shoppingReducer,
);

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    account: persistedAccountReducer,
    transaction: transactionReducer,
    listings: listingsReducer,
    shopping: persistedShoppingReducer,
    apiError: apiErrorReducer,
    apiResponse: apiResponseReducer,
  },
  devTools: true,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      /**
       * Ignore the serializable check on redux-persist.
       * https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
       */
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
