import { useEffect } from 'react';

const useDisableBodyScroll = () => {
  const enableBodyScroll = () => {
    document.body.style.overflow = 'scroll';
  };

  useEffect(() => {
    // hide the scrollbar from the body, then reset it after the modal closes, else there are 2 scrollbars
    document.body.style.overflow = 'hidden';

    return () => enableBodyScroll();
  }, []);

  return { enableBodyScroll };
};

export default useDisableBodyScroll;
