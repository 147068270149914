import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './productImage.module.scss';

type Props = {
  productName: string;
  image: string;
  listingId: string;
  category: string;
  subCategory: string;
};

function ProductImage({
  productName,
  image,
  listingId,
  category,
  subCategory,
}: Props) {
  const navigate = useNavigate();

  const navigateToPdp = () => {
    navigate(`/product/${category.toLowerCase()}/${subCategory}/${listingId}`);
  };

  return (
    <div className={styles.wrapper} onClick={navigateToPdp}>
      <div className={styles.imageWrapper}>
        <img src={image} alt="product" className={styles.image} />
      </div>
      <div className={styles.name}>{productName}</div>
    </div>
  );
}

export default ProductImage;
