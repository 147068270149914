import React from 'react';
import { Box } from 'parts';
import { IPageHeroImageProps } from './types';

function PageHeroImage({ src, alt }: IPageHeroImageProps) {
  return (
    <Box width="100%" mt="50px" maxWidth="920px">
      <img width="100%" src={src} alt={alt} />
    </Box>
  );
}

export default PageHeroImage;
