import React from 'react';
import { Listing } from 'api';
import productConditionRatings from 'constants/productConditionRatings';
import Grid from '@mui/material/Grid';
import styles from './conditionSection.module.scss';
import parentStyles from '../productScreen.module.scss';

interface Props {
  listing: Listing;
}

function ConditionSection({ listing }: Props) {
  const {
    seller,
    productCondition,
    productConditionNotes,
    productConditionRating,
  } = listing;

  const conditionName =
    productCondition.toLowerCase() === 'as-is'
      ? productConditionRatings.find(
          (condition) => condition.rating === productConditionRating,
        )?.name
      : 'New';

  return (
    <Grid item xs={12} sm={12} md={6} lg={5.2} className={styles.container}>
      <div className={`${parentStyles.sectionHeader} ${styles.header}`}>
        Condition
      </div>
      <Grid className={styles.conditionContainer}>
        <div className={styles.conditionName}>{conditionName}</div>
        <div className={styles.conditionDescription}>
          {productConditionNotes}
          <span className={styles.sellerName}>&nbsp;- {seller.name}</span>
        </div>
      </Grid>
    </Grid>
  );
}

export default ConditionSection;
