import React, { useEffect } from 'react';
import { useGetListingsQuery, Listing, ListingFile } from 'api';
import categoryIndex from 'constants/categories.json';
import { useDispatch } from 'react-redux';
import { setSelectedFilters } from 'slices/shoppingSlice';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import FeaturedItems from './FeaturedItems/FeaturedItems';
import styles from './categoryScreen.module.scss';

type Props = {
  categoryName: string;
};

function CategoryHeader({ categoryName }: Props) {
  const dispatch = useDispatch();

  const setFilter = () => {
    dispatch(setSelectedFilters({ category: [categoryName] }));
  };

  useEffect(setFilter, [categoryName, dispatch]);

  const apiArgs = {
    category: categoryName,
    sortBy: 'createdAt',
    page: '0',
    perPage: '99999',
    sortDirection: 'DESC',
  };

  const { currentData, isError, isFetching } = useGetListingsQuery(apiArgs);

  if (isError) {
    // TO DO: error message handler
  }

  if (isFetching) {
    return <Loading />;
  }

  const subcategories =
    categoryIndex.find((categoryObj) =>
      categoryObj.name.toLowerCase().includes(categoryName.toLowerCase()),
    )?.subcategories ?? [];

  const addCountAndImages = subcategories.map((subcat) => {
    const listingImages: ListingFile[] = []; // need to do this for typescript

    return {
      ...subcat,
      count: 0,
      images: listingImages,
    };
  });

  const usedSubcategories = addCountAndImages.filter((subcategoryObj) =>
    currentData?.some(
      (listing: Listing) =>
        listing.subCategory.toUpperCase() === subcategoryObj.name,
    ),
  );
  // get a count of how many listings are in each subcategory
  usedSubcategories.forEach((subcategoryObj, idx) =>
    currentData?.forEach((listing: Listing) => {
      if (listing.subCategory.toUpperCase() === subcategoryObj.name) {
        /* eslint-disable-next-line no-plusplus */
        usedSubcategories[idx].count++;
        usedSubcategories[idx].images = [
          ...usedSubcategories[idx].images,
          listing.productImages[0],
        ];
      }
    }),
  );

  const firstFourSubcategories = usedSubcategories?.slice(0, 4);
  // currently there are not enough listings to use 7 subcats
  // const nextThreeSubcategories = usedSubcategories?.slice(4, 7);

  return (
    <div className={styles.headerContainer}>
      <Grid container justifyContent="center" className={styles.maxWidth}>
        <Grid item xs={12} md={10} className={styles.title}>
          {categoryName}
        </Grid>
        {firstFourSubcategories && (
          <Grid item xs={12} md={10}>
            <FeaturedItems
              category={categoryName}
              subcategories={firstFourSubcategories}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default CategoryHeader;
