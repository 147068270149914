import React, { useState, useEffect, useCallback } from 'react';
import Dropdown from 'components/Dropdown';
import formStyles from 'style/formStyles.module.scss'; // used for global input styling

type StateOption = {
  label: string;
  value: string;
};

type StateDropdownProps = {
  onChange: (value: StateOption) => void;
  value?: string | StateOption;
  containerClassName?: string;
  buttonClassName?: string;
  disabled?: boolean;
  invalidUserInput?: boolean;
  showAsterisk?: boolean;
  assistiveText?: string;
};

const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

function StateDropdown({
  onChange,
  value,
  containerClassName,
  buttonClassName,
  showAsterisk,
  assistiveText,
  invalidUserInput,
  ...props
}: StateDropdownProps) {
  const stateDropdownOptions: StateOption[] = states.map((state) => ({
    label: state.abbreviation,
    value: state.abbreviation,
  }));

  const [selectedStateObj, setSelectedStateObj] = useState<
    StateOption | undefined
  >();

  const findOption = useCallback(
    (stateName: any) => {
      const selectedState = stateDropdownOptions.find(
        (obj) => obj.value === stateName,
      );
      if (selectedState?.value !== selectedStateObj?.value) {
        setSelectedStateObj(selectedState);
      }
      // needed for setting the value in the parent
      if (selectedState && selectedState.value !== value) {
        onChange(selectedState);
      }
    },
    [stateDropdownOptions, onChange, selectedStateObj, value],
  );

  useEffect(() => findOption(value), [value, findOption]);

  const findStateByFullName = (inputString: string) => {
    const state = states.find(
      (stateObj) =>
        stateObj.name.toLowerCase() === inputString.toLowerCase() ||
        stateObj.abbreviation.toLowerCase() === inputString.toLowerCase(),
    );

    if (state) findOption(state.abbreviation);
  };

  const [isUserInput, setIsUserInput] = useState(false);

  const showAssistiveText = assistiveText && assistiveText !== '';

  return (
    <div className={formStyles.outerWrapper}>
      <Dropdown
        value={selectedStateObj}
        label={showAsterisk ? 'State*' : 'State'}
        options={stateDropdownOptions}
        onChange={onChange}
        onInputChange={(inputValue) => {
          // this is for autofills that have full names vs abbreviations
          if (inputValue.length === 1) {
            setIsUserInput(true);
          }

          if (!isUserInput) {
            findStateByFullName(inputValue);
          }
        }}
        key={`state_select_key__${JSON.stringify(selectedStateObj)}`} // This will force Select to re-render itself when the selection is updated. Needed for autofill
        containerClassName={containerClassName}
        buttonClassName={buttonClassName}
        isSearchable
        menuWidth="100%"
        dropdownType="form"
        name="state"
        invalidUserInput={invalidUserInput}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {showAssistiveText && (
        <div
          className={`${formStyles.assistiveText} ${
            invalidUserInput && formStyles.redAssistiveText
          }`}
        >
          {assistiveText}
        </div>
      )}
    </div>
  );
}

export default StateDropdown;
