import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks';
import Grid from '@mui/material/Grid';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { Button } from 'components/Buttons';
import colorVars from 'style/index.module.scss';
import styles from './searchCta.module.scss';
import SearchBar from '../SearchBar/SearchBar';

function SearchCta() {
  const reduxShopping = useAppSelector((state) => state.shopping);
  const { keywords = '' } = reduxShopping;

  const [showSearchBar, setShowSearchBar] = useState(false);
  const search = () => {
    setShowSearchBar(true);
  };

  useEffect(() => {
    if (showSearchBar) {
      setShowSearchBar(false);
    }
  }, [keywords, showSearchBar]);

  if (showSearchBar) {
    return <SearchBar />;
  }

  return (
    <Grid container className={styles.container}>
      <Grid item sm={1.5} md={3.6} className={styles.topBackground} />
      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        container
        direction="column"
        className={styles.textContainer}
      >
        <Grid item xs={6} className={styles.title}>
          Still not finding what
          <br />
          you&apos;re looking for?
        </Grid>
        <Grid item xs={4} md={6} className={styles.subtitle}>
          The Yard&apos;s inventory changes all the time because it&apos;s
          stocked by businesses like yours
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        md={3}
        container
        justifyContent="center"
        className={styles.buttonContainer}
      >
        <Grid item xs={8} md={5}>
          <Button
            text="SEARCH"
            onClick={search}
            icon={<SearchIcon fill={colorVars.offWhite} />}
            buttonColor="sand20"
            containerClassName={styles.button}
            buttonClassName={styles.innerButton}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchCta;
