import React from 'react';
import { useGetPaymentDetails } from 'hooks';
import Grid from '@mui/material/Grid';
import styles from './paymentCard.module.scss';

type Props = {
  paymentMethodId: string;
  paymentFailed: boolean;
};

function BuyerPaymentInfo({ paymentMethodId, paymentFailed }: Props) {
  const paymentMethodDetails = useGetPaymentDetails();
  const paymentMethodInfo = paymentMethodDetails.find(
    (method) => method.id === paymentMethodId,
  );

  if (!paymentMethodInfo) {
    // if no payment method info is found, hide this section
    return null;
  }

  const paymentInfo = () => {
    const { type, icon, last4 } = paymentMethodInfo;

    return (
      <Grid container>
        <div className={styles.paymentMethodWrapper}>
          <img src={icon} alt="payment type" className={styles.paymentLogo} />
        </div>
        <Grid
          item
          xs={7}
          container
          alignItems="center"
          className={styles.paymentInfo}
        >
          {type === 'us_bank_account' && (
            <div className={styles.paymentMethod}>ACH&nbsp;</div>
          )}
          ***{last4}
          {paymentFailed && (
            <span className={styles.failedPayment}>Failed</span>
          )}
          <div className={styles.viaStripe}>via Stripe</div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
      container
      direction="column"
      className={styles.sectionContainer}
    >
      <div className={`${styles.sectionHeader} ${styles.largeHeaderSpace}`}>
        Payment Method
      </div>
      <div className={`${styles.text} ${styles.paymentInfo}`}>
        {paymentInfo()}
      </div>
    </Grid>
  );
}

export default BuyerPaymentInfo;
