const shippingClasses = [
  {
    label: 'No class',
    value: 'noClass',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '55',
    value: '55',
  },
  {
    label: '60',
    value: '60',
  },
  {
    label: '65',
    value: '65',
  },
  {
    label: '70',
    value: '70',
  },
  {
    label: '77.5',
    value: '77.5',
  },
  {
    label: '85',
    value: '85',
  },
  {
    label: '92.5',
    value: '92.5',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '110',
    value: '110',
  },
  {
    label: '125',
    value: '125',
  },
  {
    label: '150',
    value: '150',
  },
  {
    label: '175',
    value: '175',
  },
  {
    label: '200',
    value: '200',
  },
  {
    label: '250',
    value: '250',
  },
  {
    label: '300',
    value: '300',
  },
  {
    label: '400',
    value: '400',
  },
  {
    label: '500',
    value: '500',
  },
];

export default shippingClasses;
