import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetListingsQuery, Listing, ListingFile } from 'api';
import categoryIndex from 'constants/categories.json';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import CategoryTable from './CategoryTable';
import { SubcategoryTable } from '../components';
import styles from './categoryScreen.module.scss';

function CategoryScreen() {
  const params = useParams();
  const { categoryName = '' } = params;

  const apiArgs = {
    category: categoryName,
    sortBy: 'createdAt',
    page: '0',
    perPage: '99999',
    sortDirection: 'DESC',
  };

  const { currentData, isError, isFetching } = useGetListingsQuery(apiArgs);

  if (isError) {
    // TO DO: error message handler
  }

  if (isFetching) {
    return <Loading />;
  }

  const subcategories =
    categoryIndex.find((categoryObj) =>
      categoryObj.name.toLowerCase().includes(categoryName.toLowerCase()),
    )?.subcategories ?? [];

  const addCountAndImages = subcategories.map((subcat) => {
    const listingImages: ListingFile[] = []; // need to do this for typescript

    return {
      ...subcat,
      count: 0,
      images: listingImages,
    };
  });

  const usedSubcategories = addCountAndImages.filter((subcategoryObj) =>
    currentData?.some(
      (listing: Listing) =>
        listing.subCategory.toUpperCase() === subcategoryObj.name,
    ),
  );
  // get a count of how many listings are in each subcategory
  usedSubcategories.forEach((subcategoryObj, idx) =>
    currentData?.forEach((listing: Listing) => {
      if (listing.subCategory.toUpperCase() === subcategoryObj.name) {
        /* eslint-disable-next-line no-plusplus */
        usedSubcategories[idx].count++;
        usedSubcategories[idx].images = [
          ...usedSubcategories[idx].images,
          listing.productImages[0],
        ];
      }
    }),
  );

  const firstFourSubcategories = usedSubcategories?.slice(0, 4);
  // currently there are not enough listings to use 7 subcats
  // const nextThreeSubcategories = usedSubcategories?.slice(4, 7);

  return (
    <Grid container alignItems="center" direction="column">
      <CategoryTable
        categoryName={categoryName}
        tableData={currentData || []}
        className={styles.table}
      />
      {firstFourSubcategories?.map(({ name, number }) => (
        <SubcategoryTable
          subcategory={name}
          key={number}
          className={styles.table}
        />
      ))}
    </Grid>
  );
}

export default CategoryScreen;
