import React, { useState } from 'react';
import { Listing } from 'api';
import { useFormatPrice, useGetListingTotal } from 'hooks';
import Grid from '@mui/material/Grid';
import { BuyNowButton, AddToCartButton } from 'components/Buttons';
import Dropdown from 'components/Dropdown/Dropdown';
import styles from './buySection.module.scss';

type Option = {
  value: string;
  label: string;
};

type Props = {
  listing: Listing;
};

function BuySection({ listing }: Props) {
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const {
    soldAs,
    totalPrice,
    basePrice,
    listedQty,
    minimumOrder,
    remainingQty = 0,
  } = listing;

  const isSoldAsLot = soldAs.toLowerCase() === 'lot';
  const totalSelectedUnitsPrice = useGetListingTotal(
    basePrice,
    selectedQuantity,
  );

  const dropdownOptions: Option[] = [];
  if (!isSoldAsLot) {
    for (let i = minimumOrder; i <= remainingQty; i += 1) {
      dropdownOptions.push({ label: `${i}`, value: `${i}` });
    }
  }

  const buttonWrapperClassName = isSoldAsLot
    ? styles.lotActionButtons
    : styles.perUnitActionButtons;

  const quantityInLotItemText = listedQty === 1 ? 'item' : 'items';

  return (
    <Grid item xs={12} sm={9} md={5} className={styles.container}>
      <Grid item xs={12} sm={9} md={8.4} className={styles.actionContainer}>
        <div className={styles.actionHeader}>
          <Grid container direction="row" alignItems="baseline">
            <div className={styles.price}>{useFormatPrice(totalPrice)}</div>
            {!isSoldAsLot && (
              <div className={styles.amountForPrice}>
                &nbsp; &nbsp; ({selectedQuantity}
                &nbsp; <div className={styles.for}>for</div>
                &nbsp;{totalSelectedUnitsPrice})
              </div>
            )}
          </Grid>
          <div className={styles.shippingAdded}>
            This price excludes shipping and taxes
          </div>
          {isSoldAsLot && (
            <div className={styles.quantityInLot}>
              {listedQty} {quantityInLotItemText} in lot
            </div>
          )}
        </div>
        <div className={styles.divider} />
        <Grid container className={buttonWrapperClassName}>
          {!isSoldAsLot && (
            <Grid
              item
              xs={12}
              sm={3}
              md={4}
              lg={3.5}
              className={styles.quantityDropdownWrapper}
            >
              <Dropdown
                label="Quantity"
                value={dropdownOptions.find(
                  (option) => option.value === selectedQuantity.toString(),
                )}
                options={dropdownOptions}
                onChange={(option: Option) => {
                  setSelectedQuantity(+option.value);
                }}
                quantityDropdown
                menuWidth="100%"
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={isSoldAsLot ? 12 : 9}
            md={isSoldAsLot ? 12 : 8}
            lg={isSoldAsLot ? 12 : 8.5}
            container
            className={styles.actionButtons}
          >
            <Grid item xs={6.5}>
              <BuyNowButton listing={listing} buttonType="button" />
            </Grid>
            <Grid item xs={5.5}>
              <AddToCartButton listing={listing} buttonType="textLink" />
            </Grid>
          </Grid>
        </Grid>
        {!isSoldAsLot && (
          <div className={styles.availableAmount}>
            {listing.remainingQty} available to order from this seller
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default BuySection;
