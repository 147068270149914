import React, { useState, useEffect } from 'react';
import { ReactComponent as EllipsisIcon } from 'assets/icons/ellipsisIcon.svg';
import { ReactComponent as DropdownArrowIcon } from 'assets/icons/dropDownIcon.svg';
import TooltipWrapper from 'components/TooltipWrapper';
import { COLORS } from 'style/colors';
import styles from './iconDropdown.module.scss';

type ListObj = {
  label: string;
  value: string;
  selected?: boolean;
  onClick?: any;
  href?: string;
  disabled?: boolean;
};

type Props = {
  options: ListObj[];
  optionClassName?: string;
  containerClassName?: string;
  menuClassName?: string;
  iconType?: 'ellipsis' | 'dropdown-arrow'; // by default it uses an ellipsis icon
  iconColor?: string; // by default it is COLORS.leather
  icon?: JSX.Element; // if you want a custom icon, pass that instead of setting iconType
  wrappedButton?: boolean; // should the dropdown look like a button - default is false
  label?: string; // to be used with wrappedButton
  optionTooltipText?: string;
};

function IconDropdown({
  options,
  optionClassName = '',
  containerClassName = '',
  menuClassName,
  iconType = 'ellipsis',
  icon,
  iconColor = COLORS.leather,
  wrappedButton = false,
  label,
  optionTooltipText = '',
}: Props) {
  const optionRef = React.useRef<any>(null);
  const [displayMenu, setDisplayMenu] = useState(false);
  const displayClassName = displayMenu ? styles.showMenu : styles.hideMenu;

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (
        displayMenu &&
        optionRef.current &&
        !optionRef.current.contains(event.target)
      ) {
        setDisplayMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [displayMenu]);

  let Icon = EllipsisIcon;
  if (iconType === 'dropdown-arrow') {
    Icon = DropdownArrowIcon;
  }

  const wrappedDropdown = wrappedButton && styles.wrappedDropdown;

  return (
    <div
      className={`${styles.wrapper} ${containerClassName} ${wrappedDropdown}`}
      onClick={(e: any) => {
        e.stopPropagation();
        setDisplayMenu(!displayMenu);
      }}
    >
      {label && <div className={styles.wrappedLabel}>{label} &nbsp;</div>}
      {icon ? <div>{icon}</div> : <Icon fill={iconColor} />}
      <div
        className={`${styles.optionsContainer} ${menuClassName} ${displayClassName}`}
        ref={optionRef}
      >
        {options.map((option, idx) => {
          // apply the border-radius to the first and last options
          const firstOption = idx === 0 && styles.firstOption;
          const lastOption = idx === options.length - 1 && styles.lastOption;
          const disabledOption = option.disabled && styles.disabledOption;

          if (option.href) {
            return (
              <a
                className={`${styles.option} ${optionClassName} ${firstOption} ${lastOption}`}
                key={option.label}
                href={option.href}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  setDisplayMenu(false);
                }}
              >
                {option.label}
              </a>
            );
          }

          return (
            <TooltipWrapper
              tooltipText={option.disabled ? optionTooltipText : ''}
              key={option.label}
              placement="left"
            >
              <a
                className={`${styles.option} ${disabledOption} ${optionClassName} ${firstOption} ${lastOption}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!option.disabled) {
                    option.onClick();
                    setDisplayMenu(false);
                  }
                }}
              >
                {option.label}
              </a>
            </TooltipWrapper>
          );
        })}
      </div>
    </div>
  );
}

export default IconDropdown;
