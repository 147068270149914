import React, { MouseEventHandler } from 'react';
import Ripples from 'react-ripples';
import styles from './button.module.scss';

type Props = {
  containerClassName?: string;
  buttonClassName?: string;
  rippleClassName?: string;
  text?: string;
  title?: string; // this is the same as text, but was used in the old button component
  children?: JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  icon?: JSX.Element;
  iconLocation?: 'before' | 'after'; // show icon before or after text; default is after
  roundedButton?: boolean; // default is false
  buttonType?: 'primary' | 'secondary' | 'text'; // default is primary
  buttonColor?: 'black' | 'white' | 'sand' | 'sand20' | 'brightSand20' | 'red';
  textColor?: 'black' | 'sand20' | 'white'; // only needed if buttonColor is white or buttonType = text, default it black
  innerRef?: any; // needed for image uploads
  href?: string; // use for a tag
  newTab?: boolean; // if href is used, do you want to open in a new tab
  type?: 'button' | 'submit' | 'reset';
};

function Button({
  containerClassName,
  buttonClassName,
  rippleClassName = '',
  text = '',
  title,
  children,
  onClick,
  disabled = false,
  icon,
  iconLocation = 'after',
  roundedButton = false,
  buttonType = 'primary',
  buttonColor,
  textColor = 'black',
  innerRef,
  href,
  newTab = false,
  type = 'button',
  ...props
}: Props) {
  // text buttons can have black or sand text, if sand, then we need to change it to white on hover/focus
  let whiteHoverClass = '';
  let buttonClass = '';
  let disabledClass = '';

  switch (buttonType) {
    case 'primary':
      buttonClass = styles[`${buttonColor}Primary`];
      disabledClass = styles.primaryDisabled;
      break;
    case 'secondary':
      buttonClass = styles[`${buttonColor}Secondary`];
      disabledClass = styles.secondaryDisabled;
      break;
    case 'text':
      buttonClass = styles[`${textColor}TextButton`];
      disabledClass = styles.textDisabled;
      if (buttonColor === 'white' && textColor === 'sand20') {
        whiteHoverClass = styles.whiteSandHoverText;
      }
      break;
    default:
      break;
  }

  const displayedButtonText = () => {
    const buttonText = text || title;
    if (icon) {
      return iconLocation === 'before' ? (
        <>
          {icon}&nbsp; {buttonText}
        </>
      ) : (
        <>
          {buttonText}&nbsp; {icon}
        </>
      );
    }

    return buttonText;
  };

  const buttonClasses = `
    ${styles.button}
    ${buttonClassName}
    ${buttonClass}
    ${disabledClass}
    ${whiteHoverClass}
    `;
  const disableContainerClass = disabled && styles.disabledContainer;
  const roundedClass = roundedButton && styles.rounded;

  return (
    <div
      className={`
        ${styles.wrapper}
        ${containerClassName}
        ${roundedClass}
        ${disableContainerClass}
      `}
    >
      <Ripples
        className={`${styles.ripples} ${rippleClassName}`}
        during={disabled ? 0 : 600}
        color={disabled ? '' : 'rgba(0, 0, 0, .3)'}
      >
        {href ? (
          <a
            href={href}
            target={newTab ? '_blank' : '_self'}
            rel="noopener noreferrer"
            className={`
              ${buttonClasses}
              ${styles.link}
            `}
            onMouseDown={(e) => {
              // this is needed to remove the :focus styling after click
              e.preventDefault();
            }}
          >
            {children || displayedButtonText()}
          </a>
        ) : (
          <button
            /* eslint-disable-next-line react/button-has-type */
            type={type}
            className={buttonClasses}
            disabled={disabled}
            onClick={onClick}
            onMouseDown={(e) => {
              // this is needed to remove the :focus styling after click
              e.preventDefault();
            }}
            ref={innerRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          >
            {children || displayedButtonText()}
          </button>
        )}
      </Ripples>
    </div>
  );
}

export default Button;
