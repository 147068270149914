import CheckboxNew from 'components/CheckboxNew';
import { Box, Button } from 'parts';
import React from 'react';

function Components() {
  return (
    <Box p={2}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap={2}
        maxWidth="600px"
        margin="0 auto"
      >
        <Button>Primary</Button>
        <Button color="secondary">Secondary</Button>
        <Button variant="outlined">Primary Outlined</Button>
        <Button color="secondary" variant="outlined">
          Secondary Outlined
        </Button>
        <Button variant="text">Primary Text</Button>
        <Button color="secondary" variant="text">
          Secondary Text
        </Button>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap={2}
        maxWidth="600px"
        margin="0 auto"
      >
        <Box>
          <CheckboxNew />
          <span>Checkbox without a label</span>
        </Box>
        <Box>
          <CheckboxNew label="Checkbox with a label" />
        </Box>
      </Box>
    </Box>
  );
}

export default Components;
