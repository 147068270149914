import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { isEmail } from 'validator';
import { useCreateZendeskRequest } from 'hooks';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import TextInput from 'components/TextInput';
import { Button, TextLink } from 'components/Buttons';
import StandardModal from 'components/StandardModal';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import styles from './betaPasswordModal.module.scss';

type Props = {
  setCorrectPassword: (correctPassword: boolean) => void;
};

function BetaPasswordModal({ setCorrectPassword }: Props) {
  const navigate = useNavigate();
  const { submitRequest } = useCreateZendeskRequest();

  const betaPassword = process.env.REACT_APP_BETA_PASSWORD;

  const [password, setPassword] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [name, setName] = useState<string>();
  const [invalidName, setInvalidName] = useState(false);

  const [phone, setPhone] = useState('');
  const [invalidPhone, setInvalidPhone] = useState(false);

  const invalidEmailInput = !email || invalidEmail;
  const invalidPhoneInput = !phone || invalidPhone;
  const invalidNameInput = !name || invalidName;

  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showMissingInfoMessage, setShowMissingInfoMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zendeskCallbackMessage, setZendeskCallbackMessage] = useState('');

  const submitPassword = useCallback(() => {
    if (password === betaPassword) {
      setCorrectPassword(true);
    } else {
      setIncorrectPassword(true);
      setCorrectPassword(false);
    }
  }, [betaPassword, password, setCorrectPassword]);

  const submitInquiry = useCallback(async () => {
    try {
      setLoading(true);

      const ticketInfo = {
        ticketFormId: 14921052358299,
        requester: {
          name: name || 'Unknown',
          email: email || 'Unknown',
        },
        subject: 'Early Access Request',
        commentBody: 'Early Access Request',
        customFields: [
          {
            id: 15461393264667, // name
            value: name,
          },
          {
            id: 14921015098267, // email
            value: email,
          },
          {
            id: 15461491256475, // phone
            value: phone,
          },
        ],
      };
      const response = await submitRequest(ticketInfo);

      if (response.status === 201) {
        setLoading(false);
        setZendeskCallbackMessage(
          'Thank you for your interest in joining the early access. We will be in touch shortly.',
        );

        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    } catch (error) {
      // catch the error
      setLoading(false);
      setZendeskCallbackMessage(
        'Something went wrong. Please try again later.',
      );
    }
  }, [email, name, navigate, phone, submitRequest]);

  const submitEmail = useCallback(() => {
    if (invalidEmailInput || invalidPhoneInput || invalidNameInput) {
      setShowMissingInfoMessage(true);
    } else {
      submitInquiry();
    }
  }, [invalidEmailInput, invalidPhoneInput, invalidNameInput, submitInquiry]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && password !== '') {
        submitPassword();
      } else if (event.key === 'Enter' && email !== '') {
        submitEmail();
      }
    };
    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [email, password, submitEmail, submitPassword]);

  const passwordInput = () => (
    <TextInput
      containerClassName={styles.passwordInput}
      label="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      invalidUserInput={incorrectPassword}
      assistiveText={
        incorrectPassword ? 'Incorrect password. Please try again.' : ''
      }
    />
  );

  const signUpForm = () => (
    <form className={styles.formWrapper}>
      <TextInput
        containerClassName={styles.input}
        label="Name*"
        value={name}
        onChange={(e) => {
          setName(e.target.value);

          if (name && invalidName) {
            setInvalidName(false);
          }
        }}
        onBlur={() => {
          if (!name) {
            setInvalidName(true);
          } else if (name && invalidName) {
            setInvalidName(false);
          }
        }}
        invalidUserInput={invalidName}
        assistiveText={invalidName ? 'Please enter your name' : ''}
      />
      <TextInput
        containerClassName={styles.input}
        label="Email*"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);

          if (isEmail(email) && invalidEmail) {
            setInvalidEmail(false);
          }
        }}
        onBlur={() => {
          if (!isEmail(email)) {
            setInvalidEmail(true);
          } else if (isEmail(email) && invalidEmail) {
            setInvalidEmail(false);
          }
        }}
        invalidUserInput={invalidEmail}
        assistiveText={invalidEmail ? 'Please enter a valid email' : ''}
      />
      <PhoneInput
        containerClassName={styles.input}
        label="Phone*"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        isInvalid={invalidPhone}
        onBlurValidationOnly
        setIsInvalid={(invalid: boolean) => setInvalidPhone(invalid)}
        required
      />
    </form>
  );

  const content = () => {
    if (zendeskCallbackMessage !== '') {
      return (
        <div className={styles.wrapper}>
          <div className={styles.text}>{zendeskCallbackMessage}</div>
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        {loading && <Loading screenOverlay />}
        <div className={styles.text}>
          {showSignUpForm ? (
            'Submit your email to join the early access'
          ) : (
            <>
              This site is currently in beta testing. Enter the site password to
              continue or{' '}
              <TextLink
                className={`${styles.text} ${styles.textLink}`}
                onClick={() => setShowSignUpForm(true)}
                textColor="sand20"
              >
                click here
              </TextLink>{' '}
              if you’d like to join the early access
            </>
          )}
        </div>
        {showSignUpForm ? signUpForm() : passwordInput()}
        <Grid
          container
          justifyContent="space-between"
          textAlign="center"
          className={styles.buttonWrapper}
        >
          <Grid item xs={5} className={styles.cancelButton}>
            <TextLink text="CANCEL" onClick={() => navigate(-1)} />
          </Grid>
          <Grid item xs={7}>
            <Button
              text="SUBMIT"
              onClick={() => {
                if (showSignUpForm) {
                  submitEmail();
                } else {
                  submitPassword();
                }
              }}
              buttonColor="black"
              roundedButton
              containerClassName={styles.button}
            />
          </Grid>
        </Grid>
        {showMissingInfoMessage && (
          <div className={styles.missingInfoMessage}>
            You must fill out all fields to sign up for the beta
          </div>
        )}
      </div>
    );
  };

  return (
    <StandardModal
      modalClassName={styles.modal}
      modalContent={content()}
      closeModalAction={() => navigate(-1)}
      fullScreenModal={false}
    />
  );
}

export default BetaPasswordModal;
