import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  usePutListingsByListingIdUpdateMutation,
  useDeleteListingsByListingIdDeleteListingMutation,
  Listing,
} from 'api';
import { useApiResponseMessage } from 'hooks';
import IconDropdown from 'components/IconDropdown';
import { GetHelpPopup } from 'components/Popups';
import styles from './listingItem.module.scss';

type OptionObj = {
  label: string;
  value: string;
  onClick: () => void;
};

type Props = {
  listing: Listing;
  orderId: string | undefined;
  listingStatus: string;
  noOrders: boolean;
  category: string;
  subCategory: string;
  listingIsInBadState: boolean;
};

function MenuOptions({
  listing,
  orderId,
  listingStatus,
  noOrders,
  category,
  subCategory,
  listingIsInBadState,
}: Props) {
  const navigate = useNavigate();
  const { apiResponseMessage } = useApiResponseMessage();
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const { id: listingId } = listing;

  const [updateListing] = usePutListingsByListingIdUpdateMutation();
  const [deleteListingApi] =
    useDeleteListingsByListingIdDeleteListingMutation();

  const handleError = (error: any) => {
    const message =
      error?.data?.message || 'An error occurred. Please try again.';

    apiResponseMessage(message);
  };

  const updateListingStatus = async (
    statusType: 'OPEN' | 'ARCHIVED' | 'DRAFT' | 'published' | 'unarchive',
  ) => {
    try {
      const status =
        statusType === 'published' || statusType === 'unarchive'
          ? 'OPEN'
          : statusType;

      const payload: any = await updateListing({
        listingId,
        listing: {
          ...listing,
          status,
        },
      }).unwrap();

      if (payload) {
        let message = 'Your listing is now published';
        if (statusType === 'ARCHIVED') {
          message = 'Your listing is now archived';
        } else if (statusType === 'DRAFT') {
          message = 'Your listing was saved as a draft';
        } else if (statusType === 'published') {
          message = 'Your listing is now published';
        } else if (statusType === 'unarchive') {
          message = 'Your listing is now unarchived';
        }

        apiResponseMessage(message);
      }
    } catch (err: any) {
      handleError(err);
    }
  };

  const deleteListing = async () => {
    try {
      const payload: any = await deleteListingApi({
        listingId,
      }).unwrap();

      if (payload.success) {
        apiResponseMessage(`Deleted listing ${listing?.number}`);
      }
    } catch (err: any) {
      handleError(err);
    }
  };

  const viewListing = () => {
    navigate(`/product/${category.toLowerCase()}/${subCategory}/${listingId}`);
  };

  // removed for beta
  // const previewListing = () => {
  // TODO: set a 'preview' banner then
  //   navigate(`/product/${category.toLowerCase()}/${subCategory}/${listingId}`);
  // };

  // removed for beta
  // const editListing = () => {
  //   // TO DO: openEdit: true
  //   navigate(`/product/${category.toLowerCase()}/${subCategory}/${listingId}`);
  // };

  const viewOrder = () => {
    if (orderId) {
      navigate(`/selling/purchase_details/${orderId}`);
    }
  };

  const menuOptions = () => {
    const viewOrderOption: OptionObj = {
      label: 'View Order',
      value: 'view_order',
      onClick: () => viewOrder(),
    };

    const viewListingOption: OptionObj = {
      label: 'View Listing',
      value: 'view_listing',
      onClick: () => viewListing(),
    };

    const archiveOption: OptionObj = {
      label: 'Archive',
      value: 'archive',
      onClick: () => updateListingStatus('ARCHIVED'),
    };

    const unarchiveOption: OptionObj = {
      label: 'Unarchive',
      value: 'unarchive',
      onClick: () => updateListingStatus('unarchive'),
    };

    // removed for beta
    // const editOption: OptionObj = {
    //   label: 'Edit',
    //   value: 'edit',
    //   onClick: () => editListing(),
    // };

    // removed for beta
    // const draftOption: OptionObj = {
    //   label: 'Make Draft',
    //   value: 'make_draft',
    //   onClick: () => updateListingStatus('DRAFT'),
    // };

    const deleteOption: OptionObj = {
      label: 'Delete',
      value: 'delete',
      onClick: deleteListing,
    };

    // const previewOption: OptionObj = {
    //   label: 'Preview',
    //   value: 'preview',
    //   onClick: () => previewListing(),
    // };

    const publishOption: OptionObj = {
      label: 'Publish',
      value: 'publish',
      onClick: () => updateListingStatus('published'),
    };

    const helpOption: OptionObj = {
      label: 'Help',
      value: 'help',
      onClick: () => setShowHelpPopup(true),
    };

    // menu options if the listing is in Open state with no orders
    const listedOptions = [
      // editOption, removed for beta
      viewListingOption,
      // draftOption, removed for beta
      archiveOption,
      deleteOption,
    ];

    // menu options if a listing has an order created against it
    const orderedOptions = [viewOrderOption, viewListingOption];

    // menu options if the listing is in Draft state
    const draftOptions = [
      // editOption, removed for beta
      // previewOption, removed for beta
      publishOption,
      archiveOption,
      deleteOption,
    ];

    if (listingIsInBadState) {
      return [helpOption];
    }
    if (listingStatus === 'draft') {
      return draftOptions;
    }
    if (listingStatus === 'archived' && noOrders) {
      return [unarchiveOption];
    }
    if (noOrders) {
      return listedOptions;
    }
    return orderedOptions;
  };

  return (
    <>
      <IconDropdown options={menuOptions()} menuClassName={styles.iconMenu} />
      {showHelpPopup && (
        <GetHelpPopup
          closePopup={() => setShowHelpPopup(false)}
          message="There is an issue with this listing. Please reach out to support for help."
        />
      )}
    </>
  );
}

export default MenuOptions;
