import React from 'react';
import StepTracker, { Step } from 'components/StepTracker/StepTracker';
import BallotIcon from '@mui/icons-material/Ballot';
import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';
import { ReactComponent as PricingIcon } from 'assets/icons/statuses/listedStatusIcon.svg';
import { ReactComponent as ShippingIcon } from 'assets/icons/statuses/shippingStatusIcon.svg';
import { COLORS } from 'style/colors';

type Props = {
  steps: string[];
  stepIdx: number;
  continueDisabled: boolean;
  setStep: (step: string) => void;
};

function HeaderStepTracker({
  stepIdx,
  continueDisabled,
  setStep,
  steps,
}: Props) {
  const stepTrackerSteps: Step[] = [
    {
      stepName: 'category',
      onClick: () => {
        setStep(steps[0]);
      },
    },
    {
      stepName: 'product description',
      onClick: () => {
        // only allow to if the prev step is complete or if we are already past this step
        if ((stepIdx === 0 && !continueDisabled) || stepIdx > 1) {
          setStep(steps[1]);
        }
      },
    },
    {
      stepName: 'pricing',
      onClick: () => {
        // only allow to if the prev step is complete or if we are already past this step
        if ((stepIdx === 1 && !continueDisabled) || stepIdx > 2) {
          setStep(steps[2]);
        }
      },
    },
    {
      stepName: 'shipping',
      onClick: () => {
        // only allow to if the prev step is complete
        if (stepIdx === 2 && !continueDisabled) {
          setStep(steps[3]);
        }
      },
    },
  ];

  const stepTrackerIcons = [
    <BallotIcon sx={{ fontSize: '24px', color: COLORS.offWhite }} />,
    <InfoIcon fill={COLORS.offWhite} />,
    <PricingIcon fill={COLORS.offWhite} />,
    <ShippingIcon fill={COLORS.offWhite} />,
  ];

  return (
    <StepTracker
      steps={stepTrackerSteps}
      icon={stepTrackerIcons[stepIdx]}
      activeStep={stepIdx}
    />
  );
}

export default HeaderStepTracker;
