import React from 'react';
import classNames from 'classnames';
import { ITypography } from './types';
import styles from './typography.module.scss';

function Body1({ children, bold }: ITypography) {
  const boldClass = bold && styles.bold;

  return (
    <span className={classNames(styles.body1, boldClass)}>{children}</span>
  );
}

export default Body1;
