import React from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useGetStripeAccountVerified } from 'hooks';
import { UnverifiedStripeAccountPopup } from 'components/Popups';

export default function VerifiedRoute() {
  const navigate = useNavigate();
  const stripeAccountVerified = useGetStripeAccountVerified();

  return (
    <>
      {!stripeAccountVerified && (
        <UnverifiedStripeAccountPopup onClose={() => navigate(-1)} />
      )}
      <Outlet />
    </>
  );
}
