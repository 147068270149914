import React, { useState, useEffect } from 'react';
import { useGetCartQuery, Cart } from 'api';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import SearchBar from 'components/SearchBar';
import { Button } from 'components/Buttons';
import CardItem from 'components/CardItem/CardItem';
import cartIcon from 'assets/icons/cartFilledIcon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { COLORS } from 'style/colors';
import styles from './shoppingCart.module.scss';

function ShoppingCart() {
  const [cartItems, setCartItems] = useState<Cart[]>([]);
  const { currentData, isLoading } = useGetCartQuery();

  useEffect(() => {
    if (currentData?.success) {
      setCartItems(currentData?.data);
    }
  }, [currentData]);

  const [showSearchBar, setShowSearchBar] = useState(false);
  const search = () => {
    setShowSearchBar(true);
  };

  const searchSection = () => {
    if (showSearchBar) {
      return <SearchBar />;
    }

    return (
      <Button
        buttonColor="black"
        title="Search"
        icon={<SearchIcon fill={COLORS.white} />}
        onClick={search}
      />
    );
  };

  const emptyCart = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={styles.emptyCartMessage}
    >
      Your cart is empty.
      <br />
      Time to start adding things.
      <span className={styles.searchText}>
        Search our inventory—it&apos;s changing all the time as contractors like
        you list materials for sale.
      </span>
      <div className={styles.searchSectionWrapper}>{searchSection()}</div>
    </Grid>
  );

  if (isLoading) return <Loading />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerContainer}>
        <Grid container className={styles.headerWrapper}>
          <Grid item xs={12} md={10} container className={styles.header}>
            <>
              My
              <br />
              Cart
            </>
            <img
              className={styles.cartIcon}
              src={cartIcon}
              alt="Shopping cart"
            />
          </Grid>
        </Grid>
      </div>
      <Grid container className={styles.bodyContainer}>
        <Grid item xs={12} md={10} className={styles.bodyHeader}>
          Any of your saved materials are here.
          <br />
          Since materials on The Yard are sold individually by different
          sellers, each listing must be purchased separately.
        </Grid>
        <Grid item xs={12} md={10} container className={styles.content}>
          {cartItems.length < 1 ? (
            emptyCart()
          ) : (
            <Grid item xs={12} md={10} container>
              {cartItems.map((cartObj: Cart) => {
                // the Cart type is used in both GET and POST /cart; get returns a listing object,
                // post sends a string (id of listing), so I need to add this check for TS
                if (typeof cartObj.listing === 'string') return null;

                const { listing, quantity } = cartObj;
                return (
                  <CardItem
                    key={listing.id}
                    listing={listing}
                    isCart
                    cartQuantity={quantity}
                  />
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ShoppingCart;
