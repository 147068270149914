import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrdersByOrderIdQuery, Order } from 'api';
import { useIdentity } from 'hooks';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import { InspectOrderDialog } from 'components/OrderComponents';
import Header from './Header';
import PaymentCard from './PaymentCard';
import ShippingCard from './ShippingCard';
import UserSection from './UserSection';
import Timeline from './Timeline';
import DocumentsSection from './DocumentsSection';
import OrderBanner from './OrderBanner';
import styles from './orderDetails.module.scss';

type Props = {
  order: Order;
};

function Content({ order }: Props) {
  const { userId } = useIdentity();
  const { listing, buyerUserId, buyer, shipping, seller, logs, canceled } =
    order;
  const isBuyer = buyerUserId === userId;
  const userSectionUser = isBuyer ? seller : buyer;
  // hide the Shipping card if the order has been canceled and there is no shipping info
  const hideShippingCard = canceled && !shipping?.shipped;

  const [showInspectOrderDialog, setShowInspectOrderDialog] = useState(false);

  return (
    <Grid container>
      <InspectOrderDialog
        order={order}
        isOpen={showInspectOrderDialog}
        setIsOpen={setShowInspectOrderDialog}
      />
      <Header order={order} isBuyer={isBuyer} />
      <Grid container direction="column" className={styles.bodyWrapper}>
        <Grid
          container
          className={`${styles.bannerWrapper} ${styles.sectionContainer}`}
        >
          <Grid item xs={12} sm={8} md={10} xl={11}>
            <OrderBanner
              order={order}
              isBuyer={isBuyer}
              setShowInspectOrderDialog={setShowInspectOrderDialog}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          xl={11}
          container
          className={styles.cardsWrapper}
        >
          <Grid
            item
            xs={12}
            md={10}
            xl={11}
            container
            className={styles.cardContainer}
          >
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              xl={5.5}
              className={styles.cardItem}
            >
              <PaymentCard order={order} isBuyer={isBuyer} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              xl={5.5}
              className={styles.cardItem}
            >
              {!hideShippingCard && (
                <ShippingCard
                  order={order}
                  isBuyer={isBuyer}
                  setShowInspectOrderDialog={setShowInspectOrderDialog}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={11}
          xl={12}
          container
          className={styles.sellerSectionContainer}
        >
          {userSectionUser && (
            <UserSection user={userSectionUser} isBuyer={isBuyer} />
          )}
        </Grid>
        <Grid item xs={12} className={styles.timelineAndPurchasesWrapper}>
          <Grid container className={styles.sectionContainer}>
            <Grid
              item
              xs={12}
              sm={8}
              md={10}
              className={styles.timelineWrapper}
            >
              <Timeline
                logs={logs}
                sellerName={seller?.name || ''}
                buyerName={buyer?.name || ''}
                isBuyer={isBuyer}
              />
            </Grid>
          </Grid>
          <div className={styles.backgroundPurchases}>Purchases</div>
        </Grid>
      </Grid>
      <Grid container className={styles.documentsContainer}>
        <Grid
          item
          xs={12}
          md={10}
          xl={11}
          container
          className={`${styles.sectionContainer} ${styles.bottomSection}`}
        >
          <DocumentsSection
            productDocuments={listing?.productDocuments || []}
            sellerName={seller?.name || ''}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function OrderDetails() {
  const params = useParams();
  const { orderId = '' } = params;
  const [order, setOrder] = useState<Order>();
  const [refetchCount, setRefetchCount] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);

  const { currentData, refetch } = useGetOrdersByOrderIdQuery(
    { orderId },
    // forcing the query to always fetch when this component is mounted since I can't invalidate the cache after payment is posted
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (currentData?.data?.order) {
      const { order: currentOrder } = currentData.data;
      const { paid, canceled, refunded, logs } = currentOrder;

      const paymentFailed = logs?.some(
        (log: any) => log.status === 'PAYMENT-FAILED',
      );
      const waitingForPayment = !paid && !canceled;
      const waitingForRefund = canceled && !refunded && !paymentFailed;

      // refetch the order if it's waiting for payment or refund
      const refetchOrder = waitingForPayment || waitingForRefund;

      // only refetch 5 times to prevent infinite loop
      if (refetchOrder && refetchCount < 6) {
        if (!isRefetching) {
          setIsRefetching(true);
        }

        setTimeout(() => {
          refetch();
          setRefetchCount(refetchCount + 1);
        }, 500);
      } else {
        setOrder(currentOrder);
        setIsRefetching(false);
      }
    }
  }, [currentData, refetch, refetchCount, isRefetching, setIsRefetching]);

  if (!order || isRefetching) {
    return <Loading className={styles.loadingScreen} />;
  }

  return <Content order={order} />;
}

export default OrderDetails;
