import { useGetConfigQuery } from 'api';
import { useFormatPrice } from 'hooks';

const useGetListingTotal = (basePrice: number, quantity?: number) => {
  const { data: config } = useGetConfigQuery();
  const { APPLICATION_FEE_RATE } = config?.data || {};

  // calculate the listing total to display to the buyer (basePrice * quantity + yard fees)
  const priceTotal = basePrice * (quantity || 1);
  const yardFees = priceTotal * (APPLICATION_FEE_RATE || 0);
  const buyerTotal = yardFees + priceTotal;

  const formattedBuyerTotal = useFormatPrice(buyerTotal);

  return formattedBuyerTotal;
};

export default useGetListingTotal;
