import React from 'react';
import { Listing } from 'api';
import Grid from '@mui/material/Grid';
import styles from './descriptionSection.module.scss';

interface Props {
  listing: Listing;
}

function DescriptionSection({ listing }: Props) {
  const stringArray = listing.productDescription.split(' ');
  const firstSixWords = stringArray.slice(0, 6).join(' ');
  const restOfDescription = stringArray.slice(6).join(' ');

  return (
    <Grid item xs={9} md={9} className={styles.description}>
      <span className={styles.boldDescription}>{firstSixWords}</span>
      &nbsp; {restOfDescription}
    </Grid>
  );
}

export default DescriptionSection;
