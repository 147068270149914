import React from 'react';
import Grid from '@mui/material/Grid';
import styles from './loading.module.scss';

type Props = {
  className?: string;
  screenOverlay?: boolean;
};

function Loading({ className, screenOverlay }: Props) {
  const overlayClass = screenOverlay ? styles.overlay : '';

  return (
    <Grid
      container
      justifyContent="center"
      textAlign="center"
      className={`${overlayClass} ${className}`}
    >
      <div className={styles.loader} />
    </Grid>
  );
}

export default Loading;
