import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import { useGetUsersByUserIdDashboardQuery, Order } from 'api';
import IconDropdown from 'components/IconDropdown';
import {
  InspectOrderDialog,
  CancelOrderModal,
  RatingModal,
} from 'components/OrderComponents';
import { COLORS } from 'style/colors';

type OptionObj = {
  label: string;
  value: string;
  onClick?: () => void;
  href?: string;
};

type Props = {
  order: Order;
  isBuyer: boolean;
  additionalOptions?: OptionObj[];
  wrappedButton?: boolean; // if true, the dropdown will be wrapped with a label - used for the Order Details screen
};

function OrderMenu({
  order,
  isBuyer,
  additionalOptions,
  wrappedButton = false,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    id: orderId,
    status: orderStatus,
    buyer,
    seller,
    ratings = [],
  } = order;
  const sellerUserId = seller?.id ?? '';

  const nameOfUserBeingRated = isBuyer ? seller?.name : buyer?.name;
  const sellerHasRatedTheOrder = ratings.find(
    (rating) => rating?.role === 'SELLER',
  );
  const buyerHasRatedTheOrder = ratings.find(
    (rating) => rating?.role === 'BUYER',
  );

  const [openInspectOrderDialog, setOpenInspectOrderDialog] = useState(false);
  const [stripeDashboardLink, setStripeDashboardLink] = useState<string>();

  const { data: dashboardQueryData } = useGetUsersByUserIdDashboardQuery(
    { userId: sellerUserId },
    { skip: isBuyer || sellerUserId === '' },
  );

  useEffect(() => {
    if (dashboardQueryData) {
      setStripeDashboardLink(dashboardQueryData?.data?.link.url);
    }
  }, [dashboardQueryData]);

  const [showCancelOrderModal, hideCancelOrderModal] = useModal(
    () => (
      <CancelOrderModal
        orderId={orderId}
        closeCancelOrderModal={() => hideCancelOrderModal()}
      />
    ),
    [orderId],
  );

  const [showRatingModal, hideRatingModal] = useModal(
    () => (
      <RatingModal
        orderId={orderId}
        targetName={nameOfUserBeingRated || ''}
        closeRatingModal={() => hideRatingModal()}
        nestedModal={false}
      />
    ),
    [orderId, nameOfUserBeingRated],
  );

  const viewOrderDetails: OptionObj = {
    label: 'View order details',
    value: 'viewOrderDetails',
    onClick: () => {
      navigate(`/orders/purchase_details/${orderId}`);
    },
  };

  const viewOnStripe = {
    label: 'View order on Stripe',
    value: 'viewOnStripe',
    onClick: () => {
      window.open(stripeDashboardLink);
    },
  };

  const inspectOrder: OptionObj = {
    label: 'Inspect your order',
    value: 'inspectOrder',
    onClick: () => setOpenInspectOrderDialog(true),
  };

  const rateOrder: OptionObj = {
    label: 'Rate order',
    value: 'rateOrder',
    onClick: showRatingModal,
  };

  const cancelOrder: OptionObj = {
    label: 'Cancel order',
    value: 'cancelOrder',
    onClick: showCancelOrderModal,
  };

  // const printShippingInvoice = {
  //   label: 'Print shipping invoice',
  //   value: 'printInvoice',
  //   onClick: () => {
  //     // TODO: implement print shipping invoice
  //   },
  // };

  // const archiveOrder: OptionObj = {
  //   label: 'Archive order',
  //   value: 'archiveOrder',
  //   onClick: () => {
  //     // TODO: implement archive order
  //   },
  // };

  // don't show this option if the user is on the purchase details page
  const hideViewOrderDetails = location.pathname.includes('purchase_details');

  const defaultMenu = [
    ...(!hideViewOrderDetails ? [viewOrderDetails] : []),
    // only a seller can view the order on stripe
    ...(!isBuyer ? [viewOnStripe] : []),
  ];

  const orderPlacedMenu = [...defaultMenu, cancelOrder];

  const inspectOrderMenu = [...defaultMenu, inspectOrder];

  const rateOrderMenu = [...defaultMenu, rateOrder];

  let menu = defaultMenu;

  switch (orderStatus) {
    case 'PENDING-PAYMENT':
    case 'SUBMITTED':
    case 'PAID':
      menu = orderPlacedMenu;
      break;
    case 'SHIPPED':
    case 'AWAITING-PICKUP':
      menu = isBuyer ? inspectOrderMenu : defaultMenu;
      break;
    case 'COMPLETED':
      if (
        (!sellerHasRatedTheOrder && !isBuyer) ||
        (!buyerHasRatedTheOrder && isBuyer)
      ) {
        menu = rateOrderMenu;
      } else {
        menu = defaultMenu;
      }
      break;
    default:
      menu = defaultMenu;
  }

  if (additionalOptions) {
    menu = menu.concat(additionalOptions);
  }

  const dropdown = () => {
    if (wrappedButton) {
      return (
        <IconDropdown
          options={menu}
          iconColor={COLORS.midnightBlack}
          wrappedButton
          label="Actions"
        />
      );
    }

    return <IconDropdown options={menu} />;
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // cancel all modals when the component unmounts
    return () => {
      hideCancelOrderModal();
      hideRatingModal();
    };
  }, [hideCancelOrderModal, hideRatingModal]);

  return (
    <>
      {dropdown()}
      <InspectOrderDialog
        order={order}
        isOpen={openInspectOrderDialog}
        setIsOpen={() => setOpenInspectOrderDialog(false)}
      />
    </>
  );
}

export default OrderMenu;
