import React from 'react';
import { Order } from 'api';
import { useFormatPrice } from 'hooks';
import Grid from '@mui/material/Grid';
import styles from './paymentCard.module.scss';

type Props = {
  order: Order;
  isBuyer: boolean;
  priceContainerColor: string;
  releaseFundsShown: boolean;
};

function Prices({
  order,
  isBuyer,
  priceContainerColor,
  releaseFundsShown,
}: Props) {
  const {
    total,
    salesTax,
    shipping,
    fees = 0,
    basePrice = 0,
    payout = 0,
    refunded,
  } = order;

  let shippingFee = shipping?.shipped ? shipping?.fee : 'TBD';
  if (refunded && !shipping?.fee) {
    shippingFee = 0;
  }
  const showShippingOrYardFee = isBuyer ? shippingFee : fees;
  // the listing total displayed to users is the base price + fees
  const itemTotal = basePrice + fees;
  // the buyer sees the total, the seller sees what they will be paid out
  const sellerPayout = refunded ? 0 : payout;
  const totalToDisplay = isBuyer ? total : sellerPayout;
  const buyerTotalText = refunded ? 'Refunded' : 'Order Total';
  const totalText = isBuyer ? buyerTotalText : 'Due to You on close';

  const actionNeededClass = releaseFundsShown && styles.priceActionNeeded;

  return (
    <Grid
      item
      xs={12}
      container
      direction="column"
      className={`${styles.priceContainer} ${priceContainerColor} ${actionNeededClass}`}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        className={styles.priceItem}
      >
        <div>Item Price</div>
        <div>{useFormatPrice(itemTotal)}</div>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        className={styles.priceItem}
      >
        <div>Sales Tax</div>
        <div>{useFormatPrice(salesTax)}</div>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        className={styles.priceItem}
      >
        <div>{isBuyer ? 'Shipping' : 'Other Fees'}</div>
        <div>{useFormatPrice(showShippingOrYardFee)}</div>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        className={styles.total}
      >
        <div>{totalText}</div>
        <div>{useFormatPrice(totalToDisplay)}</div>
      </Grid>
    </Grid>
  );
}

export default Prices;
