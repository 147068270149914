import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import {
  LoginCredentials,
  clearLoginCredentials,
  tokenReceived,
} from 'slices/accountSlice';
import { Mutex } from 'async-mutex';
import { YardApiError } from './types';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
});

const isJwtExpiredError = (error: FetchBaseQueryError) =>
  error &&
  error.status === 401 &&
  error.data &&
  (error.data as Error).message === 'jwt expired';

const baseQueryWithAuthentication = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).account.credentials?.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const baseQueryWithTokenRefresh: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQueryWithAuthentication(args, api, extraOptions);
  const error = <YardApiError>result?.error;

  if (isJwtExpiredError(error)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = (api.getState() as RootState).account.credentials
          ?.refreshToken;
        const refreshResult = await baseQuery(
          {
            url: '/auth/refresh',
            method: 'POST',
            body: {
              refreshToken,
            },
            headers: { 'Content-Type': 'application/json' },
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          const data = <LoginCredentials>refreshResult.data;
          api.dispatch(tokenReceived(data));
          result = await baseQueryWithAuthentication(args, api, extraOptions);
        } else {
          api.dispatch(clearLoginCredentials());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  if (process.env.REACT_APP_NODE_ENV === 'development' && result?.error) {
    // log error for dev testing
    // eslint-disable-next-line no-console
    console.error(result.error);
  }

  return result;
};

export default baseQueryWithTokenRefresh;
