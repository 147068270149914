import React from 'react';
import Grid from '@mui/material/Grid';
import FormContainer from 'components/FormContainer';
import styles from './shippingCard.module.scss';

function ShippingCard() {
  const formContent = () => (
    <Grid container>
      <Grid item xs={12} className={styles.detailsWrapper}>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsHeader}>Details</div>
          <div className={styles.detailsInfo}>
            Once shipping costs have been confirmed, you will receive
            notification of final cost.
          </div>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <FormContainer
      title="Shipping"
      content={formContent()}
      className={styles.formWrapper}
      titleClassName={styles.formTitle}
      bodyClassName={styles.formBody}
      actionNeeded
    />
  );
}

export default ShippingCard;
