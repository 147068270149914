import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import SignInModal from './components/SignInModal/SignInModal';
import { useIdentity } from './hooks';

export default function AuthenticatedRoute() {
  const { loggedIn } = useIdentity();
  const [successfulLoggedIn, setSuccessfulLoggedIn] = useState<boolean>();

  useEffect(() => {
    if (successfulLoggedIn === undefined) {
      setSuccessfulLoggedIn(loggedIn);
    }
  }, [loggedIn, successfulLoggedIn]);

  /* we want to make sure the full login flow is complete before rendering the outlet.
  loggedIn is true once they are logged in, but we need to make sure the stripe 
  account is verified and set the account info in redux) */
  return successfulLoggedIn ? (
    <Outlet />
  ) : (
    <SignInModal successfulSignInCallback={() => setSuccessfulLoggedIn(true)} />
  );
}
