import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUsersByUserIdVerifyQuery } from 'api';
import { setStripeAccountVerified } from 'slices/accountSlice';
import { useIdentity } from 'hooks';

const useGetStripeAccountVerified = () => {
  const dispatch = useDispatch();
  // get stripeAccountVerified from redux store and set it as the initial state
  const { userId, stripeAccountVerified } = useIdentity();
  const [isVerified, setIsVerified] = useState<boolean>(stripeAccountVerified);

  const { currentData } = useGetUsersByUserIdVerifyQuery({
    userId,
  });

  useEffect(() => {
    if (currentData?.success) {
      const verifiedAccount = currentData?.data?.verified || false;
      setIsVerified(verifiedAccount);
      dispatch(setStripeAccountVerified(verifiedAccount));
    }
  }, [currentData, dispatch]);

  return isVerified;
};

export default useGetStripeAccountVerified;
