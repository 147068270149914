import React from 'react';
import {
  useDeletePaymentMethodsByPaymentMethodIdMutation,
  usePostPaymentMethodsByPaymentMethodIdDefaultMutation,
} from 'api';
import Grid from '@mui/material/Grid';
import IconDropdown from 'components/IconDropdown';
import styles from './paymentDetailCard.module.scss';

export type PaymentDetails = {
  id: string;
  type: string;
  icon: string;
  last4: string;
  isDefault: boolean;
};

type Props = {
  paymentOption: PaymentDetails;
  isDefaultPayment?: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

function PaymentDetailCard({
  paymentOption,
  isDefaultPayment = false,
  setIsLoading,
}: Props) {
  const [postDefaultPaymentMethod] =
    usePostPaymentMethodsByPaymentMethodIdDefaultMutation();
  const [deletePaymentMethod] =
    useDeletePaymentMethodsByPaymentMethodIdMutation();
  const { type, id: paymentMethodId, last4, icon } = paymentOption;

  const handleSetDefault = async () => {
    setIsLoading(true);
    const setDefault = await postDefaultPaymentMethod({ paymentMethodId });

    if (setDefault) {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const deleteOption = await deletePaymentMethod({ paymentMethodId });

    if (deleteOption) {
      setIsLoading(false);
    }
  };

  const dropdownOptions = [
    ...(!isDefaultPayment
      ? [
          {
            label: 'Set as Default',
            value: 'setAsDefault',
            onClick: handleSetDefault,
          },
        ]
      : []),
    {
      label: 'Delete',
      value: 'delete',
      onClick: handleDelete,
    },
  ];

  const sizeClassName = isDefaultPayment
    ? styles.defaultOptionWrapper
    : styles.otherOptionWrapper;

  return (
    <Grid container className={`${styles.wrapper} ${sizeClassName}`}>
      <Grid
        item
        xs={2}
        md={isDefaultPayment ? 1 : 2}
        className={styles.iconWrapper}
      >
        <img src={icon} alt="payment type icon" className={styles.icon} />
      </Grid>
      <Grid
        item
        xs={8}
        md={isDefaultPayment ? 10 : 9}
        container
        alignItems="center"
      >
        <span>
          {type === 'us_bank_account' && 'ACH '} ***{last4}
        </span>
        {isDefaultPayment && <div className={styles.defaultText}>Default</div>}
      </Grid>
      <Grid item xs={2} md={1} container justifyContent="flex-end">
        <IconDropdown
          options={dropdownOptions}
          menuClassName={styles.dropdownMenu}
        />
      </Grid>
    </Grid>
  );
}

export default PaymentDetailCard;
