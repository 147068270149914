const productConditionRatings = [
  {
    name: 'Poor',
    rating: 1,
    description:
      'The item has major cosmetic flaws and may be damaged, not fully functional, or being sold for parts.',
  },
  {
    name: 'Fair',
    rating: 2,
    description:
      'The item is used and has multiple cosmetic flaws or defects. It must be functional and accurately described in the listing.',
  },
  {
    name: 'Good',
    rating: 3,
    description:
      'The item is gently used, but may have one or a few minor flaws. It must be functional and accurately described in the listing.',
  },
  {
    name: 'Like New',
    rating: 4,
    description:
      'The item is new without tags, unused, in mint condition, free of defects, and shows no signs of wear.',
  },
  {
    name: 'New',
    rating: 5,
    description:
      'New means exactly that: new! The item must be unused, unaltered, and in the original, unopened packaging. For clothing, the item must be new with tags.',
  },
];

export default productConditionRatings;
