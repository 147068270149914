import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

function VerifyScreen() {
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to="/"
      replace
      state={{ verificationToken: searchParams.get('token') ?? null }}
    />
  );
}

export default VerifyScreen;
