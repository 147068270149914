import React from 'react';
import Grid from '@mui/material/Grid';
import CtaItem from 'components/CtaItem/CtaItem';
import SearchCta from '../SearchCta/SearchCta';
import styles from './bottomCtaSection.module.scss';

function BottomCtaSection() {
  return (
    <Grid container>
      <Grid item xs={12} className={styles.searchWrapper}>
        <SearchCta />
      </Grid>
      <Grid container justifyContent="center" className={styles.ctaContainer}>
        <div className={styles.ctaWrapper}>
          <CtaItem
            headerText="Interested In selling on The Yard?"
            subtext="Listing your items is easy—You provide the details, and we'll walk you through every step along the way."
            buttonText="Get Started"
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default BottomCtaSection;
