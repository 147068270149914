import React from 'react';
import { useFormatPrice } from 'hooks';
import Grid from '@mui/material/Grid';
import { ReactComponent as StarIcon } from 'assets/icons/starIcon.svg';
import styles from './priceCard.module.scss';

type Props = {
  pickUp: boolean;
  productPrice: number;
  salesTax: number | 'TBD';
  total: number;
  seller: {
    name: string;
    address: string;
    averageRating: number;
  };
  productImage: JSX.Element;
};

function PriceCard({
  pickUp,
  productPrice,
  salesTax,
  total,
  seller,
  productImage,
}: Props) {
  const shippingCost = 'TBD';

  const formattedItemPrice = useFormatPrice(productPrice);
  const formattedTax = useFormatPrice(salesTax);
  const formattedShippingCost = useFormatPrice(shippingCost);
  const formattedTotalCost = useFormatPrice(total);
  // the total isn't calculated until the tax is calculated, so if the total is 0, we show the item price
  const totalNotCalculated = total === 0;

  const pricing = () => (
    <Grid
      item
      xs={12}
      container
      direction="column"
      className={styles.pricingContainer}
    >
      <div className={styles.priceLine}>
        <div>Item Price</div>
        <div>{formattedItemPrice}</div>
      </div>
      <div className={styles.priceLine}>
        <div>Sales Tax</div>
        <div>{formattedTax}</div>
      </div>
      <div className={styles.priceLine}>
        <div>Shipping {pickUp && '(buyer pickup)'}</div>
        <div>{formattedShippingCost}</div>
      </div>
      <div className={styles.totalContainer}>
        <div>Order Total</div>
        <div className={styles.total}>
          {totalNotCalculated ? formattedItemPrice : formattedTotalCost}
        </div>
      </div>
    </Grid>
  );

  const sellerInfo = () => (
    <Grid item xs={12}>
      <div className={styles.seller}>Seller</div>
      <Grid container direction="column">
        <div className={styles.sellerName}>{seller.name}</div>
        <div className={styles.stateAndRating}>
          <div>{seller.address}</div>
          {seller.averageRating !== 0 && (
            <div className={styles.rating}>
              {seller.averageRating}
              <StarIcon className={styles.starIcon} />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );

  return (
    <Grid className={styles.wrapper}>
      <div className={styles.productImage}>{productImage}</div>
      {pricing()}
      {sellerInfo()}
    </Grid>
  );
}

export default PriceCard;
