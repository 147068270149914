import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { setSelectedFilters } from 'slices/shoppingSlice';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import styles from './breadcrumbs.module.scss';

type BreadcrumbsProps = {
  background: string;
  containerClassName?: string;
  wrapperClassName?: string;
};

function Breadcrumbs({
  background,
  containerClassName,
  wrapperClassName,
}: BreadcrumbsProps) {
  const breadcrumbs = useBreadcrumbs();
  const { listingId = '', orderId = '' } = useParams();
  const dispatch = useDispatch();

  const [removedId, setRemovedId] = useState(false);

  // for adding the category & subcategory names to redux shopping state filters
  const updateReduxShoppingState = (pathname: string) => {
    if (pathname.includes('product')) {
      const pathnameArray = pathname.split('/');
      const categoryName = pathnameArray[2].replace(/%20/g, ' ');
      // if the array length is 3, that means this is the category breadcrumb
      if (pathnameArray.length === 3) {
        dispatch(
          setSelectedFilters({
            category: [categoryName],
          }),
        );
      } else if (pathnameArray.length === 4) {
        // if the array length is 4, that means this is the subCategory breadcrumb
        const subCategoryName = pathnameArray[3].replace(/%20/g, ' ');
        dispatch(
          setSelectedFilters({
            category: [categoryName],
            subcategory: [subCategoryName],
          }),
        );
      }
    }
  };

  const navigateTo = (pathname: string) => {
    if (pathname.includes('product')) {
      const pathnameArray = pathname.split('/');

      // if the array length is 3, that means this is the category breadcrumb
      if (pathnameArray.length === 3) {
        const categoryName = pathnameArray[2].replace(/%20/g, ' ');

        return `/shopping/${categoryName}`;
      }

      // if the array length is 4, that means this is the subCategory breadcrumb
      if (pathnameArray.length === 4) {
        const categoryName = pathnameArray[2].replace(/%20/g, ' ');
        const subCategoryName = pathnameArray[3].replace(/%20/g, ' ');

        return `/shopping/${categoryName}/${subCategoryName}`;
      }

      return pathname;
    }

    return pathname;
  };

  // hide the breadcrumbs on the stripe redirect screen
  const stripeRedirectScreen = breadcrumbs.find(
    (breadcrumb) =>
      breadcrumb?.location.pathname === '/verifying_stripe_account',
  );
  if (stripeRedirectScreen) {
    return null;
  }

  return (
    <div
      className={`${containerClassName} ${styles.container} ${styles[background]}`}
    >
      <div className={`${wrapperClassName} ${styles.wrapper}`}>
        {breadcrumbs.map(({ breadcrumb, match }, index) => {
          let name = breadcrumb;

          // need this check since breadcrumb is a ReactNode which can also be a string
          if (breadcrumb && React.isValidElement(breadcrumb)) {
            // breadcrumb.props.children is where the name is stored
            name = breadcrumb.props.children.toUpperCase().replace(/%20/g, ' '); // the URL changes spaces to %20, so change back to a space
          }

          // ids are 24 chars with no spaces, so we can check that as well
          const isId =
            typeof name === 'string' &&
            name.length === 24 &&
            name.split(' ').length === 1;

          // don't show ids, 'Product' (for PDP), '404' (bad route), or 'Shopping' as breadcrumbs
          if (
            name === listingId.toUpperCase() ||
            name === orderId.toUpperCase() ||
            isId ||
            name === 'PRODUCT' ||
            name === '404' ||
            name === 'SHOPPING'
          ) {
            if (!removedId) setRemovedId(true);
            return null;
          }

          let colorClassName = styles.inactive;
          const isActive = index === breadcrumbs.length - (removedId ? 2 : 1);

          if (name === 'ORDERS' || name === 'SELLING') {
            colorClassName = styles.important;
          } else if (isActive) {
            colorClassName = styles.active;
          }

          const navigateUrl = navigateTo(match.pathname);

          return (
            <Link
              key={`${name}`}
              to={navigateUrl}
              onClick={() => {
                updateReduxShoppingState(match.pathname);
              }}
              className={`${styles.breadcrumb} ${colorClassName}`}
            >
              {name}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Breadcrumbs;
